import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter,useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { getTranslatedText } from "../../common/utilities";
import {
    getLanguageTranslation,
    setSelectedLanguage,
  } from "../../dashboard/actionMethods/actionMethods";

import {getPolicyList,removePolicy,getPolicyInfo} from '../actionMethods/actionMethods';  
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";
import '../styles/policyManagement.scss';
import AddIcon from "../../assets/images/add-icon.PNG";
import DeleteIcon  from '../../assets/images/delete.png';
import ZoomIcon  from '../../assets/images/eye.png';
import EditIcon  from '../../assets/images/pencil.png';
import Modal from 'react-modal'
import PolicyCompliance from "./policyCompliance";
import CreatePolicy from "./createPolicy";
import UpdatePolicy from "./updatePolicy";
import spinnerLoader from "../../assets/images/loader.svg"
import FailIcon from '../../assets/images/failed.png'
import DeviceStatus from "../../components/DeviceStatus";
import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'


const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        height: '14rem',
        width:'540px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
  }


function PolicyManagement(props) {
  let history=useHistory();
    let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();

    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const [selectedDate, updateSelectedDate] = useState(date);
    const [dates, setDates] = useState({
      start: moment().subtract(29, "days").toDate(),
      end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
  const [deletPolicy, updatedeletPolicy] = useState(false);
  const [createPolicyModal, updatecreatePolicyModal] = useState(false);
  const [cofirmdeletPolicy, updatecofirmdeletPolicy] = useState(false);
  const [currentDelete,updateCurrentDelete]=useState('');
  const [policyname,updatepolicyname]=useState('')
  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [PolicyList, updatePolicyList] = useState([]); 
  const [countActive,updateCountActive]=useState(0);
  const [countInActive,updateCountInActive]=useState(0);
  const [countBreached,updateCountBreached]=useState(0);
  const [ActivePolicyData, updateActivePolicyData]=useState([]);
  const [InActivePolicyData, updateInActivePolicyData]=useState([]);
  const [BreachedPlicyData,UpdateBreachedPlicyData]=useState([])
  const [CurrentTabPolicy,SetCurrentTabPolicy]=useState("Active")
  const [loaderLeft,SetLoaderleft]=useState(true);
  const [updatePolicyModal,SetUpdatePolicyModal]=useState(false);
  const [policy_id,Update_policy_id]=useState('');
  const [PolicyDetail,UpdatePolcyDetail]=useState([]);
  const [ErrorPolicy,SetErrorPolicy]=useState(false);
  const [loaderEdit,SetLoaderEdit]=useState(false);
  const[oldStartDate,SetOlStartdDate]=useState('');
  const[oldEndDate,SetOldEndDate]=useState('');
  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

   
  setTimeout(function(){
      SetLoaderleft(false);
  },2000)


    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
      let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.page_name=="Policy Compliance"); 
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);

  
  function PolicyComplianceDateChange(dates){
    setDates(dates);
  }

    useEffect(() => {
      if(permission && permission.length > 0){
      SetLoaderleft(true);
      let requestBody=[];
      requestBody.start_date=getDateFormat(dates.start);
      requestBody.end_date=getDateFormat(dates.end);

      getPolicyList(requestBody,userSession, org_id,sub_org_id).then(res => {
          if (res.status === 200) {
            updatePolicyList(res.data);
            SetLoaderleft(false);
              
          }
          else if(res.status === 302){
            SetLoaderleft(false);
 
          }
      });
    }
      }, [dates,permission]);

     
      function reloadData(){
        if(permission && permission.length > 0){
        SetLoaderleft(true);
        let requestBody=[];
        requestBody.start_date=getDateFormat(dates.start);
        requestBody.end_date=getDateFormat(dates.end);

        getPolicyList(requestBody,userSession, org_id,sub_org_id).then(res => {
          if (res.status === 200) {
            updatePolicyList(res.data);
            SetLoaderleft(false);
          }
          else if(res.status === 302){
            SetLoaderleft(false);
 
          }
      });
    }
      }
      useEffect(()=>{
        if(permission && permission.length > 0){
        let Active=0;
        let Inactive=0;
        let BreachCount=0;
        let ActivePolicyArray=[];
        let InActivePolicyArray=[];
        let BreachedPolicyArray=[]
        for(let i=0;i<PolicyList.length;i++){
          if(PolicyList[i].total_breached > 0){
            BreachedPolicyArray.push(PolicyList[i]);
            BreachCount++;
          }

          if(PolicyList[i].status=='active'){
            Active++
            ActivePolicyArray.push(PolicyList[i]);
          }else if(PolicyList[i].status=='inactive'){
            Inactive++
            InActivePolicyArray.push(PolicyList[i]);
          }else{
            BreachCount++
            BreachedPolicyArray.push(PolicyList[i])
          }
          }
          updateCountInActive(Inactive);
          updateCountActive(Active);
          updateCountBreached(BreachCount);
        
          UpdateBreachedPlicyData(BreachedPolicyArray)
          updateActivePolicyData(ActivePolicyArray)
          updateInActivePolicyData(InActivePolicyArray)
        }
      },[PolicyList,permission]);
      useEffect(()=>{

      },[ActivePolicyData]);

      
      useEffect(() => {
        if (props.language) {
          updateSelectedLangValue(props.language);
        }
      }, [props.language]);

      function changeLanguage(lang) {
        getLanguageTranslation(lang).then((res) => {
          if (res && res.status >= 200 && res.status <= 200) {
            localStorage.setItem("languageData", JSON.stringify(res.data));
            localStorage.setItem("selectedLanguage", lang);
            props.setSelectedLanguage(lang);
          }
        });
      }
    function handleManpowerManagementList() {
        props.history.push("/policy-management");
    }
    function ActivePolicyDataSHow(){
      
    }

    function handleDateSelect(date) {
        updateSelectedDate(date);

        setDates({
          start: dates.start,
          end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        })
    }

    const CurrentPolicy =[
        {name:"Cafeteria",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'cafeteria'},
        {name:"Smoking Area",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'smokingarea'},
        {name:"Board Room",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#bfbfbf",slug:'boardroom'},
        {name:"Board Room 2-2",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'boardroom2-2'},
        {name:"Ground Floor",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'groundfloor'},
        {name:"Canteen",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#bfbfbf",slug:'canteen'},    
        {name:"Canteen 2",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'canteen2'},    
        {name:"Canteen 3",capacity:20,capacitycolor:'#ff0000',acess:"Yes",acesscolor:"#00b050",slug:'canteen3'},    
    ]
    function DetailPageHandler(id){
      history.push(`/policy-detail/:${id}`);
    }
    function EditHandler(id){

      Update_policy_id(id);
      SetLoaderEdit(true);
      getPolicyInfo(userSession, org_id,id).then(res => {
        if (res.status === 200) {
       
          UpdatePolcyDetail(res.data[0]);   

          SetUpdatePolicyModal(true)
          setTimeout(function(){
            SetLoaderEdit(false);
          },1000)
          
      }else{
      
      }
    });
    }

    function DeleteHandler(element){
        updatepolicyname(element.policy_name)
        updateCurrentDelete(element.id);
        updatedeletPolicy(true);
        SetUpdatePolicyModal(false)
    }
    function OpenErrorPolicy(){
      SetErrorPolicy(true)
    }
    function CloseErrorPolicy(){
      SetErrorPolicy(false)
    }
    function confirmDelete(){
      if(currentDelete >0){
        SetLoaderleft(true);  
      removePolicy(userSession, org_id,currentDelete).then(res => {
          if (res.status === 200) {
            updatedeletPolicy(false) ;       
            updateCurrentDelete(''); 
          }
      });
      let requestBody=[];
      requestBody.start_date=getDateFormat(dates.start);
      requestBody.end_date=getDateFormat(dates.end);
      getPolicyList(requestBody,userSession, org_id,sub_org_id).then(res => {
          if (res.status === 200) {
            updatePolicyList(res.data);
            SetLoaderleft(false);
          }
      });
      updatecofirmdeletPolicy(false)
      updatecreatePolicyModal(false);
      updatedeletPolicy(false)
      SetLoaderleft(false);  
  }else{
    updatecofirmdeletPolicy(false)
      updatecreatePolicyModal(false);
      updatedeletPolicy(false)

  }

      
      
    }  


  useEffect(()=>{

  },[])  
    function closeMarkModal(){
        updatedeletPolicy(false)
        SetUpdatePolicyModal(false)
        updatecofirmdeletPolicy(false)
        updatecreatePolicyModal(false);
     

    }

    

    const generateKey = (pre) => {
      return `${ pre }_${ new Date().getTime() }`;
  }

  function showPolicies(status){
    let CurrentPolicy=[];

    if(status=='Active'){
      CurrentPolicy=ActivePolicyData
    }
    if(status=='InActive'){
      CurrentPolicy=InActivePolicyData;
    }
    if(status=='Breached'){
      CurrentPolicy=BreachedPlicyData;
    }
 
      let arr=[];

      if(CurrentPolicy.length >0){
      for(let i=0;i<CurrentPolicy.length;i++){
          let element= CurrentPolicy[i];
          arr.push(
              <div className="eachRecommendCardDiv eachRecommendCardDivOverflow" key={generateKey(i)}>
                  <Row className="mg-bottom-20">
                      <Col lg={7}>
                          <h4 className="fnt-20">{element.policy_name}</h4>
                      </Col>
                      <Col lg={5}>
                          <div className="icon-click">
                              <div className="zoom cursor-pt" onClick={()=>DetailPageHandler(element.id)}><img src={ZoomIcon}/></div>
                              {permission.edit=="True"?<div className="edit cursor-pt" onClick={()=>EditHandler(element.id)}><img src={EditIcon}/></div>:<div className="edit cursor-pt disable hover-message"><img src={EditIcon}/><span class="descHelp tootltipHelp" style={{width:"150px",right:"0px",left:"auto"}}>No permission</span></div>}
                              {permission.remove=="True"?<div className="delete cursor-pt" onClick={()=>DeleteHandler(element)}><img src={DeleteIcon}/></div>:<div className="delete cursor-pt disable hover-message"><img src={DeleteIcon}/><span class="descHelp tootltipHelp" style={{width:"150px",right:"0px",left:"auto"}}>No permission</span></div>}
                          </div>
                      </Col>
                  </Row>
                  <Row className="mg-bottom-10">
                      <Col lg={2} className="cols-1-list cols" ><div className="bg-highlight" style={{backgroundColor:element.capacity_status=='true'?element.total_capacity_breach>0?'#ef5e8c':"#00b050":"#bfbfbf"}}></div></Col>
                      <Col lg={7} className="cols-2-list cols">Capacity</Col>
                      <Col lg={3} className="cols-3-list cols">{element.capacity_status=='true'?"Yes":"No"}</Col>
                  </Row>
                  <Row>
                      <Col lg={2} className="cols-1-list cols"><div className="bg-highlight" style={{backgroundColor:element.accessibility_status=='true'?element.total_accessibility_breach>0?"#ef5e8c":"#00b050":"#bfbfbf"}}></div></Col>
                      <Col lg={7} className="cols-2-list cols">Access</Col>
                      <Col lg={3} className="cols-3-list cols">{element.accessibility_status=='true'?'Yes':'No'}</Col>
                  </Row>
                  <Row className="last-updated-on">
                      <Col lg={6} className="cols-1-list cols">{element.last_updated?"Updated On":"Created On"}</Col>
                      <Col lg={6} className="cols-3-list cols">{element.last_updated?moment(element.last_updated).format("DD MMM YYYY"):moment(element.created_on).format("DD MMM YYYY")}  
                      {/* <Col lg={6} className="cols-3-list cols">{element.last_updated?element.last_updated:element.created_on}   */}
                    </Col>
                  </Row>
              </div>
          )
      }
    }else{
      arr.push(
        <div className="eachRecommendCardDiv eachRecommendCardDivOverflow" key={generateKey()}>
            <Row className="mg-bottom-20">
                <Col lg={12}>
                    <h4 className="fnt-20 no-policy-avail">No Policy Available</h4>
                </Col>
            </Row>
        </div> )
    }

      return arr;
  }
 

    function OpenCreateModal(){
      updatecreatePolicyModal(true);
    }
    function getDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    }
    return(
<>
                   {permissionAPICall?permission.length > 0? <div className="manpowerManagementMainDiv siteViewMainDiv ">
              <div className="employeeDetailsMainDiv">
                <Container className="header-breadcrumb">
                  <Row style={{zIndex:99999,position:'relative'}}>
                    <Col lg={3} className="align-breadcrumb-left m-t-sm">
                      <div className="siteViewHeaderDiv">
                        <span className="mediumHeader">
                          {getTranslatedText("Policies Management")}
                        </span>
                      </div>
                    </Col>
      
                    <Col lg={9} className="text-right align-breadcrumb-right">
                    <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
                    </Col>
                  </Row>
                  </Container>
                  <Container>
                  
                    <Row className="m-t">
                      <Col lg={4}>
                    <div className="siteViewDetailsLeftSideDiv ht-100">
                        <div className="flexDiv">
                            <div>
                                {/* <div className="headerNameDiv">Current policies</div> */}
                                <h5 className="font-bold clr-white">Current policies</h5>
                            </div>
                        </div>
                        <div className="flexDiv">
                            <div className="tab-view">
                                <div className={CurrentTabPolicy=="Active"?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt "} onClick={()=>SetCurrentTabPolicy('Active')} ><span className="count">{countActive}</span>Actives</div>
                                <div className={CurrentTabPolicy=="Breached"?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt "} onClick={()=>SetCurrentTabPolicy('Breached')}><span className="count">{countBreached}</span>Breached</div>
                                <div className={CurrentTabPolicy=="InActive"?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt "} onClick={()=>SetCurrentTabPolicy('InActive')}><span className="count">{countInActive}</span>Inactive</div>
                            </div>
                        </div>
                        {permission.Create=="True"?<div className="create-new-button cursor-pt" onClick={OpenCreateModal}>
                            Create New <img src={AddIcon} className="float-end"/>
                        </div>:<div className="create-new-button disable hover-message" >
                            Create New <img src={AddIcon} className="float-end"/>
                            <span class="descHelp tootltipHelp">No permission</span>
                        </div>}
                        <div className={loaderLeft?"recommendMainDiv loading":"recommendMainDiv"}>
                             <div className="recommendListMainDiv m-t-md text-white">
                                 <Scrollbars  autoHide style={{ width: '100%', height: window.innerHeight - 300  }} 
                                 className="scroll-slight-right" 
                                 renderTrackHorizontal={props => <div {...props} className="track-horizontal2"/>}
                                 renderTrackVertical={props => <div {...props} className="track-vertical2"/>}
                                 renderThumbHorizontal={props => <div {...props} className="thumb-horizontal2"/>}
                                 renderThumbVertical={props => <div {...props} className="thumb-vertical2"/>}
                                 renderView={props => <div {...props} className="view"/>}
                                  >
                                    <div className="loaderDiv">
                                      <img src={spinnerLoader}/>
                                    </div>
                                    {CurrentTabPolicy=="Active"?showPolicies('Active'):""}
                                    {CurrentTabPolicy=="Breached"?showPolicies('Breached'):""}
                                    {CurrentTabPolicy=="InActive"?showPolicies('InActive'):""}
                                   
                                    </Scrollbars>
                            </div>
                        </div>





                        </div>
                        </Col>
                        <Col lg={8}>
                        <PolicyCompliance permission={permission} date={selectedDate} PolicyComplianceDateChange={PolicyComplianceDateChange} />
                        </Col>
                    </Row>
                </Container>
{/* 
                <Modal
                isOpen={deletPolicy}
                style={customStyle}
                className="delete-policy-modal"
            >
                <div className='delete-policy-conatiner'>
                   
                    <div className='logoutTitle'>Delete Policy </div>
                        <div className="Policy-name">{policyname}</div>
                    <div className='actionButton'>
                        <span className='btnText okBtn' onClick={confirmDelete}>Delete </span>
                        <span className='btnText text-design' onClick={closeMarkModal} >Cancel</span>
                    </div>
                </div>
            </Modal> */}


              <Modal
                isOpen={deletPolicy}
                style={customStyle}
            >
                <div className='logoutModal MarkforPositive'>
                   
                    <div className='logoutTitle'>Are you sure you want to delete the <br/> policy {policyname}</div>

                    <div className='actionButton'>
                        <span className='btnText okBtn' onClick={confirmDelete}>Yes, sure delete </span>
                        <span className='btnText text-design' onClick={closeMarkModal} >No, Go Back</span>
                    </div>
                </div>
            </Modal>
            
            <Modal
                isOpen={createPolicyModal}
                style={customStyle}
                className="create-policy-modal"
                shouldCloseOnOverlayClick={true}
            >
                    {/* <div className="closer-modal" onClick={closeMarkModal}>x</div> */}
                           
                           
              <CreatePolicy closeMarkModal={closeMarkModal} reloadData={reloadData} OpenErrorPolicy={OpenErrorPolicy} CloseErrorPolicy={CloseErrorPolicy} />
              
          </Modal>
          <Modal
                isOpen={updatePolicyModal}
                style={customStyle}
                className={loaderEdit?"create-policy-modal loading":"create-policy-modal"}
                shouldCloseOnOverlayClick={true}
            >
                    {/* <div className="closer-modal" onClick={closeMarkModal}>x</div> */}
                           
                    <UpdatePolicy closeMarkModal={closeMarkModal} reloadData={reloadData} policy_id={policy_id} policy_data={PolicyDetail} />
              
          </Modal>

          <Modal
                isOpen={ErrorPolicy}
                style={customStyle}
                className="alert-sucess alert-fail-policy"
                key={generateKey("PolicyFail")}
            >
                <div className='logoutModal MarkforPositive'>
                    <div className="sucess-inner">
                        <img src={FailIcon}/>
                    </div>
                    <div className='logoutTitle'>Policy Atleast one feature is mandatory </div>
                    <div className='actionButton'>
                        <span className='btnText okBtn bnt-close-bigger' onClick={CloseErrorPolicy}>Close </span>
                    </div>
                </div>
            </Modal>

            </div>
        </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}</>

    )
}

export default PolicyManagement