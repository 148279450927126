import React, { useEffect, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

function Item(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.id,
    disabled: props.disabled
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  const Element = props.as || "div";

  return (
    <Element
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...props}
    >
      {props.children}
    </Element>
  );
}

function Wrapper({
  ids,
  items,
  onSort,
  children,
  renderActive,
  wrapperElement
}) {


  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over) {
      
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      const sortedIds = arrayMove(ids, oldIndex, newIndex);
      
      onSort(sortedIds);
      setActiveId(null);
    }
  }

  const activeItem = renderActive(
    items[activeId] || {},
    activeId,
    ids.indexOf(activeId)
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={ids} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
      <DragOverlay wrapperElement={wrapperElement} className="dragging-element">
        {activeId ? activeItem : null}
      </DragOverlay>
    </DndContext>
  );
}

Wrapper.Item = Item;

export default Wrapper;
