import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import Modal from 'react-modal'
import SucessIcon from "../../assets/images/success.png"
const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        height: '14rem',
        width:'540px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
  }


  function ALertSucess(props){
   
    function HandlerCloseAlert(){
   
    }
return(
    <Modal
    isOpen={props.Alert===true?true:false}
    style={customStyle}
>
    <div className='logoutModal MarkforPositive'>
        <div className="sucess-inner">
            <img src={SucessIcon}/>
        </div>
        <div className='logoutTitle'>Message is send Sucessfully </div>
        <div className='actionButton'>
            <span className='btnText okBtn bnt-close-bigger' onClick={()=>HandlerCloseAlert}>Close </span>
        </div>
    </div>
</Modal>
)
  }
  export default ALertSucess;