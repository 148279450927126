
import React,{useEffect, useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment';

import rightIcon from '../styles/images/right-arrow.png'
import LeftIcon from '../styles/images/left-arrow.png'

import {
    getEmployeeList,
  } from "../../manPowerManagement/actionMethods/actionMethods";

import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';  
import ReactModal from 'react-modal';
import { Row,Col } from 'react-bootstrap';
import { useHistory, withRouter } from "react-router-dom";
/*
const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ffa500"
}*/

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696" 
}
let reload =0;
let changeProps=0;
const BarChart = (props) => {
    
   
    const [typeChart,SetTypeChart] = useState(props.yAxisTitle);
    const [currentDate,setCurrentDate]=useState();
    const [barData,SetBarData]=useState(false);
    const [BarAPIDataSpread,SetBarApiDataSpread]=useState([]);
    const[topFiveData,SetTopfiveData]=useState([]);
    const [seriesActive,SetSetSeriesActive]=useState('');
    const [target,SetTarget]=useState('');
    let history = useHistory();
    
    const [BarAPIDataMovement,SetBarApiDataMovement]=useState([]);
    const [BarAPIDataArea,SetBarApiDataArea]=useState([]);
    const [chartOption,SetChartOption] = useState({})
    const [useInc,SetUseInc]= useState(0)
    const [chartDate,SetChartDate]= useState("")
    const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });
  const [type,SetType]= useState('')
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6
    

      const headerRow2Disp = [
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
    "00 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
  ];




  useEffect(()=>{
    if(props.chartData != chartData){
        setChartData(props.chartData?props.chartData:{categories: [],series: []});
    }
    if(props.type!=type){
SetType(props.type?props.type:'')
    }
    if(props.chartDate!= chartDate){
        SetChartDate(props.chartDate?props.chartDate:'')
    }
    
    changeProps++;
   
  },[props])
    useEffect(()=>{
      reload++;
    let config = {
        chart: {
          type: "spline",
          defaultSeriesType: "spline",
          height: 350,
          events: {},
          lang: {
            noData: "Awaiting for data",
          },
          zoomType: "x",
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: chartData.categories,
          labels: {
            useHTML: true,
            formatter: function () {
              if (type== "week" || type== "month") {
                return this.value;
              } else {
                if (this.value == "07 AM") {
                  return `<label class="smallLabel">${
                    this.value
                  }</label><span class="smallTitleDate">${
                    type== "today"
                      ? moment(chartDate).format("DD MMM")
                      : moment(chartDate).format("DD MMM")
                  }`;
                } else if (this.value == "00 AM") {
                  return `<label class="smallLabel">${
                    this.value
                  }</label><span class="smallTitleDate">${
                   props. type == "today"
                      ? moment(chartDate).add(1, "days").format("DD MMM")
                      : moment(chartDate).add(1, "days").format("DD MMM")
                  }`;
                } else {
                  return `<label class="smallLabel">${this.value}</label>`;
                }
              }
            },
          },
          title: {
            text: "",
          },
          tickPositioner: function () {
            let positions = [];
            let tick = Math.floor(this.dataMin);
            if (type== "week" || type== "month") {
               positions = [0, 2, 5, 8, 11, 14, 17, 20, 23];  
            }else{
              positions = [0, 2, 5, 8, 11, 14, 17, 20, 23];  
            }
            return positions;
          },
        },
        yAxis: {
          allowDecimals: false,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          min: 0,
          tickAmount: 5,
          title: {
            text: "",
          },
          gridLineColor: "#fff",
          // min:pollutant=='temp'?'-10':0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          formatter: function() {
            var strTooltip = "";
              strTooltip =  this.x
              + '<br/><b>'+this.points[0].key+"</b>:   "+ this.points[0].y 
              + '<br/><b>'+this.points[1].key+"</b>:  "+ this.points[1].y
              + '<br/><b>'+this.points[2].key+"</b>:  "+ this.points[2].y
               + '<br/><b>Magic Ratio</b>: '+ this.points[1].point.magicRatio+"%" ;
                // return strTooltip;
                return strTooltip ;

          }
        },
        credits: {
          enabled: false,
        },
        legend: { enabled: props.showLegend?true:false },
        plotOptions: {
          series: {
            animation: {
              duration: 3000,
            },
            borderWidth: 1,

            marker: {
              enabled: true,
              radius: 2,
            },
            connectNulls: true,
          },
        },
            series: chartData.series
           }
           SetChartOption(config);
          
           SetUseInc(reload)
        
    },[chartData,chartDate,type])





const styles = {
    rect: {
        fill: "#000 !important",
        color: "#000 !important",
    },
    
  }

    return (
        <div className='chartCHanges'>
            <ReactHighcharts  config={chartOption} isPureConfig={true}  style={styles} containerProps={{ className: props.className }} />


        </div>
    )
}

export default BarChart