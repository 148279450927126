import axios from "axios";


let prefixURL = process.env.REACT_APP_URL;
let prefixURL_V2 = process.env.REACT_APP_URL_V2;
let prefixURL2 = process.env.REACT_APP_URL_LOGIN;

export function getPerformanceTodayData(requestBody , sessionID, id,sub_org_id) {

    return axios.get(prefixURL + `/get_productivity_list_hourly?session=${sessionID}&start_date=${requestBody.start_date}&org_id=${id}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}&end_date=${requestBody.end_date}`)
        .then(res => res.data).catch(err => err)
}

export function getCycleCountData(requestBody , sessionID, id,sub_org_id) {

    return axios.get(prefixURL + `/get_cycle_count_dashboard_report?session=${sessionID}&start_date=${requestBody.start_date}&org_id=${id}&end_date=${requestBody.end_date}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getDashboardData(requestBody , sessionID, id) {

    return axios.get(prefixURL + `/get_dashboard_data?session=${sessionID}&date=${requestBody.date}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}


export function getThreatWatchData(requestBody, sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL + `/get_threat_watch?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&rank=${requestBody.contactRank}&start_date=${requestBody.startDate}&end_date=${requestBody.endDate}&sensitivity=${requestBody.sensitivity}`)
        .then(res => res.data).catch(err => err)
}


export function getLanguageTranslation(langCode, userSession) {
    
    return axios.get(prefixURL + `/get_lang_pack?session=${userSession}&lang_code=${langCode}`)
        .then(res => res.data).catch(err => err)
}

export function getDeviceStatus( userSession,org_id,sub_org_id) {
    
    return axios.get(prefixURL + `/get_tag_overview?session=${userSession}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}



export function setSelectedLanguage(langauge) {


    return dispatch => {
        dispatch({
            type: 'selectedLangauge',
            data:langauge
        })
    }
}

export const getChartData = async (obj , sessionID, org_id,sub_org_id) => {
    try {
        let res = await axios.get(`${prefixURL}/get_index_data?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&index_name=${obj.index}&start_date=${obj.start}&end_date=${obj.end}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const getIndexLevel = async (sessionID, orgId, date) => {
    try {
        let res = await axios.get(`${prefixURL}/get_indexes?session=${sessionID}&org_id=${orgId}&date=${date}`)
        return res.data
    } catch (err) {
        return err
    }
}
export const getFacilityWidget = async (sessionID, orgId, sub_org_id) => {
    try {
        let res = await axios.get(`${prefixURL}/facility_count?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}
export const getFacilityCounter = async (sessionID, orgId, sub_org_id,start_date,end_date) => {
    try {
        let res = await axios.get(`${prefixURL}/facility_count_report?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        return res.data
    } catch (err) {
        return err
    }
}
//{{url}}/api/v1/facility_count_report_filter?session={{session}}&org_id=30&sub_org_id=1&start_date=2023-09-18&end_date=2023-09-18&department=54&sub_department=145

export const getFacilityCounterReport = async (sessionID, orgId, sub_org_id,start_date,end_date,deptID,subDept=0) => {
    try {
        let res = await axios.get(`${prefixURL}/facility_count_report_filter?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&department=[${deptID}]&sub_department=[${subDept}]`)
        return res.data
    } catch (err) {
        return err
    }
}

// https://api.traceplus.ai/traceadmin/api/v1/employee/get_employees_attendance_org_live_report?session=trace123456789&org_id=32&sub_org_id=0
export const  getLiveReportEmployee = async (sessionID, orgId, sub_org_id,start_date,end_date,deptID,subDept=0) => {
    try {
        let res = await axios.get(`${prefixURL}/employee/get_employees_attendance_org_live_report?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const  getFacilityCounterAttendance = async (sessionID, orgId, sub_org_id,date) => {
    try {
        let res = await axios.get(`${prefixURL}/get_facility_plan?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&date=${date}`)
        return res.data
    } catch (err) {
        return err
    }
}

// get_function_cyclecount?session=trace123456789&org_id=32&sub_org_id=0&start_date=2024-03-25&end_date=2024-03-25
export const  getFunctionCycleCountPlan = async (sessionID, orgId, sub_org_id,start_date,end_date) => {
    try {
        let res = await axios.get(`${prefixURL}/get_function_cyclecount?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const  getFunctionList = async (sessionID, orgId, sub_org_id) => {
    try {
        let res = await axios.get(`${prefixURL}/onboarding/get_function_list?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}
export const  getDashboardPerformanceWidget = async (sessionID, orgId, sub_org_id,start_date,end_date) => {
    try {
        let res = await axios.get(`${prefixURL}/dashboard/performance_widget?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        return res.data
    } catch (err) {
        return err
    }
}

export const  getProcessCycleCountPlan = async (sessionID, orgId, sub_org_id,start_date,end_date,version =1) => {
    try {
        if(version == 1 ){
            let res = await axios.get(`${prefixURL}/get_cycle_count_process?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
            return res.data
        }else{
            let res = await axios.get(`${prefixURL_V2}/get_cycle_count_process?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
            return res.data
        }
        
    } catch (err) {
        return err
    }
}