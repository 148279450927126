import axios from "axios";


let prefixURL = process.env.REACT_APP_URL
let prefixURL2 = process.env.REACT_APP_URL_LOGIN

export function getShiftCodeList(sessionID, id,sub_org_id) {

    return axios.get(prefixURL2 + `/employee/get_shift_code_list?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function updateShiftCodeList(requestBody) {

    return axios.post(prefixURL2 + `/employee/edit_shift_code`,requestBody)
        .then(res => res.data).catch(err => err)
}

