import React, { useState, useEffect,useRef } from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


import Barchart from './barChart'
import '../../styles/siteManagement.scss'
import DashboardLanguage from '../../../components/dashboardLanguage';
import { getSiteOverview, getSiteFootFall, getSiteAreaIndex, footfallChart,getLocationEmployee } from '../../actionMethods/actionMethods';

import spinnerLoader from '../../../assets/images/loader.svg'
import CommonDatePicker from '../../../common/commonDatePicker';
import { getTranslatedText } from '../../../common/utilities';
import ClockIcon from '../../../dashboard/styles/images/clock.png'

import { getLanguageTranslation, setSelectedLanguage } from '../../../dashboard/actionMethods/actionMethods';
import { tsAnyKeyword } from '@babel/types';
import { Scrollbars } from "react-custom-scrollbars";
import DeviceStatus from '../../../components/DeviceStatus';
import helpIcon from  "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import { getPolicyOverview } from '../../../policyManagement/actionMethods/actionMethods';
import {ReactComponent as Image404 } from  '../../../assets/images/11132-ai.svg'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';     

import DatePicker from "react-datepicker";
import { ToastContainer,toast } from 'react-toastify';
const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}

let timeArr = [
    '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'
]

let timeArr1 = [
  '7 Hr','8 Hr','9 Hr','10 Hr','11 Hr','12 Hr','13 Hr','14 Hr','15 Hr','16 Hr','17 Hr','18 Hr','19 Hr','20 Hr','21 Hr','22 Hr','23 Hr','0 Hr','1 Hr','2 Hr','3 Hr','4 Hr','5 Hr','6 Hr'
]

let timeArr2 = [
     '07 AM', '08 AM', '09 AM', '10 AM', '11 AM', '12 PM',
    '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM',
    '12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM',
]

function SiteViewDetails(props) {
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    let StartDate= localStorage.getItem('ProductiveStart')?new Date(localStorage.getItem('ProductiveStart')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : date  
    let EndDate= localStorage.getItem('ProductiveEnd')?new Date(localStorage.getItem('ProductiveEnd')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : date  
    const [dates, setDates] = useState({
        start: new Date(moment(StartDate).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(EndDate).format('YYYY-MM-DD 23:59:59')),
      });
  
    const [siteViewData, updateSiteViewData] = useState('')
    const [prevSiteData, updatePrevSiteData] = useState('')

    const [footFallValue, updateFootFallValue] = useState(0)

    const [footFallData, updateFootFallData] = useState('')

    const [selectedFootfallType, updateFootfallType] = useState('day')

    const [selectedLangValue, updateSelectedLangValue] = useState('en')

    const [locationID, updateLocationID] = useState('')

    const [selectedDate, updateSelectedDate] = useState(date)
    const [chartData, setChartData] = useState({ categories: [], series: [], top4: [] })
    const [chartLoader, setChartLoader] = useState(true)
    const [TheoricMax,UpdateTheoricMax]=useState();
    const [crowdedArea,UpdatecrowdedArea]=useState('');
    
    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
    
    const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
    const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
    
    const [sortKeyunproductive, setSortKeyunproductive] = useState('unproductive')
    const [sortTypeunproductive, setSortTypeunproductive] = useState('desc')
      
    const [selectedData,SetSelectedDate]=useState([]);
    const [selectedSeries,SetSelectedSeries]=useState('');

    const [sortKeyacess, setSortKeyacess] = useState('acess')
    const [sortTypeacess, setSortTypeacess] = useState('desc')
    const [locationVisitedData,SetLocationVisitedData]=useState([])
    const [ShowSelectedData,SetSHowSelectedData] = useState([]);
    const [SeriesName,SetSeriesName] = useState([]);
    const [initialLoader ,SetinitialLoader ] = useState(false);
    const [visitedBy,SetVisitedBy]=useState([]);
    const [policyBreach,SetPolicyBreach] =useState([])
    const [unproductiveVisitedData,SetUnproductiveVisitedData]=useState([])

    const [acessData,SetAcessData]=useState([])

    const [widthContainer, setWidthContainer] = useState(0);
    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);

  
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
     let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    

    let userSession = userDetails ? userDetails.session : '123456789'

    let org_id = userDetails ? userDetails.org_id : 6
    let idValue = props.match.params.id.replace(":", "")

    useEffect(() => {
      let perm= props.role.web_app_sitemanagement?JSON.parse(props.role.web_app_sitemanagement):null;
      if(perm){
  

          // perm.View = 'False'
          // perm.Create = 'False'
          // perm.remove = 'False'
          // perm.edit = 'False'
          SetPermission(perm)
          SetPermissionAPICall(true)
      }else{
        toast.error("TW:36 Something went wrong while fetching role on site management detail");
      }
  },[props.role,idValue]);
    function handleSiteListClick() {
        props.history.push('/site-list')
    }
function SetDataDisplay(name,data){
  SetSeriesName(name);
  SetSHowSelectedData(data);
}
    
    const setPrevSiteData = (date) => {
      if(permission && permission.length > 0){

        let idVal = props.match.params.id.replace(":", "")
        let prevReqBody = {}
        prevReqBody.date = moment(date).subtract(1, 'days').format('YYYY-MM-DD')
        prevReqBody.locationID = idVal

        getSiteOverview(prevReqBody, userSession, org_id,sub_org_id).then((res) => {
            if (res && res.data && res.data.location_details && res.data.location_details.length > 0) {
                updatePrevSiteData(res.data.location_details[0])
            }else{
              toast.error("TW:37 Something went wrong while fetching role on site overview of previous date");
            }
        })
      }
    }

    useEffect(() => {
      if(permission && permission.length > 0){

        let idVal = props.match.params.id.replace(":", "")

        if (idVal) {
            let date = getDateFormat(selectedDate)

            updateLocationID(idVal)

            setPrevSiteData(selectedDate)

            let requestBody = {}
            requestBody.date = date
            requestBody.locationID = idVal

            getSiteOverview(requestBody, userSession, org_id,sub_org_id).then(res => {
                if (res && res.data && res.data.location_details && res.data.location_details.length > 0) {
                    updateSiteViewData(res.data.location_details[0])
                    let aMaxCap= res.data.location_details[0].theroic_max_capacity;
                    let bMaxCap= res.data.location_details[0].capacity;
                    if (aMaxCap >bMaxCap ){
                        UpdateTheoricMax(bMaxCap);
                    }else{
                        UpdateTheoricMax(aMaxCap);
                    }

                    PolicyOverview();

                }else{
                  toast.error("TW:38 Something went wrong while fetching role on site overview ");
                }
            })


            requestBody.tag_serial=idVal
            requestBody.date =  getDateFormat(selectedDate)

            getLocationEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
              if(res.status==200){

                let arr=[];
                    for (let i=0;i<res.data.length;i++){
                     

                      arr.push({name:Object.keys(res.data[i])[0],time:Object.values(res.data[i])[0]});

                    }
                    

                    
                SetVisitedBy(arr);
                

              }else{
                toast.error("TW:39 Something went wrong while fetching visited location employee");
              }
            })

          }
        }

    }, [dates,permission,idValue]);


    useEffect(() => {
              if(permission && permission.length > 0){

      if(TheoricMax){
        getChartData(idValue)
      }
      
    }
    }, [TheoricMax,dates,permission,idValue])

    function PolicyOverview(){

      let requestBody=[]
      requestBody.start_date=getDateFormat(dates.start);
      requestBody.end_date=getDateFormat(dates.end);
     
      let idVal = props.match.params.id.replace(":", "")
      if(permission && permission.length > 0){

        getPolicyOverview(requestBody,userSession, org_id,'loc_team').then(res => {

          if (res.status === 200) {
                let data= res.data;
                let dataAcPush=[];
                let dataTeamName=[];
                
              Object.keys(data).forEach((key) => {
                let breach=0;
                if(data[key].loc_serial==idVal){

                
                  let accesability=data[key].accesability; 
                  let teamEMP=[];
                  Object.keys(accesability).forEach((key) => {
        
                    for ( let i=0;i< accesability[key].employee.length;i++){
                      
                      let element=accesability[key].employee[i] 
                      dataAcPush.push({team:key,name:element.name,occurence:element.count})
                      breach = breach + element.count;
                    }
                    dataTeamName.push({name:key,occurence:breach}); 
                });
              //SetLoader(false)  
              SetAcessData(dataAcPush)
              }else{
                toast.error("TW:40 Something went wrong while fetching policy overview of location");
              }
              })
            }          
        });
     }
    }

    const getBarColor = (val) => {

        
        if(parseInt(val) > parseInt(TheoricMax)){
            return riskLevelColor.high
        
        }else{
            return riskLevelColor.low
        }
    }



    const getChartData = (idVal) => {
        
        setChartLoader(true)
        setChartData({ categories: [], series: [], top4: [] })

        let d = moment(selectedDate)
        let date = getDateFormat(d)
        let requestBody={}
        requestBody.start_date=getDateFormat(dates.start);
        requestBody.end_date=getDateFormat(dates.start);
        requestBody.locationID=locationID;
        requestBody.idVal=idVal;

        if(permission && permission.length > 0){
   
        footfallChart(requestBody, userSession, org_id,sub_org_id).then((res) => {

            updateFootFallData(res.data)
            updateFootFallValue(res.day_footfall)

            let data = res.data.location_footfall
            let categories = timeArr2
            let series = []
            let top4 = []
            let  arr= []   
            let currDate = data
            let maxCount=0;
            let formatDisplayVal=[];
            let selectVal=[];
            let selectName= '';

            let cData = data
            let person=[]
            if (data) {
                let arr = [];
                for (let i = 0; i < timeArr1.length; i++) {
                    //let key = i + " Hr"
                    let key = timeArr1[i]
                    series.push({
                        y: cData[key].tag?cData[key].tag.length:cData[key].count,
                        color: getBarColor(cData[key].tag?cData[key].tag.length:cData[key].count),
                        name: timeArr2[i],
                        tooltip:cData[key].tag,
                        totalVal:cData[key].count
                    })
                    if(cData[key].tag.length > 0){

                      for(let y=0; y< cData[key].tag.length;y++){

                        if(person.indexOf(Object.keys(cData[key].tag[y])[0]) < 0 ){
                          
                           person.push(Object.keys(cData[key].tag[y])[0])
                        }


                      }
                    }

                    if(maxCount<cData[key].tag.length){
                      selectVal= cData[key].tag;
                      maxCount=cData[key].tag.length;
                      selectName=timeArr2[i];
                    }
                    

                    if(cData[key].count > parseInt(TheoricMax)){
                      arr.push(
                        <div className="ChartOverCrowded eachPeakHoursDiv" key={"Crowded"+timeArr2[i]}>
                          <img src={ClockIcon} /><span className="font-bold">{timeArr2[i]}</span>
                        </div>
                      );
                    }

 
                }

                if(selectVal.length>0){
                  for(let y=0; y< selectVal.length;y++){

                    
                      //person.push({name:Object.keys(cData[key].tag[y])[0],tag_serial:Object.values(cData[key].tag[y])[0],type:cData[key].tag[y].tag_type})
                       
                    formatDisplayVal.push( 
                  <Row style={{ alignItems: 'center' }} key={selectName+"data"+y} >
                    <Col className="b-r" style={{ maxWidth: "100%",minWidth:"100%" }} >
                        <b className="font-bold">{capitalizeFirstLetter(Object.keys(selectVal[y])[0]) }</b>
                    </Col>
                </Row>
                    )
                  }
                  SetSHowSelectedData(formatDisplayVal)
                  SetSeriesName(selectName)
                }


                
                UpdatecrowdedArea(arr);



                top4 = [...series].filter((s) => s.y > 0).sort((a, b) => (b.y - a.y)).slice(0, 4)
                

                setChartData({ categories, series, top4 })
                setChartLoader(false)
            }else{
              toast.error("TW:41 Something went wrong while fetching footfall data")
            }
        }).catch((err) => {
              toast.error("TW:42 Something went wrong while fetching footfall data")
            setChartLoader(false)
        })
      }
    }
    
    const handleDate = (date, type) => {
        setDates((prev) => ({ ...prev, [`${type}`]: date }));
        if(type=='start'){
          localStorage.setItem('ProductiveStart',date)
        }else{
          localStorage.setItem('ProductiveEnd',date)
        }

       };   


    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    function getRiskTYpe(riskIndex) {
        let risk = "";
    
        if (riskIndex <= 33) {
          risk = "LOW";
        } else if (riskIndex > 33 && riskIndex <= 66) {
          risk = "MEDIUM";
        } else if (riskIndex > 66 && riskIndex <= 100) {
          risk = "HIGH";
        } else {
          risk = "NA"
        }
    
        return risk;
      }

      function getBackgroundColorBasedOnRisk(riskIndex) {
        let risk = "";
    
        if (riskIndex <= 33) {
          risk = "lowIndexGradientColor";
        } else if (riskIndex > 33 && riskIndex <= 66) {
          risk = "mediumIndexGradientColor";
        } else if (riskIndex > 66 && riskIndex <= 100) {
          risk = "highIndexGradientColor";
        } else {
          risk = 'naIndexGradientColor'
        }
    
        return risk;
      }

    function handleDateSelect(date) {
      if(permission && permission.length > 0){
 
      updateSelectedDate(date)


        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.locationID = locationID

        //setDates((prev) => ({ ...prev, [`${type}`]: date }));
        if(date.getTime() < dates.start.getTime() ){
          setDates({
            start: date,
            end: date,
          })
          localStorage.setItem('ProductiveStart',date)
          localStorage.setItem('ProductiveEnd',date)
        }else{
             setDates((prev) => ({ ...prev, end: date }));
            localStorage.setItem('ProductiveEnd',date)

        }
        

        getSiteOverview(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res && res.data && res.data.location_details && res.data.location_details.length > 0) {
                updateSiteViewData(res.data.location_details[0])
            }else{
              toast.error("TW:43 Something went wrong while fetching site overview")
            }
        })

        setPrevSiteData(date)

        // getSiteAreaIndex(requestBody, userSession, org_id,sub_org_id).then(res => {

        // })
      }
    }

    function handleChangeFootFallType(type) {
      if(permission && permission.length > 0){
 
      updateFootfallType(type)

        let requestBody = {}
        requestBody.date = moment(selectedDate).format('YYYY-MM-DD')
        requestBody.locationID = locationID

        getSiteFootFall(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res) {
                type == 'week' ? updateFootFallValue(res.week_footfall) : updateFootFallValue(res.day_footfall)
            }else{
              toast.error("TW:44 Something went wrong while fetching site footfall")
            }
        })
      }
    }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }else{
              toast.error("TW:45 Something went wrong while fetching translation on site mangement detail")
            }
        })
    }

    const getChangePer = () => {
        let returnData = 0
        let x = prevSiteData.loc_area_index != 'na' ? prevSiteData.loc_area_index : 0
        let y = siteViewData.loc_area_index != 'na' ? siteViewData.loc_area_index : 0

        if (x) {
            returnData = parseFloat(y - x).toFixed(1);
        }
       
        return returnData
    }

    const getChangePerFormit = () => {
        let returnData = 0
        let x = prevSiteData.area_fomite_index != 'na' ? prevSiteData.area_fomite_index : 0
        let y = siteViewData.area_fomite_index != 'na' ?  siteViewData.area_fomite_index : 0
        //parseFloat(((y - x) / x) * 100).toFixed(1);
        if (x) {
            returnData = parseFloat(y - x).toFixed(1);
        }

        return returnData
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "block";
        }
      };

      const handleMouseLeave = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "none";
        }
      };

      const handleSortunproductive = (key) => {

        setSortKeyunproductive(key)
        setSortTypeunproductive(sortTypeunproductive === 'desc' ? 'asc' : 'desc')
        
        setSortKey(key)
        setSortType(sortTypeunproductive)

      }


      const handleSortlocationVisited = (key) => {
    
        setSortKeylocationVisited(key)
        setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypelocationVisited)
    
    
      }
    const handleSortAcess = (key )=>{
    
        setSortKeyacess(key)
        setSortTypeacess(sortTypeacess === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypeacess)
        
    }

    function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
        
    }

    const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
     


      let aMs = moment(valueA, 'HH:mm:ss').format('x')
      let bMs = moment(valueB, 'HH:mm:ss').format('x')
      
          if(valueA=='na' || valueB=='na')return 0;
          if (valueA == valueB) return 0;
          return (aMs > bMs) ? 1 : -1;       
   };


    function ShowCardLocation(){
        let arr2 = visitedBy;
       
     let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 35 + ((arr2.length)*35), maxHeight:450  , width: "100%"}}>
      <AgGridReact
               rowHeight={35}
              // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
              defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1,
              suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
               rowData={arr2}
               key={"AGTableAcess"}   
               overlayNoRowsTemplate={
       
                   '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                 }
  
       >
      <AgGridColumn
          field="name"
          headerName={"Visited by ("+arr2.length+")"}
         valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
         width={150}
         
      />
      <AgGridColumn
          field="time"
          headerName='Time'
         comparator={timeComparator}  
         width={70}
         sort={'desc'}
      />
      </AgGridReact>
  </div></div>


        return arr
 
    }



    function ShowCardAcess(){
        let arr2 =[];
        let data = acessData;
        arr2 = data.sort((a,b) => {
          let aMs = a.occurence
          let bMs = b.occurence
          return  sortTypeacess=='desc'? bMs - aMs  : aMs - bMs
       })
    
        // for (let index = 0; index < arr2.length; index++) {
        //     arr.push(
        //         <tr className="employee-detail-table-tr" key={"locationVisitedData"+index}>
        //           <td>{capitalizeFirstLetter(arr2[index].name)}</td>
        //           <td className="employee-detail-table-tr-td">{arr2[index].occurence}</td>
        //         </tr>
        //       );
        // }


        let widthCal=widthContainer
       let arr=<div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 250, maxHeight:250  , width: "100%"}}>
      <AgGridReact
               rowHeight={35}
              // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
              defaultColDef={{sortable: true,resizable: true,minWidth:45,
              suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
               rowData={data}
               key={"AGTableAcess"}   
               overlayNoRowsTemplate={
       
                   '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                 }
  
       >
      <AgGridColumn
          field="name"
          headerName={"Policy Breach by"}
         valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
         width={220}
         
      />
          <AgGridColumn field="occurence" headerName="Occurence" width={100}
           
          >


          </AgGridColumn>
      </AgGridReact>
  </div></div>


        return arr
    }






    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])


    if(permissionAPICall && permission.length == 0){
      return(<Col lg={12}>
        
    <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
    </Col>)
  
    }
  
    if (siteViewData) {


        return (
            <div className="siteViewMainDiv manpowerManagementMainDiv">
                <Container className='header-breadcrumb'>
                    <Row>
                        <Col lg={3} className="m-t-sm">
                            <div className="siteViewHeaderDiv">
                                <span className="smallHeader" onClick={handleSiteListClick}>{getTranslatedText('Site Management')}</span>
                                <span className="breadCrumbArrow"> &gt; </span>
                                <span className="mediumHeader">{getTranslatedText('Site View')}</span>
                            </div>
                        </Col>
                        <Col lg={9} className="text-right">
                        <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
                        </Col>
                    </Row>
                 </Container>
                 <Container>  
                    <Row className="m-t-lg">
                        <Col lg={4}>
                            <div className="siteViewDetailsLeftSideDiv" style={{ height: '100%' }}>
 
                            {/* {siteViewData.location_type=='productive'?<div className="headerNameDivBtn productive-btn">Productive</div>:""} */}
                                {/* {siteViewData.location_type=='less_productive'?<div className="headerNameDivBtn lessproductive-btn">Less Productive</div>:""} */}
                                {/* {siteViewData.location_type=='non_productive'?<div className="headerNameDivBtn nonproductive-btn">Non Productive</div>:""} */}
                                <div className="headerNameDiv">{getTranslatedText(siteViewData.name)}</div>
 
                                <div className="subHeaderDiv">{getTranslatedText(siteViewData.description)}</div>
                                {/* <div className="subHeaderDiv">9am - 6pm | 11pm - 6am</div> */}
                                <div className="subHeaderDiv">Location Tag : <b>{siteViewData.loc_tag}</b></div>
                                {/* <div className="subHeaderDiv">Category Name : <b>{siteViewData.name}</b></div> */}

                                <div className="separaterLine"></div>

                                <div className="areaCapacityMainDiv">
                                    <Row >
                                        <Col lg={4} className="text-center b-r">
                                            <h4 className="font-bold text-white">{siteViewData.area_size}</h4>
                                            <h6 className="text-white">Area Size Sq.m</h6>
                                        </Col>
                                        <Col lg={4} className="text-center b-r">
                                            <h4 className="font-bold text-white">{siteViewData.capacity}</h4>
                                            <h6 className="text-white">Capacity</h6>
                                        </Col>
                                        <Col lg={4} className="text-center">
                                            <h4 className="font-bold text-white">{siteViewData.theroic_max_capacity}</h4>
                                            <h6 className="text-white">Theoric Max</h6>
                                        </Col>
                                    </Row>
                                    <div className="separaterLine"></div>
                                    {/* <Row >
                                        <Col lg={6} className="text-center b-r">
                                            <h4 className="font-bold text-white">{siteViewData.theroic_max_capacity}</h4>
                                            <h6 className="text-white">Theoric Max</h6>
                                        </Col>
                                        <Col lg={6} className="text-center">
                                       
                                        </Col>
                                    </Row> */}

                                </div>


                                 <Row style={{ margin: 0 }} className="mostInteractedMainDiv">
                                   
                        <Col className="siteWidgetTable siteWidgetTable2" lg={12}  >
                          {/* <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"100%"}}>
                                 Visited by
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                Location Visited by employee
                                </span>

                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150 }}
                            >
                              {ShowCardLocation()}
                            </Scrollbars> 
                            
                          </table>*/}
                          {ShowCardLocation()}
                        </Col>

                        {acessData.length>0?<Col lg={12} className={'siteWidgetTable'}>
                         
                         {/* <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:"70%"}}>
                                Policy Breach by
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`breach`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`breach`)
                                  }
                                />
                                <span className="descHelp" id="breach">
                                Acess Policy Breach. 
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td" style={{width:"30%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKey === 'acess' ? sortTypeacess === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortAcess('acess')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                              {ShowCardAcess()}
                            </Scrollbars>
                          </table> */}
                           {ShowCardAcess()}
                        </Col>:""}
                      </Row> 





                            </div>
                        </Col>
                        <Col lg={8} className='left-no-space-resp employeeDetailsMainDiv'>
                        <div className="siteViewRightSideDiv">
                                <Row>
                                        
{/*Modifying new */ }
<Col lg={4} className="card-box-align">
                        <div
                          style={{ height: "165px", textAlign: 'left' }}
                          className={
                            "eachIndexDiv areaIndexMainDiv  " +
                            getBackgroundColorBasedOnRisk(
                                siteViewData.loc_area_index
                            )
                          }
                        >
                          <h6 className="font-bold ">
                            {getTranslatedText('Area Index')}
                          </h6>
                          <br />
                          <br />
                          <div className="font-normal">
                            {getTranslatedText("Risk Level")}
                          </div>

                          <Row>
                            <Col lg={4}>
                             
                              <h5 className="font-bold">
                                {getRiskTYpe(siteViewData.loc_area_index)}
                            </h5>

                            </Col>
                            <Col lg={8} className="p-l-0">
                              <div className="riskPercentageMainDiv">
                                {/* <div className="riskPercentagenNumber font-bold" style={{ display: 'inline-block' }}>{employeeDetails.spread_index}</div> */}
                                <div>
                                  <h5 className="font-bold">
                                    {siteViewData.loc_area_index}
                                  </h5>
                                </div>
                                <div
                                  className="increaseDecreasePercentageDiv font-bold"
                                  style={{ verticalAlign: "baseline" }}
                                >
                         {getChangePer() > 0 ? <span> &#8593; </span> : <span> &#8595; </span>}
                                                    {Math.abs(getChangePer()) + '%'}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>



{/*Modifying new 1 end */ }

{/*Modifying new 2 */ }
<Col lg={4} className="card-box-align">
                        <div
                          style={{ height: "165px", textAlign: 'left' }}
                          className={
                            "eachIndexDiv areaIndexMainDiv  " +
                            getBackgroundColorBasedOnRisk(
                                siteViewData.area_fomite_index
                            )
                          }
                        >
                          <h6 className="font-bold ">
                            {getTranslatedText('Fomite Index')}
                          </h6>
                          <br />
                          <br />
                          <div className="font-normal">
                            {getTranslatedText("Risk Level")}
                          </div>

                          <Row>
                            <Col lg={4}>
                             
                              <h5 className="font-bold">
                                {getRiskTYpe(siteViewData.area_fomite_index)}
                            </h5>

                            </Col>
                            <Col lg={8} className="p-l-0">
                              <div className="riskPercentageMainDiv">
                                {/* <div className="riskPercentagenNumber font-bold" style={{ display: 'inline-block' }}>{employeeDetails.spread_index}</div> */}
                                <div>
                                  <h5 className="font-bold">
                                    {siteViewData.area_fomite_index}
                                  </h5>
                                </div>
                                <div
                                  className="increaseDecreasePercentageDiv font-bold"
                                  style={{ verticalAlign: "baseline" }}
                                >
                         {getChangePerFormit() > 0 ? <span> &#8593; </span> : <span> &#8595; </span>}
                                                    {Math.abs(getChangePerFormit()) + '%'}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>



{/*Modifying new 2 end */ }


                                    <Col lg={4} className="card-box-align">
                                        <div className="footfallMainDiv" style={{ height: '165px' }}>

                                        <div style={{ marginTop: '0rem' }}>
                                            
                                        <h6 className="font-bold">
                                            {getTranslatedText('Footfall Summary')}</h6>
                                        </div> 

                                        <div className="recommendMainDiv">
                                    {/* <h5 className="font-bold text-white">{getTranslatedText('Recommend')}</h5> */}

                                    <div className="recommendListMainDiv">
                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Visited by</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.visited_by}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Spend more than 15 min</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.Spend_morethan_15mins}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="eachRecommendCardDiv">
                                            <Row>
                                                <Col lg={8}>
                                                    <span>Avg interaction time</span>
                                                </Col>

                                                <Col lg={4} className="text-right ">
                                                    <strong>
                                                        {siteViewData.loc_cum_interaction_time}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                    </div>
                                </div>
                                           
                                        </div>
                                    </Col>
                                </Row>
                            </div>



                            <div className="white-bg m-t wrapper areaIndexChartMainDiv manpowerManagementEmployeeListProductivity">
                            <Row style={{marginBottom:"15px",minHeight:"180px"}}>
                                  
                                  <Col lg={8}>
                                   <Row>
                                      <Col lg={6} style={{paddingRight:"0px"}}>
                                      <div
                                          className="startDateEndDateMainDiv date-start date-label-full"
                                          style={{ paddingLeft: "0px" }}
                                          >
                                          <label>Date</label>
                                          <DatePicker
                                              selected={dates.start}
                                              onChange={(date) => handleDate(date, "start")}
                                              dateFormat={'MMM dd'}
                                              isClearable={false}
                                              placeholderText={getTranslatedText(
                                              "Start Date"
                                              )}
                                              maxDate={moment(dates.end).toDate()}
                                              minDate={new Date('2023-01-01')}
                                          />
                                          
                                          </div>
                                      </Col>     
                                      {/* <Col lg={6}>      
                                          <div
                                              className="startDateEndDateMainDiv date-end date-label-full"
                                              style={{ paddingLeft: "0px" }}
                                              >
                                              <label>End Date</label>
                                              <DatePicker
                                                  selected={moment(dates.end).toDate()}
                                                  onChange={(date) => handleDate(date, "end")}
                                                  dateFormat={'MMM dd'}
                                                  isClearable={false}
                                                  placeholderText={getTranslatedText("End Date")}
                                                  maxDate={selectedDate}
                                                  minDate={dates.start}
                                              />
                                              
                                              </div>

                                      </Col> */}
                                      </Row>
                                      <Row style={{marginTop:"20px"}}>
                                    <Col lg={12}>
                                        <div className="chartTitle">
                                            Footfall
                                        </div>
                                    </Col>
                                    </Row>
                                    </Col>
                                    
                                    <Col lg={4} className='align-right ct-data ct-data-site'>
                        
                        <div className={initialLoader===true?'ct-graph-data loader':"ct-graph-data ct-graph-data-false"}
                        style={{height:ShowSelectedData.length> 0?((ShowSelectedData.length+ 2 )*25)>140?140:((ShowSelectedData.length+ 2 )*25):"",maxHeight:"150px"   }}
                        >
                            
                        <div>
                         <div className='smaller-header'>       
                         <div className='header-left' style={{width:"60%"}}>           
                       Footfall (<strong>{ShowSelectedData.length}</strong>)
                        </div>
                        <div className='header-right' style={{width:"40%"}}><strong style={{fontWeight:"500"}}>{SeriesName}
                          </strong></div>
                         
                        </div>
                            <Scrollbars style={{height:"100px"}}>
                                {ShowSelectedData}
                             </Scrollbars></div>
                         
                         
                         


                            <div className='loader-inner'>
                                    <img src={spinnerLoader} />
                            </div>

                            

                        </div>
                    </Col>




                          
                                </Row>
                                <Row style={{ paddingLeft : "0.5rem"}}>
                                    <Col lg={3}>
                                        <h6 className="font-bold">Peak Hours</h6>
                                        <div>
                                            {chartData.top4.map((d) => {
                                                return (
                                                    <div className="eachPeakHoursDiv" key={'Top4'+d.name}>
                                                        <img src={ClockIcon} />
                                                        <span className="font-bold">{d.name}</span>

                                                    </div>
                                                )
                                            })}
                                            {crowdedArea?<h6 className="font-bold top-seperator">Over Crowded Hours</h6>:''}
                                            <Scrollbars autoHide style={{ width: "100%", height: 130 }} >{crowdedArea}</Scrollbars>
                                            
                                        </div>
                                    </Col>
                                    <Col lg={9}>
                                        {chartLoader ?
                                            <div className="text-center">
                                                <img src={spinnerLoader} />
                                            </div>
                                            :
                                            <Barchart chartData={chartData} key={'footstep'} TheoricMax={siteViewData.theroic_max_capacity} SetDataDisplay={SetDataDisplay} date={dates.start}/>
                                        }
                                    </Col>
                                </Row>
                               


                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    else {
        return (
            <div className="text-center m-t-lg">
                <img src={spinnerLoader} className="m-t-lg" />
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(SiteViewDetails))


