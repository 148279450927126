import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import '../style/style.scss';
import AutomatedFOPFilter from "./automatedFOP/filter";
import AutomatedFOP from "./automatedFOP";
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};


function ManpowerPlaning(props) {

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();


  const [selectedDate, updateSelectedDate] = useState(date);

  const [dates, setDate] = useState({

    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [widthContainer,setWidthContainer]=useState(0);

  const [isLoading,SetisLoading] = useState(false)
  const [isLoading2,SetisLoading2] = useState(false)
  const [isLoading3,SetisLoading3] = useState(false)

  const [exportEnable,SetExportEnable] =useState(false);


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  const [dataLoad,SetDataLoad] = useState(false);

  const [filterFOP,SetFIlterFOP]= useState(false);
  const [filterPassData,SetFilterPassData]= useState({});

  const isWeekday = (date) => {

        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };

  function handleDateSelect(date) {
    updateSelectedDate(date);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    setDate({ start: endDate, end: endDate });
    SetExportEnable(false);
  }

  

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {

    let perm= props.pages.filter(el=>el.page_name=="Manpower Planning"); 
    
    if(perm){
      

        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

 useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    SetDataLoad(false);
    setDate((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }


function getDaysCount(start,end){
     
     let startDate =moment(start).toDate();
     let endDate = moment(end).toDate();
     let totalDays = moment(end).diff(start, 'days') + 1     
     let incHol= 0;
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        if(!isWeekday(startDate)){
          incHol++;
        }
      }     
      return totalDays - incHol;
}
function exportEnableHandlerFOP(){

  SetExportEnable(false);
  SetExportEnable(true);
}



function exportEnableDisable(){
  SetExportEnable(true);
}

function exportFalseFOP(){
  SetExportEnable(false);
}


function FIlterResultHandlerFOP(value,status){

  SetFilterPassData(value);
  SetExportEnable(false);
  SetFIlterFOP(false);
  if(status){
    SetFIlterFOP(status);
  }
}



  return (<>
        {permissionAPICall?
            permission.length > 0?
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            <CommonHeading title="Manpower Planning" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
              />
            </div>
          </Col>
        </Row>

        <Row className="m-t">
          <Col lg={12}>   
           <div className="manpowerManagementEmployeeListMainDiv">
              <div className="filterField">
                   <AutomatedFOPFilter filterResultHandler={FIlterResultHandlerFOP} date={selectedDate} exportEnable={exportEnableHandlerFOP} />                
                </div> 
            </div>
          </Col>
        </Row>
        < Row className="m-t">
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv manpowerManagementEmployeeListMainDiv">              
                            {isLoading?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                                isLoading2?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>
                                  : 
                                   
                                <React.Fragment>
                                    {isLoading3?
                                    <div className="text-center m-t-lg">
                                      <img src={spinnerLoader} className="m-t-lg" />
                                  </div>
                                      :""}
                              <div className="rportArea">
                              <AutomatedFOP filterActive={filterFOP} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={exportFalseFOP}/>
                               </div>

                                </React.Fragment> 
                            }

                        </div>
                    </Col>
                </Row>
      </Container>


      
    </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ManpowerPlaning)
);
