import React, { useState,useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';


import downThumbIcon from '../../assets/traceplusImages/orange_thumb.svg.svg'
import dashboardPeopleImage from '../../assets/traceplusImages/threat_watch_orange_img.svg'
import greenThumbIcon from '../../assets/traceplusImages/green_thumb.svg'
import helpIcon from '../../assets/traceplusImages/help-icon.png'

import dropdownIcon from '../../assets/images/down-arrow.png'
import DatePicker from "react-datepicker";
import { getLanguageTranslation } from '../actionMethods/actionMethods';
import { getTranslatedText } from '../../common/utilities';
import DownArrow from '../../assets/images/down-arrow(1).png';
import UPArrow from '../../assets/images/up-arrow.png';
import { Slider } from 'antd';
import { getPproductivityOverviewAll } from "../../productivity/actionMethods/actionMethods";
import{ getOnBoardEmp,getEmployeeCost }from '../actionMethods/actionMethods'
import { ReactComponent as UpIcon } from "../../assets/images/play-solid.svg";
import { ToastContainer, toast } from "react-toastify";


function TopAreaPerformance(props) {

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id = userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  const [selectedDate, updateSelectedDate] = useState(props.selectedDate);

  const [todayStats, SetTodayStats] = useState(0);
  const [liveStats, SetLiveStats] = useState(0);
  const [WeekStats, SetWeekStats] = useState(0);
  const [weekName, SetWeekName] = useState("");
  const [monthStats, SetMonthStats] = useState(0);
  const [avgCost,SetAvgCost] =useState(0)
  const [performanceTitle, SetPerformanceTitle] = useState("");
  const [loader, SetLoader] = useState(true);
  const [loaderSecondWidget,SetLoaderSecondWidget]=useState(true);
  const [toastClass, updateToastClass] = useState("errorToast");
  const [costEmp,SetCostEmp] =useState([])
  
  function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));

}



 




    function handleOnChangeContactRankValue(event) {
     //   props.handleChangeValue(event.target.value)
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }
    function formatNumberWithAbbreviation(number) {
      const billion = 1e9;
      const million = 1e6;
      const thousand = 1e3;

      if (number >= billion) {
        return (number / billion).toFixed(2) + 'B';
      } else if (number >= million) {
        return (number / million).toFixed(2) + 'M';
      } else if (number >= thousand) {
        return (number / thousand).toFixed(2) + 'k';
      } else {
        return number.toString();
      }
}
useEffect(()=>{
  updateSelectedDate(props.selectedDate);
},[props.selectedDate])

    useEffect(()=>{
              SetLoader(true);
              SetLoaderSecondWidget(true);
              
              //overall
      getOnBoardEmp(userSession,org_id,moment(selectedDate).subtract(30,'days').format("YYYY-MM-DD"),moment(selectedDate).format("YYYY-MM-DD"),sub_org_id).then(res=>{
        if(res.status==200){
          let daily = res.data.daily?res.data.daily.map(el=>{
            let item= el;
            item.emp_type='daily'
            return item
          }):[];
          let regular =res.data.regular?res.data.regular.map(el=>{
            let item= el;
            item.emp_type='regular'
            return item
          }):[]; 

          getEmployeeCost(userSession,org_id,sub_org_id).then(res2=>{

            SetCostEmp(res2.data);
            //today live
          getOnBoardEmp(userSession,org_id,moment(selectedDate).startOf('month').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'),sub_org_id).then(res3=>{
              let cost = res2.data;
              let smallStart = moment().set(props.startShift);
              let smallEnd = moment().add(addDay,'days').set(props.endShift)

              let todayDaily = res3.data.daily?res3.data.daily:[];
             todayDaily = todayDaily.filter(item=>moment(item.check_in)>= moment(smallStart) && moment(item.check_in) <= moment(smallEnd))
              let todayRegular =res3.data.regular?res3.data.regular:[]; 
              todayRegular = todayRegular.filter(item=>moment(item.check_in)>= moment(smallStart) && moment(item.check_in) <= moment(smallEnd))

            
              let all = [...regular,...daily];
              //employee with cost added;
              let allEmpWithCost = addCostkey(all,cost).map(item=>({...item,dateInFilter:moment(item.check_in).format('YYYY-MM-DD hh:mm:ss A')}));
            
              //todayCalulation Live Thing
              let todayCostEMp = addCostkey([...todayDaily,...todayRegular],cost);
            
              let todayCost = todayCostEMp.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
              },0);

              SetLiveStats(todayCost);
              SetAvgCost(todayCost/[...todayDaily,...todayRegular].length); 
              
              //master date calulation
              let masterDateEmp = allEmpWithCost.filter(item=>
                 moment(item.check_in) > moment(selectedDate).set(props.startShift)
                  && moment(item.check_in) < moment(selectedDate).add(addDay,'days').set(props.endShift)
                 );
               


 
              let todayMasterCost =masterDateEmp.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
              },0);
 
              SetTodayStats(todayMasterCost);

              //week calulation
              let monthDiff = props.getMonths( moment(selectedDate).subtract(7, "days").toDate(),moment(selectedDate).toDate(),"week");
              
              monthDiff = monthDiff[monthDiff.length - 1];

              let weekEmployee =  allEmpWithCost.filter((item) => 
              moment(item.check_in) > moment(monthDiff.start).set(props.startShift) 
                  && moment(item.check_in) < moment(monthDiff.end).add(addDay,'days').set(props.endShift)
                );
               
              let weekCOst = weekEmployee.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
              },0);

                SetWeekName(
                  monthDiff && monthDiff.weekName ? monthDiff.weekName : "Week"
                );
              SetWeekStats(weekCOst);

              //month calu;lation
              let monthEmp =
              allEmpWithCost.filter((item) =>
                moment(item.check_in) > moment(selectedDate).startOf('month').set(props.startShift)  && moment(selectedDate).add(addDay,'days').set(props.endShift) >= moment(item.check_in)
            ).reduce((prev, current) => prev + current.cost, 0)
            
            SetMonthStats(monthEmp)
           


              
              
              


              
              
              let dept = groupDataByDepartment(all);
              props.getDataFromTopAreaPerformance(allEmpWithCost,dept)
              SetLoader(false);
              SetLoaderSecondWidget(false);
            })  

          })


          //props.getDataFromTopAreaPerformance(res)
        }
      })

    },[selectedDate] )

    const syncTimer = ()=>{
   
    getOnBoardEmp(userSession,org_id,moment().subtract(1,'days').format('YYYY-MM-DD'),moment().utc().format('YYYY-MM-DD'),sub_org_id).then(res3=>{

      		let cost = [...costEmp];
              let smallStart = moment().set(props.startShift);
              let smallEnd = moment().add(addDay,'days').set(props.endShift)

              let todayDaily = res3.data.daily?res3.data.daily:[];
             todayDaily = todayDaily.filter(item=>moment(item.check_in)>= moment(smallStart) && moment(item.check_in) <= moment(smallEnd))
              let todayRegular =res3.data.regular?res3.data.regular:[]; 
              todayRegular = todayRegular.filter(item=>moment(item.check_in)>= moment(smallStart) && moment(item.check_in) <= moment(smallEnd))

              
			  let todayCostEMp = addCostkey([...todayDaily,...todayRegular],cost);
           
              let todayCost = todayCostEMp.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
              },0);

              
              SetLiveStats(todayCost);
              SetAvgCost(todayCost/[...todayDaily,...todayRegular].length);
    });

  }

  useEffect(() => {
if(costEmp.length> 0){
  const id3 = setInterval(syncTimer, 30000);

  

return () =>{
  clearInterval(id3);
  }   
}
},[costEmp]);

    function addCostkey(emp,cost){
      let arr=[];
      for(let i=0;i<emp.length;i++){
        let findCost =cost.find(el=>el.worker_id  == emp[i].worker_id);
        if(findCost){
          emp[i].cost = parseInt(findCost.cost);
          emp[i].agency= findCost.agency
        }else{
          emp[i].cost = 0;
          emp[i].agency= ''
        }
      arr.push(emp[i])
      }
      return arr;
    }

    

  function groupDataByDepartment(data) {

  const groupedData = [];
  data.forEach(item => {
    const department = item.department_name;
    const subDepartment = item.sub_department_name;

    const existingItem = groupedData.find(obj => obj.department === department);
    if (existingItem) {
      if (!existingItem.sub_departments.includes(subDepartment)) {
        existingItem.sub_departments.push(subDepartment);
      }
    } else {
      groupedData.push({
        department: department,
        sub_departments: [subDepartment]
      });
    }
  });

  return groupedData;
}


    return (
        // <div className={'threatWatchMainDiv ' + (props.contaminatedEmployeeCount <= 0 ? ' greenGradienColor' : ' orangeGradientColor')} >
        // <div className={props.todayStats > 70 ?'threatWatchMainDiv greenGradienColor': 'threatWatchMainDiv orangeGradientColor' } >
        <div className='threatWatchMainDiv whitebgShadow' >
            <Row>
                <Col lg={3}>
                    <div className="threatWatchTextDiv">
                        <div className="title">
                            {getTranslatedText('Overall Cost')}
                        </div>
                        <div className="subTitle">As of {moment(selectedDate).format('Do MMM YYYY')}</div>

                    </div>
                </Col>

                <Col lg={5} className="parent-lg-6">
                    <div className={"threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv5 threatWatchAnalyticsMainDiv4 threatWatchAnalyticsMainDiv2 threatWatchAnalyticsMainDiv3 "}>
                        <div className="eachColumnDiv contaminatedMainDiv">
                            <div className={loader?'skeleton threeList threeList3':'threeList threeList3 greenGradienColor'}>
                                <h4 className='skeleton-text' style={{paddingBottom:"0px",marginBottom:"0px"}}>On {moment(selectedDate).format("DD MMM")}</h4>
                                <div className='newHeadline' >
                                    <strong className='skeleton-text' style={{fontSize:"24px"}}>Rp {formatNumberWithAbbreviation(todayStats)}</strong>
                                    <small className='skeleton-text' >As on {moment(selectedDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")?moment().format('hh A'):"-"}</small>
                                </div>
                            </div>
                            <div className={loader?'skeleton threeList threeList3':'threeList threeList3 greenGradienColor'}>
                                <h4 className='skeleton-text' style={{paddingBottom:"0px",marginBottom:"0px"}}>{weekName?weekName:"week"}</h4>
                                <div className='newHeadline'>
                                     <strong className='skeleton-text' style={{fontSize:"24px"}}>Rp {formatNumberWithAbbreviation(WeekStats)}</strong>
                                    <small className='skeleton-text'> As on {moment(selectedDate).format("dddd")}</small>
                                </div>
                            </div>
                            <div className={loader?'skeleton threeList threeList3':'threeList threeList3 greenGradienColor'}>
                                <h4 className='skeleton-text' style={{paddingBottom:"0px",marginBottom:"0px"}} >{moment(selectedDate).format("MMM, YYYY")}</h4>
                                <div className='newHeadline'>
                                    <strong className='skeleton-text' style={{fontSize:"24px"}}>Rp {formatNumberWithAbbreviation(monthStats)}</strong>
                                    
                                    <small className='skeleton-text'>As on {moment(selectedDate).format("Do")}</small>
                                </div>
                            </div>

                        </div>


                    </div>
                </Col>

                <Col lg={4}>
                    

                        <div className={loader && loaderSecondWidget?'skeleton threatWatchAnalyticsMainDiv4 threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv2 threatWatchAnalyticsMainDiv3 greenGradienColor':'threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv3 threatWatchAnalyticsMainDiv2 threatWatchAnalyticsMainDiv3 greenGradienColor threatWatchAnalyticsMainDiv4'} >
                            {loader?"":<div className='livearea'>LIVE</div>}
                        <div className="eachColumnDiv contaminatedMainDiv">
                            <div className={loader && loaderSecondWidget?'skeleton threeList threelist50 threeList3':'threeList threeList3 threelist50 greenGradienColor'}>
                                <h4 className='skeleton-text' style={{height:"20px",fontSize:"14px"}}>Cost of labor <br/> for the current day</h4>
                                <div className='newHeadline' >
                                    <strong className={'skeleton-text'} style={{fontSize:"24px"}}>Rp {formatNumberWithAbbreviation(liveStats)}</strong>
                                  
                                </div>
                            </div>
                            <div className={loader && loaderSecondWidget?'skeleton threeList threeList3 threelist50':'threeList threeList3 threelist50 greenGradienColor'}>
                                <h4 className='skeleton-text'  style={{height:"20px",fontSize:"14px"}}>Avg. <br/> Per Worker</h4>
                                <div className='newHeadline'>
                                    <strong className={'skeleton-text'}  style={{fontSize:"24px"}}>Rp {formatNumberWithAbbreviation(avgCost)}</strong>
                                </div>
                                {/* <p className='pAbs' style={{textAlign:"center"}}>For The Actual Cost last 60 min <strong >100M</strong></p> */}
                            </div>
                            


                        </div>


                    </div>
                    
                    
                </Col>
            </Row>
        </div>
    )
}

export default TopAreaPerformance