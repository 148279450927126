import React, { useState, useEffect } from 'react'

import { useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import DashboardLanguage from '../../components/dashboardLanguage';

import '../../siteManagement/styles/siteManagement.scss'

import { getEmployeeList, getDepartmentList, atRiskEmployee, contaminatedEmployee } from '../actionMethods/actionMethods';
import {getVisitorList} from '../../visitorManagement/actionMethods/actionMethods';
import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'
import helpIcon from '../../assets/traceplusImages/help-icon.png'
import sortIcon from '../../assets/traceplusImages/sort.png'
import upIcon from '../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../assets/traceplusImages/down-arrow.png'
import dayShiftImage from '../../assets/traceplusImages/sun.svg'


import spinnerLoader from '../../assets/images/loader.svg'
import CommonDatePicker from '../../common/commonDatePicker';
import { getTranslatedText } from '../../common/utilities';
import { getLanguageTranslation, setSelectedLanguage } from '../../dashboard/actionMethods/actionMethods';
import { Checkbox, Button, Select } from 'antd';

const { Option } = Select;

const THREAT_API = {
    'risk': atRiskEmployee,
    'contaminated': contaminatedEmployee
}

function EmployeeList(props) {

    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()

    const [searchValue, updateSearchValue] = useState('')
    const [messageValue, updateMessageValue] = useState('')
    const [selectedLangValue, updateSelectedLangValue] = useState('en')
    const [employeeList, updateEmployeeList] = useState([])
    const [preDefinedEmployeeList, updatePredefinedEmployeeList] = useState([])
    const [employeeCount, updateEmployeeCount] = useState(0)
    const [isLoading, updateIsLoading] = useState(true)
    const [sortKey, setSortKey] = useState('spread_index')
    const [sortType, setSortType] = useState('desc')
    const [teamData, setTeamData] = useState({})
    const [messageError, setMessageError] = useState("")
    const [teamList, updateTeamList] = useState([])
    const [preDefinedTeamList, updatedPredefinedTeamList] = useState([])
    const [isAll, setIsAll] = useState(false)
    const [selected, setSelected] = useState([])
    const [selectedDate, updateSelectedDate] = useState(date)
    const [positiveDateThreat,updatepositiveDateThreat]=useState();
    const [tempDataRisk,setTempDataRisk]=useState([]);
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6

    let history = useHistory();

    function handleDateSelect(date) {
        updateSelectedDate(date)

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        updateIsLoading(true)
        getEmployeeList(requestBody, userSession, org_id,sub_org_id).then(res => {
            updateIsLoading(false)

            if (res.status==200) {
                updateEmployeeCount(res.count)
                updateEmployeeList(res.data)
                updatePredefinedEmployeeList(res.data)

                if (props.setGlobalSearchData) {
                    props.setGlobalSearchData(res.data)
                }
            }else{
                
            }
        }).catch(() => {
            updateIsLoading(false)
        })
        getDepartmentListData(requestBody)

    }


    function getDepartmentListData(requestBody) {
        getDepartmentList(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res && res.status >= 200 && res.status <= 299) {
                updateTeamList(res.data)
                updatedPredefinedTeamList(res.data)
            }
        })
    }

    useEffect(() => {
        if (props.date) {
            let requestBody = {}

            if(props.sensitive){
                requestBody.sensitivity=props.sensitive
            }
            if (props.type && props.type === 'threat') {
                requestBody.start = getDateFormat(props.startDate)
                requestBody.end = getDateFormat(props.endDate)
                requestBody.rank = props.rank

             
                if(props.threatEmployee === 'contaminated'){
                    THREAT_API[props.threatEmployee](requestBody, userSession, org_id,sub_org_id).then(res => {
                        updateIsLoading(false)
                       
                        if (res && res.data) {
                            let data = res.data
                              
                            updatepositiveDateThreat(data.tested_positive_on);
                            updateEmployeeCount(data.contaminated_count)
                            updateEmployeeList(data.employee_contaminated)
                            updatePredefinedEmployeeList(data.employee_contaminated)
    
                        }
                    }).catch(() => {
                        updateIsLoading(false)
                    })
                }

               else if(props.rank==1 && props.threatEmployee === 'risk'){
                THREAT_API[props.threatEmployee](requestBody, userSession, org_id,sub_org_id).then(res => {
                    updateIsLoading(false)
                    if (res && res.data) {
                        let data = res.data
                        let tempdataThreat= [];

                          
                        updatepositiveDateThreat(data.tested_positive_on);
                        if (props.threatEmployee === 'risk') {
                            for(let i1= 0 ; i1<data.atrisk_employee.length;i1++){
                                let element=data.atrisk_employee[i1];
                              
                                element.rank=parseInt(requestBody.rank);
                                tempdataThreat.push(element);
                            }

                            updateEmployeeCount(data.atrisk_employee_count)
                            updateEmployeeList(tempdataThreat)
                            updatePredefinedEmployeeList(tempdataThreat)

                        }
                        setTempDataRisk(tempdataThreat)

                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
            }else if(props.rank==2 && props.threatEmployee === 'risk'){
                let tempdataThreat=[];
               for (let i=0;i<props.rank;i++ ){
                requestBody.rank = i+1;
               
                THREAT_API[props.threatEmployee](requestBody, userSession, org_id,sub_org_id).then(res => {

                    if((i+1)==props.rank){
                      
                        updateIsLoading(false)
                } else{
                      
                }                  
                    

                  if (res && res.data) {
                        let data = res.data
                        //updatepositiveDateThreat
                         updatepositiveDateThreat(data.tested_positive_on);
                        if (props.threatEmployee === 'risk') {
                            
                            for(let i1= 0 ; i1<data.atrisk_employee.length;i1++){
                                let element=data.atrisk_employee[i1];
                                element.rank=parseInt(i+1);
                                let item  = tempdataThreat.find(item => item.tag_serial === element.tag_serial);

                                if(item && item.rank==2){

                                    tempdataThreat.splice(item,1); 
                                    tempdataThreat.push(element);
                                    
                                }
                                else if(item && item.rank==1){
                                    
                                }
                                
                                else{
                                    tempdataThreat.push(element);
                                }
                                
                                if(tempdataThreat.length==2){
                                    
                                    updateEmployeeCount(tempdataThreat.length)
                                    updateEmployeeList(tempdataThreat)
                                    updatePredefinedEmployeeList(tempdataThreat)
                                    setTempDataRisk(tempdataThreat);
                                }
                            }


                                
                        } 
                        
                        

                    }

                }).catch(() => {
                    updateIsLoading(false)
                })

            }



            }

            }else if(props.type && props.type === 'Visitor'){
                requestBody.date = getDateFormat(props.date)
                updateIsLoading(true)
                getVisitorList(requestBody, userSession, org_id,sub_org_id).then(res => {
                    updateIsLoading(false)

                    if (res) {
                        updateEmployeeCount(res.count)
                        updateEmployeeList(res.data)
                        updatePredefinedEmployeeList(res.data)
                        if (props.setGlobalSearchData) {
                            props.setGlobalSearchData(res.data)
                        }

                        
                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
            }
            
            else {
                requestBody.date = getDateFormat(props.date)
                updateIsLoading(true)
                getEmployeeList(requestBody, userSession, org_id,sub_org_id).then(res => {
                    updateIsLoading(false)

                    if (res) {
                        updateEmployeeCount(res.count)
                        updateEmployeeList(res.data)
                        updatePredefinedEmployeeList(res.data)

                        if (props.setGlobalSearchData) {
                            props.setGlobalSearchData(res.data)
                        }

                        let empData = res.data
                        let teamObj = {}
                        for (let i = 0; i < empData.length; i++) {
                            let team = empData[i]['team']
                            if (teamObj.hasOwnProperty(team)) {
                                teamObj[team] = teamObj[team] + 1
                            } else {
                                teamObj[team] = 1
                            }
                        }

                        setTeamData(teamObj)
                    }
                }).catch(() => {
                    updateIsLoading(false)
                })
                getDepartmentListData(requestBody)
            }
        }
    }, [props.date,props.reloadInc]);

    useEffect(()=>{

    },[tempDataRisk])

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    function handleManpowerManagementList() {
        history.push('/manpower-management')
    }
    function compareDesc(a, b){
        if (a.rank < b.rank) {
            return 1;
        } else if (a.rank > b.rank) {
            return -1;
        } else {
            return 0;
        }    

    }
    function compareAsc(a, b){
        if (a.rank < b.rank) {
            return -1;
        } else if (a.rank > b.rank) {
            return 1;
        } else {
            return 0;
        }
    
    }
    function handleClickCard(id) {
       if(props.type=="Visitor"){
        history.push(`/visitor-management/view/:${id}`)
       } else{
        history.push(`/manpower-management/employee-list/view/:${id}`)
       }
        
    }

    function showShiftType(type) {
        let shiftType = dayShiftImage

        switch (type) {
            case 'day':
                shiftType = dayShiftImage
                break;

            default:
                break;
        }

        return shiftType
    }
    function getBackgroundCOlor(index, activated) {
        let color = ''

        if (!activated) {
            color = '#EBECED'
        } else if (index === 0) {
            color = '#f9e1e8'
        }

        return color
    }

    function handleChangeTab(type) {
        props.handleTabViewChange(type)
        setIsAll(false)
        setSelected([])
        updateSearchValue("")
        updateEmployeeList(preDefinedEmployeeList)
        updateTeamList(preDefinedTeamList)
    }

    function showCardList(employeeList) {
      
        let arr = []
        if (props.isManpower && props.type == "Team") {
            for (let teamIndex = 0; teamIndex < teamList.length; teamIndex++) {
                const elementTeam = teamList[teamIndex]
                arr.push(
                    <div className="eachCard" key={teamIndex}>
                        <div className="card-body">

                            <Row style={{ alignItems: 'center' }}>
                                <Col lg={1} className="b-r" style={{ maxWidth: "4%" }}>
                                    <div className="" style={{ paddingLeft: '9px' }}>
                                        <Checkbox onChange={(e) => onChangeCheckbox(e, teamIndex)} checked={isAll || selected.includes(teamIndex)} />
                                    </div>
                                </Col>
                                <Col lg={2} className="b-r ">
                                    <h5 className="font-bold">{elementTeam.team || ""}</h5>
                                    <div><b>People:</b> {elementTeam.team_members_count || 0}</div>
                                </Col>
                                <Col lg={2} className="b-r text-align-center">
                                    {/* <div className="priSriMriText">Spread Index</div> */}
                                    <h6 className="font-bold">{elementTeam.spread_index !== 'na' ? elementTeam.spread_index + '%' : '-'}</h6>
                                </Col>
                                <Col lg={2} className="b-r text-align-center">
                                    {/* <div className="priSriMriText">Close Contacts</div> */}
                                    <h6 className="font-bold">{elementTeam.close_contacts !== 'na' ? elementTeam.close_contacts : '-'}</h6>
                                </Col>
                                <Col lg={2} className="b-r text-align-center">
                                    {/* <div className="priSriMriText">Interaction Time</div> */}
                                    <h6 className="font-bold">{elementTeam.interaction_time !== 'na' ? elementTeam.interaction_time : '-'}</h6>
                                </Col>
                                <Col lg={2} className="b-r text-align-center">
                                    {/* <div className="priSriMriText">Mobility Index</div> */}
                                    <h6 className="font-bold">{elementTeam.mobility_index !== 'na' ? elementTeam.mobility_index + '%' : '-'}</h6>
                                </Col>
                                <Col lg={1} className=" text-align-center">
                                    {/* <div className="priSriMriText">Visited Locations</div> */}
                                    <h6 className="font-bold">{elementTeam.popular_loc_visited !== 'na' ? elementTeam.popular_loc_visited : '-'}</h6>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }

        } else {
            let arr2=[];
            arr2 = employeeList.sort((a, b) => {
                if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                    return sortType === 'desc' ? b.spread_index - a.spread_index : a.spread_index - b.spread_index
                } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                    return -1
                } else {
                    return 1
                }
            })

            for (let index = 0; index < arr2.length; index++) {
                const element = arr2[index]
                arr.push(
                    <div className="eachCard" key={index}
                    // onClick={() => handleClickCard(element.tag_serial)}
                    >
                        <div className="card-body" style={{ backgroundColor: getBackgroundCOlor(element.sri_index, '22/08/2021') }}>
                            {props.isManpower ?
                                <Row style={{ alignItems: 'center' }}>
                                    <Col className="b-r" style={{ maxWidth: "4%" }}>
                                        <div className="">
                                            <Checkbox onChange={(e) => onChangeCheckbox(e, index)} checked={isAll || selected.includes(index)} />
                                        </div>
                                    </Col>
                                    
                                    {/* <Col5} className="flexDiv b-r">&nbsp;</Col> */}
                                    <Col className="b-r" style={{ maxWidth: "18%",minWidth:"18%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <h5 className="font-bold">{element.name}</h5>
                                        <div><b>Team:</b> {element.team}</div>
                                        <div><b>Tag:</b> {element.tag_serial}</div>
                                    </Col>

                                    <Col className="b-r" style={{ maxWidth: "12%",minWidth:"12%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Spread Index</div> */}
                                        <h6 className="font-bold">{element.spread_index !== 'na' ? element.spread_index + '%' : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "12%",minWidth:"12%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Close Conatacts</div> */}
                                        <h6 className="font-bold CloseFixSize">{element.close_contacts !== 'na' ? element.close_contacts : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "12%",minWidth: "12%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Interactions</div> */}
                                        <h6 className="font-bold CloseFixSize">{element.interaction_time !== 'na' ? element.interaction_time : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "10%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Mobility Index</div> */}
                                        <h6 className="font-bold MobilityFixSize">{element.mobility_index !== 'na' ? element.mobility_index + '%' : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "14%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Visited Location</div> */}
                                        <h6 className="font-bold VisitedFixSize">{element.popular_loc_visited !== 'na' ? element.popular_loc_visited : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "10%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <div className={element.status && element.status == "negative" ? 'riskDivNegative' : 'riskDiv'}>{element.status}</div>
                                    </Col>
                                    <Col className="flexDiv" style={{ maxWidth: "10%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Battery</div> */}
                                        <h6 className="font-bold VisitedFixSize">{element.battery !== 'na' ? element.battery + '%' : '-'}</h6>
                                    </Col>
                                </Row>
                                :
                                <Row style={{ alignItems: 'center' }}>
 
                                    <Col className="b-r" style={{ maxWidth: "2%" }}>
                                        <div className="">
                                            <Checkbox onChange={(e) => onChangeCheckbox(e, index)} checked={isAll || selected.includes(index)} />
                                        </div>
                                    </Col>
                                    <Col className="b-r" style={{ maxWidth: "15%",minWidth:"15%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <h5 className="font-bold">{element.name}</h5>
                                        <div><b>Team:</b> {element.team}</div>
                                        <div><b>Tag:</b> {element.tag_serial}</div>
                                    </Col>
                                    {props.threatEmployee=='risk'?
                                         <Col className="b-r text-align-center" style={{ maxWidth: "8%",minWidth:"8%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                                <h6 className="font-bold">{element.rank}
                                        </h6>
                                    </Col>:""}

                                    <Col className="b-r text-align-center" style={{ maxWidth: "8%",minWidth:"8%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Spread Index</div> */}
                                        <h6 className="font-bold">{element.spread_index !== 'na' ? element.spread_index + '%' : '-'}
                                        </h6>
                                    </Col>

                                    <Col className="b-r" style={{ maxWidth: "12%",minWidth:"12%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Close Conatacts</div> */}
                                        <h6 className="font-bold CloseFixSize">{element.close_contacts !== 'na' ? element.close_contacts : '-'}</h6>
                                    </Col>

                                    <Col className="b-r" style={{ maxWidth: "12%",minWidth: "12%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Interactions</div> */}
                                        <h6 className="font-bold CloseFixSize">{element.interaction_time !== 'na' ? element.interaction_time : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "10%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Mobility Index</div> */}
                                        <h6 className="font-bold MobilityModelSize">{element.mobility_index !== 'na' ? element.mobility_index + '%' : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "13%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Visited Location</div> */}
                                        <h6 className="font-bold MobilityModelSize">{element.popular_loc_visited !== 'na' ? element.popular_loc_visited : '-'}</h6>
                                    </Col>

                                    <Col className="flexDiv b-r" style={{ maxWidth: "10%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <div className={element.status && element.status == "negative" ? 'riskDivNegative' : 'riskDiv'}>{element.status || 'Negative'}</div>
                                    </Col>
                                    {props.type=='threat'&&props.threatEmployee=='contaminated'?<Col className="flexDiv b-r positive-date" style={{ maxWidth: "10%" }} >
                                        <h6 className="font-bold MobilityModelSize"> {moment(positiveDateThreat[element.tag_serial]).format("DD/MM/YYYY")  }</h6>
                                    </Col>:''}
                                    
                                    
                                    {/* <Col className="b-r" style={{ maxWidth: "8%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <h6 className="font-bold text-align-center">{element.battery !== 'na' ? element.battery + '%' : '-'}</h6>
                                    </Col> */}
                                    {props.type!='threat'?<Col className="b-r" style={{ maxWidth: "8%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Battery</div> */}
                                        <h6 className="font-bold text-align-center">{element.battery !== 'na' ? element.battery + '%' : '-'}</h6>
                                    </Col>:""}
                                    {props.type=='Visitor'?<Col className="b-r" style={{ maxWidth: "14%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Battery</div> */}
                                        <h6 className="font-bold text-align-center">{element.check_in !== null ? moment(element.check_in).format('LT'):"-"}</h6>
                                    </Col>:""}
                                    {props.type=='Visitor'?<Col className="b-r" style={{ maxWidth: "14%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        {/* <div className="priSriMriText">Battery</div> */}
                                        <h6 className="font-bold text-align-center">{element.check_out !== null? moment(element.check_out).format('LT'):"-"}</h6>
                                    </Col>:""}


                                    {props.type!='threat'?<Col style={{ marginLeft: 'auto', width: "70px", maxWidth: "6%" }} onClick={() => handleClickCard(element.tag_serial)}>
                                        <div className="arrowDiv" style={props.hideHeading ? { width: '100%' } : {}}>
                                            <img src={selectedPinkArrowIcon} />
                                        </div>
                                    
                                    </Col>:''
                                    }
                                </Row>
                            }
                        </div>
                    </div>
                )
            }
        }

        return arr
    }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }

    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    useEffect(() => {
        if (props.type !== 'Team') {

            if (preDefinedEmployeeList.length) {
                updateIsLoading(true)

                let arr = [...preDefinedEmployeeList]

                if (searchValue) {
                    let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                    let value = searchValue.replace(invalid, "")
                    arr = arr.filter(function (employeeList) {
                        return (employeeList.name.toLowerCase().search(value.toLowerCase()) !== -1 || employeeList.team.toLowerCase().search(value.toLowerCase()) !== -1 || employeeList.tag_serial.toLowerCase().search(value.toLowerCase()) !== -1)
                    })
                }

                if (sortKey === 'name') {
                    arr = arr.sort((a, b) => {
                        a = a.name.toUpperCase()
                        b = b.name.toUpperCase()

                        return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                    })
                }else if(sortKey === 'rank'){
                    

                    // arr = arr.sort((a, b) => {
                    //     a = parseInt(a.rank)
                    //     b = parseInt(b.rank)

                    //     return sortType === 'desc' ? b - a: a - b
                        
                    // })

                    arr = arr.sort(function (x, y) {
                        return x.rank - y.rank;
                    });

                 
                }
                else if (sortKey === 'spread_index') {
                    arr = arr.sort((a, b) => {
                        if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                            return sortType === 'desc' ? b.spread_index - a.spread_index : a.spread_index - b.spread_index
                        } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'close_contacts') {
                    arr = arr.sort((a, b) => {
                        if (a.close_contacts !== 'na' && b.close_contacts !== 'na') {
                            return sortType === 'desc' ? b.close_contacts - a.close_contacts : a.close_contacts - b.close_contacts
                        } else if (a.close_contacts !== 'na' && b.close_contacts === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'interaction_time') {
                    arr = arr.sort((a, b) => {
                        if (a.interaction_time !== 'na' && b.interaction_time !== 'na') {
                            let aMs = moment(a.interaction_time, 'HH:mm:ss').format('x')
                            let bMs = moment(b.interaction_time, 'HH:mm:ss').format('x')
                            return sortType === 'desc' ? bMs - aMs : aMs - bMs
                        } else if (a.interaction_time !== 'na' && b.interaction_time === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'mobility_index') {
                    arr = arr.sort((a, b) => {
                        if (a.mobility_index !== 'na' && b.mobility_index !== 'na') {
                            return sortType === 'desc' ? b.mobility_index - a.mobility_index : a.mobility_index - b.mobility_index
                        } else if (a.mobility_index !== 'na' && b.mobility_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'popular_loc_visited') {
                    arr = arr.sort((a, b) => {
                        if (a.popular_loc_visited !== 'na' && b.popular_loc_visited !== 'na') {
                            return sortType === 'desc' ? b.popular_loc_visited - a.popular_loc_visited : a.popular_loc_visited - b.popular_loc_visited
                        } else if (a.popular_loc_visited !== 'na' && b.popular_loc_visited === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'status') {
                    // arr = arr.sort((a, b) => {
                    arr = arr.sort((a, b) => {
                        a = a.status.toUpperCase()
                        b = b.status.toUpperCase()

                        return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                    })
                    // })
                } else if (sortKey === 'batteryStatus') {
                    arr = arr.sort((a, b) => {
                        if (a.battery !== 'na' && b.battery !== 'na') {
                            return sortType === 'desc' ? b.battery - a.battery : a.battery - b.battery
                        } else if (a.battery !== 'na' && b.battery === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                }
 
                updateEmployeeList(arr)
                setTempDataRisk(arr)
                updateIsLoading(false)
            }
        } else {
            if (preDefinedTeamList.length) {
                updateIsLoading(true)

                let arrTeam = [...preDefinedTeamList]


                if (searchValue) {
                    let invalid = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                    let value = searchValue.replace(invalid, "")
                    arrTeam = arrTeam.filter(function (teamList) {
                        return (teamList.team.toLowerCase().search(value.toLowerCase()) !== -1)
                    })
                }

                if (sortKey === 'teamName') {
                    arrTeam = arrTeam.sort((a, b) => {
                        a = a.team.toUpperCase()
                        b = b.team.toUpperCase()

                        return sortType === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                    })
                } else if (sortKey === 'teamppl') {
                    arrTeam = arrTeam.sort((a, b) => {
                        if (a.popular_loc_visited !== 'na' && b.popular_loc_visited !== 'na') {
                            return sortType === 'desc' ? b.popular_loc_visited - a.popular_loc_visited : a.popular_loc_visited - b.popular_loc_visited
                        } else if (a.popular_loc_visited !== 'na' && b.popular_loc_visited === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'teamMobility') {
                    arrTeam = arrTeam.sort((a, b) => {
                        if (a.mobility_index !== 'na' && b.mobility_index !== 'na') {
                            return sortType === 'desc' ? b.mobility_index - a.mobility_index : a.mobility_index - b.mobility_index
                        } else if (a.mobility_index !== 'na' && b.mobility_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'teamInteraction') {
                    arrTeam = arrTeam.sort((a, b) => {
                        if (a.interaction_time !== 'na' && b.interaction_time !== 'na') {
                            return sortType === 'desc' ? b.interaction_time - a.interaction_time : a.interaction_time - b.interaction_time
                        } else if (a.interaction_time !== 'na' && b.interaction_time === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'teamCloseContact') {
                    arrTeam = arrTeam.sort((a, b) => {
                        if (a.close_contacts !== 'na' && b.close_contacts !== 'na') {
                            return sortType === 'desc' ? b.close_contacts - a.close_contacts : a.close_contacts - b.close_contacts
                        } else if (a.close_contacts !== 'na' && b.close_contacts === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (sortKey === 'teamSpread') {
                    arrTeam = arrTeam.sort((a, b) => {
                        if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                            return sortType === 'desc' ? b.spread_index - a.spread_index : a.spread_index - b.spread_index
                        } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                }


                updateTeamList(arrTeam)
                updateIsLoading(false)
            }
        }

    }, [sortKey, sortType, searchValue])

    const handleSort = (key) => {
        setSortKey(key)
        setSortType(sortType === 'desc' ? 'asc' : 'desc')
    }
    function onChangeCheckbox(e, index) {
        let arr = [...selected]
      
        setIsAll(false)
        if (selected.includes(index)) {
            let i = arr.indexOf(index)
            arr.splice(i, 1)
            setSelected(arr)
        } else {
            arr.push(index)
            setSelected(arr)
        }
    }

    function onChangeAllCheckbox(e, type) {
        // alert(e.target.checked)
        if (e.target.checked) {
            let arr = Array.from({ length: type === 'employee' ? employeeList.length : teamList.length }, (v, i) => i);
            setIsAll((prev) => !prev)
            setSelected(arr)
        } else {
            setIsAll(false)
            setSelected([])
        }

    }

    function handleChange(value) {

    }

    function sendMessage() {
        if (messageValue == '' || messageValue == null || messageValue == undefined) {
            setMessageError("Message is required.")
        } else if (messageValue.length > 100) {
            setMessageError("Message size can not be more than 100 character.")
        } else {
            setMessageError("")
        }
    }

    return (
        <div className={props.type?"siteViewMainDiv siteManagementMainDiv siteManagementMainDiv-"+props.type:"siteViewMainDiv siteManagementMainDiv"} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}>
            <Container>

                {props.hideHeading ? '' :
                    <Row>
                        <Col lg={6}>
                            <div className="siteViewHeaderDiv">
                                <span className="smallHeader" onClick={handleManpowerManagementList}>{getTranslatedText('Manpower Management')}</span>
                                <span className="breadCrumbArrow"> &gt; </span>
                                <span className="mediumHeader">{getTranslatedText('Employee Listing')}</span>
                            </div>
                        </Col>

                        <Col lg={6} className="text-right">
                            <div className="commonLangaugeStyleDiv">
                                <DashboardLanguage
                                    selectedLangValue={selectedLangValue}
                                    changeLanguage={changeLanguage}
                                />
                            </div>

                            <div className="siteHeadingDatePickerDiv" style={{ width: '20%' }}>
                                <CommonDatePicker
                                    selectedDate={selectedDate}
                                    handleSelectDate={handleDateSelect}
                                                    weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                                />
                            </div>
                        </Col>

                    </Row>
                }

                <Row className={props.hideHeading ? '' : 'm-t'}>
                    <Col lg={12}>
                        <div className={'siteListMainDiv ' + (props.hideHeading ? 'p-l-0 p-r-0' : '')} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}>
                            <Row style={{ alignItems: 'center' }}>
                                <Col lg={props.selectedTab ? 4 : 8} className={props.hideHeading ? 'p-l-0' : ''}>
                                    <h3 className="locationsListing">
                                        {props.title ? props.title : getTranslatedText('Employees/Teams')}
                                        {!props.selectedTab ?
                                            (`(${employeeList && employeeList.length})`)
                                            : null
                                        }
                                    </h3>
                                </Col>

                                {props.selectedTab &&
                                    <Col lg={4}>
                                        <div className="empTeamTabMainDiv" style={{ float: 'right' }}>
                                            <div className={'eachTab ' + (props.selectedTab == 'employees' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('employees')}>{getTranslatedText('Employees')}</div>
                                            <div className={'eachTab ' + (props.selectedTab == 'teams' ? 'activeTabBG' : '')} onClick={() => handleChangeTab('teams')}>{getTranslatedText('Teams')}</div>
                                        </div>
                                    </Col>
                                }

                                {props.hideSearch ? '' :
                                    <Col lg={4} className={props.hideHeading ? 'p-r-0' : ''}>
                                        <div className="listingSearchMainDiv">
                                            <input type="text" value={searchValue} name="siteSearch" placeholder="Search" onChange={(event) => updateSearchValue(event.target.value)} />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            {props.isManpower || props.threatEmployee ?
                                <Row style={{ alignItems: 'center', borderRadius: "0.35rem", border: '1px solid #cad1d7' }}>
                                    <Col lg={4} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                                        <div>
                                            <label className="extra-box-label">Communication</label>
                                            <Select defaultValue="Mobile Application" className="extra-box-dropdown" onChange={handleChange}>
                                                <Option value="Mobile Application">Mobile Application</Option>
                                                <Option value="SMS">SMS</Option>
                                                <Option value="Email">
                                                    Email
                                                </Option>
                                            </Select>
                                        </div>
                                    </Col>

                                    <Col lg={7}>
                                        <div className="listingSearchMainDiv">
                                            <input type="text" value={messageValue} name="message" placeholder="Message (100 Char Max)" onChange={(event) => updateMessageValue(event.target.value)} />
                                        </div>
                                        {
                                            messageError !== "" ?
                                                <span style={{ color: "red" }}>{messageError}</span>
                                                : ""
                                        }
                                    </Col>

                                    <Col lg={1}>
                                        <div className="empTeamTabMainDiv" >
                                            {/* <button type="submit" class="loginFormButton">Send</button> */}
                                            <Button type="default" style={{ color: "blue", backgroundColor: "#ea5d8b" }} onClick={sendMessage}>
                                                Send
                                            </Button>
                                        </div>
                                    </Col>
                                </Row> : ""
                            }

                            < Row >
                                <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                                    <div className="listingRecordMainDiv">
                                        {isLoading ?
                                            <div className="text-center m-t-lg">
                                                <img src={spinnerLoader} className="m-t-lg" />
                                            </div> :
                                            // employeeList && employeeList.length > 0 ?
                                            <React.Fragment>
                                                <div className="eachCard" >
                                                    <div className="card-body">
                                                        {props.isManpower && props.type == 'Employee' ?
                                                            <Row>
                                                                <Col className='flexDiv' style={{ maxWidth: "4%" }}>
                                                                    <div className="" style={{ paddingLeft: '6px' }}>
                                                                        <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'employee')} checked={isAll} />
                                                                    </div>

                                                                </Col>
                                                                {/* <Col 5} className="flexDiv"><strong className="gridHeaderTitileCustom">&nbsp;</strong></Col> */}
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "18%",minWidth:"18%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Name</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`employeeHelp`)} onMouseLeave={() => handleMouseLeave(`employeeHelp`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'name' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('name')}
                                                                    />
                                                                    <div className='descHelp' id='employeeHelp'>Name, Team, MAC ID of his tag.</div>
                                                                </Col>
                                                                
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Spread</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`spreadHelp`)} onMouseLeave={() => handleMouseLeave(`spreadHelp`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'spread_index' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('spread_index')}
                                                                    />
                                                                    <div className='descHelp' id='spreadHelp'>Risk of transmission through human to human interaction.</div>
                                                                </Col>
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Close Contacts</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`closeContact`)} onMouseLeave={() => handleMouseLeave(`closeContact`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'close_contacts' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('close_contacts')}
                                                                    />
                                                                    <div className='descHelp' id='closeContact'>Number of contacts where transmission through human to human is over 90%</div>
                                                                </Col>
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth: "12%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Interaction Time</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`pplHelp`)} onMouseLeave={() => handleMouseLeave(`pplHelp`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'interaction_time' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('interaction_time')}
                                                                    />
                                                                    <div className='descHelp' id='pplHelp'>Cumulated interaction time with other people.</div>
                                                                </Col>

                                                                <Col className='flexDiv b-r' style={{ maxWidth: "10%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Mobility</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`mobilityHelp`)} onMouseLeave={() => handleMouseLeave(`mobilityHelp`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'mobility_index' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('mobility_index')}
                                                                    />
                                                                    <div className='descHelp' id='mobilityHelp'>Risk of transmission through human to location interaction (Fomite Risk)</div>
                                                                </Col>
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                    <strong className="gridHeaderTitileCustom">P Loc. Visited</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`pplLocationVisited`)} onMouseLeave={() => handleMouseLeave(`pplLocationVisited`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'popular_loc_visited' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('popular_loc_visited')}
                                                                    />
                                                                    <div className='descHelp' id='pplLocationVisited'>Number of popular areas visited</div>
                                                                </Col>
                                                                <Col className='flexDiv b-r' style={{ maxWidth: "10%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Status</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`emplyStatus`)} onMouseLeave={() => handleMouseLeave(`emplyStatus`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'status' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('status')}
                                                                    />
                                                                    <div className='descHelp' id='emplyStatus'>Status of the person.</div>
                                                                </Col>
                                                                <Col className='flexDiv' style={{ maxWidth: "8%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Battery</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`batteryStatus`)} onMouseLeave={() => handleMouseLeave(`batteryStatus`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'batteryStatus' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('batteryStatus')}
                                                                    />
                                                                    <div className='descHelp' id='batteryStatus'>Level of battery charge in the Personal tag</div>
                                                                </Col>
                                                            </Row>
                                                            : props.isManpower && props.type == "Team" ?
                                                                <Row>
                                                                    <Col lg={1} className='flexDiv' style={{ paddingLeft: "23px", maxWidth: "4%" }}>
                                                                        <div className="">
                                                                            <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'team')} checked={isAll} />
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col lg={0.5} className="flexDiv"><strong className="gridHeaderTitileCustom">&nbsp;</strong></Col> */}
                                                                    <Col lg={2} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom">Teams</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamHelp`)} onMouseLeave={() => handleMouseLeave(`teamHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamName' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamName')}
                                                                        />
                                                                        <div className='descHelp' id='teamHelp'>Name, Team, MAC ID of his tag.</div>
                                                                    </Col>
                                                                    <Col lg={2} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom"> Spread Index</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamSpread`)} onMouseLeave={() => handleMouseLeave(`teamSpread`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamSpread' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamSpread')}
                                                                        />
                                                                        <div className='descHelp' id='teamSpread'>Risk of transmission through human to human interaction. </div>
                                                                    </Col>
                                                                    <Col lg={2} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom"> Close Contacts</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamCloseContact`)} onMouseLeave={() => handleMouseLeave(`teamCloseContact`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamCloseContact' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamCloseContact')}
                                                                        />
                                                                        <div className='descHelp' id='teamCloseContact'>Number of contacts where transmission through human to human is over 90%</div>
                                                                    </Col>
                                                                    <Col lg={2} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom"> Interaction Time</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamInteraction`)} onMouseLeave={() => handleMouseLeave(`teamInteraction`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamInteraction' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamInteraction')}
                                                                        />
                                                                        <div className='descHelp' id='teamInteraction'>Cumulated interaction time with other people.</div>
                                                                    </Col>
                                                                    <Col lg={2} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom"> Mobility Index </strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamMobility`)} onMouseLeave={() => handleMouseLeave(`teamMobility`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamMobility' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamMobility')}
                                                                        />
                                                                        <div className='descHelp' id='teamMobility'>Risk of transmission through human to location interaction (Fomite Risk)</div>
                                                                    </Col>
                                                                    <Col lg={1} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom">P Loc. Visited</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`teamppl`)} onMouseLeave={() => handleMouseLeave(`teamppl`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'teamppl' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('teamppl')}
                                                                        />
                                                                        <div className='descHelp' id='teamppl'>Number of popular areas visited</div>
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                <Row>
                                                                    {/* <Col lg={3} className='flexDiv'>
                                                                        <strong className="gridHeaderTitileCustom">Assigned Employee</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`employeeHelp`)} onMouseLeave={() => handleMouseLeave(`employeeHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'employee' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('employee')}
                                                                        />
                                                                        <div className='descHelp' id='employeeHelp'>Name, Team, MAC ID of his tag.</div>
                                                                    </Col>
                                                                    <Col lg={2} className="flexDiv b-l">
                                                                        <strong className="gridHeaderTitileCustom">Interactions</strong>
                                                                        <img alt='' src={helpIcon} className='helpicon' onMouseEnter={() => handleMouseEnter(`interactionHelp`)} onMouseLeave={() => handleMouseLeave(`interactionHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'interaction' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('interaction')}
                                                                        />
                                                                        <div className='descHelp' id='interactionHelp'>Number of recorded Interactions at the selected date</div>
                                                                    </Col>
                                                                    <Col lg={2} className="flexDiv b-l">
                                                                        <strong className="gridHeaderTitileCustom">Battery</strong>
                                                                        <img alt='' src={helpIcon} className='helpicon' onMouseEnter={() => handleMouseEnter(`batteryHelp`)} onMouseLeave={() => handleMouseLeave(`batteryHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'battery' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('battery')}
                                                                        />
                                                                        <div className='descHelp' id='batteryHelp'>Battery Status of the Tag</div>
                                                                    </Col>
                                                                    <Col lg={2} className='flexDiv b-l'>
                                                                        <strong className="gridHeaderTitileCustom">Activated</strong>
                                                                        <img alt='' src={helpIcon} className='helpicon' onMouseEnter={() => handleMouseEnter(`activatedHelp`)} onMouseLeave={() => handleMouseLeave(`activatedHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'activated' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('activated')}
                                                                        />
                                                                        <div className='descHelp' id='activatedHelp'>Date of activation for this Personal Tags</div>

                                                                    </Col> */}
                                                            <Col className='flexDiv' style={{ maxWidth: "2%" }}>
                                                                    <div className="" style={{ paddingLeft: '6px' }}>
                                                                        <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'employee')} checked={isAll} />
                                                                    </div>
                                                            </Col>
                                                                    
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "15%",minWidth:"15%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Name</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`employeeHelp`)} onMouseLeave={() => handleMouseLeave(`employeeHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'name' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('name')}
                                                                        />
                                                                        <div className='descHelp' id='employeeHelp'>Name, Team, MAC ID of his tag.</div>
                                                                    </Col>
                                                                    {props.threatEmployee=='risk'?
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "10%",minWidth:"10%" }}>
                                                                    <strong className="gridHeaderTitileCustom">Contact Rank</strong>
                                                                    <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`contactRank`)} onMouseLeave={() => handleMouseLeave(`contactRank`)} />
                                                                    <img
                                                                        alt=''
                                                                        className='sorticon'
                                                                        src={sortKey === 'rank' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                        onClick={() => handleSort('rank')}
                                                                    />
                                                                    <div className='descHelp' id='contactRank'>Contact rank of an employee.{sortKey} {sortType} </div>
                                                                </Col>:""}
                                                                    <Col className='flexDiv b-r' 
                                                                    
                                                                    style={{ maxWidth: "8%",minWidth:"8%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Spread</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`spreadHelp`)} onMouseLeave={() => handleMouseLeave(`spreadHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'spread_index' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('spread_index')}
                                                                        />
                                                                        <div className='descHelp' id='spreadHelp'>Risk of transmission through human to human interaction.</div>
                                                                    </Col>
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Close Contacts</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`closeContact`)} onMouseLeave={() => handleMouseLeave(`closeContact`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'close_contacts' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('close_contacts')}
                                                                        />
                                                                        <div className='descHelp' id='closeContact'>Number of contacts where transmission through human to human is over 90%</div>
                                                                    </Col>
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Interaction Time</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`pplHelp`)} onMouseLeave={() => handleMouseLeave(`pplHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'interaction_time' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('interaction_time')}
                                                                        />
                                                                        <div className='descHelp' id='pplHelp'>Cumulated interaction time with other people.</div>
                                                                    </Col>

                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "10%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Mobility</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`mobilityHelp`)} onMouseLeave={() => handleMouseLeave(`mobilityHelp`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'mobility_index' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('mobility_index')}
                                                                        />
                                                                        <div className='descHelp' id='mobilityHelp'>Risk of transmission through human to location interaction (Fomite Risk)</div>
                                                                    </Col>
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "12%",minWidth:"12%" }}>
                                                                        <strong className="gridHeaderTitileCustom">P Loc. Visited</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`pplLocationVisited`)} onMouseLeave={() => handleMouseLeave(`pplLocationVisited`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'popular_loc_visited' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('popular_loc_visited')}
                                                                        />
                                                                        <div className='descHelp' id='pplLocationVisited'>Number of popular areas visited</div>
                                                                    </Col>
                                                                    
                                                                    {props.type=='threat'&&props.threatEmployee=='contaminated'?<Col className='flexDiv b-r' style={{ maxWidth: "14%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Status on<span className="small">{getDateFormat(props.endDate)}</span></strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`emplyStatus`)} onMouseLeave={() => handleMouseLeave(`emplyStatus`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'status' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('status')}
                                                                        />
                                                                        <div className='descHelp' id='emplyStatus'>Status of the person.</div>
                                                                    </Col>:<Col className='flexDiv b-r' style={{ maxWidth: "10%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Status</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`emplyStatus`)} onMouseLeave={() => handleMouseLeave(`emplyStatus`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'status' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('status')}
                                                                        />
                                                                        <div className='descHelp' id='emplyStatus'>Status of the person.</div>
                                                                    </Col> }
                                                                    {props.type=='threat'&&props.threatEmployee=='contaminated'?
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "10%" }}>
                                                                        <strong className="gridHeaderTitileCustom">
                                                                           Positive On
                                                                        </strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`positiveOn`)} onMouseLeave={() => handleMouseLeave(`positiveOn`)} />
                                                                        <div className='descHelp' id='positiveOn'>Reported Positive Date</div>
                                                                     </Col>:'' }
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "8%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Battery</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`batteryStatus`)} onMouseLeave={() => handleMouseLeave(`batteryStatus`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'batteryStatus' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('batteryStatus')}
                                                                        />
                                                                        <div className='descHelp' id='batteryStatus'>Level of battery charge in the Personal tag</div>
                                                                    </Col>
                                                                    {props.type=='Visitor'?
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "14%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Check In</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`checkin`)} onMouseLeave={() => handleMouseLeave(`checkin`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'checkin' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('checkin')}
                                                                        />
                                                                        <div className='descHelp' id='checkin'>Check In timr of the person.</div>
                                                                    </Col>:""}
                                                                    {props.type=='Visitor'?
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "14%" }}>
                                                                        <strong className="gridHeaderTitileCustom">Check Out</strong>
                                                                        <img alt='' className='helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`checkout`)} onMouseLeave={() => handleMouseLeave(`checkout`)} />
                                                                        <img
                                                                            alt=''
                                                                            className='sorticon'
                                                                            src={sortKey === 'checkout' ? sortType === 'asc' ? upIcon : downIcon : sortIcon}
                                                                            onClick={() => handleSort('checkout')}
                                                                        />
                                                                        <div className='descHelp' id='checkout'>Check Out time of the person.</div>
                                                                    </Col>:""}
                                                                    {props.type!='threat'?
                                                                    <Col className='flexDiv b-r' style={{ maxWidth: "6%" }}>
                                                                    </Col>:''
                                                                        }
                                                                </Row>
                                                        }
                                                    </div>
                                                </div>

                                                <Scrollbars autoHide style={{ width: '100%', height: window.innerHeight - (props.type ? 370 : 280) }}>
                                                    {showCardList(employeeList)}
                                                    {searchValue && employeeList.length == 0 ?
                                                        <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                                    }
                                                </Scrollbars>

                                            </React.Fragment>
                                            // : ''
                                        }

                                        {/* {searchValue && employeeList.length == 0 ?
                                            <h3 className="text-center m-t-lg">No Records Found !</h3> : ''
                                        } */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(EmployeeList))
