import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import { Slider } from 'antd';

export default function AutomatedFOPFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [volume,SetVolume]= useState('');    

    function filterResultHandler(){
        SetFilterFOP(true);

        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          productivity:filterSlide,
          volume:volume?volume:0
        }
        props.filterResultHandler(requestBody,true);
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])
    useEffect(()=>{
      setDate((prev)=>({...prev,['start']:props.date}) )
    },[props.date])


    
  const volumeHandler = (event) => {
    // SetVolume(e.target.value);
      SetFilterFOP(false);
    const { value } = event.target;
    // Allow only numbers (0-9) and the backspace key (8)
    const allowedKeys = [8];
    const allowedDigits = /^[0-9]$/;

    // Check if the pressed key is allowed
    if (value.length === 0 || (!isNaN( parseFloat(value)))) {
      if(value.length != 0){
        SetVolume(parseFloat(value));
      }else{
        SetVolume('');
      }
      
    }
    props.filterResultHandler({},false)
  };

    
    return (
<Row className="">

                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={7} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Target Performance")}
                      </label>
                    <div className="mgSpace">
                        {/* <Slider  value={filterSlide}   onChange={(val)=>{SetFilterSlide(val);SetFilterFOP(false);}}  /> */}
                         <Slider
                            value={filterSlide}

                            onChange={(val)=>{SetFilterSlide(val);SetFilterFOP(false);props.filterResultHandler({},false)}}
                            step={1}
                            min={0}
                            tipFormatter={(index) =>  index}
                            tooltipVisible
                            tipProps={{ placement: 'top', visible: true }}
                            max={100}  defaultValue={30} open={true} 
                        />
                        </div>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Projected Volume")} 
                    </label>
                     <input type="text" className="form-control"value={volume} onChange={(e)=>volumeHandler(e)}/>   
                </Col>


<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start=='' || filterSlide=="" || (volume.length > 0 )  }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                </div>
</Col>


                </Row>
                )

}