import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Checkbox, AutoComplete ,Select,Radio } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import '../../siteManagement/styles/siteManagement.scss'
import "../../policyManagement/styles/createPolicy.scss"
import spinnerLoader from  "../../assets/images/loader.svg"
import { getTranslatedText } from '../../common/utilities';
import { Scrollbars } from "react-custom-scrollbars";
// import {getSiteLocations,getSiteOverview} from '../../siteManagement/actionMethods/actionMethods';
// import {getDepartmentList,getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods';
// import {getPolicyEmployee,getPolicyLocation,getPolicyTeam,postCreatePolicy} from '../actionMethods/actionMethods';
import {checkoutTempEmp, checkoutVisitor,getTempEmpDetail,getVisitorDetails,getVisitorTagList} from '../actionMethods/actionMethods'
import '../../policyManagement/styles/createPolicy.scss'
import moment from 'moment'
import { render } from 'react-dom';
import SucessIcon from  "../../assets/images/success.png"
import CrossIcon from "../../assets/images/cross.png"



function CheckOut(props){


    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
     let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    let lastReqId = null
    const [activeTag,updateActiveTag] =useState([]);
    const [inactiveTag,updateInActiveTag] =useState([]);
    const [availableTag,updateAvailableTag] =useState([]);
    const[contactPersonData,updatecontactPersonData] =useState([])
    const[SelectedTagID,updateSelectedTagID] =useState('')
    const[SelectedTagIDError,updateSelectedTagIDError] =useState('')
    
    const [visitorEmail,updateVisitorEmail] =useState('');
    const [visitedEmployeeName,updateVisitedEmployeeName] =useState('');
    const [shiftTime,updateshiftTime] =useState('');
    const [loader,SetLoader]=useState(true);
    const { Option } = Select;
    const [visitorCheckOutFlag,updatevisitorCheckOutFlag]=useState(false);
    const [allTagRequest,SetAllTagRequest] =useState([]);
    const [visitorName,updateVisitorName]= useState('');
    const [visitorSlot,SetVisitorSlot]=useState('');
    function onChange(value) {
        
      }
      
      function onSearch(val) {
       
      }
    useEffect(()=>{

    if(props.activeTab =='visitor'){
    let data =props.tagList
    if(data.active){
        updateActiveTag(data.active)
    }
    if(data.available_for_today){
        updateAvailableTag(data.available_for_today)
    }
    if(data.inactive){
        updateInActiveTag(data.inactive)
    }    
    setTimeout(function(){SetLoader(false)},100)
    }
},[props.tagList])

useEffect(()=>{
   
    if(props.activeTab =='tempemployee'){

    let data =props.tagListTemp;
    if(data.active){
        updateActiveTag(data.active)
    }
    if(data.available_for_today){
        updateAvailableTag(data.available_for_today)
    }
    if(data.inactive){
        updateInActiveTag(data.inactive)
    }    
    setTimeout(function(){SetLoader(false)},100)
}

},[props.tagListTemp])


function submitHandleCheckOut(event){

    event.preventDefault();

    if(SelectedTagID==''){
       updateSelectedTagIDError('Please Select Tag ID')
    }else{
        //for testing Email
        updateSelectedTagIDError('')
           
    
            let requestBody=[]
            requestBody.tag_serial = SelectedTagID;
    
            checkoutVisitor(requestBody, userSession, org_id,sub_org_id).then(res => {
               
                if (res.message =='success' || res.message !='') {
                    
                    updatevisitorCheckOutFlag(true);
                  setTimeout(function(){
                    props.handleCloseModal(); 
                  },3000);
                    props.reloadData();      
    
                }else{
                    
                }
            });

    
        }    
    
    
    



}

function submitHandleCheckOutTemp(event){

    event.preventDefault();

    if(SelectedTagID==''){
       updateSelectedTagIDError('Please Select Tag ID')
    }else{
        //for testing Email
        updateSelectedTagIDError('')
           
    
            let requestBody=[]
            requestBody.tag_serial = SelectedTagID;
    
           
            checkoutTempEmp(requestBody, userSession, org_id,sub_org_id).then(res => {
           
                if (res.message =='success' || res.message !='') {
                    
                    updatevisitorCheckOutFlag(true);
                  setTimeout(function(){
                    props.handleCloseModal(); 
                  },3000);
                    props.reloadData();      
    
                }else{
                    
                }
            });

    
        }    
    
}

function onChangeTagID(e){
  
    let arr=[]
    updateSelectedTagID(e)
    let oldData=allTagRequest
    let item  = oldData.find(item => item.tag_serial === e);
    if(item){
        updateVisitorName(item.visitor_name)
        updateshiftTime(item.shift);
        
    }  else{
        if(props.activeTab=='visitor'){
            getVisitorDetails(userSession, org_id,e).then(res=>{
                arr= res.data[0];
                arr.tag_serial=e
    
                updateVisitorName(arr.visitor_name)
                updateshiftTime(arr.shift);
                           
                oldData.push(arr);
                SetAllTagRequest(oldData);
        
            }).catch();
        }else{
            let requestBody ={}
            requestBody.worker_id=e 
            getTempEmpDetail(requestBody, userSession, org_id,sub_org_id).then(res=>{
                arr= res.data[0];
                arr.tag_serial=e
    
                updateVisitorName(arr.visitor_name)
                updateshiftTime(arr.shift);
                          
                oldData.push(arr);
                SetAllTagRequest(oldData);
        
            }).catch();
            
        }

    
    }
    

}

function onFocusTagID(){

}

function onBlurTagID(){

}

function showTagID(){
    let arr= []
    for(let i=0; i<activeTag.length;i++){
        arr.push( <Select.Option key={activeTag[i]} value={activeTag[i]}>
            {activeTag[i]}
        </Select.Option>);
    }
        
    return arr;

}




    return(
        <div> {visitorCheckOutFlag===false?
        <div className={loader?'createPolicyMainDiv createPolicyMainDiv-Less-space loading':"createPolicyMainDiv createPolicyMainDiv-Less-space"}>
        <div className='visitor-loader'><img src={spinnerLoader}/><img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/></div>  
  {activeTag.length==0?<div className='message-text'><h5 className="font-bold " style={{marginBottom:"0px"}}>No Visitor in Premise </h5></div>:
<form >
<h5 className="font-bold" style={{marginBottom:"0px"}}>Check Out<img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/> </h5>

                                            <Row>
                                                <Col lg={12}>
                                            <div className="activeSelect">
                                            <label>Select Tag Serial Number</label>
                                            


                                            <Select
    showSearch
    placeholder=""
    optionFilterProp="children"
    onChange={onChangeTagID}
    onSearch={onSearch}
    value={SelectedTagID}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
   {showTagID()}
  </Select>                      
  {SelectedTagIDError?<div class="error-message">{SelectedTagIDError}</div>:""} 
                                                    
 

                                            </div>    
                                        </Col>

                                        </Row>
                                        <Row className="m-t">
                                        {<Col lg={12}  className="">
                                            <label>Name </label>
                                            <input type="text" readOnly value={visitorName} placeholder="" onChange={(e)=>updateVisitorName(e.target.value)} />
                                        </Col> }
                                        </Row>

                                        <Row className="">
                                        {<Col lg={12}  className="activeSelect ">
                                            <label>Work Slot </label>

            <input type="text" readOnly value={shiftTime} placeholder="" onChange={(e)=>updateVisitorName(e.target.value)} />
                                           {/* {policyName==''?errorName?<div class="error-message">{errorName}</div>:"":""} */}
                                        </Col> }
                                        </Row>
 
                                        <Row className="m-t">
                                        <div className='actiondiv'>
                                            {props.activeTab=='visitor'?<button type="submit" onClick={submitHandleCheckOut} className='btn button btn-submit btn-add-visitor'>Check Out!</button>:<button type="submit" onClick={submitHandleCheckOutTemp} className='btn button btn-submit btn-add-visitor'>Check Out!</button>}
                                            </div>
                                        </Row>
 


</form>
}
    </div>:<div className='createPolicyMainDiv createPolicyMainDiv-Less-space visitorcheckIn'>
    <img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/>
                    <div className="sucess-inner">
                        <img src={SucessIcon}/>

                    </div>
                    <div className='logoutTitle'>{props.activeTab =='tempemployee'?'Temporary Employee':'Visitor'} Check Out Sucessfully</div>
                    <div className='actionButton'>
                        <span className='btnText okBtn bnt-close-bigger' onClick={()=>props.handleCloseModal()}>Close </span>
                    </div>
                </div>}

    </div>
    )
}
export default CheckOut;