
import React, { useRef, useState,useEffect } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import '../../cycleCount/styles/style.scss'
import {ReactComponent as Plus} from  '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import {ReactComponent as Eye} from '../../assets/images/eye.svg'
import Scrollbars from 'react-custom-scrollbars';
//import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';
import { getPproductivitylist } from '../../productivity/actionMethods/actionMethods';

import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import {getCycleCount} from '../../cycleCount/actionMethods/actionMethods'
import ExcelJs from "exceljs";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


function CycleCount(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [dataCountCycle,SetDataCountCycle]= useState([])

 const [location,SetLocation] = useState([]);
 
 const [loader,SetLoader]=useState(true);

const [employee,SetEmployee] =useState([]);
const [permission,SetPermission]=useState({View:"False",Create:"False",edit:"False",remove:"False"});
const [permissionAPICall,SetPermissionAPICall]=useState(false);
  
 const [parentAccord,SetParentAccord]=useState([]);
 const [modalData,SetModalData]=useState([]);
 const [showModal,SetShowModal]=useState(false);

 //const [dataAll,SetDataAll] =useState([]);
 const [agDataHour,SetAgDataHour]=useState([]);
 const [widthContainer, setWidthContainer] = useState(0);
 const elementRef = useRef(null);  
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  let hourCheck = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00']
  
  const headerRow=[
    'Time','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00'
  ]

  const headerRow2Disp=[
    'Time','07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM','02 PM','03 PM','04 PM','05 PM','06 PM','07 PM','08 PM','09 PM','10 PM','11 PM','00 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM'
  ]
  const headerTime= [
    7,8,9,10,11,12,13,
    14,15,16,17,18,19,20,
    21,22,23,0,1,2,3,4,5,6
  ]


  
  useEffect(() => {

    if(permission.View && permissionAPICall){

      setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }
    
   
  }, [elementRef,permission]);

  function getRandomItem(arr,no=5) {
    let data=[];
    for(let i=0;i<no;i++){
      const randomIndex = Math.floor(Math.random() * arr.length);
      data.push(arr[randomIndex]) ;

    }
    return data;
}
function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

const [columnDefs, setColumnDefs] = useState([]);

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    let perm= props.role.manpower_management?JSON.parse(props.role.manpower_management):null;
    if(perm){



        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{

    }
},[props.pages]);

useEffect(()=>{

  SetLocation(props.locationList);
  
  if(props.filterEnableCycle){ 
    let loc = props.location_id.map((item)=>{ return props.locationList.find(ar=>ar.loc_tag == item) });

   
    let deptTag=[];
    let dept = props.department_id;
    let subdept= props.sub_department_id;
    if(dept.length == 1 && subdept.length==1 ){
      deptTag = employee.filter((item) => item.department == dept[0] && item.sub_department== subdept[0] ).map((item)=>{ return item.tag_serial});
    }
    else if (dept.length > subdept.length ){
      for(let i=0;i<dept.length;i++){
        let dt= employee.filter((item) => item.department == dept[i]).map((item)=>{ return item.tag_serial});
        
        deptTag.push(...dt);
      }
    }else{
      for(let i=0;i<subdept.length;i++){
        let dt= employee.filter((item) => item.department == dept[0] && item.sub_department== subdept[i] ).map((item)=>{ return item.tag_serial});
        deptTag.push(...dt);
      }
    }


  getCycleCount(userDetails.session,org_id,getDateFormat(props.start_date),props.cycleFilterSlug,props.cycleFilterSlug=='one_hour'?props.cycleFilter:'').then(res=>{
  
    let dataformat=[];
    let hourpush=[];
    let data=res.data;
    if(res.status==200){  
    for(let i=0;i<loc.length;i++){
      let allData= data[loc[i].loc_tag];
      let singleCount={};
      let emp=[];

      let dataNew = loc[i]
    
      singleCount.name= dataNew.name;
      singleCount.data=[];      
      if(props.cycleFilterSlug=='five_min'){
      for(let y=0;y<24;y++){
        for(let z = 0; z <=55; z = z + 5){
          let singleRecord={}
          singleRecord.hour = `${y <10?"0"+y:y}:${z <10?"0"+z+":00":z+":00"}`;
          let hourReport = `${y <10?"0"+y+":00":y+":00"}`

           let matchingHour = `${y <10?"0"+y:y}:${z <10?"0"+z:z}`;
  
          if(hourpush.indexOf(singleRecord.hour)== -1){
            hourpush.push(singleRecord.hour);
          }
  



                  let hour = y<10?"0"+y+":00":y+":00"
                  let plan = allData?allData.plan.find(item =>item.hour == hour):null;

                  if(plan){
                    
                    singleRecord.plan= plan.cycle_count
            
                  }else{
                    singleRecord.plan= 0
                    
                  }
                  let actual = allData?allData.actual.find(item =>item.contact_time == matchingHour):null;
                  
                 if(actual){
                  

                  var filteredArray = actual.nodes.filter(function(n) {
                    return deptTag.indexOf(n) !== -1;
                 });

                  singleRecord.actualWorker=filteredArray.length;
                  singleRecord.actualWorkerList= filteredArray;
                  singleRecord.reported_date = actual.reported_date;
                  singleRecord.reported_time = moment(actual.reported_date).set('hour',y).set('minute', z).set('second', 0)
                 
                 }else{
                  singleRecord.actualWorker=0;
                  singleRecord.actualWorkerList=[];
                  
                  if(hourCheck.indexOf(hourReport) > -1){
                    singleRecord.reported_date= moment(props.start_date).add('days',1).format('YYYY-MM-DD')
                    singleRecord.reported_time = moment(singleRecord.reported_date).set('hour',y).set('minute', z).set('second', 0)
                    
                  }else{
                    singleRecord.reported_date= moment(props.start_date).format('YYYY-MM-DD')
                    singleRecord.reported_time = moment(props.start_date).set('hour',y).set('minute', z).set('second', 0)
                  }

                 }
                 singleCount.data.push(singleRecord);
        } 
     
    }
  }else{

    for(let y=0;y<24;y++){
      singleCount.data[`${y}`]={}
      let hour = y<10?"0"+y+":00":y+":00"


        if(hourpush.indexOf(hour)== -1){
          hourpush.push(hour);
        }
       singleCount.data[`${y}`].hour= hour
      let plan = allData?allData.plan.find(item =>item.hour == hour):null;
      if(plan){
        if(hourCheck.indexOf(hour)> -1){
          if(plan.reported_date == moment(props.start_date).add('days',1).format('YYYY-MM-DD')){
            singleCount.data[`${y}`].plan= plan.cycle_count  
            
          }else{
            singleCount.data[`${y}`].plan= 0  
          }
   
        }else{
          singleCount.data[`${y}`].plan= plan.cycle_count
        }
        
      }else{
        singleCount.data[`${y}`].plan= 0
      }
      let actual = allData?allData.actual.find(item =>item.contact_time == hour):null;
      singleCount.data[`${y}`].actual={}
     if(actual){
      var filteredArray = actual.nodes.filter(function(n) {
        return deptTag.indexOf(n) !== -1;
     });

      if(hourCheck.indexOf(hour)> -1){
        if(actual.reported_date == moment(props.start_date).add('days',1).format('YYYY-MM-DD')){
          singleCount.data[`${y}`].actual.actualWorker=filteredArray.length;
          singleCount.data[`${y}`].actual.actualWorkerList=filteredArray;
          singleCount.data[`${y}`].reported_date= actual.reported_date
          singleCount.data[`${y}`].reported_time = moment(actual.reported_date).set('hour',y).set('minute', 0)
        }else{
          singleCount.data[`${y}`].actual.actualWorker=0;
          singleCount.data[`${y}`].actual.actualWorkerList=[];    
          singleCount.data[`${y}`].reported_date= actual.reported_date
          singleCount.data[`${y}`].reported_time = moment(actual.reported_date).set('hour',y).set('minute', 0)
        }

      }else{
        if(hourCheck.indexOf(hour)> -1){
          singleCount.data[`${y}`].reported_date=moment(props.start_date).add('days',1).format('YYYY-MM-DD');
          singleCount.data[`${y}`].reported_time = moment(props.start_date).add('days',1).set('hour',y).set('minute', 0)
        }else{
          singleCount.data[`${y}`].reported_date=moment(props.start_date).format('YYYY-MM-DD');
          singleCount.data[`${y}`].reported_time = moment(props.start_date).set('hour',y).set('minute', 0)
        }
        singleCount.data[`${y}`].actual.actualWorker=filteredArray.length;
        singleCount.data[`${y}`].actual.actualWorkerList=filteredArray;
      }

     }else{
      if(hourCheck.indexOf(hour)> -1){
        singleCount.data[`${y}`].reported_date=moment(props.start_date).add('days',1).format('YYYY-MM-DD');
        singleCount.data[`${y}`].reported_time = moment(singleCount.data[`${y}`].reported_date).set('hour',y).set('minute', 0)

      }else{
        singleCount.data[`${y}`].reported_date=moment(props.start_date).format('YYYY-MM-DD');
        singleCount.data[`${y}`].reported_time = moment(props.start_date).set('hour',y).set('minute', 0)
      }
      singleCount.data[`${y}`].actual.actualWorker=0;
      singleCount.data[`${y}`].actual.actualWorkerList=[];
     }
     
    }
   
  



    
  }


    dataformat.push(singleCount);

    }

    let agData=[];
      for(let j=0;j<hourpush.length;j++){
        let singleData = {};
        for(let i= 0 ;i<loc.length;i++){
          singleData.hour = hourpush[j];
          
          let paticularlocData= dataformat.find(item=>item.name == loc[i].name)          
          if(paticularlocData){
            if(props.cycleFilterSlug=='one_hour'){
              singleData[`${slugify(loc[i].name)}_actual`] = paticularlocData.data[j].actual.actualWorker
              singleData.reported_date = paticularlocData.data[j].reported_date
              singleData.reported_time = paticularlocData.data[j].reported_time
              singleData[`${slugify(loc[i].name)}_actualCount`] = paticularlocData.data[j].actual.actualWorkerList
              singleData[`${slugify(loc[i].name)}_planned`] = paticularlocData.data[j].plan
  
            }else{
              singleData[`${slugify(loc[i].name)}_actual`] = paticularlocData.data[j].actualWorker
              //singleData[`${slugify(loc[i].name)}_reported_date`] = paticularlocData.data[j].reported_date
              singleData.reported_date = paticularlocData.data[j].reported_date
              singleData.reported_time = paticularlocData.data[j].reported_time
              singleData[`${slugify(loc[i].name)}_actualCount`] = paticularlocData.data[j].actualWorkerList
              singleData[`${slugify(loc[i].name)}_planned`] = paticularlocData.data[j].plan
            }
          }

          
        }
        agData.push(singleData);

      }

      agData = agData.sort(function(a, b) {
        var c = moment(a.reported_time);
        var d = moment(b.reported_time);

        return c-d;
    });
      

      SetAgDataHour(agData);


      SetDataCountCycle(dataformat);

      props.exportEnableDisable();




  }
    
  })
}
  
SetLoader(false);  


},[props.filterEnableCycle,props.start_date])

useEffect(()=>{
if(props.exportEnableCycle){
  exportToExcel();
}
},[props.exportEnableCycle])

function numToSSColumnLetter(num) {
  let columnLetter = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    columnLetter = String.fromCharCode(65 + t) + columnLetter;
    num = (num - t) / 26 | 0;
  }
  return columnLetter || undefined;
}
function numberToLetters(num) {
  let letters = ''
  while (num >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
      num = Math.floor(num / 26) - 1
  }
  return letters
}
const exportToExcel = () => {
  let sheetName = "CycleCount";
  let headerName= "Report"
  const filename = "CycleCount"+"-"+moment(props.start_date).format("MMM DD")+".xlsx";
  const workbook = new ExcelJs.Workbook();
  let loc = props.location_id.map((item)=>{ return props.locationList.find(ar=>ar.loc_tag == item) });
  
  let sheet = workbook.addWorksheet('Cycle Count', {
    views: [{ state: "frozen",ySplit: 2,xSplit:1 }],
  });


  
  let rows1=["Time Stamp"];
  let rows2=[""]

  let colsData=[] 
  for(let i= 0;i<loc.length;i++){
    rows1.push(loc[i].name,'','');    
    rows2.push('Plan');
    rows2.push('Actual');
    rows2.push('Employee');
  }
  sheet.addRow(rows1);
  let index= 0;
  for(let i= 1;i<=loc.length;i++){
    index=i+1
    if(i>1){
      sheet.mergeCells(numberToLetters((i*3) +1)+(1)+':'+numberToLetters((i*3) +3)+(1)); 
    }else{
      sheet.mergeCells('B1:D1'); 
    }    

  }

  sheet.addRow(rows2);

  for(let i= 0;i<agDataHour.length;i++){
    let data=[];
    

    data.push(moment(agDataHour[i].reported_time).format('DD-MM-YYYY hh:mm A'));

    for(let j=0;j<loc.length;j++){
      data.push(agDataHour[i][`${slugify(loc[j].name)}_planned`]==0?"-":agDataHour[i][`${slugify(loc[j].name)}_planned`]);
      data.push(agDataHour[i][`${slugify(loc[j].name)}_actual`]==0?"-":agDataHour[i][`${slugify(loc[j].name)}_actual`]);
      let empName = agDataHour[i][`${slugify(loc[j].name)}_actualCount`].map((item)=>{
        let exist = employee.find((el)=>el.tag_serial == item)
        if(exist ){
          return exist.emp_name +" ("+exist.tag_serial+") ("+exist.worker_id+")";
        }
      })
      data.push(empName.length>0?empName.join(', '):"-");
    }
    sheet.addRow(data);
  }



  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;"
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };
  //const stream = fs.createWriteStream(filename);

  workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(filename, buffer);
  });
  props.exportProcessExist();



};


useEffect(() => {

},[props.exportEnableCycle])

useEffect(() => {
  let requestBody={}
    requestBody.date= getDateFormat(date);



    let arr=[];

    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug=props.type;
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
    
        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);

              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              delete element[i]['access_policy_breach']; 
              delete element[i]['less_productive']; 
              delete element[i]['non_productive']; 
              delete element[i]['productive']; 
              delete element[i]['undefined']; 
              delete element[i]['wasted_time']; 
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }

      SetEmployee(empList)      
    })



},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}


function slugify(string) {  
  return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
}


function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}

function colorDataRenderer(params,locationName){
  var eGui = document.createElement('div');
  let plan =  params.data[`${slugify(locationName)}_planned`]   
  let act =  params.data[`${slugify(locationName)}_actual`]   
    
      
      if(act==0){
        eGui.innerHTML = '-';
      }   
      else if(plan > act){
            eGui.innerHTML = '<div class="redFont">'+params.value+'</div>';
          }else{
            eGui.innerHTML = eGui.innerHTML = '<div class="greenFont">'+params.value+'</div>';
          }
          return eGui;          
    } 
    
  //params.data,params.value,location

  function ViewBoxModalHour(params,data,value,location){

    let hour = data.hour;
    let arr;    
    let value1 = dataCountCycle.find(item=>item.name==location);
    let value2 = value1?value1.data.find(item=>item.hour== hour):[];
    let hourData= [];
   
    if(value2){
      let allnode=[];
      if(props.cycleFilterSlug=='five_min'){
        allnode=value2.actualWorkerList.length===0?[]:value2.actualWorkerList
      }else{
        allnode=value2.actual.actualWorkerList.length===0?[]:value2.actual.actualWorkerList
      }
      
      for(let i =0 ;i<allnode.length;i++ ){
        let emp = employee.find(item=>item.tag_serial == allnode[i]);
        if(emp){
          hourData.push(emp);
        }else{
          let blankArray = {}
          blankArray.emp_name=' - '
          blankArray.department=' - '
          blankArray.sub_department=' - '
          blankArray.tag_serial=allnode[i];
          hourData.push(blankArray);          
        }

      }   

      arr = ModalFormatData(hourData,location,hour);
    }
    
    SetModalData(arr);
    SetShowModal(true)
  } 
  
  function ModalFormatData(hourData,location_id ,hour){

    let arr =[];
    arr.push(
      <div className='titleArea'> 
        <h4>{location_id.toLowerCase()} ({hour}) <button className='alignRightFixed' onClick={()=>SetShowModal(false)}>x</button></h4>
      </div>
    )
    
    arr.push(AGTable(hourData))
    return arr;
    } 

    function AGTable(data){

      if(data){
    
        let widthCal= 0;
        
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100- 125)/5) ;
        }else{
          widthCal=((( (window.innerWidth * 80)/100) - 125)/5) - 10;
        }
      
      
      
      let arr;
        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 50,field: 'name',
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              onCellClicked={ (event) => window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")  }
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
                      <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />

           <AgGridColumn
              field="tag_serial"
              headerName={"Tag Serial"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="department" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_department"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_department"
             headerName="View"
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} 
             width={80}
             >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else{
      return ;
    }
    
    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }
function AGTableHour(data){
  
  let arr=[];
  if(data){
 
    let cols=[];
    cols.push({headerName: 'TimeStamp',field:'reported_time',pinned:'left',width:"140",valueFormatter:(params)=>moment(params.value).format('DD-MM-YYYY hh:mm A')});
    for(let i=0;i<location.length;i++){
      let paticularlocData= dataCountCycle.find(item=>item.name == location[i].name);          
      if(paticularlocData){

      
      cols.push({
        headerName: location[i].name,
        cellStyle: { 'border-right': '1px solid #ddd'},
        headerClass:"borderRightCell",
        //autoHeight: true,
        headerHeight:30,
        rowHeight:30,
        
        children: [
          {
            field: `${slugify(location[i].name)}_planned`,
            headerName: 'Planned',
            width: props.location_id.length<6?(widthContainer - 100)/(props.location_id.length * 2) : 75,
            cellStyle: {'background-color': '#f3f4f3'},
            headerClass:"plannedTitle",
            //autoHeight: true,
            rowHeight:30,
            headerHeight:30,
            valueFormatter:(params) =>params.value==0?'-':params.value

          },
          {
            field: `${slugify(location[i].name)}_actual`,
            headerName: 'Actual',
           // width: 75,
            width: props.location_id.length<6?(widthContainer - 100)/(props.location_id.length * 2) : 75,
            onCellClicked:(params)=>ViewBoxModalHour(params,params.data,params.value,location[i].name),
            
            cellStyle: { 'border-right': '1px solid #ddd'},
            cellRenderer:(params)=>colorDataRenderer(params,location[i].name),
            headerClass:"borderRightCell",
            //autoHeight: true,
            rowHeight:30,
            headerHeight:30,
            valueFormatter:(params) =>params.value==0?'-':params.value
          },
        ],
      });
    }
    }

   return( <div style={{width:"100%",height:props.cycleFilterSlug=='one_hour'?"840px":"1200px"}} className={props.location_id.length<4?"headerHightDouble bottomScroll":"headerHightDouble"}>
    <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine">
      <AgGridReact
        rowData={data}
        columnDefs={cols}
        //defaultColDef={{sortable: true,resizable: true,filter: false}}
        defaultColDef={{sortable: true,resizable: true,rowHeight:30}}     
        //groupDisplayType={'groupRows'}      
        groupDisplayType={'groupCols'}  
        animateRows={false}    
        rowGroupPanelShow={'always'}
        columnGroupPanelShow={'always'}
        paginationAutoPageSize={true}
        pagination={true}
        headerHeight={30}
        rowHeight={30}
        scrollbarWidth={10}
        //onGridReady={onGridReady}
      ></AgGridReact>
    </div>
  </div>)



  }

}
  function  GridExample() {

 
  
    //return arr;
  };

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
        
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  function handleCloseModal(){
    SetShowModal(false)
  }

  useEffect(()=>{

    // exportEnableDisable={exportEnableDisable}
    // filterEnableDisable={filterEnableDisable}
    // min={filterSlide[0]}
    // max={filterSlide[1]}
    // date={dates}
    // department_id= {department_id}
    // sub_department_id= {sub_department_id}
    // filterEnableCycle ={filterEnableCycle}
    // exportEnableCycle = {exportEnableCycle}


  },[props])

  return (<>
    {permissionAPICall?permission.length > 0?<div className="manpowerManagementMainDiv" >
      <Container className="header-breadcrumb">
       <Row className="m-t" style={{position:"relative",zIndex:9}}>
          <Col lg={12} ref={elementRef}>
            <div className=''>
              {loader?<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>:<>{<div className=''> {props.filterEnableCycle?AGTableHour(agDataHour):""}</div>}
                    
                  </>
                    }
            </div>
          </Col>
        </Row>
      </Container>
      <ReactModal
                isOpen={showModal}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>


    </div>:<Col lg={12}>
    {/* <div className='errorPermission'></div> */}
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>}
  </>);
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(CycleCount)
);
