import React,{useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696" 
}
const BarChartPerformance = (props) => {

    const [selectedDate, setSelectedDate] = useState(null);

    const handleBarClick = (date) => {
        setSelectedDate(date);
        // Call the callback function to send the selected date to the parent
        if (props.onDateSelected) {
            props.onDateSelected(date);
        }
    };
    let config = {
        chart: {
            type: 'column',
            height:props.height,
            borderRadius: 8,
            zoomType: 'x',
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        legend:{
            enabled:false
        },
        plotOptions: {
            series: {
                allowPointSelect:true,
                states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
              },
              point: {
                events: {
                  click: function () {
                    const point = this;
                    const chart = point.series.chart;
          
                    // Check if this point is already selected
                    const isAlreadySelected = point.selected;
          
                    // Deselect all points in the series
                    point.series.points.forEach((p) => {
                      p.update({
                        selected: false,
                        color: '#90cf4e',        // Default color when deselected (green)
                        borderColor: '#90cf4e', // Default border color when deselected (green)
                      }, true, false);
                    });
          
                    // Toggle the selection state for this point
                    point.update({
                      selected: !isAlreadySelected,
                      color: !isAlreadySelected ? '#ef5e8c' : '#90cf4e', 
                      borderColor: !isAlreadySelected ? '#ef5e8c' : '#90cf4e', 
                    }, true, false);
          
                   
                    if (props.performanceType === 'week') {
                      const tooltip = point.options.tooltip;
                      if (tooltip) {
                        //handleBarClick(tooltip);
                      }
                    } else {
                      const category = point.category;
                      if (category) {
                        handleBarClick(category);
                      }
                    }
                  },
                },
              },
            },
          }
,          
          
        // plotOptions: {

        //     series: {
        //         allowPointSelect:true,
        //         states: {
        //             select: {
        //                 color: '#ef5e8c',
        //                 borderColor:'#ef5e8c',
        //             },
        //         },  
        //         point: {
        //             events: {
        //                 click: function () {
        //                     if (props.performanceType === 'week') {
        //                         const tooltip = this.options.tooltip;
        //                         if (tooltip) {
        //                             handleBarClick(tooltip);
                                    
        //                         }
        //                     } else {
        //                         const category = this.category;
        //                         if (category) {
        //                             handleBarClick(category);
        //                         }
        //                     }
        //                 }
        //             }
                    
        //         }
        //     },
            
        // },
        tooltip: {
            useHTML: true,
            formatter:  function () {
               if(props.performanceType=='week'){
               
                return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.point.options.tooltip}</span><span style="font-size: 12px" class="tool-tip-result" style="font-weight:bold">${this.y}%</span><br/>
                <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                <span dx="0" class="tool-tip-name">  </span><span dx="0" class="tool-tip-date">${this.series.name}%</tspan>`;
       }
               else{
            
                return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${moment(this.x).format('DD MMM')}</span><span style="font-size: 12px" class="tool-tip-result" style="font-weight:bold">${this.y}%</span><br/>
                <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                <span dx="0" class="tool-tip-name">  </span><span  dx="0" class="tool-tip-date">${this.series.name}</tspan>`;
       }
               },
                
             borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: 15
        },
        xAxis: {
            categories: props.categories || [1,2,3,4,5,6,7],
            tickPositioner:function () {
                if(props.performanceType!='week'){

                    let positions = []
                    let tick = Math.floor(this.dataMin)
                    let increment = 0
                    if(props.activeMonth.days==31){
                        increment = 5
                    }else if(props.activeMonth.days==30){
                        increment = 8
                    }else{
                        increment = 6
                    }
                    
                    
        
                    if (this.dataMax === this.dataMin) {
                        return [0]
                    }
                   
                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
                 //   positions.splice(positions[positions.length - 1], 1);
                    //positions.push(props.categories.length - 1)
                    
                    return positions;         
               }else{

                }

            },
            labels: {
                useHTML:true,
                formatter: function () {
 
                    if(props.performanceType=='week'){
                        return this.value
                    }else{
                        return moment(this.value).format('DD MMM')     
                    }
                        
                }
            },
        },
        yAxis: {
            title: {
                text: null
            },
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 1,
            min: 0,
            max : 100,
            // max: 100,
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            },
 
            labels: {
                enabled: true
            },
            labels: {
                useHTML:true,
                formatter: function () {
                        return this.value+"%"    
                }
            },
        },
        series:props.series,
        
    }
    
    return (
        <div>
            <ReactHighcharts config={config} />
        </div>
    )
}

export default BarChartPerformance