import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Checkbox, AutoComplete ,Select,Radio } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import '../../siteManagement/styles/siteManagement.scss'
import "../../policyManagement/styles/createPolicy.scss"
import spinnerLoader from  "../../assets/images/loader.svg"
import { getTranslatedText } from '../../common/utilities';
import { Scrollbars } from "react-custom-scrollbars";
// import {getSiteLocations,getSiteOverview} from '../../siteManagement/actionMethods/actionMethods';
// import {getDepartmentList,getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods';
// import {getPolicyEmployee,getPolicyLocation,getPolicyTeam,postCreatePolicy} from '../actionMethods/actionMethods';
import {addVisitor,getContactPersonList,getVisitorTagList,getproductiveCat, addTempEmp } from '../actionMethods/actionMethods'
import '../../policyManagement/styles/createPolicy.scss'
import moment from 'moment'
import { render } from 'react-dom';

import SucessIcon from  "../../assets/images/success.png"
import CrossIcon from "../../assets/images/cross.png"
import {getShift} from '../../productivity/actionMethods/actionMethods'
function CheckIn(props){


    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
     let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    let lastReqId = null
    const [activeTag,updateActiveTag] =useState([]);
    const [inactiveTag,updateInActiveTag] =useState([]);
    const [availableTag,updateAvailableTag] =useState([]);
    const [contactPersonData,updatecontactPersonData] =useState([])
    const [SelectedTagID,updateSelectedTagID] =useState('')
    const [SelectedTagIDError,updateSelectedTagIDError] =useState('')
    const [visitorName,updateVisitorName] =useState('');
    const [visitorNameError,updateVisitorNameError] =useState('');
    const [visitorEmail,updateVisitorEmail] =useState('');
    const [visitorMobile,updateVisitorMobile] =useState('');
    
    const [visitorEmailError,updateVisitorEmailError] =useState('');
    const [visitedEmployeeName,updateVisitedEmployeeName] =useState('');
    const [visitedEmployeeNameError,updateVisitedEmployeeNameError] =useState('');
    const [shiftTime,updateshiftTime] =useState('');
    const [shiftList,SetShiftList] = useState([]);
    const [shiftTimeError,updateshiftTimeError] =useState('');
    const [loader,SetLoader]=useState(true);   
    const [visitorCheckInFlag,updatevisitorCheckInFlag]=useState(false);
    const [productivityList,SetProductivityList] = useState(['Inbound building','Outbound building']);
    const [productivity,SetProductivity] = useState('')
    
    const { Option } = Select;


      
      function onSearch(val) {

      }
    useEffect(()=>{

    getContactPersonList(userSession, org_id,sub_org_id).then(res=>{
        if(res.status==200){
            updatecontactPersonData(res.data);
        }


    }).catch()

    
    getproductiveCat(userSession, org_id,sub_org_id).then(res=>{
        if(res.status==200){
            SetProductivityList(res.data);
        }
    }).catch()

    getShift(userSession,org_id,'','').then(res=>{ 
        if(res.status=200){
          SetShiftList(res.data);
        }
      })
/*    if(activeTab == 'visitor'){
        
        getVisitorTagList(userSession, org_id,sub_org_id).then(res => {
            if (res.status === 200) {
                let data =res.data;
                if(data.active){
                    updateActiveTag(data.active)
                }
                if(data.available_for_today){
                    updateAvailableTag(data.available_for_today)
                }
                if(data.inactive){
                    updateInActiveTag(data.inactive)
                }
                
                setTimeout(function(){SetLoader(false)},100)
            }
            else if(res.status === 302){
            
            }
        });
    }
*/    

},[])

useEffect(()=>{
    if(props.activeTab =='visitor'){
        
    let data =props.tagList
    if(data.active){
        updateActiveTag(data.active)
    }
    if(data.available_for_today){
        updateAvailableTag(data.available_for_today)
    }
    if(data.inactive){
        updateInActiveTag(data.inactive)
    }    
    setTimeout(function(){SetLoader(false)},100)
}
},[props.tagList])

useEffect(()=>{
   
    if(props.activeTab =='tempemployee'){

    let data =props.tagListTemp;
    if(data.active){
        updateActiveTag(data.active)
    }
    if(data.available_for_today){
        updateAvailableTag(data.available_for_today)
    }
    if(data.inactive){
        updateInActiveTag(data.inactive)
    }    
    setTimeout(function(){SetLoader(false)},100)
}

},[props.tagListTemp])
function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

useEffect(()=>{

},[activeTag,inactiveTag])

function submitHandleCheckIn(event){
    

    event.preventDefault();
   
if(SelectedTagID==''||visitorName==''||visitedEmployeeName==''){
   
    SelectedTagID==''?updateSelectedTagIDError('Please Select Tag ID'):updateSelectedTagIDError('')
    visitorName==''?updateVisitorNameError("Please Enter Visitor Name"):updateVisitorNameError("")
    visitedEmployeeName==''?updateVisitedEmployeeNameError("Please Enter Visiting to employee name"):updateVisitedEmployeeNameError('')

}else{
   
    if(!validateEmail(visitorEmail)){
        updateVisitorEmailError("Email is invalid")
    }
    //for form submission
    else{
        updateVisitorEmailError("")

        let requestBody=[]
        requestBody.tag_serial = SelectedTagID;
        requestBody.visitor_name = visitorName;
        requestBody.visitor_email = visitorEmail;
        requestBody.visited_employee = visitedEmployeeName;
        requestBody['shift'] = shiftTime;
        requestBody.org_id = org_id
 
        
        addVisitor(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res.message =='Policy Created' || res.message !='') {
                
                updatevisitorCheckInFlag(true);
              setTimeout(function(){
                props.handleCloseModal(); 
              },3000);
                props.reloadData();      

            }else{
                
            }
        });
       

    }    

}

}

function submitHandlerCheckInTemp(event){
    event.preventDefault();
    
    if(SelectedTagID==''||visitorName==''|| (visitorEmail!='' && !validateEmail(visitorEmail))){
       
        SelectedTagID==''?updateSelectedTagIDError('Please Select Tag ID'):updateSelectedTagIDError('')
        visitorName==''?updateVisitorNameError("Please Enter Visitor Name"):updateVisitorNameError("")
        
        !validateEmail(visitorEmail)?updateVisitorEmailError("Email is invalid"):updateVisitorEmailError("")
    }else{

        let requestBody={}
        requestBody.session=userDetails.session
        requestBody.worker_id= SelectedTagID
        requestBody.emp_name = visitorName
        requestBody.emp_mobile =visitorMobile
        requestBody.category_id= productivity

        let item = productivityList.find(item=>item.id== productivity);
      
        if(item){
            requestBody.productivity = JSON.parse(item.productivity)    
        }else{
            requestBody.productivity = null    
        }
        requestBody.shift = shiftTime
        requestBody.org_id= org_id;
        
      
        addTempEmp(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res.message =='Policy Created' || res.message !='') {
                
                updatevisitorCheckInFlag(true);
              setTimeout(function(){
                props.handleCloseModal(); 
              },3000);
                props.reloadData();      

            }else{
                
            }
        });

    }
}
function onChangeTagID(e){

updateSelectedTagID(e)
}

function onFocusTagID(){

}

function onBlurTagID(){

}

function showTagID(){
    let arr= []
    for(let i=0; i<availableTag.length;i++){
        arr.push( <Select.Option key={availableTag[i]} value={availableTag[i]}>
            {availableTag[i]}
        </Select.Option>);
    }
        
    return arr;

}
function onChangeSlot(e){
    updateshiftTime(e)
}
function onChangeEmployee(e){
    updateVisitedEmployeeName(e)
}


function showEmployeeName(){
    let arr= []
    
    for(let i=0; i<contactPersonData.length;i++){
        arr.push( <Select.Option key={contactPersonData[i].name} value={contactPersonData[i].name}>
            {contactPersonData[i].name}
        </Select.Option>);
    }
        
    return arr;

}


    return(
<div>
    {visitorCheckInFlag===false?
    <div className={loader?'createPolicyMainDiv createPolicyMainDiv-Less-space loading':"createPolicyMainDiv createPolicyMainDiv-Less-space"}>
      <div className='visitor-loader'><img src={spinnerLoader}/></div>  
{availableTag.length==0?<div className='message-text'><h5 className="font-bold" style={{marginBottom:"0px"}}>Visitor tags Not available </h5><img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/></div>:
<form>
<img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/>
<h5 className="font-bold" style={{marginBottom:"0px"}}>Check In </h5>

                                            <Row>
                                                <Col lg={12}>
                                            <div className="activeSelect">
                                            <label>Select Tag Serial Number</label>
                                            


                                            <Select
    showSearch
    placeholder=""
    optionFilterProp="children"
    onChange={onChangeTagID}
    onSearch={onSearch}
   value={SelectedTagID}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
   {showTagID()}
  </Select>                                         
  {SelectedTagID==''?SelectedTagIDError?<div class="error-message">{SelectedTagIDError}</div>:"":""}

                                            </div>    
                                        </Col>

                                        </Row>
                                        <Row className="">
                                        {<Col lg={12}  className="">
                                            <label>Enter Name </label>
                                            <input type="text" value={visitorName} placeholder="" onChange={(e)=>updateVisitorName(e.target.value)} />
                                            {visitorName==''?visitorNameError?<div class="error-message">{visitorNameError}</div>:"":""}
                                        </Col> }
                                        </Row>
                                        <Row className="">
                                        {<Col lg={12}  className="">
                                            <label>Enter Email </label>
                                            <input type="text" value={visitorEmail} placeholder="" onChange={(e)=>updateVisitorEmail(e.target.value)} />
                                            {visitorEmailError?<div class="error-message">{visitorEmailError}</div>:""}
                                        </Col> }
                                        </Row>
                  
                                        <Row className="">
                                        {<Col lg={12}  className="">
                                            <label>Enter Mobile </label>
                                            <input type="text" value={visitorMobile} placeholder="" onChange={(e)=>updateVisitorMobile(e.target.value)} />
                                            {visitorEmailError?<div class="error-message">{visitorEmailError}</div>:""}
                                        </Col> }
                                        </Row>
                                        {props.activeTab=='visitor'?
                                        <Row className="">
                                        {<Col lg={12}  className="activeSelect ">
                                            <label>Enter Employee to Visit </label>
           
                                            <Select
    showSearch
    placeholder=""
    optionFilterProp="children"
    onChange={onChangeEmployee}
    onSearch={onSearch}
    value={visitedEmployeeName}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
   {showEmployeeName()}
  </Select>                             
  {visitedEmployeeName==''?visitedEmployeeNameError?<div class="error-message">{visitedEmployeeNameError}</div>:"":""}    
                                        </Col> }
                                        </Row>:""}
                                        <Row className="">
                                        {<Col lg={12}  className="activeSelect ">
                                            <label>Work Slot</label>
           
                                            {/* <input type="text" value={shiftTime} placeholder="" onChange={(e)=>updateshiftTime(e.target.value)} /> */}
                                            <Select
                                                showSearch
                                                placeholder=""
                                                optionFilterProp="children"
                                                onChange={(val)=>updateshiftTime(val) }
                                                value={shiftTime}
                                            >
                                            {shiftList.map(item=>{
                                                 return <Option value={item.shift_id} key={item.shift_name}>{item.shift_name}</Option>
                                                
                                            })}
                                            </Select>
         
                       
  {shiftTime==''?shiftTimeError?<div class="error-message">{shiftTimeError}</div>:"":""}
                       {/* {policyName==''?errorName?<div class="error-message">{errorName}</div>:"":""} */}
                                        </Col> }
                                        {props.activeTab=='tempemployee'?<Col lg={12}  className="activeSelect ">
                                            <label>Productivity</label>
                                         
                                            <Select
                                            showSearch
                                            placeholder=""
                                            optionFilterProp="children"
                                            onChange={(val)=>SetProductivity(val) }
                                            value={productivity}
                                        >
                                        {productivityList.map(item=>{
                                             return <Option value={item.id} key={item.id}>{item.category_name}</Option>
                                            
                                        })}
                                        </Select>                                                   
                                            </Col>:""
    }
                                        </Row>
 
                                        <Row className="m-t">
                                        <div className='actiondiv'>
                                        {props.activeTab=='visitor'? <button type="submit" onClick={submitHandleCheckIn} className='btn button btn-submit btn-add-visitor'>Check In!</button>:<button type="submit" onClick={submitHandlerCheckInTemp} className='btn button btn-submit btn-add-visitor'>Check In!</button>}
                                            </div>
                                        </Row>
 


</form>}

    </div>:<div className='createPolicyMainDiv createPolicyMainDiv-Less-space visitorcheckIn'>
    <img src={CrossIcon} className='crossicon' onClick={props.handleCloseModal}/>
                    <div className="sucess-inner">
                        <img src={SucessIcon}/>
                    </div>
                    <div className='logoutTitle'>{props.activeTab =='tempemployee'?'Temporary Employee':'Visitor'} Check In Sucessfully</div>
                    <div className='actionButton'>
                        <span className='btnText okBtn bnt-close-bigger' onClick={()=>props.handleCloseModal()}>Close </span>
                    </div>
                </div>
           
            }
    </div>
    )
}
export default CheckIn;