import React,{useEffect, useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment';

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function ChartReport(props) {
const [average,SetAverage]=useState(0);
const [maxValue,SetMaxValue]=useState(0);

    useEffect(()=>{
        if(props.breaktime){

        }else{

        
        let allVal=0
       
        for (let i=0;i<props.chartData.series.length;i++){
            let result = props.chartData.series[i].data.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0);
              allVal=allVal+result;
        }
       
       // SetAverage(allVal/props.chartData.series.length);

    }

    },[props.chartData])

    useEffect(()=>{
        let maxVal=0
     for (let i=0; i<props.chartData.series.length; i++){
        let sr=props.chartData.series[i]
    //   let newMax=  sr.data.sort(function(a, b) {
    //         return b - a;
    //       });
    //       maxVal =newMax[0]>  maxVal?newMax[0]:maxVal
     }
     SetMaxValue(maxVal)

    },[props.chartData])

    function formatNumberWithAbbreviation(number) {
     const billion = 1e9;
      const million = 1e6;
      const thousand = 1e3;

      if (number >= billion) {
        return (number / billion).toFixed(2) + 'B';
      } else if (number >= million) {
        return (number / million).toFixed(2) + 'M';
      } else if (number >= thousand) {
        return (number / thousand).toFixed(2) + 'k';
      } else {
        return number.toString();
      }


 }


    let config = {
        chart: {
            type: 'column',
            zoomType: 'x',
            height: props.height?props.height: 400,
            //spacingLeft: 42,
                  },
        exporting: {
            enabled: false
        },

            legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 0,
        enabled: props.legendEnable,
        useHTML: props.useHTML?props.useHTML:false,
        labelFormatter: function () {
            if(this.options.legend){
                return '<span title="' + this.name + '">' + this.options.legend  + ' '+this.name+'</span>';
            }else{
                return '<span title="' + this.name + '">'+this.name+'</span>';
            }
            
        }
    
    },
    
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            useHTML: true,
            formatter:  function () {   
               
                  return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x}</span><span style="font-size: 10px" class="tool-tip-date">${props.breaktime ||this.series.name =="Performance" || props.hideUnd?this.series.options.tooltipWeek?this.series.options.tooltipWeek:"":this.series.options.acSeries?this.series.options.acSeries:this.series.options.department}</span><br/>
                         <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                         <span dx="0" class="tool-tip-name"> ${this.series.name}: </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${props.sortby && props.sortby=="amount"?formatNumberWithAbbreviation(this.y):this.y.toFixed(2)+"%"}</tspan>`;
                },
             borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: 15
        },
        xAxis: {
            categories: props.chartData.categories || [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 10);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
            labels: {
                enabled: true,
                useHTML: true,
                formatter:  function () {
                    return `<b>${this.value}</b>`
                }
            },
            events: {
                load: function (e) {
                    
                //this.series[0].data[index].setState('hover');
                }
            },
          //  gridLineDashStyle: 'ShortDash',
           // gridLineWidth: 1,
            // max: (start + 3 * move),
            // min: (start + 2 * move)
        },
        yAxis: {
            title: {
                text: " "
            },
            //gridLineDashStyle: 'ShortDash',
           // gridLineWidth: 0,
            min: 0,

            max:props.sortby && props.sortby=="amount"?null:props.sortby && props.sortby =='hour'?maxValue:100,
            labels: {
                enabled: true,
            },
            labels: {
                useHTML:true,
                formatter: function () {
                    if(props.sortby && props.sortby=="amount"){
                        return formatNumberWithAbbreviation(this.value)
                    }
                    if(props.sortby && props.sortby =='hour'){
                        return this.value+"hr"       
                    }else{
                        return this.value+"%"    
                    }
                     
                }
            },
            tickAmount:6,
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                               
                            }
                        }
                    }
                }
            },
            // plotLines: [
                
            //     {
            //         color:"#000",
            //         width:props.breaktime?0: 1,
            //         value: average,
            //         dashStyle: 'LongDash',
            //         zIndex: 100,
            //         //rotation: 90,
            //         label: props.breaktime?{}:{
            //             text: average.toFixed(2),
            //             style: {
            //                 color: '#000',
            //                 fontWeight: 'bold',
            //             },
            //         }
            //     },
                
            // ]





                    
        },

        series:props.breaktime? [{
            name: 'Break Time',
            showInLegend: false,
            cursor: 'pointer',
                    
            data: props.chartData?.series || [],
            
        }]:props.chartData.series || []
    }
   
    return (
            <div id={"propskey"+props.keys?props.keys:"-"}>
                <ReactHighcharts  config={config}  isPureConfig={true} key={props.key?props.key:"uniqueKey"+Math.random(0,10)}/>
            </div>
        )
}


export default ChartReport;