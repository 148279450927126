import axios from "axios";


let prefixURL = process.env.REACT_APP_URL
let prefixURL2 = process.env.REACT_APP_URL_LOGIN

export function getVisitorTagList(sessionID, id) {

    return axios.get(prefixURL + `/get_visitor_tag_list?session=${sessionID}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}

export function getVisitorList(requestBody,sessionID, id) {

    return axios.get(prefixURL + `/get_visitor_list?session=${sessionID}&org_id=${id}&date=${requestBody.date}`)
        .then(res => res.data).catch(err => err)
}

export function getTempTagList(sessionID, id) {

    return axios.get(prefixURL + `/get_temp_emp_tag_list?session=${sessionID}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}



export function addVisitor(requestBody, userSession, org_id,sub_org_id) {
    return axios.post(prefixURL+ '/add_visitor', {
        session: userSession,
        tag_serial: requestBody.tag_serial,
        visitor_name: requestBody.visitor_name,
        visitor_email: requestBody.visitor_email,
        visited_employee: requestBody.visited_employee,
        shift: requestBody.shift,
        org_id: org_id,
        mobile:requestBody.mobile,
    }).then(res => res.data).catch(err => err)
}

export function addTempEmp(requestBody, userSession, org_id,sub_org_id) {
    return axios.post(prefixURL+ '/add_temp_emp', requestBody).then(res => res.data).catch(err => err)
}
export function checkoutTempEmp(requestBody, userSession, org_id,sub_org_id) {
    return axios.post(prefixURL+ '/checkout_temp_emp', requestBody).then(res => res.data).catch(err => err)
}

export function getTempEmpDetail(requestBody,sessionID, id) {
 
    return axios.get(prefixURL + `/get_temp_emp_details?session=${sessionID}&org_id=${id}&worker_id=${requestBody.worker_id}`)
        .then(res => res.data).catch(err => err)
}

export function getTempEmpList(sessionID,id, date) {
 
    return axios.get(prefixURL + `/get_temp_emp_list?session=${sessionID}&org_id=${id}&date=${date}`)
        .then(res => res.data).catch(err => err)
}

export function getproductiveCat(sessionID,id) {
 
    return axios.get(prefixURL2 + `/employee/get_productivity_category?session=${sessionID}&org_id=${id}&`)
        .then(res => res.data).catch(err => err)
}





export function checkoutVisitor(requestBody, userSession, org_id,sub_org_id) {
    return axios.post(prefixURL+ '/checkout_visitor', {
        session: userSession,
        tag_serial: requestBody.tag_serial,
        org_id: org_id,
        
    }).then(res => res.data).catch(err => err)
}

export function getContactPersonList(sessionID, id) {

    return axios.get(prefixURL + `/get_contact_person_list?session=${sessionID}&org_id=${id}`)
        .then(res => res.data).catch(err => err)
}

export function getVisitorDetails(sessionID, id,tag_serial) {

    return axios.get(prefixURL + `/get_visitor_details?session=${sessionID}&org_id=${id}&tag_serial=${tag_serial}`)
        .then(res => res.data).catch(err => err)
}

