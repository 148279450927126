import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


import '../../assets/styles/common.scss'
import '../styles/dashboard.scss'
import ThreatWatch from './threatWatch';

import helpIcon from '../../assets/traceplusImages/help-icon.png'
import pinkArrowIcon from '../../assets/traceplusImages/pink_outline_right_arrow_icon.svg'
import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'

import { getDashboardData, getThreatWatchData, getLanguageTranslation, setSelectedLanguage, getChartData, getIndexLevel } from '../actionMethods/actionMethods';

import EmployeeList from '../../manPowerManagement/components/employeeList'

import SiteMangementList from '../../siteManagement/components/index'

import DashboardChart from './dashboardChart';
import moment from 'moment'
import 'antd/dist/antd.css';
import DashboardLanguage from '../../components/dashboardLanguage';

import spinnerLoader from '../../assets/images/loader.svg'

import CommonDatePicker from '../../common/commonDatePicker';
import { prepareDateObj } from './helper'
import { titles } from './constant'
import { getTranslatedText } from '../../common/utilities';
import { getDepartmentList, getEmployeeList } from '../../manPowerManagement/actionMethods/actionMethods';
import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';
import { PresetStatusColorTypes } from 'antd/lib/_util/colors';
import AgGridEmployeeList from '../../manPowerManagement/components/agGridEmployeeList';
import { atRiskEmployee } from '../../manPowerManagement/actionMethods/actionMethods';

import { sidebarMenuItems } from  '../../common/menuItems';
import DeviceStatus from '../../components/DeviceStatus';
import { getRoleDetail } from '../../common/actionMethods';
import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import ExcelJS from 'exceljs';

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}

const riskBackgroundColor = {
    "low": '#e7f6ef',
    "medium": "#faf7e5",
    "high": "#fef0f4"
}

const indexTitleArray = [
    // {
    //     title: 'Population Risk Index',
    //     isSelected: true,
    //     id: 'population',
    //     desc: ''
    // },
    {
        title: 'Spread Index',
        isSelected: true,
        id: 'spread',
        desc: 'Risk of virus transmission through human to human interaction.'
    },
    {
        title: 'Movement Index',
        isSelected: false,
        id: 'mobility',
        desc: 'Overall risk of visiting risky locations by employees.'
    },

    {
        title: 'Area Index',
        isSelected: false,
        id: 'area',
        desc: 'Risk at the highly visited location.'
    }
]

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '90%'
    },
};

function Dashboard(props) {
    let date = localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date((new Date().setDate(new Date().getDate() - 1)));
    let interval = 1440
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
      let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let org_id = userDetails ? userDetails.org_id : 6
    let totalEmployeeTag = localStorage.getItem('totalEmployeeTag') ? localStorage.getItem('totalEmployeeTag') : 0;
    let totalSiteLocation = localStorage.getItem('totalSiteLocation') ? localStorage.getItem('totalSiteLocation') : 0;
    const [employeeCount, updateEmployeeCount] = useState(0)
    const [orgId, updateOrgId] = useState(1)
    const [orgCount, updateOrgCount] = useState(0)
    const [contaminatedEmployeeCount, updateContaminatedEmployeeCount] = useState(0);
    const [atRiskCount, updateAtRiskCount] = useState(0);
    const [threatWatchColor, updateThreatWatchColor] = useState('green')
    const [selectedDate, updateSelectedDate] = useState(date)
    const [startDateValue, updateStartDateValue] = useState(moment(date).subtract(7, 'days').toDate())
    const [endDateValue, updateEndDateValue] = useState(date)
    const [toastClass, updateToastClass] = useState('successToast')
    const [employeePopupFlag, updateEmployeePopupFlag] = useState(false)
    const [locationPopupFlag, updateLocationPopupFlag] = useState(false)
    const [chartLoader, setChartLoader] = useState(true)
    const [contactRankValue, updateContactRankValue] = useState(1)
    const [selectedLangValue, updateSelectedLangValue] = useState('en')
    const [chartData, setChartData] = useState({ categories: [], series: [], chartData: [] })
    const [indexTitle, updateIndexTitle] = useState(0)
    const [indexLevel, setIndexLevel] = useState({ area: '', mobility: '', population: '', spread: '' })
    const [startChartDateValue, updateStartChartDateValue] = useState(moment().subtract(29, 'days').toDate())
    const [endChartDateValue, updateEndChartDateValue] = useState(date)
    const [statusCount, updateStatusCount] = useState({ personal: 0, location: 0  })
    const [modalType, setModalType] = useState('')
    const [threatEmployee, setThreatEmployee] = useState('')
    const [BarApiData,SetBarApiData]=useState([]);
    const [BarApiData2,SetBarApiData2]=useState([]);
    
    const [BarApiDataSpreadTeam,SetBarApiDataSpreadTeam]=useState([]);
    const [BarApiDataSpreadEmployee,SetBarApiDataSpreadEmployee]=useState([]);
    const [BarApiDataMovement,SetBarApiDataMovement]=useState([]);
    const [BarApiDataArea,SetBarApiDataArea]=useState([]);
    const [topFiveData,SetTopfiveData]=useState([]);
    const [BarDataLoader,SetBarDataLoader]= useState(false);
    const [ShowSelectedData,SetSHowSelectedData]=useState([]);
    const [typeChart,SetTypeChart]=useState('Spread Index');
    const [ChartDate,SetChartDate]=useState('');
    const [CurrentTabChart,SetTabCurrentChart]=useState('Employee')
    const [initialLoader,SetInitialLoader]=useState(false);
    const [infinitefix,SetInfiniteFix]=useState(0);
    const [sensitiveType,SetSensitiveType]=useState(0);
    const [rank2Data,SetRank2Data]=useState([]);
    const initialMenu = localStorage.getItem('menuseq')?JSON.parse(localStorage.getItem('menuseq')):sidebarMenuItems.map(item=>{return item.title})    
    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    

    useEffect(() => {

       
        
            if (props.match.path == '/'  ) {
              
                if(parseInt(userDetails.org_id)== 31){

                    let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[0])
                    props.history.push(menuItem.paths)
                  
                }else if(parseInt(userDetails.org_id)!= 31 && initialMenu[0]!='Cost Management' ){
                    let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[0])
                    props.history.push(menuItem.paths)
                    
                }else if(initialMenu[0] == 'Cost Management'){
                    
                    let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[1])
                    
                    props.history.push(menuItem.paths)

                }
                
            }
  

    },[]);

useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.page_name=="Health Management");
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);



    useEffect(() => {
        if(permission && permission.length > 0){

        
    
            let requestBody = {}
            requestBody.date = getDateFormat(selectedDate)
            requestBody.contactRank = contactRankValue
            requestBody.startDate = getDateFormat(startDateValue)
            requestBody.endDate = getDateFormat(endDateValue)
            let type=''
            if(sensitiveType==0){
                type="low"
            }else if(sensitiveType==1){
                type="medium"
            }else if(sensitiveType==2){
                type="high"
            }
            requestBody.sensitivity= sensitiveType 
            getThreatWatchDataValues(requestBody)
    
            getLanguageTranslation(selectedLangValue, userSession).then(res => {
            })
    
            fetchIndexLevel(getDateFormat(selectedDate))
        }
    }, [permission]);


    useEffect(()=>{
        if(permission && permission.length > 0){
            if(contactRankValue==2){
                let requestBody = {};

                requestBody.start = getDateFormat(startDateValue)
                requestBody.end = getDateFormat(endDateValue)
                let type ='low';
                if(sensitiveType==0){
                    type="low"
                }else if(sensitiveType==1){
                    type="medium"
                }else if(sensitiveType==2){
                    type="high"
                }
                requestBody.sensitivity= type 

                let arr=[];
                let newTemp=[];
                let rankOne={};
                let rankTwo={};
                for(let i=1;i<3; i++){
                    requestBody.rank = i
                    atRiskEmployee(requestBody, userSession, org_id,sub_org_id).then(res =>{
                        

                        
                        if(i==1){
                            rankOne = res.data;
                        }
                        if(i==2){
                            rankTwo = res.data
                        }
                        arr.push({data:res.data})
                        if(arr.length==2){
                            for(let i1=0;i1<rankOne.atrisk_employee.length;i1++){
                                rankOne.atrisk_employee[i1].rank=1;
                                newTemp.push(rankOne.atrisk_employee[i1]);
                            }
                            for(let i1=0;i1<rankTwo.atrisk_employee.length;i1++){

                                let item=rankOne.atrisk_employee.find(item => item.tag_serial== rankTwo.atrisk_employee[i1].tag_serial)
                                if(item){

                                }else{
                                    rankTwo.atrisk_employee[i1].rank=2;
                                    newTemp.push(rankTwo.atrisk_employee[i1]);
                                }
                                
                            }
                            SetRank2Data(newTemp)
                        }
                        
                    }).then().catch()
                    
            }

            }else{
                SetRank2Data([]);
            }
         }
    },[permission,contactRankValue,startDateValue,endDateValue,sensitiveType])

    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    useEffect(() => {
        if(permission && permission.length > 0){
            setChartDetail(getDateFormat(startChartDateValue), getDateFormat(endChartDateValue))
        }
    }, [indexTitle,permission])

    
    const getStatusCount = (date) => {
        let requestBody = {}
        requestBody.date = getDateFormat(date)
        if(permission && permission.length > 0){
        getEmployeeList(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res.status === 200) {
                updateStatusCount(prev => ({ ...prev, personal: res.count || 0 }))
                let totalEmployeeTagCount = res.count ? res.count:0;
                localStorage.setItem('totalEmployeeTag', totalEmployeeTagCount);
                
            }
        })

        getSiteLocations(requestBody, userSession, org_id,sub_org_id).then((res) => {
            if (res.status === 200) {
                updateStatusCount(prev => ({ ...prev, location: res.data && res.data.location_count ? res.data.location_count : 0 }))
                
                let totalSiteLocationCount = res.data.location_count ? res.data.location_count:0;
                localStorage.setItem('totalSiteLocation', totalSiteLocationCount);
            }
        })
    }

    }

    const getBarColor = (val) => {
        if (val < 33) {
            return riskLevelColor.low
        } else if (val < 66) {
            return riskLevelColor.medium
        } else {
            return riskLevelColor.high
        }
    }

useEffect(()=>{
    let requestBody = {}
    requestBody.date = getDateFormat(selectedDate)
    requestBody.contactRank = contactRankValue
    requestBody.startDate = getDateFormat(startDateValue)
    requestBody.endDate = getDateFormat(endDateValue)
    requestBody.sensitivity=SensitiveTypeConversion(sensitiveType)
    if(permission && permission.length > 0){
        getThreatWatchDataValues(requestBody)
    }
},[sensitiveType,permission])

    const setChartDetail = (startDateValue = null, endDateValue = null) => {
        setChartLoader(true)
        setChartData({ categories: [], series: [], chartData: [] })

        let obj = {
            index: titles[indexTitle].toLowerCase(),
            start: startDateValue,
            end: endDateValue
        }
        if(permission && permission.length > 0){
        getChartData(obj, userSession, org_id,sub_org_id).then((res) => {
            let data = res.index_data
            let categories = []
            let series = []

            if (data && Array.isArray(data)) {
                let startDate = new Date(startDateValue)
                let endDate = new Date(endDateValue)

                for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
                    let d = moment(startDate).format('YYYY-MM-DD')
                    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                    let d2 = new Date(startDate);
                    let dayName = days[d2.getDay()];
                    let displayDate = moment(d).format('DD MMM')
                    let index = data.findIndex((i) => moment(i.timestamp).format('YYYY-MM-DD') === d)

                    if (index > -1) {
                        categories.push(displayDate)
                        series.push({ y: data[index][obj.index], name: displayDate, color:getBarColor(data[index][obj.index]),hover:"#000", tooltipDate :dayName,acdate:getDateFormat(d2) })      
                    } else {
                        categories.push(displayDate)
                        series.push({ y: 0, name: displayDate, color: getBarColor(0) })
                    }
                }

                setChartLoader(false)
                setChartData({ categories, series })
            }

        }).catch((err) => {

            setChartLoader(false)
        })
        }
    }

    function SensitiveVal(val){
     
            
            SetSensitiveType(val)
             
    }

    function fetchIndexLevel(date) {
        if(permission && permission.length > 0){
        getIndexLevel(userSession, org_id, date).then((res) => {
            if (res && res.indexes) {
                let data = res.indexes
                setIndexLevel({
                    area: data.area || '-',
                    mobility: data.mobility || '-',
                    spread: data.spread || '-',
                    population: data.population || '-'
                })
            }
        })
     }
    }

    function getThreatWatchDataValues(requestBody) {
        if(permission && permission.length > 0){
        getThreatWatchData(requestBody, userSession, org_id,sub_org_id).then(res => {
            if (res && res.status >= 200 && res.status <= 299) {
                // updateThreatWatchColor(res.color)
                updateContaminatedEmployeeCount(res.data.no_contaminated)
                updateOrgCount(res.data.atrisk_locations)
                updateAtRiskCount(res.data.atrisk_employee)
            }
        })
        }
    }

    // function getDashboardDataValues(requestBody) {
    //     getDashboardData(requestBody, userSession, org_id,sub_org_id).then(res => {
    //         if (res && res.status >= 200 && res.status <= 299) {
    //             if (res.data) {
    //                 updateEmployeeCount(res.data.num_employees)
    //             }
    //         }
    //     }).catch(err => err)
    // }

    function handleIndexTabClick(index) {
        let arr = [...indexTitleArray]

        SetSHowSelectedData([])
        getDataHandlerChartCLick(arr[index].title,getDateFormat(endDateValue))
        for (let indexVal = 0; indexVal < arr.length; indexVal++) {
            const element = arr[indexVal];

            if (indexVal == index) {
                arr[indexVal].isSelected = true
            }
            else {
                arr[indexVal].isSelected = false
            }

        }
        updateIndexTitle(index)
    }
    

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }

    function getLevel(id) {
        return indexLevel[id] ? indexLevel[id].toLowerCase() : ''
    } 

    function showIndexTab(titleArray) {
        let arr = []

        for (let index = 0; index < titleArray.length; index++) {
            const element = titleArray[index];
            let level = getLevel(element.id)

            arr.push(
                <div
                key={"indexTitle"+element.title}
                    className={'populationRiskMainDiv utilityPadding mb-3 spreadMobilityAreaIndexMainDiv'}
                    onClick={() => handleIndexTabClick(index)}
                    style={ index === indexTitle ? { color: riskLevelColor[`${level || 'low'}`], borderColor: riskLevelColor[`${level || 'low'}`], backgroundColor: riskBackgroundColor[`${level || 'low'}`] } : {}}
                >
                    <Row>
                        <Col lg={6} className="p-r-0">
                            <div className='indexBox'>
                                <div className="indexText">
                                    {getTranslatedText(element.title)}                                
                                    {element.desc ?
                                        <React.Fragment>
                                            <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`desc_${index}`)}
                                                onMouseLeave={() => handleMouseLeave(`desc_${index}`)}
                                            />
                                            <div className='indexDesc' id={`desc_${index}`} >{element.desc}</div>
                                        </React.Fragment>
                                        : null
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="riskLevelMainDiv ">
                                <div className="riskLevelTitleDiv">
                                {indexLevel[element.id] =='na' ? "--": indexLevel[element.id]  }  
                                </div>
                                <div className="riskLevelSubtitleDiv">
                                    Risk Level
                                </div>
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="pinkArrowIconDiv mt-3">
                                <img src={element.isSelected ? selectedPinkArrowIcon : pinkArrowIcon} />
                            </div>
                        </Col>
                    </Row>
                </ div>
            )

        }

        return arr
    }

    function SensitiveTypeConversion(val){
        let type=''
        if(val==0){
            type="low"
        }else if(val==1){
            type="medium"
        }else if(val==2){
            type="high"
        }
        return type
    }

    function handleChangeValue(value) {
        let requestBody = {}
        requestBody.date = getDateFormat(selectedDate)
        requestBody.contactRank = value
        requestBody.startDate = getDateFormat(startDateValue)
        requestBody.endDate = getDateFormat(endDateValue)
        requestBody.sensitivity=SensitiveTypeConversion(sensitiveType)
        if(permission && permission.length > 0){
            updateContactRankValue(value)
            getThreatWatchDataValues(requestBody)
        }
    }

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }


    function handleDateSelect(date) {
        if(permission && permission.length > 0){
        updateSelectedDate(date)
        updateEndChartDateValue(date)
       
        //updateThreatWatchColor('')

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.contactRank = contactRankValue

        //getDashboardDataValues(requestBody)        
        let startDate =  new Date().setDate(new Date().getDate() - 29)
        let startDateThreatWatch = moment(date).subtract(7, 'days').toDate()
       
        let endDate = date;

        requestBody.startDate = getDateFormat(startDateThreatWatch)
        requestBody.endDate = getDateFormat(endDate)
        requestBody.sensitivity=SensitiveTypeConversion(sensitiveType)
        
       // getStatusCount(date)
        updateStartDateValue(startDateThreatWatch)
        updateEndDateValue(endDate)
        updateStartChartDateValue(startDate)
        //updateEndChartDateValue(endDate)
        fetchIndexLevel(getDateFormat(date))
        getThreatWatchDataValues(requestBody)
        setChartDetail(getDateFormat(startDate), getDateFormat(endDate))
        }
    }

    function handleSelectStartDate(date) {
        if(permission && permission.length > 0){
        updateStartDateValue(date)

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.contactRank = contactRankValue
        requestBody.startDate = getDateFormat(date)
        requestBody.endDate = getDateFormat(endDateValue)
        requestBody.sensitivity=SensitiveTypeConversion(sensitiveType)

        getThreatWatchDataValues(requestBody)
        // setChartDetail(requestBody.date, getDateFormat(endDateValue))
        }
    }




    function handleSelectEndDate(date) {
        //date = new Date(date).setDate(date.getDate() + 1)
        if(permission && permission.length > 0){
        updateEndDateValue(date)

        let startDate = moment(startDateValue)
        let endDate = moment(date)
        let isBefore = startDate.isBefore(endDate)

        if (isBefore) {
            let requestBody = {}
            requestBody.date = getDateFormat(date)
            requestBody.contactRank = contactRankValue
            requestBody.startDate = getDateFormat(startDateValue)
            requestBody.endDate = getDateFormat(date)
            requestBody.sensitivity=SensitiveTypeConversion(sensitiveType)
            getThreatWatchDataValues(requestBody)
            // setChartDetail(getDateFormat(startDateValue), requestBody.date)
        } else {
            updateToastClass('errorToast')
            toast('End Date Should be Greater Than Start Date.', {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                hideProgressBar: true
            })
        }
      }
    }


    function handleSelectChartStartDate(date) {
        if(permission && permission.length > 0){
        updateStartChartDateValue(date)

        let requestBody = {}
        requestBody.date = getDateFormat(date)
        requestBody.contactRank = contactRankValue
        requestBody.startDate = getDateFormat(date)
        requestBody.endDate = getDateFormat(endChartDateValue)

        // getThreatWatchDataValues(requestBody)
        setChartDetail(requestBody.date, getDateFormat(endChartDateValue))
        }
    }

    function handleSelectChartEndDate(date) {
      //  date = new Date(date).setDate(date.getDate() + 1)
      if(permission && permission.length > 0){  
      updateEndChartDateValue(date)

        let startDate = moment(startChartDateValue)
        let endDate = moment(date)
        let isBefore = startDate.isBefore(endDate)

        if (isBefore) {
            let requestBody = {}
            requestBody.date = getDateFormat(date)
            requestBody.contactRank = contactRankValue
            requestBody.startDate = getDateFormat(startChartDateValue)
            requestBody.endDate = getDateFormat(date)

            // getThreatWatchDataValues(requestBody)
            setChartDetail(getDateFormat(startChartDateValue), requestBody.date)
        } else {
            updateToastClass('errorToast')
            toast('End Date Should be Greater Than Start Date.', {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                hideProgressBar: true
            })
        }
      }
    }

    function handleEmployeeClick() {
        props.history.push('/manpower-management/employee-list')
    }

    function changeLanguage(lang) {
        getLanguageTranslation(lang, userSession).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }

    function handleUpdateEmployeePopup(type) {
        updateEmployeePopupFlag(true)
        setModalType('threat')
        setThreatEmployee(type)
    }

    function handleUpdateLocationPopup() {
        updateLocationPopupFlag(true)
        setModalType('threat')
    }

    function handleCloseModal() {
        updateEmployeePopupFlag(false)
        updateLocationPopupFlag(false)
        setModalType('')
        setThreatEmployee('')
    }
    
    useEffect(()=>{
        if(permission && permission.length > 0){
if(indexTitle==0){
    getDataHandlerChartCLick('Spread Index',getDateFormat(endDateValue))


}
if(indexTitle==1){
    getDataHandlerChartCLick('Movement Index',getDateFormat(endDateValue))


}
if(indexTitle==2){
        
    getDataHandlerChartCLick('Area Index',getDateFormat(endDateValue))

}
        }
    },[endDateValue,permission])

    useEffect(()=>{
        let arr2=[...BarApiData];
        SetInitialLoader(true);    
        
        if(CurrentTabChart=="Employee"){
            arr2=[...BarApiData];
            ForamtChartEmpoyee(arr2)
        }else if(CurrentTabChart=="Team"){
            arr2=[...BarApiData2];
            ForamtChartTeam(arr2)
        }
        
        if(typeChart=='Movement Index'){
            arr2=[...BarApiData];

            ForamtChartMovement(arr2)
        
        }else if(typeChart=='Area Index'){
            arr2=[...BarApiData];
            ForamtChartArea(arr2)
        }
    

       
    },[BarApiData,CurrentTabChart,infinitefix])

    
    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
    }
    
    function ForamtChartEmpoyee(arr2){
        let dataFormat=[];
        
        for (let i =0 ;i<arr2.length;i++){
            let element=arr2[i];
            dataFormat.push(
                <Row key={"formatChartEmployee"+element.tag_serial} style={{ alignItems: 'center' }} onClick={() => handleClickCard(typeChart,element.tag_serial)}>
                <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} onClick={() => handleClickCard(typeChart,element.tag_serial)}>
                    <b className="font-bold">{capitalizeFirstLetter(element.name)}</b>
                </Col>
                <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} onClick={() => handleClickCard(typeChart,element.tag_serial)}>
                    {/* <div className="priSriMriText">Spread Index</div> */}
                    <h6 className="font-bold">{element.spread_index !== 'na' ? element.spread_index + '%' : '-'}</h6>
                </Col>
            </Row>

            )
        }
        
    SetSHowSelectedData(dataFormat);

    SetInitialLoader(false);
    
    }
    function ForamtChartTeam(arr2){
        let dataFormat=[];
        
        for (let i =0 ;i<arr2.length;i++){
            let element=arr2[i];
            dataFormat.push(
                <Row key={"ForamtChartTeam"+element.team} style={{ alignItems: 'center' }}>
                <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} >
                    <b className="font-bold">{capitalizeFirstLetter(element.team)}</b>
                </Col>
                <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} >
                    {/* <div className="priSriMriText">Spread Index</div> */}
                    <h6 className="font-bold">{element.spread_index !== 'na' ? element.spread_index + '%' : '-'}</h6>
                </Col>
            </Row>

            )
        }
        
    SetSHowSelectedData(dataFormat);

    SetInitialLoader(false);
    }
    function ForamtChartMovement(arr2){
        let dataFormat=[];
        
        
        for (let i =0 ;i<arr2.length;i++){
            let element=arr2[i];
            dataFormat.push(
                <Row key={"formatChartMovement"+element.loc_tag} style={{ alignItems: 'center' }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                    <b className="font-bold">{capitalizeFirstLetter(element.name)}</b>
                </Col>
                <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                    <h6 className="font-bold">{element.loc_area_index !== 'na' ? element.loc_area_index + '%' : '-'}</h6>
                </Col>
            </Row>

            )
        }

        
    SetSHowSelectedData(dataFormat);
   
    SetInitialLoader(false);       
    }
    function ForamtChartArea(arr2){
        let dataFormat=[];
         
        for (let i =0 ;i<arr2.length;i++){
            let element=arr2[i];
            dataFormat.push(
                <Row key={"ForamtChartArea"+element.loc_tag} style={{ alignItems: 'center' }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                    <b className="font-bold">{capitalizeFirstLetter(element.name)}</b>
                </Col>
                <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} onClick={() => handleClickCard(typeChart,element.loc_tag)}>
                    <h6 className="font-bold">{element.loc_area_index !== 'na' ? element.loc_area_index + '%' : '-'}</h6>
                </Col>
            </Row>

            )
        }

        
    SetSHowSelectedData(dataFormat);
        
    SetInitialLoader(false);       
        
    }

    function TabHandlerChart(type){        
          SetTabCurrentChart(type)
    }

    useEffect(()=>{
        


    },[ChartDate])



    function handleClickCard(type,id){

        localStorage.setItem('selectedDate',ChartDate)
        if(type=='Spread Index'){
            props.history.push(`/manpower-management/employee-list/view/:${id}`)
        }else{
            props.history.push(`/site-list/view/:${id}`)
        }
    }

    function getDataHandlerChartCLick(type,date){
        SetSHowSelectedData([]);
        SetBarDataLoader(true);
        SetChartDate(date);
        SetTypeChart(type);
        SetBarApiData([]); 
        let count=infinitefix+1;
        
        SetInfiniteFix(count);
        
        SetInitialLoader(true);
        
        
        if(type=='Spread Index'){
            getDataHandlerChartSpread(type,date)
        }                
        if(type=='Area Index'){

            getDataHandlerChartArea(type,date)
        }
        if(type=='Movement Index'){
            
            getDataHandlerChartMovement(type,date)
        }

    }

    function getDataHandlerChartSpread(type,date){
        let requestBody = {}
        requestBody.date = date;
        let SpreadEmp=[...BarApiDataSpreadEmployee];        
        let SpreadTeam=[...BarApiDataSpreadTeam];
        SetInitialLoader(true);

            let item  = SpreadEmp.find(item => item.date === date);
            if(item){
                SetBarApiData(item.data);
            }else{
            getEmployeeList(requestBody, userSession, org_id,sub_org_id).then(res => {           
                if (res) {
                  let arr2=[];
                    arr2 = res.data.sort((a, b) => {
                        if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                            return  b.spread_index - a.spread_index
                        }
                        else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                            return -1
                        }
                         else {
                            return 1
                        }
                    })

                    arr2 = arr2.slice(0, 5);
                    let arr4=[]
                     for(let i=0;i<arr2.length;i++){
                        if(arr2[i].spread_index==0 ||arr2[i].spread_index=='-'||arr2[i].spread_index=='na' ){}
                        else{
                            arr4.push(arr2[i])
                        }
                    }
                    
                    SetBarApiData(arr4);   
                  SpreadEmp.push({date:date,data:arr4});
                  SetBarApiDataSpreadEmployee(SpreadEmp);  
                 
            
                }
            }).catch(() => {
            })
            getDepartmentList(requestBody, userSession, org_id,sub_org_id).then(res => {
                if (res) {
                    let arr2=[];
                    arr2 = res.data.sort((a, b) => {
                        if (a.spread_index !== 'na' && b.spread_index !== 'na') {
                            return  b.spread_index - a.spread_index
                        } else if (a.spread_index !== 'na' && b.spread_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                    })
                    arr2 = arr2.slice(0, 5);

                    

                    let arr4=[]
                    for(let i=0;i<arr2.length;i++){
                       if(arr2[i].spread_index==0 ||arr2[i].spread_index=='-'||arr2[i].spread_index=='na' ){}
                       else{
                           arr4.push(arr2[i])
                       }
                   }
                   
                    SetBarApiData2(arr4);
                    SpreadTeam.push({date:date,data:arr4})
                    SetBarApiDataSpreadTeam(SpreadTeam);
                }

                }).catch(() => {
                });

                
        
    }
    }

    function getDataHandlerChartArea(type,date){
        let requestBody = {}
        requestBody.date = ChartDate;
        let AreatData=[...BarApiDataArea]; 
        let arr2=[];
        let arr3=[];     
        SetInitialLoader(true);


            let item  = AreatData.find(item => item.date === date);
            if(item){

                SetBarApiData(item.data);

            }else{
            getSiteLocations(requestBody, userSession, org_id,sub_org_id).then(res => {           
                if (res) {
   
                    arr2 = res.data.location_details;
                    for (let i=0;i<arr2.length;i++){

                    }
                    arr2 = arr2.sort((a, b) => {
                        if (a.loc_area_index !== 'na' && b.loc_area_index !== 'na') {
                            return  b.loc_area_index - a.loc_area_index
                        } else if (a.loc_area_index !== 'na' && b.loc_area_index === 'na') {
                            return -1
                        } else {
                            return 1
                        }
                        
                    })
                    arr2= arr2.slice(0,1);
                    

                    SetBarApiData(arr2);   
                    AreatData.push({date:date,data:arr2});
                    SetBarApiDataArea(AreatData);

            
                }
            }).catch(() => {
            })
    }


    }

    function getDataHandlerChartDashbaord(text){
        SetSHowSelectedData(text);
    }
    function getDataHandlerChartMovement(type,date){
        let requestBody = {}
        requestBody.date = ChartDate;
        let MovementData=[...BarApiDataMovement]; 
        let arr2=[];
        let arr3=[];    
        let arr4=[]; 
        SetInitialLoader(true);


            let item  = MovementData.find(item => item.date === date);
            if(item){

                SetBarApiData(item.data);
            }else{
            getSiteLocations(requestBody, userSession, org_id,sub_org_id).then(res => {           
                if (res) {
                  
                    arr2 = res.data.location_details;
                    for(let i=0;i<arr2.length;i++){
                        if(arr2[i].risk_status=="Risk"){
                            arr3.push(arr2[i])
                        }                
                    }

                    arr4 = arr3.slice(0, 5);
                    SetBarApiData(arr4);   
                    MovementData.push({date:date,data:arr4});
                    SetBarApiDataMovement(MovementData);
                }
            }).catch(() => {
            })
    }
}

    return (
        <>
        {permissionAPICall?permission.length > 0?
        <div className="dashboardComponentMainDiv">
            <Container >
                <Row>
                    <Col lg={3} className="m-t-sm">
                        <CommonHeading title={getTranslatedText('Dashboard')} />
                    </Col>
                    <Col lg={9} className="text-right">
                        <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>
                        
                    </Col>
                </Row>

                {
                    threatWatchColor ?
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <ThreatWatch
                                    handleSelectStartDate={handleSelectStartDate}
                                    handleSelectEndDate={handleSelectEndDate}
                                    orgCount={orgCount}
                                    startDate={startDateValue}
                                    endDate={endDateValue}
                                    selectedDate={selectedDate}
                                    handleChangeValue={handleChangeValue}
                                    contaminatedEmployeeCount={contaminatedEmployeeCount}
                                    atRiskCount={atRiskCount}
                                    threatWatchColor={threatWatchColor}
                                    openEmployeePopup={handleUpdateEmployeePopup}
                                    openLocationPopup={handleUpdateLocationPopup}
                                    SensitiveVal={SensitiveVal}
                                    sensitive={sensitiveType}
                                    rank2Data={rank2Data}
                                    permission={permission}
                                    role={props.role}
                                />
                            </Col>
                        </Row> : <Row className="text-center">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <img src={spinnerLoader} />
                            </Col>
                        </Row>
                }

                <div className="dashboardGraphAndIndexMainDiv">
                    <Row>
                        <Col lg={4} className="p-r-0">
                            {showIndexTab(indexTitleArray)}
                        </Col>
                        {chartLoader ?
                            <Col className="text-center" lg={8}>
                                <img src={spinnerLoader} />
                            </Col>
                            :
                            <Col lg={8}>
                                
                                <DashboardChart
                                    yAxisTitle={`${indexTitleArray[indexTitle].title}`}
                                    risk={'low'}
                                    chartData={chartData}
                                    chartType={titles[indexTitle]}
                                    startDate={startChartDateValue}
                                    endDate={endChartDateValue}
                                    interval={interval}
                                    handleSelectStartDate={handleSelectChartStartDate}
                                    handleSelectEndDate={handleSelectChartEndDate}
                                    selectedDate={selectedDate}
                                    getDataHandlerChartCLick={getDataHandlerChartCLick}
                                    ShowSelectedData={ShowSelectedData} 
                                   
                                    currentTab={CurrentTabChart}
                                    chartDate={ChartDate}
                                    TabHandlerChart={TabHandlerChart}
                                    initialLoader={initialLoader}
                                    infinitefix={infinitefix}
                                    getDataHandlerChartDashbaord={getDataHandlerChartDashbaord}
                                    permission={permission}
                                />
                            </Col>
                        }
                    </Row>
                </div>
            </Container >

            <ReactModal
                isOpen={employeePopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                
            >
                <div className={"modal-mapowermanagement "+threatEmployee}>
                
                <AgGridEmployeeList
                    hideHeading={true}
                    title={modalType ? threatEmployee === 'risk' ? 'At Risk Employees' : 'Contaminated Employees' : 'Employee'}
                    hideGlobalSearch={true}
                    date={selectedDate}
                    startDate={startDateValue}
                    endDate={endDateValue}
                    rank={contactRankValue}
                    type={modalType}
                    threatEmployee={threatEmployee}
                    sensitive={SensitiveTypeConversion(sensitiveType)}
                    rank2Data={rank2Data}
                    AgkeyTable={modalType ? threatEmployee === 'risk' ? 'At Risk Employees' : 'Contaminated Employees' : 'Employee'}
                    //height={(60*window.innerHeight)/100}
                    height={modalType ? threatEmployee === 'risk' ? (60*window.innerHeight)/100 : (60*window.innerHeight)/100 : (75*window.innerHeight)/100}
                    columnexist={modalType ? threatEmployee === 'risk' ? 9 : 9 : 8}
                    isModalData={true}
              

                    />
                </div>
            </ReactModal>


            <ReactModal
                isOpen={locationPopupFlag}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
 
            >
                <div className="modal-sitemanagement">
                <SiteMangementList
                    hideHeading={true}
                    hideGlobalSearch={true}
                    title={modalType ? 'At Risk Locations' : 'Location Tags'}
                    startDate={startDateValue}
                    endDate={endDateValue}
                    rank={contactRankValue}
                    type={modalType}
                    AgkeyTable={modalType ? 'At Risk Locations' : 'Location Tags'}
                    height={(75*window.innerHeight)/100} 
                    columnexist={8}
                    isModalData={true}
                    role={props.role}
                />
                </div>
            </ReactModal>


            <ToastContainer
                toastClassName={toastClass}
            />
        </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}</>
    )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(Dashboard))
