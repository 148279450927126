import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button, Modal,Select } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import { getShiftCodeList, updateShiftCodeList } from "../actionMethods/actionMethods";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ToastContainer, toast } from "react-toastify";
import { Collapse } from 'antd'
// import { getShiftCodeList } from "../actionMethods/actionMethods";
const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "400px",
  },
};

function ShiftManagement(props) {
const { Panel } = Collapse;
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
  const {Option} = Select
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [shiftList,SetShiftList] = useState([]);
  const [shiftListDefault,SetShiftListDefault] = useState([]);
  const [loader,setLoader] = useState(true);
  const [editModal,SetEditModal] =useState(false);
  const [shiftDetail,SetShiftDetail] = useState({});
  const [inc,SetInc] = useState(0);
  const [shiftSearch,SetShiftSearch] = useState('');
  const [activeTab,SetActiveTab]= useState([''])
  const hour=['00','01','02','03','04','05','06','07','08','09','10','11','12',"13","14","15","16","17","18","19","20","21","22","23"];
  const minute= ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60"]



    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
   let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  

  useEffect(() => {
    let pagePerm= props.pages.filter(el=>el.page_name=="Shift Management")

      SetPermission(pagePerm);
      SetPermissionAPICall(true)
},[props.pages]);


  useEffect(() => {
    if(permission && permission.length > 0){
    getShiftCodeList(userSession, org_id,sub_org_id).then(res => {
        if (res.status === 200) {
          
          // let data = res.data.map((el,index)=>{
          //   let single=el;
          //   single.serial = index + 1;
          //   if(single.shift_code.toLowerCase().startsWith("s")){
          //     single.shift_cat="Morning"
          //   }
          //   else if(single.shift_code.toLowerCase().startsWith("p")){
          //     single.shift_cat="Evening"
          //   }else if(single.shift_code.toLowerCase().startsWith("m")){
          //     single.shift_cat="Night"
          //   }
          //   return single;
          // });

          let data = res.data;
          let morning=  data.filter(el=>el.shift_group.toLowerCase().startsWith("p")).map((el,index)=>({...el,serial:index +1}));
          let evening=  data.filter(el=>el.shift_group.toLowerCase().startsWith("s")).map((el,index)=>({...el,serial:index +1}));
          let night=  data.filter(el=>el.shift_group.toLowerCase().startsWith("m")).map((el,index)=>({...el,serial:index +1}));
          let arr=[];
          arr.push({name:"Morning",data:morning})
          arr.push({name:"Afternoon / Evening",data:evening})
          arr.push({name:"Night",data:night})
          SetShiftListDefault(arr);
          SetShiftList(arr);
          setLoader(false);
        }

    });
  }
    
    }, [selectedDate,permission,inc]);

    function onSearchShift(val){
      SetShiftSearch(val);
      let data = [...shiftListDefault];
      let arr =[];
      
      for(let i=0;i<data.length;i++){
      
        if(data[i].name.toLowerCase().includes(val)){
          
          arr.push(data[i])
        }else if(data[i].data.filter(el=>el.shift_group.toLowerCase().includes(val) ).length > 0 ){
          
            arr.push({name:data[i].name, data:data[i].data.filter(el=>el.shift_group.toLowerCase().includes(val) )})
        }
      }

      if(val.length > 0){
        SetActiveTab(arr.map(el=>el.name.toLowerCase()))
      }else{
        SetActiveTab([])
      }
      

      SetShiftList(arr);

    }

   const ShiftAccordion = (data,shiftSearch) => {
  
  return (
      <Collapse
        className="customAccord" activeKey={activeTab}
       onChange={(val)=>SetActiveTab(val)}
       
       >
      {data.length> 0?data.map((el,index) => (
        <Panel header={
          <div className="costBodayDept" style={{width:"100%"}}>
          <Row>
            <Col lg={12}>{el.name}</Col>
          </Row>
        </div>
          }
           key={el.name.toLowerCase()}
          >
            <div className="costBodaySubDept" style={{width:"100%"}}>
          {AGTable(el.data,el.name+"AGTable")}
          </div>
        </Panel>
      )):<h4 className="ErrorNoFound">No Data Found</h4>}
    </Collapse>
  );
}; 

function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}
function capitalizeFirstLetter(string) {
  if(string){
    return string.toLowerCase();
  }
}
function AGTable(data,key){
  
  if(data){

  let widthCal= 0;
  
  let arr;
  let arr2=[]
  const cellClassRules = {
    "cell-no-space":true 
  };
  
    arr=<div className={key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36) , width: "calc(100% - 1px)"}}>
      
      <AgGridReact
          rowHeight={35}
          
          headerHeight={35}

        defaultColDef={{sortable: true,resizable: true,minWidth:45, flex:1 , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
        showGrid={false}
          //pagination={true}
          rowData={data}
          key={key}
       //   onCellClicked={ (event) => handleClickCard(event.data.worker_id,activeEmp,event) }
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
      <AgGridColumn
          field="serial"
          headerName={"S.No"}
          width={80}
          maxWidth={80}
      />

      <AgGridColumn
          field="shift_code"
          headerName={"Shift Code"}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
          cellClass={"textCapUppercase"}
      />
      <AgGridColumn
          field="start_time"
          headerName={"Start Time"}
      />
      <AgGridColumn
          field="end_time"
          headerName={"End Time"}
      />
            <AgGridColumn
          field="last_updated"
          headerName={"Last Updated"}
          valueFormatter={(params)=>moment(params.value).format('DD MMM YYYY HH:mm')}
      />
      

      <AgGridColumn
          field="id"
          headerName={"Action"}
          cellRenderer={EdiButtonRender}
    
      />   
  </AgGridReact>
  </div></div>
  
  return arr
}else{
  return ;
}

}


  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }





  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  function addZero(val){
    if(val  < 10){
      val= `0${parseInt(val)}`
    }

    return val;
  }
  function editHandlerAlert(data){

    let detail = data;
    detail.start_hour= addZero(data.start_time.split(":")[0])
    detail.start_minute= addZero(data.start_time.split(":")[1])
    detail.end_minute= addZero(data.end_time.split(":")[1])
    detail.end_hour= addZero(data.end_time.split(":")[0])
    SetShiftDetail(detail);
    SetEditModal(true)
  }
    function EdiButtonRender(params){
        

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            editHandlerAlert(params.data)
          }
        });
      
    //   {"chaitanya added ${permissionManpower && permissionManpower.edit !== 'True' ? 'disabled' : ''} in below buttons "}
        
            eGui.innerHTML=`<div class="element-icon ant-table-cell">
                                <button class="btn btn-edit btn-smaller btn-icon acessEnable" data-action="edit" data-el="${params.value}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit</button>
                                
                            </div>`;
                


      
         return eGui;



    }

    function modalEditHandler(data){
      let requestBody={
        "shift_code_id" : shiftDetail.id,
        "shift_code" : shiftDetail.shift_code,
        "start_time" : shiftDetail.start_hour+":"+shiftDetail.start_minute+":00",
        "end_time" :  shiftDetail.end_hour+":"+shiftDetail.end_minute+":00",
        "org_id" : userDetails.org_id,
        "sub_org_id" : userDetails.sub_org_id,
        "session" : userDetails.session
      }
      updateShiftCodeList(requestBody).then(res=>{
        // res.status = 200
        if(res.status === 200){
          SetEditModal(false) ;
          toast.success("Shift updated sucessfully");         
          SetInc((prev)=>prev + 1);
        }else{
          toast.error(res?.message || "Failed to update shift")
        }
      })


    }


  return (
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <ToastContainer/>
        <Row style={{zIndex:99,position:"relative"}}>
          <Col lg={6}>
            <CommonHeading title="Shift Management" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
              />
            </div>
          </Col>
        </Row>



<div className='listingRecordMainDiv manpowerManagementEmployeeListMainDiv'  style={{height:loader?"100vh":"auto", minHeight:400}}>
        <Row className="m-t">
          
            {loader?<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>:<Col lg={12}>
                      <div className="topAreaShiftFilter"style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="left300"><h4>Shift</h4></div>
                             <div className="right300" >
                                <input type="text" value={shiftSearch} placeholder="Search Shift" onChange={(e)=>onSearchShift(e.target.value)} className="form-control" style={{maxWidth:300}} />
                            </div>     
                      </div>
              {ShiftAccordion(shiftList,shiftSearch)}</Col>}
         {editModal && (
           <ReactModal
            isOpen = {editModal}
            onCancel={() => SetEditModal(false)}
            style={customStyles}
          >
            
            <div className="modalHeader"><h4>Edit Shift<div className="closeBtn" onClick={()=>SetEditModal(false)}>x</div></h4></div>
            <div className="modalContentArea">
              <div className="form-field" style={{marginBottom:10}}>
                <label>Shift Name</label>
                <input type="text"  className={shiftDetail.shift_code.length===0?"form-control error":"form-control"} value={shiftDetail.shift_code} readOnly />
              </div>
              <div className="form-field" style={{marginBottom:10}}>
                <label>Start Time</label>
                <Row>
                  <Col lg={6}>
                    <Select
                      showSearch
                      value={shiftDetail.start_hour}
                      onChange={(val)=>SetShiftDetail((prev)=>({...prev,start_hour:val}))}
                      className="bdrSelect"
                      style={{width:"100%"}}
                    >
                     {hour.map(el=>{
                      return <Option value={el} key={"hour"+el}>{el}</Option>
                     })} 
                    </Select>
                  </Col>
                  <Col lg={6}>
                    <Select
                      showSearch
                      value={shiftDetail.start_minute}
                      onChange={(val)=>SetShiftDetail((prev)=>({...prev,start_minute:val}))}
                      className="bdrSelect"
                      style={{width:"100%"}}
                    >
                     {minute.map(el=>{
                      return <Option value={el} key={"minute"+el}>{el}</Option>
                     })} 
                    </Select>
                  </Col>
                </Row>
              </div>
              <div className="form-field" style={{marginBottom:20}}>
                <label>End Time</label>
                <Row>
                  <Col lg={6}>
                    <Select
                      showSearch
                      value={shiftDetail.end_hour}
                      onChange={(val)=>SetShiftDetail((prev)=>({...prev,end_hour:val}))}
                      className="bdrSelect"
                      style={{width:"100%"}}
                    >
                     {hour.map(el=>{
                      return <Option value={el} key={"hour"+el}>{el}</Option>
                     })} 
                    </Select>
                  </Col>
                  <Col lg={6}>
                    <Select
                      showSearch
                      value={shiftDetail.end_minute}
                      onChange={(val)=>SetShiftDetail((prev)=>({...prev,end_minute:val}))}
                      className="bdrSelect"
                      style={{width:"100%"}}
                    >
                     {minute.map(el=>{
                      return <Option value={el} key={"minute"+el}>{el}</Option>
                     })} 
                    </Select>
                  </Col>
                </Row>
                
              </div>
              <div className="btndiv">
                     <button className="btn btn btn-submit btn-filter btn-pink btn-submit" disabled={shiftDetail.shift_code.length ===0} onClick={()=>modalEditHandler()} style={{width:"100%",textAlign:"center"}}>Update Shift</button>
                </div>
                          </div>
          </ReactModal> )}
        </Row>
        </div>
      </Container>


    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ShiftManagement)
);
