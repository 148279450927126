import axios from 'axios'


let prefixURL2 = process.env.REACT_APP_URL_LOGIN

let token = 'tituw3958589'

export function getRoleDetail( role_id , sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/admin/view_role_info?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&role_id=${role_id}` ).then(res=>res.data).catch(err=>err)
}


//http://stagingapi.traceplus.ai/traceadmin/api/v1/clientapp/get_org_webpages?session=605b75fc110a43be933a87a715a03086&org_id=30
export function getOrgWebPages( sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/clientapp/get_org_webpages?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}` ).then(res=>res.data).catch(err=>err)
}

export function getHolidayList(sessionID,org_id,sub_org_id){
    return axios.get(prefixURL2 + `/organization/get_holidays_list?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}` ).then(res=>res.data).catch(err=>err)
}

