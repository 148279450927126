import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "../../../siteManagement/styles/siteManagement.scss";
import "../../../manPowerManagement/style/manpowerManagement.scss"
import spinnerLoader from "../../../assets/images/loader.svg";
import CommonDatePicker from "../../../common/commonDatePicker";
import { getTranslatedText } from "../../../common/utilities";
import { Checkbox, Button, Select } from 'antd';
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../../dashboard/actionMethods/actionMethods";
import { prepareDateObj } from "../../../dashboard/components/helper";

import downArrowFill from "../../../assets/images/down-arrowFill.png";
import DashboardLanguage from "../../../components/dashboardLanguage";
import batteryIcon from "../../../assets/traceplusImages/battery.svg";
import emailIcon from "../../../assets/traceplusImages/employee_email_icon.svg";
import dropdownIcon from "../../../assets/images/down-arrow.png";
import selectedPinkArrowIcon from "../../../assets/traceplusImages/pink_right_arrow_icon.svg";
import empIDIcon from "../../../assets/traceplusImages/employee_id_icon.svg";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import SucessIcon from "../../../assets/images/success.png"
import FailIcon from "../../../assets/images/fail.png"

import '../../styles/policyCompliance.scss'
import '../../styles/policyDetail.scss'
import Modal from 'react-modal'
import ExportIcon from "../../../assets/images/export.png"
import ALertSucess from '../alert';
import {getPolicZoom,getPolicyLocation} from '../../actionMethods/actionMethods'
import ReactExport from "react-export-excel";
import DeviceStatus from "../../../components/DeviceStatus";

import {ReactComponent as Image404 } from  '../../../assets/images/11132-ai.svg'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function PolicyDetail(props) {
  let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
  let interval = 5;
  let idVal = props.match.params.id.replace(":", "");

  const [employeeDetails, updateEmployeeDetails] = useState("");
  const [prevEmployeeDetails, updatePrevEmployeeDetails] = useState("");

  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [infectedFlag, updateInfectedFlag] = useState(false);

  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [prevemployeeData, setprevEmployeeData] = useState("");
  const [employeeHistoryData, setEmployeeHistoryData] = useState('');

  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });
  const [loader,SetLoader]=useState(true);

  const [chartData, setChartData] = useState({ series: [], categories: [] });

  const[SelectedCapacity,SetSelectedCapacity]=useState([])
  const[IsAllCapacity,SetIsAllCapacity]=useState(false);
  const[SelectedAcess,SetSelectedAcess]=useState([]);
  const[IsAllAcess,SetIsAllAcess]=useState(false);
 // const [testedPositiveDate, updateTestedPositiveDate] = useState(null);

    const [sortKey, setSortKey] = useState('access')
    const [sortType, setSortType] = useState('desc')

    const [testedPositiveDate, updateTestedPositiveDate] = useState(date);
    const [chartLoader, setChartLoader] = useState(true);

    const[dataCapacity,SetDataCapacity]=useState([]);
    const[dataAccess,SetAcessCapacity]=useState([]);
  
    const [sortKeyaccess, setSortKeyaccess] = useState('access')
    const [sortTypeaccess, setSortTypeaccess] = useState('desc')
  
    const [sortKeycapacity, setSortcapacity] = useState('capacity')
    const [sortTypecapacity, setSortTypecapacity] = useState('desc')
    const[Alert,SetAlert]=useState(false);
    const[AlertFail,SetAlertFail]=useState(false);
    const [capacityBreach,setCapacityBreach]=useState([]);
    const[accessBreach,setAcessBreach]=useState([])
    const[loopDummy,SetLoopDummy]=useState(0);
    const [PolicyName,SetPolicyName]=useState('');
    const[LocSerial,setLocSerial]=useState('');
    const[LocationName,SetLocationName]=useState('');

    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);

    
    
    let Height= window.innerHeight - 100;
    const initialHeight = Height >810?Height:810; 
    const leftheight = initialHeight - 460;

    const customStyle = {
      overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
      },
      content: {
          position: 'absolute',
          height: '14rem',
          width:'540px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0'
      }
    }
  

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  const EMPDATA =[
      {
          name:"Bruce Banner",
          team:"Gamma Team Resarch",
          startdate:"13-11-2021",
          enddate:"15-11-2021",
      }
  ]




  
useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.sub_page_name=="Policy Compliance Detail"); 
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);



  useEffect(()=>{
    if(permission && permission.length > 0){
        getPolicyLocation( userSession, org_id,sub_org_id).then(res => {
          if (res.status === 200) {
            let data= res.data;
            Object.keys(data).forEach((key) => {
              if(LocSerial==key){
                SetLocationName(data[key])
              }
          })              

          }
      });
    }
  },[LocSerial,permission])





  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }



useEffect(() => {
  if(permission && permission.length > 0){
  DateChangeHandler()
  }
}, [dates,permission]);

  useEffect(() => {
      SetDataCapacity(capacityBreach);
      SetAcessCapacity(accessBreach);       

}, [])


  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }

  const handleDate = (date, type) => {
    setDates((prev) => ({ ...prev, [`${type}`]: date }));
  };

  function DateChangeHandler(){
    if(permission && permission.length > 0){
    let requestBody=[]
    requestBody.start_date=getDateFormat(dates.start);
    requestBody.end_date=getDateFormat(dates.end);
    requestBody.policy_id=idVal;
    let arrCapacity=[];
    let arrAcess=[];
    SetLoader(true);
    getPolicZoom(requestBody,userSession, org_id,sub_org_id).then(res => {
            if (res.status === 200) {

                let data= res.data.policy;

                let accessibility=data.accessibility.team
                let capacity =data.capacity.team
                let index=0;

              SetPolicyName(data.name);
              setLocSerial(data.loc_serial);
              Object.keys(accessibility).forEach((key) => {
                let element= accessibility[key].employee;

                Object.keys(element).forEach((key1) => {

                  arrAcess.push({
                      name:element[key1].name,
                      team:key,
                      occurence:element[key1].count
                    })
                  })             
              })
              Object.keys(capacity).forEach((key) => {
                let element= capacity[key].employee;

               Object.keys(element).forEach((key1) => {
                      arrCapacity.push({
                      name:element[key1].name,
                      team:key,
                      occurence:element[key1].count
                    })
                  })              
              })
               SetAcessCapacity(arrAcess);
              SetDataCapacity(arrCapacity);    
              setCapacityBreach(arrCapacity);
              setAcessBreach(arrAcess)      
               SetLoader(false)

            }
                          
        });

      }
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleManpowerManagementList() {
    props.history.push("/policy-management");
  }

function CloseAlert(){
  SetAlert(false);
  SetAlertFail(false)

}
function OpenAlert(){
  SetAlert(true);
}

  function handleDateSelect(date) {

    updateSelectedDate(date)


    }








  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };






  const handleSort = (key) => {
    
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
  }

  function onChangeAllCheckbox(e, type) {
    // alert(e.target.checked)
    if (e.target.checked && type=="Capacity") {
        let arr = Array.from({ length: type === 'Capacity' ? dataCapacity.length : dataCapacity.length }, (v, i) => i);
        SetIsAllCapacity((prev) => !prev)
        SetSelectedCapacity(arr)
       
    }else if(e.target.checked && type=="Access"){
      let arr = Array.from({ length: type === 'Access' ? dataAccess.length : dataAccess.length }, (v, i) => i);
      SetIsAllAcess((prev) => !prev)
      SetSelectedAcess(arr)
     
    } else if(type=="Capacity") {
        SetIsAllCapacity(false)
        SetSelectedCapacity([])
        
    }else if(type =='Access'){
      SetIsAllAcess(false)
      SetSelectedAcess([])
      
    }

}
function onChangeCheckbox(e, index) {
  let arr = [...SelectedCapacity]
  SetIsAllCapacity(false)
  if (SelectedCapacity.includes(index)) {
      let i = arr.indexOf(index)
      arr.splice(i, 1)
      SetSelectedCapacity(arr)
  } else {
      arr.push(index)
      SetSelectedCapacity(arr)
  }
}
function onChangeCheckboxAcess(e, index) {
  let arr = [...SelectedAcess]
  SetIsAllAcess(false)
  if (SelectedAcess.includes(index)) {
      let i = arr.indexOf(index)
      arr.splice(i, 1)
      SetSelectedAcess(arr)
  } else {
      arr.push(index)
      SetSelectedAcess(arr)
  }
}

  function showMostCapacityBreach(data) {
    let arr = [];
    let arr2=[];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      arr.push(
<tr className="employee-detail-table-tr" key={generateKey(index)}>      
                {/* <td style={{width:"20px"}}>
                  <Checkbox onChange={(e) => onChangeCheckbox(e, index)} checked={IsAllCapacity || SelectedCapacity.includes(index)} />                  
                </td> */}
                <td style={{width:"40%"}}>{capitalizeFirstLetter(element.name)}</td> 
               <td style={{width:"35%"}}>{capitalizeFirstLetter(element.team)}</td>
                <td style={{width:"25%"}} className="employee-detail-table-tr-td occurence-center">{element.occurence}</td>
            </tr>
      );




    }
    return arr;
  }

  const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

  
  function showMostAccessBreach() {
    let arr = [];
    let arr2=[];

    let data =dataAccess;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if(element.occurence > 0){     
      arr.push(

<tr className="employee-detail-table-tr" key={generateKey(index)}>      
                {/* <td style={{width:"20px"}}>
                <Checkbox onChange={(e) => onChangeCheckboxAcess(e, index)} checked={IsAllAcess || SelectedAcess.includes(index)} />
                </td> */}
                <td style={{width:"40%"}}>{capitalizeFirstLetter(element.name)}</td>
                <td style={{width:"35%"}}>{capitalizeFirstLetter(element.team)}</td>
                <td  className="employee-detail-table-tr-td occurence-center" style={{width:"25%"}}>{element.occurence}</td>
            </tr>
      );
              }
    }

    return arr;
  }

function handleSortcapacity(){
   
    setSortTypecapacity(sortTypecapacity=='desc'?'asc':'desc');
    
}
function handleSortAcess(){
    setSortTypeaccess(sortTypeaccess=='desc'?'asc':'desc')
}

useEffect(() => {
    let arr=[];
    arr = capacityBreach.sort((a,b) => {
        return  sortTypecapacity=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetDataCapacity(arr); 
       
  }, [sortTypecapacity]);


  useEffect(() => {
    let arr=[];
    arr = accessBreach.sort((a,b) => {
        return  sortTypeaccess=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetAcessCapacity(arr)
  }, [sortTypeaccess]);

  function OpenAlertFail(){

    SetAlertFail(true);
  }
  

  
  if(permissionAPICall && permission.length == 0){
    return(<Col lg={12}>
      
  <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
  </Col>)

  }
  
  if (capacityBreach || accessBreach) {
 
    return (
      <div className={loader?"manpowerManagementMainDiv siteViewMainDiv table-box-center loading":"manpowerManagementMainDiv siteViewMainDiv table-box-center"} >
           <div className="loading-div">
                  <img src={spinnerLoader}/>
             </div>

        <div className="employeeDetailsMainDiv">
          <Container className="header-breadcrumb">
              
            <Row>
              <Col lg={5} className="m-t-sm">
                <div className="siteViewHeaderDiv">
                  <span
                    className="smallHeader"
                    onClick={handleManpowerManagementList}
                  >
                    {getTranslatedText("Policy Management")}
                  </span>
                  <span className="breadCrumbArrow"> &gt; </span>
                  <span className="mediumHeader">
                    {getTranslatedText("Detail")}
                  </span>
                </div>
              </Col>

              <Col lg={7} className="text-right">
              <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
              </Col>
            </Row>
            </Container>
<Container>
            <div className="mostInteractedMainDiv mostInteractedMainDiv2 m-t-lg" style={{height:initialHeight}}>
          
      
            <div className="m-b">
            <div className="compliance-header" style={{ marginBottom: 20 }}>
            <Row>
              <Col lg={6}>
                  
                    <h5 className="font-bold">{PolicyName}</h5>
                    <h6 className="font-bold">{LocationName}</h6>
            </Col>
            <Col lg={6} className="date-align-right">
              <div lg={3} className="p-r-0 p-l-0" style={{maxWidth:"240px"}} >
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />

                            </div>
                          </div>
                          <div  className="p-l-0" style={{maxWidth:"240px"}}>
                            <div
                              className="startDateEndDateMainDiv date-end"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                             
                            </div>
                            </div>
                          </Col>
                  </Row>
              </div>

                      <Row style={{ margin: 0 }}>
                        <Col className="p-l-0" lg={6}>
                           <div className="top-title"> <h6>Capacity breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                                {/* <th style={{width:"20px"}}>
                                <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'Capacity')} checked={IsAllCapacity} />
                                  </th> */}
                                 <th style={{width:"40%"}}>Name   
                                 <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`CapacityinterName`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`CapacityinterName`)
                                  }
                                />
                                <span className="descHelp" id="CapacityinterName">
                                Name of Employee
                                </span>
                                </th>
                              <th style={{width:"35%"}}>
                                Team
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`CapacityinterHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`CapacityinterHelp`)
                                  }
                                />
                                <span className="descHelp" id="CapacityinterHelp">
                                List of Team 
                                </span>

                              </th>
                              <th  className="employee-detail-table-tr-td occurence-center" style={{width:"25%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypecapacity === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortcapacity('Occurence')}

                                />
                                <span className="descHelp" id="Occurence">
                                Occurence of breaches 
                                </span>

                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 250,minHeight:leftheight }}
                            >
                              {showMostCapacityBreach(dataCapacity)}
                            </Scrollbars>

                          </table>
                          <div className="alertDiv alertDiv-down-10" style={{float:"right"}}>
                      <ExcelFile  element={
                                    <div className="viewAllEmployeesButton mr-10"> 
                                  <img src={ExportIcon} style={{maxWidth:"20px",verticalAlign:"bottom"}} /> Export Excel</div>           
                                              
                                              } filename="Capacity Breach Data">
                          <ExcelSheet data={dataCapacity} name="capacity">
                          <ExcelColumn label="Name" value="name"/>
                          <ExcelColumn label="Team" value="team"/>
                          <ExcelColumn label="Occurence" value="occurence"/>
                          </ExcelSheet>
                          </ExcelFile>



                          <div className="viewAllEmployeesButton disabledBtn">
                          Alert
                          </div>
                          </div>

                        </Col>

                        <Col className="p-l-0 p-r-0" lg={6}>
                        <div className="top-title">   <h6>Access breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                             {/* <th style={{width:"20px"}}><Checkbox onChange={(e) => onChangeAllCheckbox(e, 'Access')} checked={IsAllAcess} /></th> */}
                              <th style={{width:"40%"}} >
                                Name
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interName2`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interName2`)
                                  }
                                />
                                <span className="descHelp" id="interName2">
                                List of Employee
                                </span>

                              </th>
                              <th style={{width:"35%"}}>
                                Team
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interTeam`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interTeam`)
                                  }
                                />
                                <span className="descHelp" id="interTeam">
                                List of Team
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:"25%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypeaccess === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortAcess('access')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 250,minHeight:leftheight }}
                            >
                              {showMostAccessBreach()}
                            </Scrollbars>

                          </table>
                          <div className="alertDiv alertDiv-down-10" style={{float:"right"}}>
                            <ExcelFile  element={
                                          <div className="viewAllEmployeesButton mr-10"> 
                                               <img src={ExportIcon} style={{maxWidth:"20px",verticalAlign:"bottom"}} /> Export Excel
                                            </div>           
                              } filename="Acess Breach Data">
                              <ExcelSheet data={dataAccess} name="AcessBreach">
                                  <ExcelColumn label="Name" value="name"/>
                                  <ExcelColumn label="Team" value="team"/>
                                  <ExcelColumn label="Occurence" value="occurence"/>
                              </ExcelSheet>
                          </ExcelFile>
                              <div className="viewAllEmployeesButton disabledBtn" >
                                Alert
                              </div>
                            </div>

                        </Col>
                        

                      </Row>
                      
                    </div>
                    
             <div style={{width:"100%",marginTop:"60px"}}>
             {/* <div className="alertDiv" style={{float:"right"}}>

<div className="viewAllEmployeesButton">
  OK
</div>
</div> */}
</div>
</div>
<Modal
    isOpen={Alert===true?true:false}
    style={customStyle}
    className="alert-sucess"
>
    <div className='logoutModal MarkforPositive'>
        <div className="sucess-inner">
            <img src={SucessIcon}/>
        </div>
        <div className='logoutTitle'>Message is send Sucessfully </div>
        <div className='actionButton'>
            <span className='btnText okBtn bnt-close-bigger' onClick={CloseAlert}>Close </span>
        </div>
    </div>
</Modal>
<Modal
    isOpen={AlertFail}
    style={customStyle}
    className="alert-sucess alert-fail"
>
    <div className='logoutModal MarkforPositive'>
        <div className="sucess-inner">
            <img src={FailIcon}/>
        </div>
        <div className='logoutTitle'>Message  send <span style={{color:"#ff0000"}}>fail</span> </div>
        <div className='actionButton'>
            <span className='btnText bnt-close-smaller btn-cancel' onClick={CloseAlert}>Close </span>
            <span className='btnText okBtn bnt-close-smaller'>Retry </span>
        </div>
    </div>
</Modal>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(PolicyDetail)
);
