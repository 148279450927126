export const ground = [
  {
    x: 13.150971859600826,
    z: -8.191170245396185,
    levelIndex: 0,
  },
  {
    x: 38.76286474251818,
    z: -8.16076833830061,
    levelIndex: 0,
  },
  {
    x: 41.41431717596051,
    z: -10.907488317456098,
    levelIndex: 0,
  },
  {
    x: 45.76996714898969,
    z: -10.931192929772987,
    levelIndex: 0,
  },
  {
    x: 46.16878393236105,
    z: -12.065911919144547,
    levelIndex: 0,
  },
  {
    x: 48.47295487907417,
    z: -12.087552956286236,
    levelIndex: 0,
  },
  {
    x: 49.00568544561274,
    z: -10.932214880614008,
    levelIndex: 0,
  },
  {
    x: 52.971312606244375,
    z: -10.945560355239621,
    levelIndex: 0,
  },
  {
    x: 52.98144770874784,
    z: -12.041806202214046,
    levelIndex: 0,
  },
  {
    x: 55.46884618493987,
    z: -12.050522734017436,
    levelIndex: 0,
  },
  {
    x: 55.489457915457166,
    z: -8.916845198653748,
    levelIndex: 0,
  },
  {
    x: 56.122198727355055,
    z: -8.168637132805422,
    levelIndex: 0,
  },
  {
    x: 71.15326986805682,
    z: -8.223516502558008,
    levelIndex: 0,
  },
  {
    x: 71.14529317852958,
    z: -44.32734755404198,
    levelIndex: 0,
  },
  {
    x: 54.49083701991365,
    z: -44.35943070445595,
    levelIndex: 0,
  },
  {
    x: 54.51321373562766,
    z: -35.31824536298333,
    levelIndex: 0,
  },
  {
    x: 38.7837592919385,
    z: -35.317927506478554,
    levelIndex: 0,
  },
  {
    x: 38.74559179722954,
    z: -36.35416682472641,
    levelIndex: 0,
  },
  {
    x: 31.64054681569246,
    z: -36.36262783909807,
    levelIndex: 0,
  },
  {
    x: 29.115628138382647,
    z: -35.84586629848322,
    levelIndex: 0,
  },
  {
    x: 25.618671727437636,
    z: -32.510888785924706,
    levelIndex: 0,
  },
  {
    x: 25.046765092303993,
    z: -32.989748735642856,
    levelIndex: 0,
  },
  {
    x: 11.563242830302435,
    z: -32.97518921552479,
    levelIndex: 0,
  },
  {
    x: 9.368627885855526,
    z: -12.07468461260607,
    levelIndex: 0,
  },
  {
    x: 10.85944776225363,
    z: -12.077120721308429,
    levelIndex: 0,
  },
  {
    x: 13.629272214197389,
    z: -9.481641469059795,
    levelIndex: 0,
  },
  {
    x: 13.131734386241394,
    z: -8.915866830953647,
    levelIndex: 0,
  },
];
// export const ground2 = [
//       {
//         "levelIndex": 0,
//         "x": -35.2750555742273,
//         "z": 29.189921046153607,
//         "elevation": 0.01200000010433655
//       },
//       {
//         "levelIndex": 0,
//         "x": -35.419524260295134,
//         "z": 15.627959219313052,
//         "elevation": 0.01200000010432234
//       },
//       {
//         "levelIndex": 0,
//         "x": -14.74936456774308,
//         "z": 16.39473090646167,
//         "elevation": 0.010999999940409566
//       },
//       {
//         "levelIndex": 0,
//         "x": -14.594542025103806,
//         "z": -53.30281451008989,
//         "elevation": 0.0020000000950091135
//       },
//       {
//         "levelIndex": 0,
//         "x": 89.51395864344659,
//         "z": -53.48996172569387,
//         "elevation": 0.010999999940395355
//       },
//       {
//         "levelIndex": 0,
//         "x": 93.8879634278082,
//         "z": 29.077808504118437,
//         "elevation": 0.1459592682240185
//       },
//       {
//         "levelIndex": 0,
//         "x": -32.4353694454518,
//         "z": 29.357761235041263,
//         "elevation": 0.01200000010432234
//       },
//       {
//         "levelIndex": 0,
//         "x": -33.77664968307346,
//         "z": 29.412293833212075,
//         "elevation": 0.012000000104308128
//       }
//     ];
export const ground2= [
      {
        "levelIndex": 0,
        "x": -35.97161241396151,
        "z": 29.54197453789262,
        "elevation": 0.021794472898946537
      },
      {
        "levelIndex": 0,
        "x": -35.89593550995909,
        "z": 15.850189828002815,
        "elevation": 0.01200000010432234
      },
      {
        "levelIndex": 0,
        "x": -17.710734553422043,
        "z": 16.472986337559114,
        "elevation": 0.010999999940381144
      },
      {
        "levelIndex": 0,
        "x": -17.750021289396756,
        "z": 16.727709371210782,
        "elevation": 0.010999999940381144
      },
      {
        "levelIndex": 0,
        "x": -18.112013540122888,
        "z": 16.218776731856806,
        "elevation": 0.012000000104308128
      },
      {
        "levelIndex": 0,
        "x": -17.54447363616439,
        "z": 16.488301995030152,
        "elevation": 0.01200000010432234
      },
      {
        "levelIndex": 0,
        "x": -17.730927153749846,
        "z": -53.53597785138012,
        "elevation": 0.04077698726243284
      },
      {
        "levelIndex": 0,
        "x": 93.88796723807195,
        "z": -52.87508952755203,
        "elevation": 1.9364639885826307
      },
      {
        "levelIndex": 0,
        "x": 93.3061314194041,
        "z": 29.54197471042771,
        "elevation": 1.2287577643199086
      },
      {
        "levelIndex": 0,
        "x": -35.59180483409913,
        "z": 29.541974541490767,
        "elevation": 1.6619447849506628
      }
    ]

export const categoryPolyline =[
  {
  "id": "9fb84128-b5f6-56d1-b00a-ca23a2e1f35f",
  "name": "Multifunction ",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": 51.22610687672928,
      "z": -22.776484922384597,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 51.23804551154066,
      "z": -40.84404271980152,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 77.88083131283369,
      "z": -40.9074034231382,
      "elevation": 0.012000000104293918
    },
    {
      "levelIndex": 0,
      "x": 77.65966839276223,
      "z": -22.663010954845067,
      "elevation": 0.01200000010432234
    },
    {
      "levelIndex": 0,
      "x": 51.466416783129915,
      "z": -22.805447122138634,
      "elevation": 0.012000000104308128
    }
  ]
},
{
  "id": "469a35fd-55a2-5e9c-b0b5-c45df7a134c3",
  "name": "Gate IB",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": 77.33022597830188,
      "z": 14.91218891908623,
      "elevation": 0.01200000010432234
    },
    {
      "levelIndex": 0,
      "x": 77.96395609070723,
      "z": -42.76670119876937,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 87.3225292073156,
      "z": -42.549187153785084,
      "elevation": 0.35116537017051996
    },
    {
      "levelIndex": 0,
      "x": 87.17599375412951,
      "z": 15.00257650100382,
      "elevation": 0.044746293905234324
    },
    {
      "levelIndex": 0,
      "x": 77.19891001973534,
      "z": 15.002576494353221,
      "elevation": 0.01966434006999407
    }
  ]
},
{
  "id": "28b31893-1407-55ee-934b-b81e7ecc1c47",
  "name": "Sort Area",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -13.76955233654656,
      "z": 6.974401158499926,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": -13.673445446025333,
      "z": -20.465040088449477,
      "elevation": 0.01200000010432234
    },
    {
      "levelIndex": 0,
      "x": 75.55256916318946,
      "z": -20.6192187672893,
      "elevation": 0.012000000104293918
    },
    {
      "levelIndex": 0,
      "x": 75.53244643182424,
      "z": 6.953953731229529,
      "elevation": 0.01200000010432234
    },
    {
      "levelIndex": 0,
      "x": -13.520245420565494,
      "z": 6.992923522341794,
      "elevation": 0.0020000000949949026
    }
  ]
},
{
  "id": "d55a7c2a-ac4a-5730-8337-8b79fa7003b9",
  "name": "Gate OB",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -5.993072210569444,
      "z": 15.002576434788615,
      "elevation": 0.08414554501524663
    },
    {
      "levelIndex": 0,
      "x": -6.131223849756061,
      "z": 7.644754348602561,
      "elevation": 0.0020000000949593755
    },
    {
      "levelIndex": 0,
      "x": 75.78412672576331,
      "z": 7.585755051784361,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 75.95501306566959,
      "z": 15.002576493029053,
      "elevation": 0.31331551220726794
    },
    {
      "levelIndex": 0,
      "x": -5.591827570042771,
      "z": 15.002576435073749,
      "elevation": 0.5546005745557352
    }
  ]
},
{
  "id": "32ad6a0b-12a0-5ab0-978b-c78c34a2d05c",
  "name": "RTM & Airlinehaul",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -13.689286610190411,
      "z": -40.8239578160711,
      "elevation": 0.007000000216081048
    },
    {
      "levelIndex": 0,
      "x": 49.40940499360336,
      "z": -40.45455184663792,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 49.284072764645146,
      "z": -26.097696403940645,
      "elevation": 0.0020000000949949026
    },
    {
      "levelIndex": 0,
      "x": -0.05698398400607729,
      "z": -26.11799077517248,
      "elevation": 0.007000000216066837
    },
    {
      "levelIndex": 0,
      "x": -0.37424537071917285,
      "z": -24.34037645620171,
      "elevation": 0.007000000216066837
    },
    {
      "levelIndex": 0,
      "x": -13.910854289474273,
      "z": -24.00619888994541,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": -14.053016566541746,
      "z": -40.287696130873016,
      "elevation": 0.0020000000949949026
    }
  ]
},
{
  "id": "17c68827-b3d3-50fe-88b6-0d92c084d9ca",
  "name": "Non ops",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -17.7280343743602,
      "z": -53.31982283045688,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": 93.69490801589123,
      "z": -53.38680067193211,
      "elevation": 0.010999999940381144
    },
    {
      "levelIndex": 0,
      "x": 94.43457580479543,
      "z": 15.4487145492537,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 94.5020670459621,
      "z": 15.648152671399197,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 87.32752782277329,
      "z": 13.129591808197034,
      "elevation": 2.320709197723687
    },
    {
      "levelIndex": 0,
      "x": 89.02892989995851,
      "z": -47.82931464293583,
      "elevation": 0.010999999940395355
    },
    {
      "levelIndex": 0,
      "x": 86.97862273090954,
      "z": -48.616204832004925,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": 81.4304333186387,
      "z": -48.68712507849878,
      "elevation": 0.0020000000949806918
    },
    {
      "levelIndex": 0,
      "x": 81.41749336021203,
      "z": -44.24127023200788,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": 59.171898894970596,
      "z": -43.624917449490034,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": 41.35885239746552,
      "z": -44.65010151857856,
      "elevation": 3.0000000000000426
    },
    {
      "levelIndex": 0,
      "x": 41.36602167885272,
      "z": -43.09414831061403,
      "elevation": 1.7947631155871022
    },
    {
      "levelIndex": 0,
      "x": 8.756350354557789,
      "z": -43.15316789817484,
      "elevation": 2.542289135348881
    },
    {
      "levelIndex": 0,
      "x": 7.361090864961859,
      "z": -44.75821541272467,
      "elevation": 0.0020000000949949026
    },
    {
      "levelIndex": 0,
      "x": 0.3271799329573142,
      "z": -45.88396665083167,
      "elevation": 2.420877807595076
    },
    {
      "levelIndex": 0,
      "x": -0.03207170447205954,
      "z": -44.693181989769855,
      "elevation": 2.6215880601134813
    },
    {
      "levelIndex": 0,
      "x": -11.476345599663738,
      "z": -44.49632106256381,
      "elevation": 2.2575765719524696
    },
    {
      "levelIndex": 0,
      "x": -13.677253406857673,
      "z": -49.68016103490404,
      "elevation": 0.48245232397889026
    },
    {
      "levelIndex": 0,
      "x": -13.677253337059518,
      "z": -50.6319907463656,
      "elevation": 2.726534735832331
    },
    {
      "levelIndex": 0,
      "x": -13.677253839305209,
      "z": -43.78292182649715,
      "elevation": 2.8857293387468417
    },
    {
      "levelIndex": 0,
      "x": -15.292816527588485,
      "z": -42.04451341614646,
      "elevation": 0.0020000000949949026
    },
    {
      "levelIndex": 0,
      "x": -17.734388313710355,
      "z": -41.22481636097873,
      "elevation": 0.43433557823318836
    },
    {
      "levelIndex": 0,
      "x": -17.734388289159703,
      "z": -52.490448503180595,
      "elevation": 1.0244800404041996
    },
    {
      "levelIndex": 0,
      "x": -17.7343882892144,
      "z": -52.465345812549266,
      "elevation": 1.262296122396208
    },
    {
      "levelIndex": 0,
      "x": -16.274604117050934,
      "z": -53.585977851380115,
      "elevation": 2.631953579818699
    }
  ]
},
{
  "id": "4f6e7ac2-b79b-5b08-aacb-e60c08b9928f",
  "name": "Outdoor",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -35.789034054571,
      "z": 15.281589892256724,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 93.55585895358054,
      "z": 15.010237896206814,
      "elevation": 0.010999999940395355
    },
    {
      "levelIndex": 0,
      "x": 93.88796342780819,
      "z": 29.341029316303786,
      "elevation": 0.2840165884546053
    },
    {
      "levelIndex": 0,
      "x": -35.875322693189446,
      "z": 29.033643892428216,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": -35.59551037020451,
      "z": 15.744313669951577,
      "elevation": 0.012000000104308128
    }
  ]
},
{
  "id": "32ad6a0b-12a0-5ab0-978b-c78c34a2d05c",
  "name": "Entrance Gate",
  "coordinates": [
    {
      "levelIndex": 0,
      "x": -13.689286610190411,
      "z": -40.8239578160711,
      "elevation": 0.007000000216081048
    },
    {
      "levelIndex": 0,
      "x": 49.40940499360336,
      "z": -40.45455184663792,
      "elevation": 0.012000000104308128
    },
    {
      "levelIndex": 0,
      "x": 49.284072764645146,
      "z": -26.097696403940645,
      "elevation": 0.0020000000949949026
    },
    {
      "levelIndex": 0,
      "x": -0.05698398400607729,
      "z": -26.11799077517248,
      "elevation": 0.007000000216066837
    },
    {
      "levelIndex": 0,
      "x": -0.37424537071917285,
      "z": -24.34037645620171,
      "elevation": 0.007000000216066837
    },
    {
      "levelIndex": 0,
      "x": -13.910854289474273,
      "z": -24.00619888994541,
      "elevation": 0.0020000000950091135
    },
    {
      "levelIndex": 0,
      "x": -14.053016566541746,
      "z": -40.287696130873016,
      "elevation": 0.0020000000949949026
    }
  ]
}
]
