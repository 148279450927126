
import React, { useEffect, useMemo, useState } from 'react';
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts-exporting'
import moment from "moment";

// HighchartsExporting(ReactHighcharts.Highcharts)

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}

let config = {
        chart: {
            type: 'column',
            height: 420,
        },
        title: {
            text: ''
        },
     xAxis: {
            categories: [],
            
            align:"left",
            startOnTick:true,
 
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min: 0,
            max:100,
            labels: {
                enabled: true,
                formatter:function(){
                    
                    return this.value+"%";
                    
                   
                }
            },
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'normal',
                    fontSize:"5px",
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        }, 
       tooltip: {
            useHTML: true,
            pointFormat: '<span style="background:{series.color};background: #90cf4e;width: 5px;height: 5px;border-radius: 5px;display: inline-block;margin-right: 2px;"></span>{series.name}: <b>{point.y:.2f}%</b></span><br/>',
            width: 180, 
            style: {
                minWidth: '170px', // Set your desired minimum width here
            },
        },
          plotOptions: {
            column: {
            dataLabels: {
                enabled: true,
                formatter: function() {
                return this.y.toFixed(0);
                }
            }
            }
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                    formatter: function() {
                        
                        return this.y.toFixed(0);
                    },
                    style: {
                    fontWeight: 'bold',
                    fontSize:"10px",
                    color: '#32325d',   
                }
                
            },
               states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
                },    
            point:{
                    events: {
                        click: (e) => {
                          
                            e.point.select(true);
                        
                        //    props.modalChart(moment(e.point.category).format('YYYY-MM-DD'));
                        }
                    }
                }
        },

                },

        series:[]
    }

    let reload=0;
const StackChartDrillDown = (props) => {
    

    
    const [series,SetSeries]= useState(props.series?props.series:[]);
    const [helpEnable,SetHelpEnable]= useState(props.helpEnable?true:false);
    const [typePerormance,SetTypePerormance]= useState(props.typePerormance?props.typePerormance:"");
    const [categories,SetCategories]= useState(props.categories?props.categories:[]);
    const [height,SetHeight]= useState(props.height?props.height:420);
    const [width,SetWidth]= useState(props.width?props.width:"100%");
    const [incUse,SetIncUse]= useState(0)
    const [chartOption,SetChartOption] = useState({});
    useEffect(()=>{

            SetHelpEnable(props.helpEnable?props.helpEnable:false);
            SetSeries(props.series?props.series:[]);
            SetTypePerormance(props.typePerormance?props.typePerormance:'');
            SetCategories(props.categories?props.categories:[]);
            SetHeight(props.height?props.height:'');
            SetWidth(props.width?props.width:'');

    },[props])
    useEffect(()=>{
        reload++
        let chartOp=config;
        let type= categories.length < 7?'week':'month';
       
        chartOp.series= series;
        //chartOp.plotOptions.column.dataLabels.enabled = helpEnable
        chartOp.plotOptions.column.point = {
                    events: {
                        click: (e) => {
                            
                            e.point.select(true);
                           props.modalChart(moment(e.point.category).format('YYYY-MM-DD'));
                        }
                    }
                }
        chartOp.plotOptions.column.dataLabels.enabled = helpEnable
        chartOp.xAxis.labels={}
        chartOp.xAxis.labels.formatter = function(){
                    
                    if(typePerormance && (typePerormance=='week' || typePerormance=='today' || typePerormance=='month')){
                         return this.value       
                    }else{
                    return type=="week"?moment(this.value).format('ddd, MMM DD'):moment(this.value).format('DD MMM');
                    }
                   
                }
        chartOp.xAxis.tickPositioner=  function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment;
                if(type=='week' || props.typePerormance=='today'){
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?1:1);
                }else{
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?2:1);
                }
                
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }        


            chartOp.xAxis.categories=categories;
            chartOp.chart.height=height
            
            
            SetChartOption(chartOp)
            SetIncUse(reload)


    },[helpEnable,series,typePerormance,categories,height,width])
        




    return (
        <React.Fragment>
                <ReactHighcharts config={chartOption}  key={props.key1?props.key1:"uniqueKey"} />
        </React.Fragment>
    )
}


export default React.memo(StackChartDrillDown);