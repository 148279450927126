import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";

import "../../../siteManagement/styles/siteManagement.scss";
import "../../style/manpowerManagement.scss";

import {
  employeeChart,
  getEmployeeDetails,
  getEmployeeIndex,
  postMarkedemployee,
} from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import CommonDatePicker from "../../../common/commonDatePicker";
import { getTranslatedText } from "../../../common/utilities";
import AreaChart from "./areaChart";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../../dashboard/actionMethods/actionMethods";
import { prepareDateObj } from "../../../dashboard/components/helper";

import downArrowFill from "../../../assets/images/down-arrowFill.png";
import DashboardLanguage from "../../../components/dashboardLanguage";

import batteryIcon from "../../../assets/traceplusImages/battery.svg";
import emailIcon from "../../../assets/traceplusImages/employee_email_icon.svg";
import dropdownIcon from "../../../assets/images/down-arrow.png";
import selectedPinkArrowIcon from "../../../assets/traceplusImages/pink_right_arrow_icon.svg";
import empIDIcon from "../../../assets/traceplusImages/employee_id_icon.svg";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import calenderIcon from '../../../assets/traceplusImages/calendar_icon_white.svg'
import Modal from 'react-modal'
import DeviceStatus from "../../../components/DeviceStatus";
import {ReactComponent as Image404 } from '../../../assets/images/11132-ai.svg'

function EmployeeDetails(props) {
  let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
  let interval = 5;
  let idVal = props.match.params.id.replace(":", "");

  const [employeeDetails, updateEmployeeDetails] = useState("");
  const [prevEmployeeDetails, updatePrevEmployeeDetails] = useState("");

  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [infectedFlag, updateInfectedFlag] = useState(false);

  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [prevemployeeData, setprevEmployeeData] = useState("");
  const [employeeHistoryData, setEmployeeHistoryData] = useState('');

  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });
  const [chartData, setChartData] = useState({ series: [], categories: [] });
 // const [testedPositiveDate, updateTestedPositiveDate] = useState(null);
  const [testedPositiveDate, updateTestedPositiveDate] = useState(date);
  const [chartLoader, setChartLoader] = useState(true);

  const [sortKey, setSortKey] = useState('locationVisited')
  const [sortType, setSortType] = useState('desc')
  
  const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
  const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
  
  const [sortKeymostInteracted, setSortKeymostInteracted] = useState('mostInteracted')
  const [sortTypemostInteracted, setSortTypemostInteracted] = useState('desc')

  const [sortKeycloseContact, setSortKeycloseContact] = useState('closeContact')
  const [sortTypecloseContact, setSortTypecloseContact] = useState('desc')

  const [sortKeyriskyLocations, setSortKeyriskyLocations] = useState('riskyLocations')
  const [sortTyperiskyLocations, setSortTyperiskyLocations] = useState('desc')

  const [showMarkbox,UpdateMarkBox] =useState(false);
  const [showHistorybox,UpdateHistoryBox] =useState(false);


  const[spreadIndexSelectedDate, updateIndexSelectedDate]=useState('');

  const[testedDatePositive,updatetestedDatePositive]=useState(date);


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);


useEffect(() => {
    let perm = props.pages.filter(el=>el && el.sub_page_name=="manpower detail");
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);



  const setPrevEmployeeIndexData = (date) => {
    let idVal = props.match.params.id.replace(":", "");
    let prevReqBody = {};
    prevReqBody.date = moment(date).subtract(1, "days").format("YYYY-MM-DD");
    prevReqBody.tag_serial = idVal;
 
  /*  getEmployeeDetails(prevReqBody, userSession, org_id,sub_org_id).then((res) => {
      if (res && res.data) {
        updatePrevEmployeeDetails(res.data[0]);
      }
    });
    */
  };



  useEffect(() => {
    if(permission && permission.length > 0){
    
    if (idVal) {
      let requestBody = {};
      requestBody.date = getDateFormat(selectedDate);
      requestBody.tag_serial = idVal;
      let higherdate='';
    

      //setPrevEmployeeIndexData(selectedDate);
      
      getEmployeeDetails(requestBody, userSession, org_id,sub_org_id).then((res) => {
        if (res && res.data) {
          updateEmployeeDetails(res.data[0]);


          

          let arr = [];
          let tested_history = res.data[0].tested_history;
  
          let arr2=[];
          arr2 = tested_history.sort((a,b) => {
            let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
            let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')
    
            return  bMs - aMs
          })

          let date1='';
          for (let index = 0; index < arr2.length; index++) {
            const element = arr2[index];
  

  if(element[1]=='positive' ){

  if(date1==''){
    date1=element[2];
  }
  if (date1 > element[2]) {
    higherdate= date1;
    
  }else{
    higherdate= element[2];
  }

  

  
}



            arr.push(
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td Style="text-transform:capitalize">{element[1]}</td>
                <td>{moment(element[2]).format("DD/MM/YYYY") }</td>
              </tr>
          
            );
          }

          if(higherdate!=''){
            updateTestedPositiveDate(new Date(higherdate)) 
            updatetestedDatePositive(new Date(higherdate))
          }

          
          setEmployeeHistoryData(arr);

           let requestBody = {};

      let newdate2 = new Date(new Date(selectedDate).valueOf() - 86400000);
      requestBody.date = getDateFormat(newdate2);
      requestBody.tag_serial = idVal;
      // requestBody.tag_serial = idVal;
      //     getEmployeeDetails(requestBody, userSession, org_id,sub_org_id).then((res) => {
      //       if (res && res.data) {
      //         updateEmployeeDetails(res.data[0]);

    
      //         let arr = [];
      //         let tested_history = res.data[0].tested_history;
      //         for (let index = 0; index < tested_history.length; index++) {
      //           const element = tested_history[index];
    
      //           arr.push(
      //             <tr key={index + 1}>
      //               <td>{index + 1}</td>
      //               <td Style="text-transform:capitalize">{element[1]}</td>
      //               <td>{moment(element[2]).format("DD/MM/YYYY") }</td>
      //             </tr>
              
      //           );
      //         }
         
              setPrevEmployeeIndexData(selectedDate);
         

        }
      });
    }

  }

  }, [permission,idVal]);


  
  useEffect(() => {
    if(permission && permission.length > 0){
 
    let requestBody = {};
   
    let newdate2 = new Date(new Date(selectedDate).valueOf() - 86400000);
    requestBody.date = getDateFormat(newdate2);
    requestBody.tag_serial = idVal;
    requestBody.tag_serial = idVal;
        getEmployeeDetails(requestBody, userSession, org_id,sub_org_id).then((res) => {
          if (res && res.data) {
            setprevEmployeeData(res.data[0]);
    

      }
    });
  }
  }, [permission,idVal]);




  useEffect(() => {
    if(permission && permission.length > 0){
      setTableData();
    }
  }, [dates,permission,idVal]);

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  function handleTestedPositiveDateSelect(date) {
    updateTestedPositiveDate(date);
    
  }

  useEffect(() => {
    if(permission && permission.length > 0){

    getChartData();
    }
  }, [selectedDate,permission,idVal]);

  useEffect(() => {
    if (sortKey && sortType) {
      let data = [...employeeData]
      let arr = data[0] || []

      if (sortKey === 'locationVisited') {
        arr = arr.contact_locations || []
      } else if (sortKey === 'mostInteracted') {
        arr = arr.contact_person || []
      } else if (sortKey === 'closeContact') {
        arr = arr.close_contact_people || []
      } else if (sortKey === 'riskyLocations') {
        arr = arr.risk_location || []
      }

      arr = arr.sort((a,b) => {
        let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
        let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')

        return sortType === 'desc' ? bMs - aMs : aMs - bMs
      })
 

      updateEmployeeIndexData(data)

    }
  }, [sortKey, sortType])


  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }
  

  const getChartData = () => {
    setChartLoader(true);
    setChartData({ categories: [], series: [] });

    let idVal = props.match.params.id.replace(":", "");
    let d = moment(selectedDate).add(1, "day").toDate();
    let date = getDateFormat(d);
    let startDate = getDateFormat(
      new Date(selectedDate).setDate(selectedDate.getDate() - 30)
    );
    let obj = {
      start: startDate,
      end: date,
      emp_id: idVal,
    };
    if(permission && permission.length > 0){

    employeeChart(obj, userSession, org_id,sub_org_id)
      .then((res) => {
        let data = res?.emp_pri;

        if (data && Array.isArray(data)) {
          let params = {
            startDate: startDate,
            endDate: date,
            type: "pri",
            interval,
            data,
          };

          let series = prepareDateObj(params);

          setChartData({ series: series.sort((a, b) => a[0] - b[0]) });
        }
        setChartLoader(false);
      })
      .catch((err) => {

        setChartLoader(false);
      });
    }
  };

  const handleDate = (date, type) => {
    setDates((prev) => ({ ...prev, [`${type}`]: date }));
  };

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleManpowerManagementList() {
    props.history.push("/manpower-management");
  }

  // function handleEmployeeList(params) {
  //   props.history.push("/manpower-management/employee-list");
  // }

  function handleChangeInfected(flag) {
    updateInfectedFlag(flag);
    
  }

  function getBackgroundColorBasedOnRisk(riskIndex) {
    let risk = "";

    if (riskIndex <= 33) {
      risk = "lowIndexGradientColor";
    } else if (riskIndex > 33 && riskIndex <= 66) {
      risk = "mediumIndexGradientColor";
    } else if (riskIndex > 66 && riskIndex <= 100) {
      risk = "highIndexGradientColor";
    } else {
      risk = 'naIndexGradientColor'
    }

    return risk;
  }

  function getRiskTYpe(riskIndex) {
    let risk = "";

    if (riskIndex <= 33) {
      risk = "LOW";
    } else if (riskIndex > 33 && riskIndex <= 66) {
      risk = "MEDIUM";
    } else if (riskIndex > 66 && riskIndex <= 100) {
      risk = "HIGH";
    } else {
      risk = "NA"
    }

    return risk;
  }

  function handleDateSelect(date) {
    if(permission && permission.length > 0){

    updateSelectedDate(date);

    let start = new Date().setDate(new Date().getDate() - 29);
    let end = new Date(date);

    setDates({ start, end });

    let requestBody = {};
    requestBody.date = getDateFormat(date);
    requestBody.tag_serial = idVal;

    setPrevEmployeeIndexData(date);
 
    getEmployeeDetails(requestBody, userSession, org_id,sub_org_id).then((res) => {
      if (res && res.data) {
        updateEmployeeDetails(res.data[0]);
        
      }
    });

    setTableData();
  }
  }

  const setTableData = () => {
    if(permission && permission.length > 0){

    let payload = {};
    payload.tag_serial = idVal;
    payload.start = getDateFormat(dates.start);
    payload.end = getDateFormat(dates.end);

    getEmployeeIndex(payload, userSession, org_id,sub_org_id).then((res) => {
      if (res && res.data) {
        updateEmployeeIndexData(res.data);
        setEmployeeData(JSON.parse(JSON.stringify(res.data)));
      }
    });
  }
  };

  const getChangePer = (key) => {

    let returnData = 0;
    let x = prevemployeeData[key] || 0;
    let y = employeeDetails[key] || 0;
   // let divider = x > 0 ? x : 1; 
    
if(parseFloat(x) > 0 ){
  returnData = parseFloat(y - x).toFixed(1);

}else{
  returnData = parseInt(y - x);
}
    

  
    

    return returnData;
  };

  // function showEmployeeMonthView(daysPrecent) {
  //   let monthDays = moment(selectedDate).daysInMonth();
  //   let month = moment(selectedDate).format("MMM");
  //   let dayPer = parseInt((daysPrecent / monthDays) * 100) + "%";

  //   return (
  //     <div className="eachAttendanceDiv">
  //       <div className="monthDiv">{month}</div>
  //       <div className="progressBarDiv">
  //         <div className="daysProgressBG" style={{ width: dayPer }}></div>
  //       </div>
  //       <div className="daysDiv">{daysPrecent} days</div>
  //     </div>
  //   );
  // }

  // function showEmployeeList(employeeList) {
  //   let arr = [];

  //   for (let index = 0; index < employeeList.length; index++) {
  //     const element = employeeList[index];

  //     arr.push(
  //       <React.Fragment>
  //         <div className="mostInereractedEmpDiv">{element.person_name}</div>
  //       </React.Fragment>
  //     );
  //   }

  //   return arr;
  // }

  function showMostVisitedAreas(mostVisitedAreas) {
    let arr = [];
    let arr2=[];
 
    arr2 = mostVisitedAreas.sort((a,b) => {
      let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
      let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')

      return  bMs - aMs 
        })


    for (let index = 0; index < mostVisitedAreas.length; index++) {
      const element = mostVisitedAreas[index];

      arr.push(
        <Col lg={4}>
          <div className="eachMostVisitedAreaDiv">
            <div className="iconDiv greenGradientBGColor">
              <img src={empIDIcon} />
            </div>
            <div className="areaNameDiv">
              <h6 className="font-bold">{element.area}</h6>
              <div>
                <div className="categoryName">{element.area_category}</div>
              </div>
            </div>
          </div>
        </Col>
      );
    }

    return arr;
  }
  function ViewHistoryHandler(){
 
    UpdateHistoryBox(!showHistorybox)  
  }
  function MarkModalHandler(){
    UpdateMarkBox(!showMarkbox)
  }
 function MarkPositiveHandler(){
  let requestBody = {};

  requestBody.date = getDateFormat(testedPositiveDate);
  
  requestBody.tag_serial = employeeDetails.tag_serial;
   if(employeeDetails.status=='negative'){
    requestBody.status ='positive';
  
   }else if(employeeDetails.status=='positive'){ 
    requestBody.status ='negative';
  
   }else if(employeeDetails.status=='quarantine'){ 
    requestBody.status ='negative';
  
   }else{
  
    requestBody.status = employeeDetails.status;
   }
   postMarkedemployee(requestBody, userSession, org_id,sub_org_id).then((res) => {
    if (res && res.data) {
      let requestBody = {};
      requestBody.date = getDateFormat(testedPositiveDate);
      requestBody.tag_serial = employeeDetails.tag_serial;
      UpdateMarkBox(false);    
      getEmployeeDetails(requestBody, userSession, org_id,sub_org_id).then((res) => {
        if (res && res.data) {
          updateEmployeeDetails(res.data[0]);
        }
      });

    }
  });



 }
function closeMarkModal(){
  UpdateMarkBox(false);
  UpdateHistoryBox(false);

}

function alreadyPositiveHandler(){

}



const customStyle = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      height: '14rem',
      width:'540px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}

const customStyle2 = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      height: '15rem',
      width:'25rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };

  // const getAttendancePer = (attended) => {
  //   let monthDays = moment(selectedDate).daysInMonth();
  //   let returnData = parseInt((attended / monthDays) * 100);

  //   return returnData;
  // };

  function showCardListContactPeople() {
    let arr = [];
    let data = employeeIndexData[0]?.contact_person || [];
    let arr2 =[];
    arr2 = data.sort((a,b) => {
      let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
      let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')
      return  sortTypemostInteracted=='desc'? bMs - aMs  : aMs - bMs
   })

    for (let index = 0; index < arr2.length; index++) {
      let element = data[index];
      Object.keys(element).forEach((key) => {
        arr.push(
          <tr className="employee-detail-table-tr">
            <td>{capitalizeFirstLetter(key)}</td>
            <td className="employee-detail-table-tr-td">{capitalizeFirstLetter(element[key])}</td>
          </tr>
        );
      });
    }
    return arr;
  }

  function showCardLocationVisited() {
    let arr = [];
    let data = employeeIndexData[0]?.contact_locations || [];
    let arr2 =[];
    arr2 = data.sort((a,b) => {
      let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
      let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')
      //sortKeylocationVisited==='desc'?'asc':'desc'
      return  sortTypelocationVisited=='desc'?bMs - aMs : aMs - bMs 
        })
    for (let index = 0; index < arr2.length; index++) {
      let element = arr2[index];
      Object.keys(element).forEach((key) => {
        arr.push(
          <tr className="employee-detail-table-tr">
            <td>{capitalizeFirstLetter(key)}</td>
            <td className="employee-detail-table-tr-td">{capitalizeFirstLetter(element[key])}</td>
          </tr>
        );
      });
    }
    return arr;
  }

  function showCardCloseContact() {
    let arr = [];
    let data = employeeIndexData[0]?.close_contact_people || [];
    let arr2 =[];
    arr2 = data.sort((a,b) => {
      let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
      let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')

      return sortTypecloseContact=='desc'? bMs - aMs : aMs - bMs 
        })

    for (let index = 0; index < arr2.length; index++) {
      let element = arr2[index];
      Object.keys(element).forEach((key) => {
        arr.push(
          <tr className="employee-detail-table-tr">
            <td>{capitalizeFirstLetter(key)}</td>
            <td className="employee-detail-table-tr-td">{capitalizeFirstLetter(element[key])}</td>
          </tr>
        );
      });
    }

    return arr;
  }

  function showCardListRisk() {
    let arr = [];
    let data = employeeIndexData[0]?.risk_location || [];
    let arr2 =[];
    
    arr2 = data.sort((a,b) => {
      let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
      let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')

      return sortTyperiskyLocations=='desc'? bMs - aMs : aMs - bMs 
        })

 


    for (let index = 0; index < arr2.length; index++) {
      let element = arr2[index];
      Object.keys(element).forEach((key) => {
        arr.push(
          <tr className="employee-detail-table-tr">
            <td>{capitalizeFirstLetter(key)}</td>
            <td className="employee-detail-table-tr-td">{capitalizeFirstLetter(element[key])}</td>
          </tr>
        );
      });
    }

    return arr;
  }



  const handleSort = (key) => {
    
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
  }

  const handleSortmostInteracted = (key) => {

    setSortKeymostInteracted(key)
    setSortTypemostInteracted(sortTypemostInteracted === 'desc' ? 'asc' : 'desc')
    
    setSortKey(key)
    setSortType(sortTypemostInteracted)


  }

  const handleSortlocationVisited = (key) => {
    
    setSortKeylocationVisited(key)
    setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
    setSortKey(key)
    setSortType(sortTypelocationVisited)


  }

  const handleSortcloseContact = (key) => {
   
    setSortKeycloseContact(key)
    setSortTypecloseContact(sortTypecloseContact === 'desc' ? 'asc' : 'desc')
    

    setSortKey(key)
    setSortType(sortTypecloseContact)
  }

  const handleSortriskyLocations = (key) => {
   
    setSortKeyriskyLocations(key)
    setSortTyperiskyLocations(sortTyperiskyLocations === 'desc' ? 'asc' : 'desc')
    setSortKey(key)
    setSortType(sortTyperiskyLocations)
  }

  // if(permissionAPICall && permission.length == 0){
  //   return(<Col lg={12}>
      
  // <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
  // </Col>)

  // }
  if(permissionAPICall && permission.length== 0){
return(<Col lg={12}>
      
  <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
  </Col>)

  }
  else if (employeeDetails && employeeIndexData) {
 
    return (
      
      <div className="manpowerManagementMainDiv siteViewMainDiv ">
        <div className="employeeDetailsMainDiv">
          <Container>
            <Row>
              <Col lg={5} className="m-t-sm">
                <div className="siteViewHeaderDiv">
                  <span
                    className="smallHeader"
                    onClick={handleManpowerManagementList}
                  >
                    {getTranslatedText("Manpower Management")}
                  </span>
                  <span className="breadCrumbArrow"> &gt; </span>
                  <span className="mediumHeader">
                    {getTranslatedText("Employee View")}
                  </span>
                </div>
              </Col>

              <Col lg={7} className="text-right">
              

                <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
              </Col>
            </Row>
            {employeeDetails ? (
			
              <Row className="m-t">
                <Col lg={4}>
                  <div className="siteViewDetailsLeftSideDiv ht-100">
                    <div className="flexDiv">
                      <div>
                        <div className="headerNameDiv">
                          {employeeDetails.name}
                        </div>
                        <div className="subHeaderDiv">
                          {employeeDetails.team}
                        </div>
                      </div>
                      <div className="sendMessageDiv">
                        {/* <div className="viewAllEmployeesButton">
                          Send Message
                        </div> */}
                      </div>
                    </div>

                    <div className="subHeaderDiv m-t">
                      <img src={emailIcon} /> &nbsp;
                      {employeeDetails.email}
                    </div>
                    {/* <div className="subHeaderDiv">
                      <img src={empIDIcon} /> &nbsp;
                      {employeeDetails.org_id}
                    </div> */}

                    <div className="batteryMainDiv m-t-lg">
                      <img src={batteryIcon} /> &nbsp;
                      {employeeDetails.battery}{employeeDetails.battery=='na'? '' : '%'}&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>Registered Device : </span>
                      {employeeDetails.tag_serial}
                    </div>

                    
                    
                    <div className="historyOfInfectionDiv inflex-some-space m-t">
                      <div className="history-header">
                        <Row className="mt-0 infectionFlex">                        
                          <Col lg={8}>
                            <h5 className="text-white font-bold m-t-md mt-0 ">
                              Covid Status
                            </h5>
                          </Col>
                          <Col lg={4}>
                           <div className={( employeeDetails.status=='negative'|| employeeDetails.status=='Negative') ?
                               'btn-green btn-covid-negative indictaor-button-covid' : 
                              'btn-red btn-covid-positive indictaor-button-covid'}>
                                {employeeDetails.status}                        
                            </div>
                          </Col>
                        </Row>
                   </div> 

                      <div className="infectedText">
                     {/*    {" "}
                        This Employee was{" "}
                        {employeeDetails.infected == "No" ? " Not" : ""}{" "}
                        infected previously{" "} 
            */} </div>
                      <Row className="m-t infectionFlex">
                        <Col lg={7}>
                          <span>{(employeeDetails.status=='na' || employeeDetails.status=='negative')? 'Mark as Infected' : 'Mark as cured'}</span>
                        </Col>
                        <Col lg={2} className="text-right p-r-0">
                          <span className="text-white">
                            {infectedFlag ? "Yes" : "No"}
                          </span>
                        </Col>
                        <Col lg={3} className="text-right ">
                          <Switch
                            checked={infectedFlag}
                            onChange={handleChangeInfected}
                          />
                        </Col>
                      </Row>
                      
                      {testedDatePositive && employeeDetails.status=='negative'?
                      <Row
                      className="m-t infectionFlex"
                      style={
                        infectedFlag
                          ? {}
                          : { pointerEvents: "none", opacity: 0.4 }
                      }
                      >

                        {employeeDetails.status!='negative' || infectedFlag?
                        <>
                        <Col lg={7}>
                          <span>Tested Positive On</span>
                        </Col>
                        <Col lg={5} className="text-right">
                          <div className="testedPositiveDatePickerMainDiv">
                            {/* <CommonDatePicker
                              selectedDate={testedPositiveDate}
                              handleSelectDate={handleTestedPositiveDateSelect}
                              hideIcon={true}
                            /> */}
                          {/* {testedDatePositive} */}
                          {/* {testedPositiveDate} */}
                          <img src={calenderIcon} />
                          <DatePicker
                            selected={testedPositiveDate}
                            onChange={d => handleTestedPositiveDateSelect(d)}
                            dateFormat={"MMM dd"}
                            isClearable={false}
                            placeholderText={getTranslatedText("Select Date")}
                            maxDate={new Date()}
                            minDate={new Date('2023-01-01')}
                            //placeholderText={'Select Date'}
                        />
                         </div>
                        </Col></>:""
                        }
                      </Row>:
                      <Row
                        className="m-t infectionFlex"
                        style={
                          infectedFlag
                            ? {}
                            : { pointerEvents: "none", opacity: 0.4 }
                        }
                      >
                        <Col lg={7}>
                          <span>Tested {(employeeDetails.status=='na' || employeeDetails.status=='negative')? 'Positive' : 'Negative'} On</span>
                        </Col>
                        <Col lg={5} className="text-right">
                          <div className="testedPositiveDatePickerMainDiv">
                            {/* <CommonDatePicker
                              selectedDate={testedPositiveDate}
                              handleSelectDate={handleTestedPositiveDateSelect}
                              hideIcon={true}
                            /> */}
                            




                              {/* <img src={downArrowFill} /> */}

                              <img src={calenderIcon} />
                          <DatePicker
                            selected={testedPositiveDate}
                            onChange={d => handleTestedPositiveDateSelect(d)}
                            dateFormat={"MMM dd"}
                            isClearable={false}
                            placeholderText={getTranslatedText("Select Date")}
                            maxDate={new Date()}
                            minDate={new Date('2023-01-01')}
                            //placeholderText = {'Select Date'}
                          
                        />

                          </div>
                        </Col>
                      </Row>}
                      <div className="history-footer">
                        <Row className="m-t infectionFlex">
                          <Col lg="6">
                              <div className="view-history red-font"onClick={ViewHistoryHandler}>View History</div>
                             <div className={employeeDetails.tested_history.length > 0 ?"lightboxmodalhistory lightboxmodalhistory-havedata":"lightboxmodalhistory lightboxmodalhistory-nodata"} Style={showHistorybox===false ? 'display:none':'display:block'}> 
                                <div className='logoutModal ViewCovidHistory'>
                                    <div className="closer-modal" onClick={closeMarkModal}>x</div>
                                    
                                    {employeeDetails.tested_history.length > 0 ? <Scrollbars autoHide style={{ width: "100%", height: 260 }} >
                                    
                                    <table>
                                        <thead>
                                          <th>No</th>
                                          <th>Covid Status</th>
                                          <th>Reported on</th>
                                        </thead>
                                        <tbody>
                                          {employeeHistoryData}
                                        </tbody>
                                        </table>
                                    </Scrollbars>:<h5 className="font-bold m-t-md mt-0 ">No Infected History</h5>  }
 
                                  </div>
                             </div>
                          </Col>
                          <Col lg="6" className="m-t mt-0">
                          {permission.edit == 'True'? <button  className="viewAllEmployeesButton" style={{float:"right"}} onClick={MarkModalHandler}>
                          Update Status
                        </button>:<button  className="viewAllEmployeesButton hover-message disable" style={{float:"right"}} >
                          Update Status
                          <span class="descHelp tootltipHelp" style={{width:"150px"}}>No permission</span>
                        </button>}
                          </Col>
                        </Row>
                      </div>  

                    </div>
                    
                    <div className="historyOfInfectionDiv m-t">
                      <Row className="infectionFlex">
                        <Col lg={7}>
                          <span>Cumulated interaction time with people</span>
                        </Col>

                        <Col lg={5} className="text-right ">
                          <p>hh:mm:ss</p>
                          <strong>
                            {employeeDetails.interaction_time_people || 0}
                          </strong>
                        </Col>
                      </Row>
                    </div>
                    <div className="historyOfInfectionDiv m-t">
                      <Row className="infectionFlex">
                        <Col lg={7}>
                          <span>Cumulated interaction time with location</span>
                        </Col>

                        <Col lg={5} className="text-right ">
                          <p>hh:mm:ss</p>
                          <strong>
                            {employeeDetails.interaction_time_location || 0}
                          </strong>
                        </Col>
                      </Row>
                    </div>
                    <div className="historyOfInfectionDiv m-t">
                      <Row className="infectionFlex">
                        <Col lg={7}>
                          <span>Close contact with people count</span>
                        </Col>

                        <Col lg={5} className="text-right ">
                          {/* <p>hh:mm:ss</p> */}
                          <strong>
                            {employeeDetails.close_contact_people || 0}
                          </strong>
                        </Col>
                      </Row>
                    </div>
                    <div className="historyOfInfectionDiv m-t">
                      <Row className="infectionFlex">
                        <Col lg={7}>
                          <span>Close contact with location count</span>
                        </Col>

                        <Col lg={5} className="text-right ">
                          {/* <p>hh:mm:ss</p> */}
                          <strong>
                            {employeeDetails.close_contact_locations || 0}
                          </strong>
                        </Col>
                      </Row>
                    </div>

                    <Modal
                isOpen={showMarkbox}
                style={customStyle}
            >
                <div className='logoutModal MarkforPositive'>
                   
                    <div className='logoutTitle'>Are you sure you want to Change the <br/> Covid Status for {employeeDetails.name}</div>

                    <div className='actionButton'>
                        <span className='btnText okBtn' onClick={MarkPositiveHandler}>Yes, Change to {employeeDetails.status=='negative'?'Positive':'cure'} </span>
                        <span className='btnText text-design' onClick={closeMarkModal} >No, Go Back</span>
                    </div>
                </div>
            </Modal>

                  </div>
                </Col>

                <Col lg={8}>
                  <div className="indexMainDiv">
                    <Row>
                      <Col lg={3}>
                        <div
                          style={{ height: "165px", textAlign: 'left' }}
                          className={
                            "eachIndexDiv " +
                            getBackgroundColorBasedOnRisk(
                              employeeDetails.spread_index
                            )
                          }
                        >
                          <h6 className="font-bold ">
                            {getTranslatedText("Spread Index")}
                          </h6>
                          <br />
                          <br />
                          <div className="font-normal">
                            {getTranslatedText("Risk Level")}
                          </div>

                          <Row>
                            <Col lg={4}>
                              <h5 className="font-bold">
                                {getRiskTYpe(employeeDetails.spread_index,)}
                              </h5>
                            </Col>
                            <Col lg={8} className="p-l-0">
                              <div className="riskPercentageMainDiv">
                                {/* <div className="riskPercentagenNumber font-bold" style={{ display: 'inline-block' }}>{employeeDetails.spread_index}</div> */}
                                <div>
                                  <h5 className="font-bold">
                                    {employeeDetails.spread_index}
                                  </h5>
                                </div>
                                <div
                                  className="increaseDecreasePercentageDiv font-bold"
                                  style={{ verticalAlign: "baseline" }}
                                >
                                  {getChangePer("spread_index") > 0 ? (
                                    <span> &#8593; </span>
                                  ) : (
                                    <span> &#8595; </span>
                                  )}
                                  {Math.abs(getChangePer("spread_index")) + "%"}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div
                          style={{ height: "165px", textAlign: 'left' }}
                          className={
                            "eachIndexDiv " +
                            getBackgroundColorBasedOnRisk(
                              employeeDetails.mobility_index
                            )
                          }
                        >
                          <h6 className="font-bold ">
                            {getTranslatedText("Mobility Index")}
                          </h6>
                          <br />
                          <br />
                          <div className="font-normal">
                            {getTranslatedText("Risk Level")}
                          </div>

                          <Row>
                            <Col lg={4}>
                              <h5 className="font-bold">
                                {getRiskTYpe(employeeDetails.mobility_index)}
                              </h5>
                            </Col>
                            <Col lg={8} className="p-l-0">
                              <div className="riskPercentageMainDiv">
                                {/* <div className="riskPercentagenNumber font-bold" style={{ display: 'inline-block' }}>{employeeDetails.mobility_index}</div> */}
                                <div>
                                  <h5 className="font-bold">
                                    {employeeDetails.mobility_index}
                                  </h5>
                                </div>
                                <div
                                  className="increaseDecreasePercentageDiv font-bold"
                                  style={{ verticalAlign: "baseline" }}
                                >
                                  {getChangePer("mobility_index") > 0 ? (
                                    <span> &#8593; </span>
                                  ) : (
                                    <span> &#8595; </span>
                                  )}
                                  {Math.abs(getChangePer("mobility_index")) +
                                    "%"}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div
                          style={{ height: "165px", textAlign: 'left' }}
                          className={
                            "eachIndexDiv " +
                            getBackgroundColorBasedOnRisk(
                              employeeDetails.activity_index
                            )
                          }
                        >
                          <h6 className="font-bold ">
                            {getTranslatedText("Activity Index")}
                          </h6>
                          <br />
                          <br />
                          <div className="font-normal">
                            {getTranslatedText("Risk Level")}
                          </div>

                          <Row>
                            <Col lg={4}>
                              <h5 className="font-bold">
                                {getRiskTYpe(employeeDetails.activity_index)}
                              </h5>
                            </Col>
                            <Col lg={8}  className="p-l-0">
                              <div className="riskPercentageMainDiv">
                                {/* <div className="riskPercentagenNumber font-bold" style={{ display: 'inline-block' }} >{employeeDetails.activity_index}</div> */}
                                <div>
                                  <h5 className="font-bold">
                                    {employeeDetails.activity_index}
                                  </h5>
                                </div>
                                <div
                                  className="increaseDecreasePercentageDiv font-bold"
                                  style={{ verticalAlign: "baseline" }}
                                >
                                  {getChangePer("activity_index") > 0 ? (
                                    <span> &#8593; </span>
                                  ) : (
                                    <span> &#8595; </span>
                                  )}
                                  {Math.abs(getChangePer("activity_index")) +
                                    "%"}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="siteViewDetailsLeftSideDiv">
                          <Row>
                            <Col lg={10}>
                              <span>Interacted Employee Count</span>
                            </Col>

                            <Col lg={2} className="text-right ">
                              <strong>
                                {employeeDetails.interacted_employee_count || 0}
                              </strong>
                            </Col>
                          </Row>
                        </div>
                        <div className="siteViewDetailsLeftSideDiv m-t">
                          <Row>
                            <Col lg={10}>
                              <span>Risk Location Count</span>
                            </Col>

                            <Col lg={2} className="text-right ">
                              <strong>
                                {employeeDetails.risk_locations || 0}
                              </strong>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mostInteractedMainDiv m-t-lg">
                    <Row>
                      <Col lg={4}>
                        <h5 className="font-bold">Most Interacted with</h5>
                      </Col>
                      <Col lg={8}>
                        <Row className="flex">
                          <Col lg={3} className="p-r-0">
                            <div
                              className="startDateEndDateMainDiv"
                              style={{ paddingLeft: "0px" }}
                            >
                              Start Date
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                // dateFormat={"dd MMM, yyyy"}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                          <Col lg={1} className="p-l-0"></Col>
                          <Col lg={3} className="p-l-0">
                            <div
                              className="startDateEndDateMainDiv"
                              style={{ paddingLeft: "0px" }}
                            >
                              End Date
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="m-t-lg m-b">
                      <Row style={{ margin: 0 }}>
                        <Col className="p-l-0" lg={6}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th>
                                Most Interacted People
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of people and time spend in employee bubble (radius of 6 feet or 2 meters)
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td">
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKeymostInteracted === 'mostInteracted' ? sortTypemostInteracted === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortmostInteracted('mostInteracted')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150 }}
                            >
                              {showCardListContactPeople()}
                            </Scrollbars>
                            <div className="alertDiv">
                              <div className="viewAllEmployeesButton">
                                Alert
                              </div>
                            </div>
                          </table>
                        </Col>
                        <Col lg={6}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th>
                                Location Visited
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`locHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`locHelp`)
                                  }
                                />
                                <span className="descHelp" id="locHelp">
                                Visited areas and time spend. 
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td">
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKeylocationVisited === 'locationVisited' ? sortTypelocationVisited === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortlocationVisited('locationVisited')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                              {showCardLocationVisited()}
                            </Scrollbars>
                          </table>
                        </Col>
                      </Row>
                      <Row style={{ margin: "1.5rem 0 0" }}>
                        <Col className="p-l-0" lg={6}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{ width: "70%" }}>
                                Close contacts with people
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`closeHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`closeHelp`)
                                  }
                                />
                                <span className="descHelp" id="closeHelp">
                                List of people and number of close contacts (over 15 minutes).
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td">
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKeycloseContact === 'closeContact' ? sortTypecloseContact === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortcloseContact('closeContact')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150 }}
                            >
                              {showCardCloseContact()}
                            </Scrollbars>
                            <div className="alertDiv">
                              <div className="viewAllEmployeesButton">
                                Alert
                              </div>
                            </div>
                          </table>
                        </Col>
                        <Col lg={6}>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th>
                                Risky location visited
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`riskHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`riskHelp`)
                                  }
                                />
                                <span className="descHelp" id="riskHelp">
                                list of risky area visited.
                                </span>
                              </th>
                              <th className="employee-detail-table-tr-td">
                                Time
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortKeyriskyLocations === 'riskyLocations' ? sortTyperiskyLocations === 'asc' ? upIcon : downIcon : sortIcon}
                                  onClick={() => handleSortriskyLocations('riskyLocations')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              autoHeight
                              style={{ width: "100%" }}
                            >
                              {showCardListRisk()}
                            </Scrollbars>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  {employeeDetails.most_visited &&
                  employeeDetails.most_visited.length > 0 ? (
                    <div className="mostInteractedMainDiv m-t-md">
                      <Row>
                        <Col lg={4}>
                          <h5 className="font-bold">Most Visited Areas :</h5>
                        </Col>
                      </Row>

                      <Row className="m-t">
                        {showMostVisitedAreas(employeeDetails.most_visited)}
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(EmployeeDetails)
);
