import axios from "axios";


let prefixURL = process.env.REACT_APP_URL


export function getLanguageTranslation(langCode, userSession) {
    
    return axios.get(prefixURL + `/get_lang_pack?session=${userSession}&lang_code=${langCode}`)
        .then(res => res.data).catch(err => err)
}

export function getOnBoardEmp( userSession,org_id,startDate,endDate,sub_org_id) {   
    return axios.get(prefixURL + `/onboarding/get_onboarding_report?session=${userSession}&org_id=${org_id}&start_date=${startDate}&end_date=${endDate}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getEmployeeCost(userSession,org_id,sub_org_id){

    return axios.get(prefixURL + `/get_worker_cost?session=${userSession}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}


export function setSelectedLanguage(langauge) {


    return dispatch => {
        dispatch({
            type: 'selectedLangauge',
            data:langauge
        })
    }
}

export const getFacilityWidget = async (sessionID, orgId, sub_org_id) => {
    try {
        let res = await axios.get(`${prefixURL}/facility_count?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}`)
        return res.data
    } catch (err) {
        return err
    }
}
export const getFacilityCounter = async (sessionID, orgId, sub_org_id,start_date,end_date) => {
    try {
        let res = await axios.get(`${prefixURL}/facility_count_report?session=${sessionID}&org_id=${orgId}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        return res.data
    } catch (err) {
        return err
    }
}
