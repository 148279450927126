import React, { useState, useEffect,useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'

import { Link } from 'react-router-dom'

import ReactModal from "react-modal";
import { Checkbox, Modal, Select   } from 'antd';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ExcelJs from "exceljs";
import ChartReport from "./ChartReport";
import { getPproductivityDetailOverview } from "../../productivity/actionMethods/actionMethods";
import moment from "moment";
import { getPeformanceIndividualHistory } from "../../productivity/actionMethods/actionMethods";
import { eqProps, prop } from "ramda";
import {getPerformanceHistory,getProcessCyclecount, testingEmail} from '../actionMethods/actionMethods'
import { getOnBoardEmp } from "../../costManagement/actionMethods/actionMethods";
// import {ReactComponent as StarFill} from '../../assets/images/star-sharp.svg'
// import {ReactComponent as StarEmpty} from '../../assets/images/star-sharp-empty.svg'
import { Collapse } from 'antd';
// import EmailGovernance from "./email/EmailGovernance";
import DatePicker from "react-date-picker";
import { governanceEmail } from "./email/EmailGovernance";
import { emailPattern } from  '../../common/utilities';
import { ToastContainer, toast } from "react-toastify";
import Highcharts from "highcharts";
// import * as htmlToImage from 'html-to-image';
// import { toPng } from 'html-to-image';
import CollapsePanel from "antd/lib/collapse/CollapsePanel";


export default function Governance(props){

    let StarFill =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f2bc19"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM351.9 311.8l33.72 103.9l-88.25-64.18c-5.61-4.08-13.21-4.08-18.82 0L190.3 415.6l33.72-103.9c2.139-6.589-.2053-13.81-5.808-17.88L129.9 229.6h109.2c6.933 0 13.08-4.465 15.22-11.06L288 114.8l33.7 103.8c2.141 6.594 8.285 11.06 15.22 11.06h109.2l-88.34 64.24C352.2 297.1 349.8 305.2 351.9 311.8z"/></svg>`;
    let StarEmpty =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#b5b1a6"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"/></svg>`;
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;

    let configChart = {
    chart: {
        type: 'pie',
        height:200
    },
    title:{
      text:""
    },
    exporting:{
      enabled:false
    },
    plotOptions: {
        pie: {
        allowPointSelect: false,
        cursor: 'pointer',
            dataLabels: {
                enabled: false,
            }
        }
    },
    series: []
  }
    const {Option} = Select
    const [allDataPeformance,SetAllDataPerformance]=useState([]);
    const [allProcess,SetAllProcess] = useState([]);
    const [dataPeformance,SetDataPerformance]=useState([]);
    const [showDataDefault,SetShowDataDefault]=useState([]);
    const [chartLoader,SetChartLoader]=useState(true);
    const [filterBtn,SetFilterBtn]= useState([])
    const [filterBtnAgency,SetFilterBtnAgency]= useState([])
    const [filterActive,SetFilterActive]= useState([])
    const [chartData,SetChartData] = useState({series:[],categories:[],week:[]})
    const [chartDataAgency,SetChartDataAgency] = useState({series:[],categories:[],week:[]})
    const [filterActiveAgency,SetFilterActiveAgency] = useState('');
    const [chart1,SetChart1] = useState({series:[],categories:[]})
    const [chart2,SetChart2] = useState(true)
    const [loader,SetLoader]=useState(true);
    const [isLoading,SetisLoading] =useState(true);
    const [dateCount,SetDateCount] =useState(true);
    const [totalcount,SetTotalCount] =useState(true);
    const [count, SetCount]= useState(0)
    const { Panel } = Collapse;
    const monthInt= ['','Jaunary',"February","March","April","May","June","July","August","September","October","November","December"]
    const [week,SetWeek]=useState([]);
    const [showDataAgency,SetShowDataAgency] =useState([])
    const [showDataFunction,SetShowDataFunction] =useState([])
    const [showDataProcess,SetShowDataProcess] =useState([])
    const [emailModal,SetEmailModal] = useState(props.emailModal)
    const [reportDate,SetReportDate] = useState()
    const [reportDateError,SetReportDateError] = useState()

    const [modalPreview,SetModalPreview] = useState(false);
    const [emailData,SetEMailData] = useState();
    const [color,setColor1]= useState( ['#745dd1','#cb3765','#fbce30','#28c4b2','#da60d9','#058DC7','#cb3765','#4895e1','#BB89CE','#289092','#04EECB','#9E3F54','#F0861B','#6148F4','#0FC499','#CC4156','#9E52FB'])
    
    const [emailTo,SetEMailTO]= useState('');
    const [loaderPreview,SetLoaderPreview] =useState(true);
    const [allMonth,SetAllMonth] = useState([])
    const [senderEMail,SetSenderEmail]= useState('')
    const [senderEMailError,SetSenderEmailError]= useState('')
    const [sendToEMail,SetSendToEmail]= useState('')
    const [sendMessage,SetSendMessage]= useState('')
    const [sendToEMailError,SetSendToEmailError]= useState('')
  let EmailOrg=[
    {org_id:28,title:"Lazada GRO",sub_org_id : 0},
    {org_id:30,title:"Lazada CMG - Bulky",sub_org_id:1},   
    {org_id:30,title:"Lazada CMG - MWH",sub_org_id:2},
    {org_id:31,title:"Lion Parcel",sub_org_id:0}
  ]
    useEffect(()=>{
      
      let lastmonth= getMonths(new Date(props.start_date),new Date(),'month');
      
      let lastSelect = lastmonth.filter(item=>moment(item.start) <= moment(props.start_date) && (moment(item.end) <= moment(props.end_date) || moment(item.end) >= moment(props.end_date) ) )
      
      if(lastSelect[0]){
        SetReportDate(moment(lastSelect[0].start).format('YYYY-MM')+"-01,"+lastSelect[0].end)
      }else{
        SetReportDate()
      }
      
      SetAllMonth(getMonths(new Date('2023-01-01'),new Date(),'month'))
      SetEmailModal(props.emailModal);
    },[props.emailModal])
    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

      useEffect(()=>{
        const data = {
                subject: 'Report View Traceplus',
                html_content: ``,
                email: 'faisal@traceplus.ai',
            };
       
      },[])


      function ratingCal(val){
        if(parseFloat(val)>= 90){
            return 5
        }else if(parseFloat(val)>= 80 && parseFloat(val)< 90){
            return 4
        }
        else if(parseFloat(val)>= 70 && parseFloat(val)< 80){
            return 3
        }
        else if(parseFloat(val)>= 60 && parseFloat(val)< 70){
            return 2
        }
        else if(parseFloat(val)>= 50 && parseFloat(val) < 60){
            return 1
        }else{
            return 0
        }

      }

      const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]


       const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };

  // useEffect(()=>{
  //   sendEmail()
  // },[])

async function emailPreviewHandler(){
  let check=true;
  //check date
  var emailRegex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
  
  if(!reportDate){
      SetReportDateError("Please Select Month for report");
      check=false;
  } 
  else if(reportDate==""){
    SetReportDateError("Please Select Month for report");
    check=false;
  }else{
    SetReportDateError("");
  }
  //check if Invalid Send From
  if(senderEMail!="" && !emailRegex.test(senderEMail)){
    check=false;
    SetSenderEmailError("Email ID is incorrect");
  }else{
    SetSenderEmailError("");
  }

  //checked sendToEMail
  if(sendToEMail==""){
    SetSendToEmailError("Send To is Required Field");
    check=false;
  }else if(!emailRegex.test(sendToEMail)){
    check=false;
    SetSendToEmailError("Send To Email ID is invalid");
  }else{

   SetSendToEmailError(""); 
  }

  if(check){
    props.closeEmailModal();
    SetLoaderPreview(true);
    SetModalPreview(true); 
    let startDate = reportDate.split(',')[0];
    let endDate = reportDate.split(',')[1];
   await filterPerformance('email',startDate,endDate);
  
  
  }

}
 
function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}

  function slugify(string) {
    return string
      .toString()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "_");
  }

function isNumeric(value) {
    return typeof value === 'number' && !isNaN(value);
}

function getDaysCount(start,end){
     
     let startDate =moment(start).toDate();
     let endDate = moment(end).toDate();
     let totalDays = moment(end).diff(start, 'days') + 1     
     let incHol= 0;
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        if(!isWeekday(startDate) ){
          incHol++;
        }
      }
    
     
      return totalDays - incHol;
}

  const isWeekday = (date) => {
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return     
        }else{
        return day ;
        }        
  };


useEffect(async()=>{
//let res= dummy;
    filterPerformance('report',props.start_date,props.end_date);

},[props.filterEnableCycle])



function countEmployeesWithLowPerformance(employees, consecutiveMonths ,months,type) {
  let count = 0;
  let array=[];

  for (const employee of employees) {
    let lowPerformanceCount = 0;
    // let months = ["August", "September", "October"];

    for (const month of months) {
      if(consecutiveMonths ==1 &&  month=="October"){
       
      }
      
      if (employee[month] < props.threeshold[1] && employee[month] > 0 && employee.worker_type==type) {
        lowPerformanceCount++;
        if (lowPerformanceCount >= consecutiveMonths) {
          array.push(employee);
          count++;
          break; // No need to continue checking for this employee
        }
      } else {
        lowPerformanceCount = 0; // Reset the count if performance >= 60
      }
    }
  }

  return {"employee":array,count:count};
}

function extractFunctions(jsonObj) {
  const result = [];
  
  // Iterate through each activity
  for (const activity in jsonObj.data) {
    const functions = {};
    functions.name = activity;
    functions.function = [];

    // Iterate through each date entry
    for (const date in jsonObj.data[activity]) {
      const entry = jsonObj.data[activity][date];

      // Iterate through each function in the date entry
      for (const func in entry) {
        // Skip keys "active", "plan", and "compliance"
        if (func !== "active" && func !== "plan" && func !== "compliance") {
          // Check if the function is not already added
          if (!functions.function.includes(func)) {
            functions.function.push(func);
          }
        }
      }
    }
    
    result.push(functions);
  }

  return result;
}



async function filterPerformance(typeReport,startDate,endDate){
      try {
        let processList = [];
        let process = await getProcessCyclecount(userSession,org_id,sub_org_id,getDateFormat(startDate),getDateFormat(endDate));
        // Call the function and print the result
const resultProcess = extractFunctions(process);
        
if(props.filterEnableCycle){
  if(typeReport=="report"){
    SetisLoading(true);
  }

// let emp=[];
let allemp =  []
let allData =[];
// sendEmail();
let moDiff= getMonths(new Date(startDate),new Date(endDate),props.reportPerformance);

let lastThree = getMonths(new Date(moment(endDate).subtract(95,'days') ),new Date(moment(endDate)),'month');
  let newThree = [...lastThree].slice(-3);
  newThree = newThree.sort((a,b)=> {
    return new Date(a.start) - new Date(b.start)
  })
if(typeReport=="email"){
  moDiff= getMonths(new Date(newThree[0]?.start),new Date(endDate),'month');
}

 
 let incLoop=0;
//for(let m= 0; m < props.type.length;m++){
  let record={};
const mergedData = {
  "data": {},
  "message": "Success",
  "status": 200
};
let strtDate = startDate
if(typeReport=="email"){
  
  strtDate= newThree[1]?.start;
}

let empList = await getOnBoardEmp(userSession,org_id,getDateFormat(strtDate),getDateFormat(endDate),sub_org_id).then(res=>  res );
let outOfEmpUnique = 0;
let outOfEmp = [];


  if(empList.status==200){
      let regular = empList.data.regular?empList.data.regular:[];
      let daily = empList.data.daily?empList.data.daily:[];  
    
      let regMap = [];
      let dayMap = [];
      let uniqueRegWorker=[]
       outOfEmp = [...regular,...daily].filter(item=>moment(item.check_in) > moment(startDate).set({hour:0,minute:0}) && moment(item.check_in) <= moment(endDate));
// Regular Mapping Start
    regular.forEach((el) => {
      
          let agency = el.agency?el.agency:"";
          
          let single= {
            "access_policy_breach": 0,
            "agency_name":agency ,
            "emp_name": el.name,
            "less_productive": 0,
            "non_productive": 0,
            "productive": 0,
            "tag_serial": el.tag_serial,
            "undefined": 0,
            "wasted_time": 0,
            "worker_id": el.worker_id,
            "department": el.department_name,
            "sub_department": el.sub_department_name,
            "worker_type":el.worker_type,
            "check_in":el.check_in,
          }
          if(regMap.find(el=> el.worker_id == single.worker_id)){
           
          }else{
            regMap.push(single); 
          }
         
      }); 
   

     daily.forEach((el) => {

          let agency = el.agency?el.agency:"";
        let single= {
            "access_policy_breach": 0,
            "agency_name": agency,
            "emp_name": el.name,
            "less_productive": 0,
            "non_productive": 0,
            "productive": 0,
            "tag_serial": el.tag_serial,
            "undefined": 0,
            "wasted_time": 0,
            "worker_id": el.worker_id,
            "department": el.department_name,
            "sub_department": el.sub_department_name,
            "worker_type":el.worker_type,
            "check_in":el.check_in
          }
          if(dayMap.find(el=> el.worker_id == single.worker_id)){
           
          }else{
           
              dayMap.push(single); 
          }
          
      }); 
      allemp.push(...regMap,...dayMap);
   
    }else{
      toast.error("Please try again after sometimes")
    }

const uniqueWorkerIds = new Set(outOfEmp.map(item => item.worker_id));

outOfEmpUnique = uniqueWorkerIds.size

    let typeList=props.type
if(typeReport=="email"){
  typeList=['employee','daily_worker'];

}
  for (const [m,row] of typeList.entries()) {
 

let requestBody ={}
requestBody.start_date = getDateFormat(startDate);
if(typeReport=="email"){
  requestBody.start_date = newThree[0]?.start;
}


requestBody.end_date = getDateFormat(endDate);

requestBody.slug =typeList[m];


let resp= await getPerformanceHistory(requestBody,userDetails.session,userDetails.org_id,userDetails.sub_org_id);

for (const key in resp.data) {
  if (mergedData.data.hasOwnProperty(key)) {
    // If the key exists in mergedData, merge the inner objects
    mergedData.data[key] = {
      ...mergedData.data[key],
      ...resp.data[key]
    };
  } else {
    // If the key doesn't exist in mergedData, add it
    mergedData.data[key] = { ...resp.data[key] };
  }
}
}



let dataIn = mergedData.data;
let undefined=0  

Object.keys(mergedData.data).forEach(key2=>{
  
  let empAr = allemp.find(item=>item.worker_id == key2);

  if(!empAr){
    undefined++
  }else{

    let data = Object.keys(mergedData.data[key2]).map((key) =>({
      date:key,
      worked_hour:mergedData.data[key2][key]}
      )).filter(el=> isWeekday(el.date));
  
  
  if(data.length > 0){
                  let noofPreset= moment(endDate).diff(startDate, 'days') + 1           
                          
                  let empDetail={
                    worked_hour:data.reduce((prev,current)=>prev+current.worked_hour,0)/data.filter(item=>item.worked_hour>0 ).length,
                    department:empAr.department,
                    sub_department:empAr.sub_department,
                    name:empAr.emp_name,
                    tag_serial:empAr.tag_serial,
                    worker_id:empAr.worker_id,
                    date:data?data[0].date:"",
                    //rating:ratingCal(res.data[key].work_hours),
                    agency_name:empAr.agency_name,
                    daysCount:(data.length/ noofPreset )* 100 ,
                    worker_type:empAr.worker_type
                  };

                let avg=0
                let monthAVG=0;
               
                for(let j=0;j<moDiff.length;j++){
                  let noofPresetThis = getDaysCount(moDiff[j].start,moDiff[j].end) 
                  if(props.reportPerformance=="month" || typeReport=="email"){
                    let thisMonth = data.filter(item=>moment(item.date) >= moment(moDiff[j].start) && moment(item.date) <= moment(moDiff[j].end) && parseInt(item.worked_hour) > 0);
                   
                  empDetail[`${moment(moDiff[j].start).format('MMMM')}`]= thisMonth.reduce((prev,current)=>
                      prev + current.worked_hour,0)/thisMonth.filter(item=>parseInt(item.worked_hour) > 0).length    
                                     
                  empDetail[`${moment(moDiff[j].start).format('MMMM')}`] = isNumeric(empDetail[`${moment(moDiff[j].start).format('MMMM')}`])?empDetail[`${moment(moDiff[j].start).format('MMMM')}`]:0
                  if(empDetail[`${moment(moDiff[j].start).format('MMMM')}`] >0){
                    monthAVG++; 
                  }
                  //present 
                  
                  empDetail[`${moment(moDiff[j].start).format('MMMM')}_present`] = thisMonth.length 
                 

                  avg = isNaN(empDetail[`${moment(moDiff[j].start).format('MMMM')}`])?avg: avg + parseFloat(empDetail[`${moment(moDiff[j].start).format('MMMM')}`]);
                  }else{
                    let thisMonth= data.filter(item=>moment(item.date) >= moment(moDiff[j].start) && moment(item.date) <= moment(moDiff[j].end)  && parseInt(item.worked_hour) > 0)
                    empDetail[`${moDiff[j].weekName}`]= thisMonth.reduce((prev,current)=>
                      prev + current.worked_hour,0)/thisMonth.filter(item=>parseInt(item.worked_hour) > 0).length                   
                  empDetail[`${moDiff[j].weekName}`] = isNumeric(empDetail[`${moDiff[j].weekName}`])?empDetail[`${moDiff[j].weekName}`]:0
                  empDetail[`${moDiff[j].weekName}_present`] = thisMonth.length 
                             
                  if(empDetail[`${moDiff[j].weekName}`] >0){
                    monthAVG++; 
                  }
                  avg = isNaN(empDetail[`${moDiff[j].weekName}`])?avg:avg + parseFloat(empDetail[`${moDiff[j].weekName}`]);
                  
                  }
                }
                avg = avg/monthAVG;
                empDetail.monthAverage = isNumeric(avg)?avg:0;
               // empDetail.rating= ratingCal(isNumeric(avg)?avg:0),
                allData.push(empDetail);
                
  }
 

              }
})

  let processsMade=[];

  for(let i=0;i<resultProcess.length;i++){
    let single = AGTableFunctionCalculation(allData,resultProcess[i].function,resultProcess[i].name);
    processsMade.push(single);
  }
  
  SetAllProcess(processsMade);

if(typeReport=="report"){
        SetDataPerformance(allData);
          SetAllDataPerformance(allData);
          

          
        
          AGTable(allData,"AllDataEMpPeform")  
        AGTableAgency(allData,"AllDataEMpPeformAgency")
        AGTableFunction(allData,"AllDataEMpPeformFunction") 
       


 props.exportEnableFunction();
 SetChartLoader(false);
      SetisLoading(false);
}else{

  
  let monthName = newThree.map(item=>moment(item.start).format('MMMM'));
  


  let regaularWarn = allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0 && item.worker_type=="regular") 

  let employeeOneWarn = regaularWarn.length
  let employeeSecondWarn = countEmployeesWithLowPerformance(allData,2,monthName.slice(-2),'regular').count
  let employeeThirdWarn = countEmployeesWithLowPerformance(allData,3,monthName,'regular').count

  
  let dailyWarn = allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0 && item.worker_type=="daily")

  let dailyOneWarn = dailyWarn.length
  let dailySecondWarn = countEmployeesWithLowPerformance(allData,2,monthName.slice(-2),'daily').count
  let dailyThirdWarn= countEmployeesWithLowPerformance(allData,3,monthName,'daily').count
  //calculation for agency
   let agencyList =[];

  let dataIn = allData;
  
        for(let i=0;i<dataIn.length;i++){
          if(agencyList.find(e=>e.agency_name == dataIn[i].agency_name)){

          }else{
        if(moment(dataIn[i].check_in) >= moment(startDate) ){ 
            let agencySingle ={
                agency_name:dataIn[i].agency_name
            }
            let thisAgency =dataIn.filter(item=>item.agency_name == dataIn[i].agency_name)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month' || props.reportPerformance =='week'){
                  let monthData= thisAgency.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
 
                    avg = isNaN(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?avg: avg + parseFloat(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                         
              }else{
                 let monthData= thisAgency.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    agencySingle[`${moDiff[j].weekName}`] = monthData;
                    agencySingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    agencySingle[`${moDiff[j].weekName}_complaince`] = (1 -  (agencySingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(agencySingle[`${moDiff[j].weekName}_complaince`])?avg: avg + parseFloat(agencySingle[`${moDiff[j].weekName}_complaince`]);
                    if(agencySingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            agencySingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            agencySingle.daysCount = monthAgency
          
            agencyList.push(agencySingle);
          }
          }
        }

       //calculation for function 
        let deptList =[];
        for(let i=0;i<dataIn.length;i++){
          if(deptList.find(e=>e.department == dataIn[i].department )){

          }else{
            if(moment(dataIn[i].check_in) >= moment(startDate) ){ 

            let deptSingle ={
                department:dataIn[i].department
            }
            let thisDept =dataIn.filter(item=>item.department == dataIn[i].department)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month' || props.reportPerformance =='week'){
                  let monthData= thisDept.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length               
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.department == dataIn[i].department && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
 
                    avg = isNaN(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?0+avg : avg + parseFloat(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                         
              }else{
                 let monthData= thisDept.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    deptSingle[`${moDiff[j].weekName}`] = monthData;
                    deptSingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.department==dataIn[i].department && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    deptSingle[`${moDiff[j].weekName}_complaince`] = (1 -  (deptSingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(deptSingle[`${moDiff[j].weekName}_complaince`])?0+avg: avg + parseFloat(deptSingle[`${moDiff[j].weekName}_complaince`]);
                    if(deptSingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;

            deptSingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            deptSingle.daysCount = monthAgency
          
            deptList.push(deptSingle);
          }
          }
        }


  
  let rawEmail = governanceEmail;
  let orgName =userDetails.org_name?userDetails.org_name:EmailOrg.find(item=>item.org_id == org_id && item.sub_org_id == userDetails.sub_org_id)?EmailOrg.find(item=>item.org_id == org_id && item.sub_org_id == userDetails.sub_org_id).title:'Lazada';
  let report_date = formatDateOrdinal(new Date(startDate)) +" - "+formatDateOrdinal(new Date(endDate));

  rawEmail = rawEmail.replace(/{{org_logo}}/,`<img alt="" height="auto" src="${userDetails.org_logo}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="150" />`);
  rawEmail = rawEmail.replace(/{{org_name}}/,orgName);
  rawEmail=rawEmail.replace(/{{report_date}}/,report_date);
  rawEmail=rawEmail.replace(/{{report_date}}/,report_date);
  
 let regex  = new RegExp(`{{report_date}}`, 'g'); // Use a global regular expression
  
rawEmail =rawEmail.replace(regex, report_date);

let regex2  = new RegExp(`{{reportThressholdFilter}}`, 'g'); // Use a global regular expression  
rawEmail =rawEmail.replace(regex2, props.threeshold[1]+"%");

 

  rawEmail = rawEmail.replace(/{{report_duration}}/," Duration "+getDaysCount(startDate,endDate)+" days "); 
  rawEmail = rawEmail.replace(/{{reportSendFrom}}/,senderEMail?senderEMail:" - " ); 
  rawEmail = rawEmail.replace(/{{reportMessage}}/,sendMessage?sendMessage:" - "); 
    
  
  rawEmail = rawEmail.replace(/{{reportThreesholdWorker}}/,`<strong>${allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0).length}</strong> out of <strong>${outOfEmpUnique}</strong>`); 
  rawEmail = rawEmail.replace(/{{reportThreesholdFunction}}/,`<strong>${allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0).length}</strong> out of <strong>${outOfEmpUnique}</strong>`); 
  rawEmail = rawEmail.replace(/{{reportThreesholdAgency}}/,`<strong>${allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] <  props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0).length}</strong> out of <strong>${outOfEmpUnique}</strong>`); 
  //rawEmail = rawEmail.replace(/{{reportpieworker}}/,`<img height="auto" src="https://traceplus.ai/wp-content/uploads/2023/10/Pie-Chart.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200" />`); 
  
    
  rawEmail = rawEmail.replace(/{{reportWorkerTable}}/,`<table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Inter, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tbody><tr style="border-bottom:1px solid #DEE1E1;">
                            <th css-class="tableHead" class="tableHead tableHeadLeft" align="left">LOW PERFORMERS(<span style="color:#DD441B">&lt;${props.threeshold[1]}%</span>)</th>
                            <th css-class="tableHead" class="tableHead" align="right">REGULAR WORKERS</th>
                            <th css-class="tableHead" class="tableHead" align="right">DAILY WORKERS</th>
                          </tr>
                          <tr style="border-bottom:1px solid #DEE1E1;">
                            <td css-class="tableData" class="tableData tableDataLeft" align="left">1st Warning <span class="smalltext">Brief by TL</span><span class="smallborder" style="background: #FDD485;"></span></td>
                            <td css-class="tableData" class="tableData" align="right"> ${employeeOneWarn}</td>
                            <td css-class="tableData" class="tableData" align="right">${dailyOneWarn}</td>
                          </tr>
                          <tr style="border-bottom:1px solid #DEE1E1;">
                            <td css-class="tableData" class="tableData tableDataLeft" align="left">2nd Warning <span class="smalltext">Give notice</span><span class="smallborder" style="background: #FF931D;"></span></td>
                            <td css-class="tableData" class="tableData" align="right">${employeeSecondWarn}</td>
                            <td css-class="tableData" class="tableData" align="right">${dailySecondWarn}</td>
                          </tr>
                          <tr style="border-bottom:1px solid #DEE1E1;">
                            <td css-class="tableData" class="tableData tableDataLeft" align="left">3rd Warning <span class="smalltext">Convert to DW/EOC</span><span class="smallborder" style="background: #DD441B;"></span></td>
                            <td css-class="tableData" class="tableData" align="right">${employeeThirdWarn}</td>
                            <td css-class="tableData" class="tableData" align="right">${dailyThirdWarn}</td>
                          </tr>
                        </tbody></table>`);
         let agencyHtml= '';
         let last2= newThree.slice(-2);
  
         for(let i=0;i<agencyList.length;i++){

          agencyHtml+=`  <tr style="border-bottom:1px solid #DEE1E1;">
                            <td css-class="tableData" class="tableData tableDataLeft" align="left"><span class="colorSmall" style="background:${color[i]}"></span> ${agencyList[i].agency_name}</td>
                            <td css-class="tableData" class="tableData" align="right"> ${agencyList[i][`${moment(last2[1].start).format('MMMM')}`]} ${agencyList[i][`${moment(last2[1].start).format('MMMM')}`] > agencyList[i][`${moment(last2[0].start).format('MMMM')}`]?`<span class="arrowthing"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/uparrow2.png" style="margin-right:2px;margin-left:2px">${Math.abs(agencyList[i][`${moment(last2[1].start).format('MMMM')}`] - agencyList[i][`${moment(last2[0].start).format('MMMM')}`]) }</span>`:`<span class="arrowthing arrowthingfail"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/downArrowWebp-1.png" style="margin-right:2px;margin-left:2px">${Math.abs(agencyList[i][`${moment(last2[1].start).format('MMMM')}`] - agencyList[i][`${moment(last2[0].start).format('MMMM')}`])}</span>` } </td>
                            <td css-class="tableData" class="tableData" align="right">${agencyList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`]} </td>
                            <td css-class="tableData" class="tableData" align="right"> ${agencyList[i][`${moment(last2[1].start).format('MMMM')}_complaince`].toFixed(2)}% ${agencyList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] > agencyList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]?`<span class="arrowthing"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/uparrow2.png" style="margin-right:2px;margin-left:2px">${Math.abs(agencyList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] - agencyList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]).toFixed(2) }%</span>`:`<span class="arrowthing arrowthingfail"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/downArrowWebp-1.png" style="margin-right:2px;margin-left:2px">${Math.abs(agencyList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] - agencyList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]).toFixed(2)}%</span>` } </td>
                        </tr>`
         }               

  rawEmail = rawEmail.replace(/{{reportAgencyTable}}/,`<table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Inter, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tbody><tr style="border-bottom:1px solid #DEE1E1;">
                            <th css-class="tableHead" class="tableHead tableHeadLeft" align="left">Agency</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/worker.png"/></span> Total workers</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/network.png"</span> Below ${props.threeshold[1]}%</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/clock.png"></span> Compliance</th>
                          </tr>
                          ${agencyHtml}                     
                        </tbody></table>`);

                      let deptHtml= '';
         
         for(let i=0;i<deptList.length;i++){

          if(deptList[i][`${moment(startDate).format('MMMM')}`] > 0){

          
              // 1 index is recent 0 index is old
          deptHtml+=`  <tr style="border-bottom:1px solid #DEE1E1;">
                            <td css-class="tableData" class="tableData tableDataLeft" align="left"><span class="colorSmall" style="background: ${color[i]}"></span> ${deptList[i].department}</td>
                            <td css-class="tableData" class="tableData" align="right"> ${deptList[i][`${moment(last2[1].start).format('MMMM')}`]} ${deptList[i][`${moment(last2[1].start).format('MMMM')}`] > deptList[i][`${moment(last2[0].start).format('MMMM')}`]?`<span class="arrowthing"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/uparrow2.png" style="margin-right:2px;margin-left:2px">${Math.abs(deptList[i][`${moment(last2[1].start).format('MMMM')}`] - deptList[i][`${moment(last2[0].start).format('MMMM')}`]) }</span>`:`<span class="arrowthing arrowthingfail"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/downArrowWebp-1.png" style="margin-right:2px;margin-left:2px">${Math.abs(deptList[i][`${moment(last2[1].start).format('MMMM')}`] - deptList[i][`${moment(last2[0].start).format('MMMM')}`])}</span>` } </td>
                            <td css-class="tableData" class="tableData" align="right">${deptList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`]} </td>
                            <td css-class="tableData" class="tableData" align="right"> ${deptList[i][`${moment(last2[1].start).format('MMMM')}_complaince`].toFixed(2)}% ${deptList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] > deptList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]?`<span class="arrowthing"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/uparrow2.png" style="margin-right:2px;margin-left:2px">${Math.abs(deptList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] - deptList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]).toFixed(2) }%</span>`:`<span class="arrowthing arrowthingfail"><img class="arrowSmaller" src="https://traceplus.ai/wp-content/uploads/2023/10/downArrowWebp-1.png" style="margin-right:2px;margin-left:2px">${Math.abs(deptList[i][`${moment(last2[1].start).format('MMMM')}_complaince`] - deptList[i][`${moment(last2[0].start).format('MMMM')}_complaince`]).toFixed(2)}%</span>` } </td>
                        </tr>`
            }
         }               
  
  rawEmail = rawEmail.replace(/{{reportFunctionTable}}/,`<table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Inter, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
  <tr style="border-bottom:1px solid #DEE1E1;">
                            <th css-class="tableHead" class="tableHead tableHeadLeft" align="left">Agency</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/worker.png"/></span> Total workers</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/network.png"</span> Below ${props.threeshold[1]}%</th>
                            <th css-class="tableHead" class="tableHead" align="right"><span class="smallIcon"><img src="https://traceplus.ai/wp-content/uploads/2023/10/clock.png"></span> Compliance</th>
                          </tr>
                          ${deptHtml}                     
                        </tbody></table>`);

let agencyConfig = {
  type: 'pie',
  data: {   
    datasets: [{
      data: [],
      backgroundColor: [],
    }]
  },
  options: { 
              legend: {
                  labels: {
                      fontColor: "black",
                      fontSize: 12
                  }
              },
              plugins: {
        datalabels: {
          color: "white",
        }
      }
    }
}


let workerConfig= {
  type: 'pie',
  data: {   
    datasets: [{
      data: [],
      backgroundColor: [],
    }]
  },
  options: { 
              legend: {
                  labels: {
                      fontColor: "black",
                      fontSize: 12
                  }
              },
              plugins: {
        datalabels: {
          color: "white",
        }
      }
    }
};

let regCount= allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0 && item.worker_type=="regular").length;
let dailCount = allData.filter(item=>item[`${moment(startDate).format('MMMM')}`] < props.threeshold[1] && item[`${moment(startDate).format('MMMM')}`] >0 && item.worker_type=="daily").length
let totalCount = regCount+dailCount;



// rawEmail = rawEmail.replace(/{{reportNoCache}}/,`<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" /><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Expires" content="0" />`); 




rawEmail = rawEmail.replace(/{{reportRegularColor}}/,"#28c4b2"); 
rawEmail = rawEmail.replace(/{{reportDailyColor}}/,"#745dd1"); 

rawEmail = rawEmail.replace(/{{reportRegularCount}}/,regCount); 
rawEmail = rawEmail.replace(/{{reportDailyCount}}/,dailCount); 

if(regCount  > 0){
  workerConfig.data.datasets[0].data.push(regCount)
  workerConfig.data.datasets[0].backgroundColor.push('#28c4b2');  
  rawEmail = rawEmail.replace(/{{reportRegularPercent}}/,((regCount/totalCount)*100).toFixed(2)+"%"); 
}else{
  rawEmail = rawEmail.replace(/{{reportRegularPercent}}/,"0%"); 
}



if(dailCount> 0){
rawEmail = rawEmail.replace(/{{reportDailyPercent}}/,((dailCount/totalCount)*100).toFixed(2)+"%"); 
workerConfig.data.datasets[0].data.push(dailCount)
workerConfig.data.datasets[0].backgroundColor.push('#745dd1');
}else{
rawEmail = rawEmail.replace(/{{reportDailyPercent}}/,"0%"); 
}




let functionConfig = {
  type: 'pie',
  data: {   
    datasets: [{
      data: [],
      backgroundColor: [],
    }]
  },
  options: { 
              legend: {
                  labels: {
                      fontColor: "black",
                      fontSize: 12
                  }
              },
              plugins: {
        datalabels: {
          color: "white",
        }
      }
    }
};

for(let i=0;i<deptList.length;i++){
  if(deptList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`] > 0){
    functionConfig.data.datasets[0].data.push(deptList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`])
    functionConfig.data.datasets[0].backgroundColor.push(color[i])
  }
 
}

for(let i=0;i<agencyList.length;i++){
  if(agencyList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`] > 0){
    agencyConfig.data.datasets[0].data.push(agencyList[i][`${moment(last2[1].start).format('MMMM')}_threeshold`])
    agencyConfig.data.datasets[0].backgroundColor.push(color[i])
  }

}



rawEmail = rawEmail.replace(/{{report_agency_pie}}/,`<img height="auto" class="test" src="https://quickchart.io/chart?v=2.9.4&c=${encodeURIComponent(JSON.stringify(agencyConfig))}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200" />`);                                               
rawEmail = rawEmail.replace(/{{reportpieworker}}/,`<img height="auto" class="test" src="https://quickchart.io/chart?v=2.9.4&c=${encodeURIComponent(JSON.stringify(workerConfig))}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200" />`);                                               
rawEmail = rawEmail.replace(/{{reportpiefunction}}/,`<img height="auto" class="test" src="https://quickchart.io/chart?v=2.9.4&c=${encodeURIComponent(JSON.stringify(functionConfig))}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200" />`);                                               


const dynamicHTML = rawEmail; // Your dynamic HTML content
const blob = new Blob([dynamicHTML], { type: 'text/html' });
const url = URL.createObjectURL(blob);

const iframe = document.querySelector('.iframeEmail');

iframe.src = url
iframe.height = "450px"

  SetEMailData(rawEmail);
  SetLoaderPreview(false);

}
  


}else{
  SetDataPerformance([]);
  SetAllDataPerformance([]);
}
} catch (err) {

    }
  }

  const ProcessDetails = ({ process, allData }) => {
        let moDiff = getMonths(new Date(props.start_date), new Date(props.end_date), props.reportPerformance);


        let widthCal =  (props.widthContainer - 90) / (2 + moDiff.length);


  return (
    <>
                    <div className="headerAreaCost" style={{ width: ((2 + moDiff.length) * widthCal) + 5, minWidth: "100%" }}>
                    <Row>
                        <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>Process Name</Col>
                        {moDiff.map(el => {
                            if (props.reportPerformance == 'month') {
                                return <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>{`${moment(el.start).format('MMMM')}`}</Col>;
                            } else {
                                return <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>{`${el.weekName}`}</Col>;
                            }
                        })}
                        <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>Compliance %</Col>
                    </Row>
                </div>
    
    <Collapse accordion className="newTripple customAccord customAccord2">
      {process.map((item, index) => (
        <Panel
         header={
          <div className="costBodayDept" >
          <Row>
            <Col style={{maxWidth:widthCal,minWidth:widthCal,paddingLeft:"30px"}}>{item.name}</Col>
          {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <Col style={{maxWidth:widthCal,minWidth:"100px"}}>
                            <span className="titleComp">{isNaN(item[`${moment(el.start).format('MMMM')}_complaince`])?0:item[`${moment(el.start).format('MMMM')}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${moment(el.start).format('MMMM')}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${moment(el.start).format('MMMM')}_threeshold`].toFixed(0)}</span></span>

                    </Col>
              }else{
                   return <Col style={{maxWidth:widthCal,minWidth:widthCal}}>
                    
                    
                           <span className="titleComp">{isNaN(item[`${el.weekName}_complaince`].toFixed(2))?0:item[`${el.weekName}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${el.weekName}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${el.weekName}_threeshold`].toFixed(0)}</span></span>
                    
                    </Col>
              }
          })}
            <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${isNaN(item.daysCount)?0:item.daysCount.toFixed(2)}%`}</Col>
          </Row>
        </div>
          
          
      } 
      key={"prcoess"+item.name}>



<Collapse key={"table_Content" + item.name}>

      {AGTableFunctionProcess(allData,item.function)}
  </Collapse>


        </Panel>
      ))}
    </Collapse>

    </>

  );
};

const FunctionDetails = ({ functions,allData }) => {
  
  return (
    <div>
      {functions.map((func, index) =>{
     
    return (
   
        <Panel header={func.department} key={index}>
          {AGTableSingle(allData.filter(el=>el.sub_department == func.department),func.department)}
        </Panel>
      )
    }
    
    )}
    </div>
  );
};

  function formatDateOrdinal(date) {
  const options = { month: 'short', day: 'numeric', ordinal: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}


      useEffect(()=>{
          if(props.filterEnableCycle){
            if(props.employee.length > 0 && allDataPeformance.length>0)
            AGTable(allDataPeformance,"AllDataEMpPeform") 
    
            AGTableAgency(allDataPeformance,"AllDataEMpPeformAgency");
            AGTableFunction(allDataPeformance,"AllDataEMpPeformFunction") 
          }
      },[props.threeshold])




      useEffect(()=>{
      
        if(props.exportEnableCycle){
          exportToExcel();
        }
        },[props.exportEnableCycle])
        const exportToExcel = () => {
            let sheetName = "Worker Governance";
            let headerName= "Report"
            let fileName='';
            let moDiff = getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance)

            let data = allDataPeformance
            //key = data.length

        let arr;
        let arr2=[];
        // let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        
         let newData=[]

      for(let j=0;j<moDiff.length;j++){

            if(props.reportPerformance =='month'){
                
                newData.push(...data.filter(item=> item[moment(moDiff[j].start).format('MMMM')] >= props.threeshold[0] && item[moment(moDiff[j].start).format('MMMM')] <= props.threeshold[1] && item[moment(moDiff[j].start).format('MMMM')]  > 0));  
            }else{
              newData.push(...data.filter(item=> item[`${moDiff[j].weekName}`] >= props.threeshold[0] && item[`${moDiff[j].weekName}`] <= props.threeshold[1] && item[`${moDiff[j].weekName}`] > 0));  
            }
      }




  

const uniqueNames = new Set();

newData.forEach(entry => {
    uniqueNames.add(entry);
});

// Now 'uniqueNames' contains the unique values from the "name" key

// If you want to convert the Set back to an array, you can do this:
const uniqueNamesArray = Array.from(uniqueNames);

         let dataSet= uniqueNamesArray.filter(item=>item.monthAverage > 0);
        




            




            if(props.start_date == props.end_date){
                fileName = "Governance"+"-"+moment(props.start_date).format("MMM DD")+".xlsx";
            }else{
                fileName = "Governance"+"-"+moment(props.start_date).format("MMM DD")+" - "+moment(props.end_date).format("MMM DD")+".xlsx";
            }
            
            const workbook = new ExcelJs.Workbook();
            let loc = [];
            let arr3=[]
          
          
            let worksheet = workbook.addWorksheet('Worker Governance');
            worksheet.addRow(['Min Filter',props.threeshold[0]])
            worksheet.addRow(['Max Filter',props.threeshold[1]])
            worksheet.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
            worksheet.addRow(['End Date',moment(props.end_date).format("MMM DD")])
            let cols =[
               {name:'Name', header: 'Name', key: 'emp_name'},
              {name:'Worker ID', header: 'Worker ID', key: 'worker_id'},   
              {name:'Agency Name', header: 'Agency Name', key: 'agency_name'},
            
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                    
                     cols.push({name:moment(moDiff[j].start).format('MMMM')+" Performance %", header:moment(moDiff[j].start).format('MMMM')+" Performance %", key: moment(moDiff[j].start).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).format('MMMM')+" Attendance (days)", header:moment(moDiff[j].start).format('MMMM')+" Attendance (days)", key: moment(moDiff[j].start).format('MMMM')  } );

                  }else{
                   cols.push({name:moDiff[j].weekName+" Performance %", header:moDiff[j].weekName+" Performance %", key: moDiff[j].weekName});
                   cols.push({name:moDiff[j].weekName+" Attendance (days)", header:moDiff[j].weekName+" Attendance (days)", key: moDiff[j].weekName});

                  }
            
                }
                // cols.push({name:'Attendance %', header: 'Attendance %', key: 'daysCount'})

            worksheet.addTable({
              name: 'WorkerGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : cols,
              rows: dataSet.map((e) => {
                let arr =[];
                let arr2=[];

                    for(let j=0;j<moDiff.length;j++){

                      if(props.reportPerformance=='month'){
                          arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')])?0:parseFloat(e[moment(moDiff[j].start).format('MMMM')].toFixed() )) ;
                          arr2.push(e[`${moment(moDiff[j].start).format('MMMM')}_present`]);
                        
                      }else{
                          arr2.push(isNaN(e[moDiff[j].weekName])?0:parseFloat(e[moDiff[j].weekName]));
                          arr2.push(e[`${moDiff[j].weekName}_present`]);
                      }
                  }
                  arr.push(e.name,e.worker_id,e.agency_name,...arr2.map(l=> l));
                 return arr;
              })
            })
          
            worksheet.state = 'visible';
            const table = worksheet.getTable('WorkerGovernance');
            // for (let i = 0; i < table.table.columns.length; i++) {
            //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
            //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
            //     type: "pattern",
            //     pattern: "solid",
            //     fgColor: { argb: "d0cfcf" }
            //   };
          
            // }
            worksheet.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });

            // AGency start
//agency calculation
        let agencyList =[];
       let dataIn = allDataPeformance.filter(item=>item.monthAverage >= props.threeshold[0] && item.monthAverage > 0)
       
        for(let i=0;i<dataIn.length;i++){
          if(agencyList.find(e=>e.agency_name == dataIn[i].agency_name)){

          }else{

            let agencySingle ={
                agency_name:dataIn[i].agency_name
            }
            let thisAgency =dataIn.filter(item=>item.agency_name == dataIn[i].agency_name)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month'){
                  let monthData= thisAgency.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         
                  
      
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 ).length

                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
 
                    avg = isNaN(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?0+avg: avg + parseFloat(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                         
              }else{
                 let monthData= thisAgency.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;
                
                  
                    agencySingle[`${moDiff[j].weekName}`] = monthData;
                    agencySingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    agencySingle[`${moDiff[j].weekName}_complaince`] = (1 -  (agencySingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(agencySingle[`${moDiff[j].weekName}_complaince`])?0 + avg: avg + parseFloat(agencySingle[`${moDiff[j].weekName}_complaince`]);
                    if(agencySingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            agencySingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            agencySingle.daysCount = monthAgency
           
            agencyList.push(agencySingle);
          }
        }
        
      
      let data2=[]   
       data2 =  agencyList


            


          const worksheet2 = workbook.addWorksheet('Agency Governance');
          worksheet2.addRow(['Min Filter',props.threeshold[0]])
          worksheet2.addRow(['Max Filter',props.threeshold[1]])
          worksheet2.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
          worksheet2.addRow(['End Date',moment(props.end_date).format("MMM DD")])


                        let cols2 =[
               {name:'Agency Name', header: 'Agency Name', key: 'agency_name'},
                
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                     cols2.push({name:moment(moDiff[j].start).format('MMMM')+" Total", header:moment(moDiff[j].start).format('MMMM')+" Total", key: moment(moDiff[j].start).format('MMMM')  } );
                     cols2.push({name:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", header:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", key: moment(moDiff[j].start).format('MMMM')+"_threeshold"  } );
                     cols2.push({name:moment(moDiff[j].start).format('MMMM')+" Complaince", header:moment(moDiff[j].start).format('MMMM')+" Complaince", key: moment(moDiff[j].start).format('MMMM')+"_complaince"  } );

                  }else{
                       cols2.push({name:moDiff[j].weekName+" Total", header:moDiff[j].weekName+" Total", key: moDiff[j].weekName});
                       cols2.push({name:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", header:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", key: moDiff[j].weekName+"_threeshold"});
                       cols2.push({name:moDiff[j].weekName+" Compliance", header:moDiff[j].weekName+" Compliance", key: moDiff[j].weekName+"_complaince"});
                  }
            
                }
                cols2.push({name:'Avg. Compliance', header: 'Avg. Compliance', key: 'daysCount'})

            worksheet2.addTable({
              name: 'AgencyGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : cols2,
              rows: data2.map((e) => {
                let arr =[];
                let arr2=[];

                    for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')])?0:e[moment(moDiff[j].start).format('MMMM')]);
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_threeshold"])?0:e[moment(moDiff[j].start).format('MMMM')+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_complaince"])?0:e[moment(moDiff[j].start).format('MMMM')+"_complaince"].toFixed(2));

                  }else{
                      arr2.push(isNaN(e[moDiff[j].weekName])?0:e[moDiff[j].weekName]);
                      arr2.push(isNaN(e[moDiff[j].weekName+"_threeshold"].toFixed(2))?0:e[moDiff[j].weekName+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moDiff[j].weekName+"_complaince"].toFixed(2))?0:e[moDiff[j].weekName+"_complaince"].toFixed(2));

                  }
                                      }

                  arr.push(e.agency_name,...arr2.map(l=> parseFloat(l)),parseFloat(e.monthAverage.toFixed(2)));
                 return arr;
              })
            })
            
          
            worksheet2.state = 'visible';
            const table2 = worksheet2.getTable('AgencyGovernance');
           
            worksheet2.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
            
          const worksheet3 = workbook.addWorksheet('Function Governance');
          worksheet3.addRow(['Min Filter',props.threeshold[0]])
          worksheet3.addRow(['Max Filter',props.threeshold[1]])
          worksheet3.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
          worksheet3.addRow(['End Date',moment(props.end_date).format("MMM DD")])



            

//Function 
        let deptList =[];
        //let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        for(let i=0;i<dataIn.length;i++){
          if(deptList.find(e=>e.department == dataIn[i].department) ){

          }else{

            let deptSingle ={
                department:dataIn[i].department
            }
            let thisDept =dataIn.filter(item=>item.department == dataIn[i].department)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month'){
                  let monthData= thisDept.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         

      
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.department == dataIn[i].department && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
 
                    avg = isNaN(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?0+avg: avg + parseFloat(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                         
              }else{
                 let monthData= thisDept.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    deptSingle[`${moDiff[j].weekName}`] = monthData;
                    deptSingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.department==dataIn[i].department && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    deptSingle[`${moDiff[j].weekName}_complaince`] = (1 -  (deptSingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(deptSingle[`${moDiff[j].weekName}_complaince`])?avg+0: avg + parseFloat(deptSingle[`${moDiff[j].weekName}_complaince`]);
                    if(deptSingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            deptSingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            deptSingle.daysCount = monthAgency
          
            deptList.push(deptSingle);
          }
        }
       
      
      let data3=[]   
       data3 =  deptList

          let col3 =[
               {name:'Function Name', header: 'Function Name', key: 'name'},
                
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                     col3.push({name:moment(moDiff[j].start).format('MMMM')+" Total", header:moment(moDiff[j].start).format('MMMM')+" Total", key: moment(moDiff[j].start).format('MMMM')  } );
                     col3.push({name:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", header:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", key: moment(moDiff[j].start).format('MMMM')+"_threeshold"  } );
                     col3.push({name:moment(moDiff[j].start).format('MMMM')+" Complaince", header:moment(moDiff[j].start).format('MMMM')+" Complaince", key: moment(moDiff[j].start).format('MMMM')+"_complaince"  } );

                  }else{
                       col3.push({name:moDiff[j].weekName+" Total", header:moDiff[j].weekName+" Total", key: moDiff[j].weekName});
                       col3.push({name:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", header:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", key: moDiff[j].weekName+"_threeshold"});
                       col3.push({name:moDiff[j].weekName+" Compliance", header:moDiff[j].weekName+" Compliance", key: moDiff[j].weekName+"_complaince"});
                  }
            
                }
                col3.push({name:'Avg. Compliance', header: 'Avg. Compliance', key: 'daysCount'})

            worksheet3.addTable({
              name: 'FunctionGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : col3,
              rows: data3.map((e) => {
                let arr =[];
                let arr2=[];
                for(let j=0;j<moDiff.length;j++){
                  if(props.reportPerformance=='month'){
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')])?0:e[moment(moDiff[j].start).format('MMMM')]);
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_threeshold"])?0:e[moment(moDiff[j].start).format('MMMM')+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_complaince"])?0:e[moment(moDiff[j].start).format('MMMM')+"_complaince"].toFixed(2));
                  }else{
                      arr2.push(isNaN(e[moDiff[j].weekName])?0:e[moDiff[j].weekName]);
                      arr2.push(isNaN(e[moDiff[j].weekName+"_threeshold"])?0:e[moDiff[j].weekName+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moDiff[j].weekName+"_complaince"])?0:e[moDiff[j].weekName+"_complaince"].toFixed(2));
                  }
                }
                arr.push(e.department,...arr2.map(l=> parseFloat(l)),parseFloat(e.monthAverage.toFixed(2)));
                
                return arr;
              })
            })
          
            worksheet3.state = 'visible';
            const table3 = worksheet3.getTable('FunctionGovernance');
           
            worksheet3.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });


            

//Process 
  const worksheet4 = workbook.addWorksheet('Process Governance');
          worksheet4.addRow(['Min Filter',props.threeshold[0]])
          worksheet4.addRow(['Max Filter',props.threeshold[1]])
          worksheet4.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
          worksheet4.addRow(['End Date',moment(props.end_date).format("MMM DD")])
      
      let data4=[...allProcess]

          let col4 =[
               {name:'Process Name', header: 'Process Name', key: 'name'},
                
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                     col4.push({name:moment(moDiff[j].start).format('MMMM')+" Total", header:moment(moDiff[j].start).format('MMMM')+" Total", key: moment(moDiff[j].start).format('MMMM')  } );
                     col4.push({name:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", header:moment(moDiff[j].start).format('MMMM')+" Below "+props.threeshold[1]+"%", key: moment(moDiff[j].start).format('MMMM')+"_threeshold"  } );
                     col4.push({name:moment(moDiff[j].start).format('MMMM')+" Complaince", header:moment(moDiff[j].start).format('MMMM')+" Complaince", key: moment(moDiff[j].start).format('MMMM')+"_complaince"  } );

                  }else{
                       col4.push({name:moDiff[j].weekName+" Total", header:moDiff[j].weekName+" Total", key: moDiff[j].weekName});
                       col4.push({name:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", header:moDiff[j].weekName+" Below "+props.threeshold[1]+"%", key: moDiff[j].weekName+"_threeshold"});
                       col4.push({name:moDiff[j].weekName+" Compliance", header:moDiff[j].weekName+" Compliance", key: moDiff[j].weekName+"_complaince"});
                  }
            
                }
                col4.push({name:'Avg. Compliance', header: 'Avg. Compliance', key: 'daysCount'})

            worksheet4.addTable({
              name: 'ProcessGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : col4,
              rows: data4.map((e) => {
                let arr =[];
                let arr2=[];
                for(let j=0;j<moDiff.length;j++){
                  if(props.reportPerformance=='month'){
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')])?0:e[moment(moDiff[j].start).format('MMMM')]);
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_threeshold"])?0:e[moment(moDiff[j].start).format('MMMM')+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moment(moDiff[j].start).format('MMMM')+"_complaince"])?0:e[moment(moDiff[j].start).format('MMMM')+"_complaince"].toFixed(2));
                  }else{
                      arr2.push(isNaN(e[moDiff[j].weekName])?0:e[moDiff[j].weekName]);
                      arr2.push(isNaN(e[moDiff[j].weekName+"_threeshold"])?0:e[moDiff[j].weekName+"_threeshold"].toFixed(2));
                      arr2.push(isNaN(e[moDiff[j].weekName+"_complaince"])?0:e[moDiff[j].weekName+"_complaince"].toFixed(2));
                  }
                }
                arr.push(e.name,...arr2.map(l=> parseFloat(l)),parseFloat(e.monthAverage.toFixed(2)));
                
                return arr;
              })
            })
          
            worksheet4.state = 'visible';
            const table4 = worksheet4.getTable('ProcessGovernance');
           
            worksheet4.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
            

            table.commit();
            table2.commit();
            table3.commit();
            table4.commit();


            const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
          

          
          
          
            
          
            props.exportProcessExist();
          
          
          
          };
      function capitalizeFirstLetter(string) {
        if(string){
          return string.toLowerCase();
        }
      }


      function starRating(val){
        let rate='';
        if(val==0){
            return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
        }else{
            for(let i=1;i<=5;i++){
                if(i<=val){
                    rate+=`${StarFill}`;
                }else{
                    rate+=`${StarEmpty}`;
                }
            }
        }
        return `<div class="ratingFill ratingAG">${rate}</div>`;
      }


      function valueRenderPerformance(params,label,passed="agency"){
        
        var op =document.createElement('div');
    
        var eGui = document.createElement('div');
        if(params.value > -1){
    if(params.value == 0 ){
          eGui.innerHTML=`<div class="disabledCell headerSmallSize"> - </div>`;
        }
        else if(params.value > props.threeshold[1] ){
          eGui.innerHTML=`<div class="greenHeader headerSmallSize">${params.value.toFixed(2)}% / ${params.data[label+"_present"]} days</div>`;
        }else{
          eGui.innerHTML=`<div class="redHeader headerSmallSize">${params.value.toFixed(2)}% / ${params.data[label+"_present"]} days</div>`;

        }

        }else{
       
        }
        
            
      return eGui;  
      }

      function starRatingRender(params){
        

        var op =document.createElement('div');
    
        var eGui = document.createElement('div');
         
            eGui.innerHTML=`${starRating(params.value)}`;
                
    
    
      
         return eGui;
    
    
    
    }

    const cellClassRules = {
  "cell-no-space":true 
};



      function AGTable(data,key){

        key = data.length

        let arr;
        let arr2=[];
         let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
       
         let newData=[]
      
      for(let j=0;j<moDiff.length;j++){

            if(props.reportPerformance =='month'){
      
                newData.push(...data.filter(item=> item[moment(moDiff[j].start).format('MMMM')] >= props.threeshold[0] && item[moment(moDiff[j].start).format('MMMM')] <= props.threeshold[1] && item[moment(moDiff[j].start).format('MMMM')]  > 0));  
            }else{
              newData.push(...data.filter(item=> item[`${moDiff[j].weekName}`] >= props.threeshold[0] && item[`${moDiff[j].weekName}`] <= props.threeshold[1] && item[`${moDiff[j].weekName}`] > 0));  
            }
      }

     


  

const uniqueNames = new Set();

newData.forEach(entry => {
    uniqueNames.add(entry);
});

// Now 'uniqueNames' contains the unique values from the "name" key

// If you want to convert the Set back to an array, you can do this:
const uniqueNamesArray = Array.from(uniqueNames);

          data= uniqueNamesArray.filter(item=>item.monthAverage > 0);
        if(data){  
         
      
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(3+moDiff.length) :(props.widthContainer - 90)/ (3+moDiff.length)      
      
          arr2.push(  
            <AgGridColumn
                field="name"
                headerName={"Name"}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
                cellClass = {"ag-cell--textUppercase"}
                minWidth={100}
                key={key+"name"}
            />
          )
          

     
        arr2.push(  
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
              cellClass = {"ag-cell--textUppercase"}
              minWidth={100}
                key={key+"worker_id"}
          />
        )
    
         arr2.push( 
      <AgGridColumn
        field="agency_name"
        headerName={"Agency Name"}
        cellClass={'textCapitalize textCap'}
        minWidth={100}
        key={key+"agency_name"}
      />
     )
          
     

      
          for(let j=0;j<moDiff.length;j++){

            if(props.reportPerformance =='month'){
                       
        arr2.push( 
          
            <AgGridColumn
              field={moment(moDiff[j].start).format('MMMM')}
              headerName={`${moment(moDiff[j].start).format('MMMM')}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              //valueFormatter={(params)=>parseFloat(params.value.toFixed(2))}
              minWidth={120}
              key={`${key} ${moment(moDiff[j].start).format('MMMM')}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,moment(moDiff[j].start).format('MMMM'),'From table')}
            /> )
            }else{
                                 
                      arr2.push( 
          
            <AgGridColumn
              field={moDiff[j].weekName}
              headerName={`${moDiff[j].weekName}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              //valueFormatter={(params)=>parseFloat(params.value.toFixed(2))}
              minWidth={120}
              cellClassRules= {cellClassRules}
              key={`${key}${moDiff[j].weekName}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              cellRenderer= {(params)=>valueRenderPerformance(params,moDiff[j].weekName,'From table')}
              //cellRenderer= {(params)=>starRatingRender(params)}
            /> )
            }
  
          }
        
          
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                

                enableRangeSelection= {true}
                onCellClicked={ (event) => handleClickCard(event.data.worker_id,event.data)}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
        
                }}
                width={widthCal}
                minWidth={100}
                headerHeight={35}
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >    
        {arr2}
        </AgGridReact>
        </div></div>
        
        SetShowDataDefault(arr);          
            
    }
            
    
    
      }

        function handleClickCard(tag_serial,data){
          
          let type= data.worker_type;
    let date= data.date;
    let dt =date.split('-');

    localStorage.setItem('ProductiveStart',new Date(dt[2], dt[1] - 1, dt[0]));
    localStorage.setItem('ProductiveEnd',new Date(dt[2], dt[1] - 1, dt[0]));
    // localStorage.setItem("selectedDate",new Date(dt[2], dt[1] - 1, dt[0]))
          
    if(type=='employee' ||type=='regular' ){

    window.open(
      `/productivity/employee/:${tag_serial}/${date}`, "_blank");
    }else{
          window.open(
      `/productivity/daily_worker/:${tag_serial}/${date}`, "_blank");
    }

  }

    function AGTableSingle(data,key){

        key = data.length
        let arr;        
        let arr2=[];
         let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
         let newData=[]
      for(let j=0;j<moDiff.length;j++){
        
            if(props.reportPerformance =='month'){
              
                newData.push(...data.filter(item=> item[moment(moDiff[j].start).format('MMMM')] >= props.threeshold[0] && item[moment(moDiff[j].start).format('MMMM')] <= props.threeshold[1] && item[moment(moDiff[j].start).format('MMMM')] > 0 ));  
            }else{
              newData.push(...data.filter(item=> item[`${moDiff[j].weekName}`] >= props.threeshold[0] && item[`${moDiff[j].weekName}`] <= props.threeshold[1] && item[`${moDiff[j].weekName}`] > 0));  
            }
      }



const uniqueNames = new Set();

newData.forEach(entry => {
    uniqueNames.add(entry);
});

// Now 'uniqueNames' contains the unique values from the "name" key

// If you want to convert the Set back to an array, you can do this:
const uniqueNamesArray = Array.from(uniqueNames);
          
          data= uniqueNamesArray;
                  if(data){  
          data = data.filter(item=>item.monthAverage > 0);
      
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(3+moDiff.length) :(props.widthContainer - 90)/ (3+moDiff.length)      
          
          arr2.push(  
            <AgGridColumn
                field="name"
                headerName={"Name"}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
                cellClass = {"ag-cell--textUppercase"}
                minWidth={100}
                key={key+"single_name"}
            />
          )

     
        arr2.push(  
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
              cellClass = {"ag-cell--textUppercase"}
              minWidth={100}
              key={key+"single_worker_id"}
          />
        )
    
         arr2.push( 
      <AgGridColumn
        field="agency_name"
        headerName={"Agency Name"}
        cellClass={'textCapitalize textCap'}
        minWidth={100}
        key={key+"single_agency_name"}
      />
     )
          
     

      
          for(let j=0;j<moDiff.length;j++){
            if(props.reportPerformance =='month'){
        arr2.push( 
          
            <AgGridColumn
              field={moment(moDiff[j].start).format('MMMM')}
              headerName={`${moment(moDiff[j].start).format('MMMM')}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              //valueFormatter={(params)=>parseFloat(params.value.toFixed(2))}
              minWidth={120}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,moment(moDiff[j].start).format('MMMM'),'single')}
              key={`${key}single_${moment(moDiff[j].start).format('MMMM')}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
            /> )
            }else{
             
            arr2.push( 
          
            <AgGridColumn
              field={moDiff[j].weekName}
              headerName={`${moDiff[j].weekName}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              //valueFormatter={(params)=>parseFloat(params.value.toFixed(2))}
              minWidth={120}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,moDiff[j].weekName,'single')}
              key={`${key}single_${moDiff[j].weekName}(${getDaysCount(moDiff[j].start,moDiff[j].end)} days)`}
              //cellRenderer= {(params)=>starRatingRender(params)}
            /> )
            }
  
          }
        
            
        
    
    
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                

                enableRangeSelection= {true}
                onCellClicked={ (event) => handleClickCard(event.data.worker_id,event.data)}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
        
                }}
                width={widthCal}
                minWidth={100}
                headerHeight={35}
                // onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data)}
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >    
        {arr2}
        </AgGridReact>
        </div></div>
        return arr;
                  }

}

      function AGTableAgency(dataIn,key){

        key = dataIn.length

        let arr;
        let arr2=[];
        let agencyList =[];
        let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        for(let i=0;i<dataIn.length;i++){
          if(agencyList.find(e=>e.agency_name == dataIn[i].agency_name)){

          }else{

            let agencySingle ={
                agency_name:dataIn[i].agency_name
            }
            let thisAgency =dataIn.filter(item=>item.agency_name == dataIn[i].agency_name)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month'){
                  let monthData= thisAgency.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         
                  
      
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (agencySingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
 
                    avg =isNaN(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?avg+0: avg + parseFloat(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(agencySingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                         
              }else{
                 let monthData= thisAgency.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    agencySingle[`${moDiff[j].weekName}`] = monthData;
                    agencySingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.agency_name==dataIn[i].agency_name && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    agencySingle[`${moDiff[j].weekName}_complaince`] = (1 -  (agencySingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(agencySingle[`${moDiff[j].weekName}_complaince`])?avg +0:avg + parseFloat(agencySingle[`${moDiff[j].weekName}_complaince`]);
                    if(agencySingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            agencySingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            agencySingle.daysCount = monthAgency
          
            agencyList.push(agencySingle);
          }
        }
        
      
      let data=[]   
       data =  agencyList

        if(data){  
          data = data.sort((a,b)=>{
            return new Date(a.date) -  new Date(b.date)
          })
          
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(2+moDiff.length) :(props.widthContainer - 90)/ (2+moDiff.length)      
          widthCal = widthCal > 150?widthCal:150   

    arr =<div className="WholeTableFLow">
                                <div className="headerAreaCost" style={{width:((2+ moDiff.length) * widthCal)+ 5,minWidth:"100%"}}>
                              <Row >
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Agency Name</Col>
                                {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <Col key={`parent_${moment(el.start).format('MMMM')}`} style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMMM')}`}</Col>
              }else{
                   return <Col key={`parent_${moment(el.start).format('MMMM')}`} style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}</Col>
              }
          })}
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Compliance %</Col>
                              </Row>
                            </div>
     <Collapse accordion className="customAccord customAccord2" style={{width:((2+ moDiff.length) * widthCal) +5,minWidth:"100%"}}>
      {data.map((item, index) => (
        <Panel
        key={"super_parent"+index+JSON.stringify(item)}
        header={<div className="costBodayDept" >
          <Row>
            <Col style={{maxWidth:widthCal,minWidth:widthCal,paddingLeft:"30px"}}>{item.agency_name}</Col>
          {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <Col key={`${item.agency_name}_single_${moment(el.start).format('MMMM')}`} style={{maxWidth:widthCal,minWidth:"100px"}}>
                            <span className="titleComp">{isNaN(item[`${moment(el.start).format('MMMM')}_complaince`].toFixed(2))?0:item[`${moment(el.start).format('MMMM')}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${moment(el.start).format('MMMM')}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${moment(el.start).format('MMMM')}_threeshold`].toFixed(0)}</span></span>

                    </Col>
              }else{
                   return <Col key={`${item.agency_name}_single_${el.weekName}`} style={{maxWidth:widthCal,minWidth:widthCal}}>
                    
                    
                           <span className="titleComp">{isNaN(item[`${el.weekName}_complaince`].toFixed(2))?0:item[`${el.weekName}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${el.weekName}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${el.weekName}_threeshold`].toFixed(0)}</span></span>
                    
                    </Col>
              }
          })}
            <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${isNaN(item.daysCount)?0:item.daysCount.toFixed(2)}%`}</Col>
          </Row>
        </div>}>
          <Collapse key={"childValue"+item.agency_name}>
      
                
                {AGTableSingle(dataIn.filter(el=>el.agency_name==item.agency_name),item.agency_name,'single')}
      
          </Collapse>
        </Panel>
      ))}
    </Collapse></div>
            
      
        SetShowDataAgency(arr);
        // SetShowDataDefault(arr);                      
    }
            
    
    
      }
      
      
function AGTableFunction(dataIn,key){

        key = dataIn.length

        let arr;
        let arr2=[];
        let deptList =[];
        let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        for(let i=0;i<dataIn.length;i++){
          if(deptList.find(e=>e.department == dataIn[i].department)){

          }else{

            let deptSingle ={
                department:dataIn[i].department
            }
            let thisDept =dataIn.filter(item=>item.department == dataIn[i].department)
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month'){
                  let monthData= thisDept.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         
                  
      
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.department == dataIn[i].department && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
                    
 
                    avg = isNaN(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?avg +0:avg + parseFloat(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                 
                       
              }else{
                 let monthData= thisDept.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    deptSingle[`${moDiff[j].weekName}`] = monthData;
                    deptSingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.department==dataIn[i].department && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    deptSingle[`${moDiff[j].weekName}_complaince`] = (1 -  (deptSingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;


                    avg = isNaN(deptSingle[`${moDiff[j].weekName}_complaince`])? avg +0:avg + parseFloat(deptSingle[`${moDiff[j].weekName}_complaince`]);
                    if(deptSingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            
            deptSingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            deptSingle.daysCount = monthAgency
          
            deptList.push(deptSingle);
          }
        }
        
      
      let data=[]   
       data =  deptList

        if(data){  
          data = data.sort((a,b)=>{
            return new Date(a.date) -  new Date(b.date)
          })
          
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(2+moDiff.length) :(props.widthContainer - 90)/ (2+moDiff.length)      
          widthCal = widthCal > 150?widthCal:150   
          

    arr =<div className="WholeTableFLow">
                                <div className="headerAreaCost" style={{width:((2+ moDiff.length) * widthCal)+ 5,minWidth:"100%"}}>
                              <Row >
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Function Name</Col>
                                {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMMM')}`}</Col>
              }else{
                   return <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}</Col>
              }
          })}
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Compliance %</Col>
                              </Row>
                            </div>
     <Collapse accordion className="customAccord customAccord2" style={{width:((2+ moDiff.length) * widthCal) +5,minWidth:"100%"}}>
      {data.map((item, index) => (
        <Panel 
        key={"panel_"+item.department}
        header={<div className="costBodayDept" >
          <Row>
            <Col style={{maxWidth:widthCal,minWidth:widthCal,paddingLeft:"30px"}}>{item.department}</Col>
          {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <Col style={{maxWidth:widthCal,minWidth:"100px"}}>
                            <span className="titleComp">{isNaN(item[`${moment(el.start).format('MMMM')}_complaince`])?0:item[`${moment(el.start).format('MMMM')}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${moment(el.start).format('MMMM')}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${moment(el.start).format('MMMM')}_threeshold`].toFixed(0)}</span></span>

                    </Col>
              }else{
                   return <Col style={{maxWidth:widthCal,minWidth:widthCal}}>
                    
                    
                           <span className="titleComp">{isNaN(item[`${el.weekName}_complaince`].toFixed(2))?0:item[`${el.weekName}_complaince`].toFixed(2)}</span>
                            <span className="titleCompSmaller">Total : <span style={{color:"#21723c"}}>{item[`${el.weekName}`].toFixed(0)}</span></span>
                            <span className="titleCompSmaller"> {`Below `+props.threeshold[1]}% : <span style={{color:"#870f1a"}}>{item[`${el.weekName}_threeshold`].toFixed(0)}</span></span>
                    
                    </Col>
              }
          })}
            <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${isNaN(item.daysCount)?0:item.daysCount.toFixed(2)}%`}</Col>
          </Row>
        </div>}>
          <Collapse key={"table_Content"+item.department}>
      
                
                {AGTableSingle(dataIn.filter(el=>el.department==item.department),item.department,'single')}
      
          </Collapse>
        </Panel>
      ))}
    </Collapse></div>
            
      
        SetShowDataFunction(arr);
        // SetShowDataDefault(arr);                      
    }
            
    
    
      }

function AGTableFunctionProcess(dataIn, process, key) {
    key = dataIn.length;

    let arr;
    let arr2 = [];
    let deptList = [];
    let moDiff = getMonths(new Date(props.start_date), new Date(props.end_date), props.reportPerformance);

    let data = [];
    data = process;

    if (data) {

        let widthCal = key == 'allEmployeee' ? (props.widthContainer - 60) / (2 + moDiff.length) : (props.widthContainer - 90) / (2 + moDiff.length);
        
        widthCal = widthCal > 150 ? widthCal : 150;
        arr =
            <div className="WholeTableFLow WholeTableFLowProcess">

                <Collapse accordion className="customAccord customAccord2" style={{ width: ((2 + moDiff.length) * widthCal) + 5, minWidth: "100%" }}>
                    {data.map((item, index) => (
                        <Panel
                            key={"panel_" + item.department}
                            header={
                                <div className="costBodayDept">
                                    <Row>
                                        <Col style={{ maxWidth: widthCal, minWidth: widthCal, paddingLeft: "30px" }}>{item.department}</Col>
                                        {moDiff.map(el => {
                                            if (props.reportPerformance == 'month') {
                                                return (
                                                    <Col style={{ maxWidth: widthCal, minWidth: "100px" }}>
                                                        <span className="titleComp">{isNaN(item[`${moment(el.start).format('MMMM')}_complaince`]) ? 0 : item[`${moment(el.start).format('MMMM')}_complaince`].toFixed(2)}</span>
                                                        <span className="titleCompSmaller">Total : <span style={{ color: "#21723c" }}>{item[`${moment(el.start).format('MMMM')}`].toFixed(0)}</span></span>
                                                        <span className="titleCompSmaller"> {`Below ` + props.threeshold[1]}% : <span style={{ color: "#870f1a" }}>{item[`${moment(el.start).format('MMMM')}_threeshold`].toFixed(0)}</span></span>
                                                    </Col>
                                                );
                                            } else {
                                                return (
                                                    <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>
                                                        <span className="titleComp">{isNaN(item[`${el.weekName}_complaince`].toFixed(2)) ? 0 : item[`${el.weekName}_complaince`].toFixed(2)}</span>
                                                        <span className="titleCompSmaller">Total : <span style={{ color: "#21723c" }}>{item[`${el.weekName}`].toFixed(0)}</span></span>
                                                        <span className="titleCompSmaller"> {`Below ` + props.threeshold[1]}% : <span style={{ color: "#870f1a" }}>{item[`${el.weekName}_threeshold`].toFixed(0)}</span></span>
                                                    </Col>
                                                );
                                            }
                                        })}
                                        <Col style={{ maxWidth: widthCal, minWidth: widthCal }}>{`${isNaN(item.daysCount) ? 0 : item.daysCount.toFixed(2)}%`}</Col>
                                    </Row>
                                </div>
                            }
                        >
                            <Collapse key={"table_Content" + item.name}>
                                {AGTableSingle(dataIn.filter(el => el.sub_department == item.department), item.department, 'single')}
                            </Collapse>
                        </Panel>
                    ))}
                </Collapse>
            </div>;

       return  arr;
    }
}

function AGTableFunctionCalculation(dataIn,deptartmentList,processName){


        let arr;
        let arr2=[];
        let deptList =[];
        let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        for(let i=0;i<deptartmentList.length;i++){
          if(deptList.find(e=>e.department == deptartmentList[i])){

          }else{

            let deptSingle ={
                department:deptartmentList[i],
            }
            let thisDept =dataIn.filter(item=>item.sub_department.toLowerCase() == deptartmentList[i].toLowerCase());
           
            
            let avg=0;
            let nonZero=0
            for(let j=0;j<moDiff.length;j++ ){

              if(props.reportPerformance =='month'){
                  let monthData= thisDept.filter(item=> item[`${moment(moDiff[j].start).format('MMMM')}`] > 0).length         
                  
      
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}`] = monthData;
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`] = dataIn.filter(el=>el.sub_department == deptartmentList[i] && el[`${moment(moDiff[j].start).format('MMMM')}`] > 0 && el[`${moment(moDiff[j].start).format('MMMM')}`] <= props.threeshold[1]  ).length
                    deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = (1 -  (deptSingle[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]/monthData)) *100 ;
                    if(isNaN(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])){
                        deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = 0;                        
                    }
 
                    avg = isNaN(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`])?avg +0:avg + parseFloat(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);
                    if(deptSingle[`${moment(moDiff[j].start).format('MMMM')}_complaince`] > 0){
                      nonZero++;
                    }
                 
                       
              }else{
                 let monthData= thisDept.filter(item=> item[`${moDiff[j].weekName}`] > 0).length;

                    deptSingle[`${moDiff[j].weekName}`] = monthData;
                    deptSingle[`${moDiff[j].weekName}_threeshold`] = dataIn.filter(el=>el.sub_department==deptartmentList[i] && el[`${moDiff[j].weekName}`] > 0 && el[`${moDiff[j].weekName}`] <= props.threeshold[1] ).length
                    deptSingle[`${moDiff[j].weekName}_complaince`] = (1 -  (deptSingle[`${moDiff[j].weekName}_threeshold`]/monthData)) *100 ;

                    if(isNaN(deptSingle[`${moDiff[j].weekName}_complaince`])){
                        deptSingle[`${moDiff[j].weekName}_complaince`] =0;                        
                    }


                    avg = isNaN(deptSingle[`${moDiff[j].weekName}_complaince`])? avg +0:avg + parseFloat(deptSingle[`${moDiff[j].weekName}_complaince`]);
                    if(deptSingle[`${moDiff[j].weekName}_complaince`] > 0){
                      nonZero++;
                    }
              }
            }
            avg = avg/nonZero;
            
            deptSingle.monthAverage =  isNumeric(avg)?avg:0;
            let monthAgency =avg
            deptSingle.daysCount = isNumeric(monthAgency)?monthAgency:0
          
            deptList.push(deptSingle);
          }
        }
        
      
      let data={};
        data.name = processName;
        data.function = deptList;
      //  data =  deptList

      
      //  now process reduce
      
       let avg=0;
        for(let j=0;j<moDiff.length;j++){
        
          

        if(props.reportPerformance =='month'){
              data[`${moment(moDiff[j].start).format('MMMM')}`]= deptList.reduce((prev,current)=>prev + current[`${moment(moDiff[j].start).format('MMMM')}`],0 );
              data[`${moment(moDiff[j].start).format('MMMM')}_threeshold`]=deptList.reduce((prev,current)=>prev + current[`${moment(moDiff[j].start).format('MMMM')}_threeshold`],0);
              data[`${moment(moDiff[j].start).format('MMMM')}_complaince`] = deptList.reduce((prev,current)=>prev +current[`${moment(moDiff[j].start).format('MMMM')}_complaince`],0) / deptList.length;             
              
              avg = avg + parseFloat(data[`${moment(moDiff[j].start).format('MMMM')}_complaince`]);

          }else{ 
              data[`${moDiff[j].weekName}`]= deptList.reduce((prev,current)=>prev + current[`${moDiff[j].weekName}`],0 );
              data[`${moDiff[j].weekName}_threeshold`]=deptList.reduce((prev,current)=>prev + current[`${moDiff[j].weekName}_threeshold`],0);
              data[`${moDiff[j].weekName}_complaince`]= deptList.reduce((prev,current)=>prev +current[`${moment(moDiff[j].start).format('MMMM')}_complaince`],0) / deptList.length;
              avg = avg + parseFloat(data[`${moDiff[j].weekName}_complaince`]);
              }
            // data.push(deptSingle);
       }
       
       data.daysCount = deptList.reduce((prev,current)=>prev + current.daysCount,0)/deptList.length;
       data.monthAverage =  deptList.reduce((prev,current)=>prev + current.monthAverage,0)/deptList.length;
    return data;    
      }      

      function AGGridEMptyMEssage(key){
        let msg;
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        return msg
      }




const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        heigh:"90%",
        maxHeight: '95%'
    },
};


async function sendEmailHandler(){

  const iframe = document.querySelector('.iframeEmail');
  iframe.style.display="none";
  iframe.style.display="none";
  iframe.height="0px";
  iframe.src=""
  SetLoaderPreview(true);

        let removeCache= '<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" /><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Expires" content="0" />'
        let rawEmail = emailData;
        rawEmail.replace(removeCache,"");
        let dateStart = reportDate.split(",")
       

        const data = {
                subject: 'Governance Report of '+moment(dateStart[0]).format("MMMM"),
                html_content: rawEmail,
                email: sendToEMail,
            };

        testingEmail(data).then(res=>{
          
          if(res?.data?.message){
            toast.success("Email Sent Sucess");
          }else{
            toast.error("Fail to Sent report");
          }
          

          SetModalPreview(false);
        })

      }

      return (
  
        props.filterEnableCycle?<>
         <div className="reportChart">
<ToastContainer/>
          {/* <EmailGovernance/> */}
                    <ReactModal
                isOpen={emailModal}
                style={customStyles}
                onRequestClose={()=>props.closeEmailModal()}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-share "}>
                    <div className="modalShareHeader">
                             <h4>Share governance report<span style={{float:"right",cursor:"pointer"}} onClick={()=>props.closeEmailModal()}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 18L18 6M6 6L18 18" stroke="#364141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg> </span></h4>      
                    </div>
                    <div className="modalShareBody">
                      <Row mb={10}>
                          <Col lg={12}><label>Reporting Period *</label></Col>
                          <Col lg={12}><Select
                            onChange={(e)=>{SetReportDate(e);}}
                            width={"100%"}
                            value={reportDate}
                            className="form-control form-control-my"
                          >
                        
                          {allMonth.map(el=>{

                              return <Option value={el.start+","+el.end} key={el.start+el.end}>{moment(el.start).format('MMMM YYYY')+" : "+ moment(el.start).format('DD MMM')+" - "+moment(el.end).format('DD MMM')}</Option>
                          }
                            
                            ) }
                            </Select>
                          </Col>
                      </Row>
                      
                       <Row mb={10}>
                          <Col lg={12}><label>Send To *</label></Col>
                          <Col lg={12}><input type="text" value={sendToEMail} className={sendToEMailError?"form-control error":"form-control"} onChange={(e)=>SetSendToEmail(e.target.value)} /></Col>
                      </Row>
                      
                    </div>
                    <div className="modalShareFooter btndiv">
                      <button className="btn btn-transaparent" style={{marginRight:"10px"}} onClick={()=>SetEmailModal(false)}>Cancel</button>
                      <button className="btn btn-submit btn-filter" onClick={()=>{emailPreviewHandler()}}>Preview</button>
                    </div>
                               
                   </div>
            </ReactModal>


              <ReactModal
                isOpen={modalPreview}
                style={customStyles}
                onRequestClose={()=>SetModalPreview(false)}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-share "}>
                    <div className="modalShareHeader">
                             <h4>Share governance report<span style={{float:"right",cursor:"pointer"}} onClick={()=>SetModalPreview(false)}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 18L18 6M6 6L18 18" stroke="#364141" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </span></h4>      
                    </div>
                    <div className="modalShareBody">
{/* {emailData} */}
{/* <div dangerouslySetInnerHTML={{ __html: emailData }} /> */}
{/* <div id="firstChart"></div>
<div id="secondChart"></div>
<div id="thirdChart"></div> */}

{loaderPreview?<div style={{textAlign:"center"}}><img src={spinnerLoader} style={{marginTop:"50px"}} /></div>:""}

 <iframe
      title="Lazada Report"
      // srcDoc={""}
      width="100%"
      height="0px"
      frameBorder="0"
      className="iframeEmail"
    />
    
    
    
                    </div>
                    <div className="modalShareFooter btndiv">
                      <button className="btn btn-transaparent" onClick={()=>SetModalPreview(false)} style={{marginRight:"10px"}}>Cancel</button>
                      <button className="btn btn-submit btn-filter" disabled={loaderPreview} onClick={()=>{sendEmailHandler()}}>Send Email</button>
                    </div>
                               
                   </div>
            </ReactModal>


      
      

         {isLoading?
          <div className="text-center loader-filter ">
                                      
                                      <div className="loadingInfo loadingInfo2" style={{marginBottom:"20px"}}>
                                    
                                        <img src={spinnerLoader} /> 
                                      </div>
                                        
                                  </div>:<>
                                  <div className="dividerSection">
                                  <h4>Worker Governance</h4>
                                  {showDataDefault}
                                  </div>
                                   <div className="dividerSection">
                                  <h4>Agency Governance</h4>
                                  {showDataAgency}
                                  </div>

                                   <div className="dividerSection">
                                  <h4>Function Governance</h4>
                                  {showDataFunction}
                                    </div>
                                  <div className="dividerSection">
                                  <h4>Process Governance</h4>
                                  {/* {showDataProcess} */}
                                  {allProcess.length > 0 && allDataPeformance.length > 0 ?<ProcessDetails process={allProcess} allData={allDataPeformance}/>:<h4>No Process Found</h4>}
                                  </div>
                                  

                                  </> 
        }
        
        </div>
        </>:""
      )

}