import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import { Select, Slider } from 'antd';
import { getProcessList } from "../../actionMethods/actionMethods";

export default function MovementPlanActualFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [selectedDepartment,SetSelectedDepartemnt] = useState(props.department.map(el=>el.name));
    const [department,SetDeparment]= useState(props.department);
    const [employee,SetEmployee]=useState([]);
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [initial,SetInitial]= useState(false)

    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    

        let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;

    useEffect(()=>{
        if(dates.start && dates.end){
        getProcessList(userSession,org_id,sub_org_id,moment(dates.start).format('YYYY-MM-DD'),moment(dates.end).format('YYYY-MM-DD')).then(res=>{
          SetSelectedDepartemnt(res.data.map(el=>el.process_name));
          SetDeparment(res.data.map(el=>el.process_name));
          SetInitial(true);  
        })

          }
      
        
      
      
    },[dates])

    

    function filterResultHandler(){
        SetFilterFOP(true);
        let start= workday.start_hour.split(":");
        let end =workday.end_hour.split(":");
        
        let requestBody={
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.end).format('YYYY-MM-DD') ,

          process:selectedDepartment,        
          employee:employee.length===0?props.employee:employee
        }
        

        props.filterResultHandler(requestBody,true);
        
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])
    useEffect(()=>{

      setDate((prev)=>({...prev,['start']:props.date,['end']:props.date}) )
    },[props.date])



function onChangeDepartment(val){

  let dept= [...department];

  if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<dept.length;i++){
        value.push(dept[i])
      }

      if((val.length -1) == value.length ){
        SetSelectedDepartemnt([]);  
        SetEmployee([])

      }else{
        // SetEmployee(props.employee)
        SetSelectedDepartemnt(value);
      }

      
  }else{

    // SetEmployee(props.employee.filter(el=>val.indexOf(el.name) > -1))
    SetSelectedDepartemnt(val);
  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
  // SetExportEnable(false);
}


    
    
    return (
<Row className="">

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        //maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        maxDate={dates.end}
                        
                      />
                   
                    </div>
                  </Col>
                                    <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        value={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={dates.start}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={4} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Process")}
                      </label>
                        <Select
                          onChange={(e)=>{SetExportEnable(false);props.filterResultHandler({},false);onChangeDepartment(e)}}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={selectedDepartment}
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          {/* {props.department?props.department.map((el,index)=> <Select.Option key={"dept"+index+el.name} value={el.name}>{el.name}</Select.Option>):""} */}
                          {department.map((el,index)=>{
                                return <Select.Option key={"dept"+index+el} value={el}>{el}</Select.Option>
                          })}
                        
                        </Select>
                  </Col>


<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start=='' || selectedDepartment.length === 0 }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                {/* <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={true} >Export</button> */}
                </div>
</Col>


                </Row>
                )

}