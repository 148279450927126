import React from 'react'
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts-exporting'
import moment from "moment";

HighchartsExporting(ReactHighcharts.Highcharts)

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}


const StackChart = (props) => {
  

    let type= props.categories.length < 7?'week':'month';
    let config = {
        chart: {
            type: 'column',
            height: 270,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: props.categories,
            labels : {
                formatter:function(){
              
                   return type=="week"?moment(this.value).format('ddd, MMM DD'):moment(this.value).format('DD MMM');
                }

              },

              tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment;
                if(type=='week'){
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?2:2);
                }else{
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?6:7);
                }
                
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min: 0,
            max:100,
            labels: {
                enabled: false
            },
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },        
        tooltip: {
            useHTML: true,
            formatter:  function () {
                 return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${props.type=='week'?moment(this.x).format("ddd, MMM DD"):moment(this.x).format("DD MMM")}</span><br/>
                         <span dx="0" class="tool-tip-name">${this.series.name} : </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y.toFixed(2)}</tspan>`;                         
                } ,
        },
        // plotOptions: {
        //     column: {
        //         stacking: 'normal',
        //         dataLabels: {
        //             enabled: false
        //         }
        //     }
        // },

        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: props.helpEnable?true:false,
                    formatter: function() {
                        
                        return this.y.toFixed(0);
                    },
                    style: {
                    fontWeight: 'bold',
                    fontSize:"10px",
                    color: '#32325d',   
                }
                
            },
                 
        },

                },


        series: props.series
    }
    

    return (
        <ReactHighcharts config={config} />
    )
}

export default StackChart