import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';


import downThumbIcon from '../../assets/traceplusImages/orange_thumb.svg.svg'
import dashboardPeopleImage from '../../assets/traceplusImages/threat_watch_orange_img.svg'
import greenThumbIcon from '../../assets/traceplusImages/green_thumb.svg'
import helpIcon from '../../assets/traceplusImages/help-icon.png'

import dropdownIcon from '../../assets/images/down-arrow.png'
import DatePicker from "react-datepicker";
import { getLanguageTranslation } from '../actionMethods/actionMethods';
import { getTranslatedText } from '../../common/utilities';

import { Slider } from 'antd';

function ThreatWatch(props) {



    function handleOnChangeContactRankValue(event) {
        props.handleChangeValue(event.target.value)
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }

    const marks = {
        0: 'Low',
        1: 'Medium',
        2: 'High', 
      };

function HandleRange(e){
    Setsensitivity(e)
    props.SensitiveVal(e)
}

      const [sensitivity,Setsensitivity]=useState(props.sensitive);

    return (
        <div className={'threatWatchMainDiv ' + (props.contaminatedEmployeeCount <= 0 ? ' greenGradienColor' : ' orangeGradientColor')} >
            <Row>
                <Col lg={3}>
                    <div className="threatWatchTextDiv">
                        <div className="title">
                            {getTranslatedText('Threat Watch')}
                        </div>
                        <div className="subTitle">As of {moment(props.selectedDate).format('Do MMM YYYY')}</div>

                        <div className="workSpaceStripeDiv">
                            {
                                // props.threatWatchColor && props.threatWatchColor == 'green' ? getTranslatedText('Hey, Your Workplace Is Safe !') : getTranslatedText('Hey, your Workplace is Not Safe!')
                                props.contaminatedEmployeeCount <= 0 ?'Hey, Your Workplace Is Safe !' : 'Hey, Your Workplace Is Not Safe!'
                            }
                            <div className={'thumbIconDiv greenBorderColor ' + (props.contaminatedEmployeeCount > 0 ? 'red':' green' )}>
                                
                                {/* <img src={props.threatWatchColor && props.threatWatchColor == 'green' && props.contaminatedEmployeeCount > 0 ? downThumbIcon : greenThumbIcon } /> */}
                                <img src={props.contaminatedEmployeeCount <= 0 ? greenThumbIcon : downThumbIcon } />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6} className="parent-lg-6">
                    <div className="threatWatchAnalyticsMainDiv">
                        <div className="eachColumnDiv contaminatedMainDiv">

                            <div className="contaminatedDetails" style={{width : "45%"}}>
                                <div className="titleText font-bold">{getTranslatedText('Contaminated')}</div>
                                

                                <div className="eachRecordDiv">
                                    <div className="font-bold countDiv" onClick={() => props.openEmployeePopup('contaminated')} style={{cursor:"pointer"}}  >{props.contaminatedEmployeeCount}</div>
                                    <div className="labelDiv" onClick={() => props.openEmployeePopup('contaminated')} style={{cursor:"pointer"}} >{getTranslatedText('Employees')}
                                    <img alt='' className='ds-helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`ContaminatedHelp`)} onMouseLeave={() => handleMouseLeave(`ContaminatedHelp`)} />
                                    </div>
                                    <div className='descHelp' id='ContaminatedHelp'>Infected people.</div>
                                </div>
                                
                            </div>

                            <div className="contaminatedDetails" style={{width : "55%"}}>
                                <div className="titleText font-bold">{getTranslatedText('At Risk')}</div>
                                <div className="eachRecordDiv m-t">
                                    <span className="font-bold countDiv"  onClick={() => props.openEmployeePopup('risk')} style={{cursor:"pointer"}} >{props.rank2Data.length>0?props.rank2Data.length:props.atRiskCount}&nbsp;</span>
                                    <span className="labelDiv" onClick={() => props.openEmployeePopup('risk')} style={{cursor:"pointer"}}>{getTranslatedText('Employees')}
                                    <img alt='' className='ds-helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`threatEmployeeHelp`)} onMouseLeave={() => handleMouseLeave(`threatEmployeeHelp`)} />
                                    </span>
                                    <div className='descHelp' id='threatEmployeeHelp'>List en people who are at risk because of a contact with an infected individual.</div>
                                </div>

                                <div className="eachRecordDiv">
                                    <span className="font-bold countDiv"  onClick={props.openLocationPopup} style={{cursor:"pointer"}}>{props.orgCount}&nbsp;</span>
                                    <span className="labelDiv" onClick={props.openLocationPopup} style={{cursor:"pointer"}}>{getTranslatedText('Locations')}
                                    <img alt='' className='ds-helpicon' src={helpIcon} onMouseEnter={() => handleMouseEnter(`threatLocationHelp`)}  onMouseLeave={() => handleMouseLeave(`threatLocationHelp`)} />
                                    </span>
                                    <div className='descHelp' id='threatLocationHelp'>List en locations visited by an infected individual.</div>
                                </div>

                            </div>
                        </div>
                        <div className="eachColumnDiv contactRankStartDateEndDateMainDiv">
                            <Row>
                                <Col lg={6}>
                                    <div className="contactRankText m-t">{getTranslatedText('Contact Rank')}</div>
                                    <div className="contactRankSelectDropdownDiv">
                                        {/* <div className="dropdownIconDiv">
                                            <img src={dropdownIcon} />
                                        </div> */}
                                        <select onChange={handleOnChangeContactRankValue}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                </Col>
                                
                                <Col lg={6}>
                                    <div className={"contactRankText m-t contactRankText-"+sensitivity}>
                                        {getTranslatedText('Interaction Time')}</div>
                                     <div className={"contactRankText  contactRank-range contactRank-range-"+sensitivity}> 
                                          <Slider
                                                marks={marks}
                                                defaultValue={sensitivity} max={2} min={0} 
                                                tooltipVisible={false} 
                                                onChange={HandleRange}
                                                />
                                        </div>
                                </Col>

                            </Row>

                            <Row className="m-t-xs">
                                <Col lg={6} className="p-r-0 start-date-threat">
                                    <div className="contactRankText">{getTranslatedText('Start Date')}</div>
                                    <div className="startDateEndDateMainDiv">
                                        <DatePicker
                                            selected={props.startDate}
                                            onChange={date => props.handleSelectStartDate(date)}
                                            dateFormat={'MMM dd'}
                                            isClearable={false}
                                            maxDate={moment(props.endDate).subtract(1, 'days').toDate()}
                                            minDate={new Date('2023-01-01')}
                                        />
                                        {/* <div className="dropdownIconDiv">
                                            <img src={dropdownIcon} />
                                        </div> */}
                                    </div>
                                </Col>

                                <Col lg={6} className="p-l-0 end-date-threat">
                                    <div className="contactRankText">{getTranslatedText('End Date')}</div>
                                    <div className="startDateEndDateMainDiv">
                                        <DatePicker
                                            selected={props.endDate}
                                            onChange={date => props.handleSelectEndDate(date)}
                                            dateFormat={'MMM dd'}
                                            isClearable={false}
                                            maxDate={props.selectedDate}
                                            minDate={props.startDate}
                                        />
                                        {/* <div className="dropdownIconDiv">
                                            <img src={dropdownIcon} />
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </Col>

                <Col lg={3}>
                    <div className="dashboardPeopleImageMaiDiv">
                        <img src={dashboardPeopleImage} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ThreatWatch