import React,{useState,useEffect} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment";
import {getPolicyOverview} from '../actionMethods/actionMethods'
import { getTranslatedText } from "../../common/utilities";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PolicyList from "./list/policyTable";


function PolicyCompliance(props){
    let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
    let interval = 5;

    const [selectedLangValue, updateSelectedLangValue] = useState("en");
  
  
    const [selectedDate, updateSelectedDate] = useState(props.date?props.date:date);
    const [dates, setDates] = useState({
      start: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD 23:59:59')),
      end: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD 23:59:59')),
    });
  
    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
  
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
    useEffect(()=>{
   
      updateSelectedDate(props.date)
      setDates({
        start: new Date(moment(props.date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(props.date).format('YYYY-MM-DD 23:59:59')),
      })
    },[props.date])

    const handleDate = (date, type) => {
        setDates((prev) => ({ ...prev, [`${type}`]: date }));

        
      };    
      useEffect(()=>{
//        props.PolicyComplianceDateChange(dates);
      },[dates])






    return (<div className="white-bg wrapper policy-management-date areaIndexChartMainDiv">


            <Row className="" style={{zIndex:999,position:'relative'}}>
                <Col lg={6}>
                <h5 className="font-bold">Policy Compliance</h5>
                    </Col>
                          <Col lg={3} className="p-r-0 p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                          <Col lg={3} className="p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-end"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                             
                            </div>
                          </Col>
                        </Row>
                        <PolicyList end_date={dates.end} start_date={dates.start} level="all"/>

    </div>)

}

export default PolicyCompliance;