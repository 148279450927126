import { pipe, A, D, F } from '@mobily/ts-belt';
import pointInPolygon from 'point-in-polygon';

export const boundingBox2d = (coordinates) =>
  pipe(
    coordinates,
    A.reduce(
      {
        xMin: Infinity,
        xMax: -Infinity,
        zMin: Infinity,
        zMax: -Infinity,
      },
      ({ xMin, xMax, zMin, zMax }, pt) => ({
        xMin: Math.min(xMin, pt.x),
        xMax: Math.max(xMax, pt.x),
        zMin: Math.min(zMin, pt.z),
        zMax: Math.max(zMax, pt.z),
      })
    ),
    D.set('levelIndex', coordinates[0].levelIndex)
  );

export const computeCenter = (coordinates) => {
  const bb = boundingBox2d(coordinates);
  return {
    x: (bb.xMax + bb.xMin) / 2,
    z: (bb.zMax + bb.zMin) / 2,
    levelIndex: coordinates[0].levelIndex,
  };
};

export const computeGrid = ({ bb, gridSize, gridGap, elevation = 3 }) => {
  const xSpan = bb.xMax - bb.xMin;
  const xCells = Math.ceil(xSpan / gridSize);
  const xOffset = (xCells * gridSize - xSpan) / 2;
  const x0 = bb.xMin - xOffset;
  const zSpan = bb.zMax - bb.zMin;
  const zCells = Math.ceil(zSpan / gridSize);
  const zOffset = (zCells * gridSize - zSpan) / 2;
  const z0 = bb.zMin - zOffset;

  const grid = pipe(
    A.range(0, xCells - 1),
    A.map((xi) =>
      pipe(
        A.range(0, zCells - 1),
        A.map((zi) => ({
          id: `${xi}-${zi}`,
          coordinates: [
            {
              x: x0 + xi * gridSize + gridGap / 2,
              z: z0 + zi * gridSize + gridGap / 2,
              levelIndex: bb.levelIndex,
            },
            {
              x: x0 + (xi + 1) * gridSize - gridGap / 2,
              z: z0 + zi * gridSize + gridGap / 2,
              levelIndex: bb.levelIndex,
            },
            {
              x: x0 + (xi + 1) * gridSize - gridGap / 2,
              z: z0 + (zi + 1) * gridSize - gridGap / 2,
              levelIndex: bb.levelIndex,
            },
            {
              x: x0 + xi * gridSize + gridGap / 2,
              z: z0 + (zi + 1) * gridSize - gridGap / 2,
              levelIndex: bb.levelIndex,
            },
          ],
          position: {
            x: x0 + (xi + 0.5) * gridSize,
            z: z0 + (zi + 0.5) * gridSize,
            levelIndex: bb.levelIndex,
            elevation,
          },
        }))
      )
    ),
    A.flat,
    F.toMutable
  );

  return grid;
};

const coordinateToTuple = (pt) => [pt.x, pt.z];

export const isPointInPolygon = (pt, polygon) =>
  pointInPolygon(coordinateToTuple(pt), polygon.map(coordinateToTuple));
