import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button, Switch } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import ExportPdf from "../../assets/images/exportPdf.png";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import BarChart from "../../siteManagement/components/barChart";
import ChartReportMultiAxis from "./ChartReportMultiAxis";
import { Select   } from 'antd';
import { getDashboardReportOverview } from "../actionMethods/actionMethods";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import Highcharts from "highcharts";
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
// import dummyReportOverview from '../../assets/dummyReportOverview.json'
const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};


function ReportOverview(props) {
  const { Option } = Select;
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
     let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6; 
  if(parseInt(userDetails.org_id)== 31 && date < new Date('2023-08-01') ){
    date =  new Date();    
  }
  const [selectedDate, updateSelectedDate] = useState(date);
  const [reportby,SetReportBy] =useState('day');
  
  const [chartreportby,SetChartReportBy] =useState('percent');
  const [chartSortBy,SetChartSortBy]=useState('working_hour');
  
  const [departmentLower,SetDepartmentLower] =useState([]);
  const [subDepartmentLower,SetSubDepartmentLower] =useState([]);
  const [empNeedAttention,SetEmpNeedAttention] = useState([]);
    
  let yaxis =[{ 
    labels: {
        format: '{value} Nos',
        style: {
            color: Highcharts.getOptions().colors[0]
        }
    },
    title: {
        text: 'Total Count',
        style: {
            color: Highcharts.getOptions().colors[0]
        }
    },
    opposite: true
},{ 
  labels: {
      format: '{value}hrs',
      style: {
          color: Highcharts.getOptions().colors[1]
      }
  },
  title: {
      text: 'Total Hours',
      style: {
          color: Highcharts.getOptions().colors[1]
      }
  },
  opposite: true
},
{ 
      labels: {
          format: '${value}',
          style: {
              color: Highcharts.getOptions().colors[2]
          }
      },
      title: {
          text: 'Total Bill',
          style: {
              color: Highcharts.getOptions().colors[2]
          }
      },
      opposite: true
  },
  
{ 
  labels: {
      format: '{value}%',
      style: {
          color: Highcharts.getOptions().colors[1]
      }
  },
  title: {
      text: 'Percent',
      style: {
          color: Highcharts.getOptions().colors[1]
      }
  },
  opposite: true
},

]

  const [chartData,SetChartData]=useState({series:[],categories:[],yAxis:yaxis,total:[]});
  const [chartDataReport,SetChartDataReport]=useState({series:[],categories:[],yAxis:[],total:[]});
  
  const [chartDataCompare,SetChartDataCompare]=useState({series:[],categories:[],yAxis:[],total:[]});


  const [compareFilter1,SetCompareFilter1]=useState('percent');
  // const [compareFilter1,SetCompareFilter1]=useState('percent');
  const [compareFilter2,SetCompareFilter2]=useState('all');
  // const [compareFilter3,SetCompareFilter3]=useState('total_gained');
  const [compareFilter3,SetCompareFilter3]=useState('logged');

  const [chartDataBadges,SetChartDataBadges]=useState({series:[],categories:[],yAxis:[],total:[]});
  const [chartDataRating,SetChartDataRating]=useState({series:[],categories:[],yAxis:[],total:[]});
  
  
  const [badgeBy,SetBadgeby]=useState('percent');
  const [ratingBy,SetRatingby]=useState('percent');
  const [ratingfor,SetRatingfor]=useState('all');
  const [badgeChart,SetBadgeChart]=useState('all')


  const [goldCount,SetGoldCount]=useState(0)
  const [silverCount,SetSilverCount]=useState(0)
  const [noBadgeCount,SetNoBadgeCount]=useState(0)
  
  const [fiveCount,SetFiveCount]=useState(0)
  const [fourCount,SetFourCount]=useState(0)
  const [threeCount,SetThreeCount]=useState(0)
  const [twoCount,SetTwoCount]=useState(0)
  const [oneCount,SetOneCount]=useState(0)
  const [zeroCount,SetZeroCount]=useState(0)

  const [department,SetDepartment]=useState([])
  const [subdepartment,SetSubDepartment]=useState([])

  const [topPerformBy,SetTopPerformBy]=useState('all');
  const [lowPerformBy,SetLowPerformBy]=useState('all');
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [dates, setDates] = useState({
    start: parseInt(userDetails.org_id)==31 && moment(date).subtract(28, "days").toDate() < new Date('2023-08-01') ?new Date('2023-08-01'): moment(date).subtract(28, "days").toDate(),
    // start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");
  const [selectedTab, updatedSelectedTab] = useState("employees");
  
  const [regularCount,SetRegularCount]=useState(0);
  const [regularWorkingHour,SetRegularWorkingHour]=useState(0);
  const [regularWastedHour,SetRegularWastedHour]=useState(0);
  const [dailyCount,SetDailyCount]=useState(0);
  const [dailyWorkingHour,SetDailyWorkingHour]=useState(0);
  const [dailyWastedHour,SetDailyWastedHour]=useState(0);
  const [conversionRate,SeConversionRate]=useState(1.5);
  const [conversionDailyCount,SeConversionDailyCount]=useState(1);
  const [conversionDailyWastedCount,SeConversionDailyWastedCount]=useState(1);
  const [conversionRegularCount,SeConversionRegularCount]=useState(1);
  const [conversionRegularWastedCount,SeConversionRegularWastedCount]=useState(1);
  
  const [percentRegularWorkHour,SetPercentRegularWorkHour]=useState(0);
  const [percentRegularWastedHour,SetPercentRegularWastedHour]=useState(0);

  const [displayError,SetDisplayError]=useState(false);




  const [percentDailyWorkHour,SetPercentDailyWorkHour]=useState(0);
  const [percentDailyWastedHour,SetPercentDailyWastedHour]=useState(0);

  const [totalBillRegularHrs,SetTotalBillRegularHrs]=useState(0)
  const [totalBillDailyHrs,SetTotalBillDailyHrs]=useState(0)

  const [totalShiftRegular,SetTotalShiftRegular]=useState(0)
  const [totalShiftDaily,SetTotalShiftDaily]=useState(0)

  const [activeTab,SetActiveTab] =useState("overview");
  const [chart1,SetChart1]=useState(true);
  const [chart1Delay,SetChart1Delay]=useState(true);
  const [chart2,SetChart2]=useState(true)
  const [chart3,SetChart3]=useState(true)

  const [topPerformerReg,SetTopPerformerReg] =useState([]);
  const [topPerformerDaily,SetTopPerformerDaily] =useState([]);
  const [topPerformer,SetTopPerformer]=useState([]);
  const [topPerformerDept,SetTopPerformerDept] =useState([])
  const [topPerformerSubDept,SetTopPerformerSubDept] =useState([])
  const [topFunction,SetTopFunction]=useState('percentage')
  const [bottomFunction,SetBottomFunction]=useState('percentage')
  const [topFunctionBy,SetTopFunctionBy]=useState('department')
  const [bottomFunctionBy,SetBottomFunctionBy]=useState('department')


  const[regualrSeries,SetRegularSeries]=useState([])
  const[dailySeries,SetDailySeries]=useState([])
  const [agencyList,SetAgencyList] = useState([]);
  const [agency,SetAgency] = useState([]);
  

  let lastReqId = null
  let StarFill =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f2bc19"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM351.9 311.8l33.72 103.9l-88.25-64.18c-5.61-4.08-13.21-4.08-18.82 0L190.3 415.6l33.72-103.9c2.139-6.589-.2053-13.81-5.808-17.88L129.9 229.6h109.2c6.933 0 13.08-4.465 15.22-11.06L288 114.8l33.7 103.8c2.141 6.594 8.285 11.06 15.22 11.06h109.2l-88.34 64.24C352.2 297.1 349.8 305.2 351.9 311.8z"/></svg>`;
  let StarEmpty =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#b5b1a6"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"/></svg>`;
  let series=[];
  const [dataAll,SetDataAll]=useState([]);
  const monthInt= ['','Jaunary',"February","March","April","May","June","July","August","September","October","November","December"]
  const month=[
    {month:"Jan",days:31,intMonth:1},
    {month:"Feb",days:28,intMonth:2},
    {month:"Mar",days:31,intMonth:3},
    {month:"Apr",days:30,intMonth:4},
    {month:"May",days:31,intMonth:5},
    {month:"Jun",days:30,intMonth:6},
    {month:"Jul",days:31,intMonth:7},
    {month:"Aug",days:31,intMonth:8},
    {month:"Sep",days:30,intMonth:9},
    {month:"Oct",days:31,intMonth:10},
    {month:"Nov",days:30,intMonth:11},
    {month:"Dec",days:31,intMonth:12},
]
let ReportTitle=[
  {org_id:28,title:"Report Lazada GRO - Sortation",sub_org_id : 0},
  {org_id:30,title:"Report Lazada CMG - Bulky",sub_org_id:1},
  {org_id:30,title:"Report Demo",sub_org_id:0},
  {org_id:30,title:"Report Lazada CMG - MWH",sub_org_id:2},
  {org_id:32,title:"Report Lionparcel MegaHub",sub_org_id:2}
]

const [reportTitle,SetReportTile]=useState("Report");
useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.sub_page_name=="Report Download"); 
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);

useEffect(()=>{
  if(permission.length > 0){
      let rT= ReportTitle.find(item=>item.org_id==org_id && item.sub_org_id==sub_org_id);
      if(rT){
        SetReportTile(rT.title)
      }
  }
},[permission])
  
  function ratingCal(val){
    if(parseFloat(val)>= 90){
        return 5
    }else if(parseFloat(val)>= 80 && parseFloat(val)< 90){
        return 4
    }
    else if(parseFloat(val)>= 70 && parseFloat(val)< 80){
        return 3
    }
    else if(parseFloat(val)>= 60 && parseFloat(val)< 70){
        return 2
    }
    else if(parseFloat(val)>= 50 && parseFloat(val) < 60){
        return 1
    }else{
        return 0
    }

  }

  function ConvertSectoDay(n) {
    let day =parseInt( n / (24 * 3600));

    n = n % (24 * 3600);
    let hour = parseInt(n / 3600);

    n %= 3600;
    let minutes = n / 60;

    n %= 60;
    let seconds = n;

    return ((day*24) + hour + "hr "  
            + minutes.toFixed() +  "min"  );
            
}

function asHours(val){
  let sp= val.split(':');
  let day=0
  let hour=0
  let sec= 0
  let min=0
  if(sp.length==4){
   day = 86400 * parseFloat(sp[0])
      hour = 3600 * parseFloat(sp[1])
  min = 60 * parseFloat(sp[2])
  sec =  parseFloat(sp[3])
  }else{
    hour = 3600 * parseFloat(sp[0])
    min = 60 * parseFloat(sp[1])
    sec =  parseFloat(sp[2])

  }

return day+hour+min+sec;
}

const isWeekday = (date) => {
  let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
  let holiday=props.holiday?props.holiday:[]
  const day = moment(date).isoWeekday();

  if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
      return     
  }else{
  return day ;
  }   
};


  useEffect(()=>{
    if(permission.length > 0){
      SetChart1(true)
      let requestBody={};
      requestBody.start_date=getDateFormat(dates.start)
      requestBody.end_date=getDateFormat(dates.end)
      requestBody.agency=agency.length> 0 ?agency.join(","):'all'
      let deptPercentage=[];
      let dailyAttennce =0;
      let regularAttennce =0;
      let regHour=0
      let dailyHour=0
      let dailyWasted=0
      let regularWasted=0

      let percentRegWkHr=0
      let percentDailyWkHr=0
      let percentDailyWastedHour=0
      let percentRegWastedHr=0
      let sr1 =0,sr2=0,sr3=0,sr4=0,sr5=0,sr0=0
      let gCount=0,sCount=0,nCount=0; 
      let regsfthrs=0,dailysfthrs=0;

      let subDepartmentAvg=[];
      let empAgency= [];
    
      // let res = dummyReportOverview
      getDashboardReportOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{
      
        let data=[]

        let startDate = new Date(dates.start)
        let endDate = new Date(dates.end)
      let  dayLoop=0;
        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          let loopDate= startDate
          let dataCheck= res.data[moment(loopDate).format('YYYY-MM-DD')]?res.data[moment(loopDate).format('YYYY-MM-DD')]:null
          if(moment(loopDate).isoWeekday() == 7 || dataCheck===null){
        
            data.push({
              "date": moment(loopDate).format('YYYY-MM-DD'),
              "daily_attendance": 0,
              "regular_attendance": 0,
              "regular_working_hour": "00:00:00",
              "regular_shift_hour": "00:00:00",
              "daily_working_hour": "00:00:00",
              "daily_shift_hour": "00:00:00",
              "regular_wasted_hour": "00:00:00",
              "daily_wasted_hour": "00:00:00",
              "regular_working_percentage": 0,
              "daily_working_percentage": 0,
              "regular_waste_percentage": 0,
              "daily_waste_percentage": 0,
              "regular_working_total_hour": "00:00:00",
              "daily_working_total_hour": "00:00:00",
              "department_working_time": "00:00:00",
              "departments_need_attention": {},
              "department_productivity_percentage": {},
              "sub_department_working_time": {},
              "sub_department_productivity_percentage": {},
              "regular_silver_badge": 0,
              "daily_silver_badge": 0,
              "regular_gold_badge": 0,
              "daily_gold_badge": 0,
              "regular_no_badge": 0,
              "daily_no_badge": 0,
              "regular_five_star_percent": 0,
              "regular_four_star_percent": 0,
              "regular_three_star_percent":0,
              "regular_two_star_percent": 0,
              "regular_one_star_percent": 0,
              "regular_zero_star_percent": 0,
              "regular_five_star_count": 0,
              "regular_four_star_count": 0,
              "regular_three_star_count": 0,
              "regular_two_star_count": 0,
              "regular_one_star_count": 0,
              "regular_zero_star_count": 0,
              "daily_five_star_percent": 0,
              "daily_four_star_percent": 0,
              "daily_three_star_percent": 0,
              "daily_two_star_percent": 0,
              "daily_one_star_percent": 0,
              "daily_zero_star_percent": 0,
              "daily_five_star_count": 0,
              "daily_four_star_count": 0,
              "daily_three_star_count": 0,
              "daily_two_star_count": 0,
              "daily_one_star_count": 0,
              "daily_zero_star_count": 19,
              "list_of_top_twenty_performers":[]
            });
          }else{
            let singleRecord={}
            //date
            singleRecord.date=moment(loopDate).format('YYYY-MM-DD')
            //attendance
            singleRecord.daily_attendance= dataCheck.daily_worker.attendance
            dailyAttennce = dailyAttennce+ dataCheck.daily_worker.attendance;
            singleRecord.regular_attendance= dataCheck.regular_worker.attendance
            regularAttennce=regularAttennce+ dataCheck.regular_worker.attendance
    
            //working_hour
            singleRecord.regular_working_hour=dataCheck.regular_worker.working_hours
            singleRecord.regular_shift_hour=dataCheck.regular_worker.total_shift_hours
            regHour= regHour+ moment.duration(dataCheck.regular_worker.working_hours).asHours();
            // regHour= regHour+ asHours(dataCheck.regular_worker.working_hours);
    
            singleRecord.daily_working_hour=dataCheck.daily_worker.working_hours
            singleRecord.daily_shift_hour=dataCheck.daily_worker.total_shift_hours
            dailyHour = dailyHour + moment.duration(dataCheck.daily_worker.working_hours).asHours();
            //dailyHour = dailyHour + asHours(dataCheck.daily_worker.working_hours);
    
            //wasted_hour
            singleRecord.regular_wasted_hour=dataCheck.regular_worker.waste_hours
            singleRecord.daily_wasted_hour=dataCheck.daily_worker.waste_hours
            regularWasted = regularWasted + moment.duration(dataCheck.regular_worker.waste_hours).asHours();
            // regularWasted = regularWasted + asHours(dataCheck.regular_worker.waste_hours);
            dailyWasted=dailyWasted +  moment.duration(dataCheck.daily_worker.waste_hours).asHours();
            // dailyWasted=dailyWasted +  asHours(dataCheck.daily_worker.waste_hours);
    
            
            //working_percentage
            singleRecord.regular_working_percentage=dataCheck.regular_worker.working_percentage

            if(dataCheck.regular_worker.working_percentage!=0){
              if(percentRegWkHr==0){
                percentRegWkHr =  (percentRegWkHr + dataCheck.regular_worker.working_percentage)  
              }else{
                percentRegWkHr =  (percentRegWkHr + dataCheck.regular_worker.working_percentage)/2
              }
              
            }
            if(dataCheck.daily_worker.working_percentage!=0){
              if(percentDailyWkHr==0){
                percentDailyWkHr=  (percentDailyWkHr + dataCheck.daily_worker.working_percentage)  
              }else{
                percentDailyWkHr=  (percentDailyWkHr + dataCheck.daily_worker.working_percentage)/2
              }
              
            }
            singleRecord.daily_working_percentage=dataCheck.daily_worker.working_percentage
    
            //waste_percentage
            singleRecord.regular_waste_percentage=dataCheck.regular_worker.waste_percentage
            singleRecord.daily_waste_percentage=dataCheck.daily_worker.waste_percentage
          empAgency.push(...dataCheck.regular_worker.employees_list.map(item=>item.agency_name)); 
         
          empAgency.push(...dataCheck.daily_worker.employees_list.map(item=>item.agency_name));     

            if(dataCheck.regular_worker.waste_percentage!=0){
              if(percentRegWastedHr==0){
                percentRegWastedHr =(percentRegWastedHr+dataCheck.regular_worker.waste_percentage)  
              }else{
                percentRegWastedHr =(percentRegWastedHr+dataCheck.regular_worker.waste_percentage)/2;
              }
              
            }
            if(dataCheck.daily_worker.waste_percentage!=0){
              if(percentDailyWastedHour==0){
                percentDailyWastedHour=(percentDailyWastedHour + dataCheck.daily_worker.waste_percentage)
              }else{
                percentDailyWastedHour=(percentDailyWastedHour + dataCheck.daily_worker.waste_percentage)/2;
              }
            
            }
            //totalhour
            singleRecord.regular_working_total_hour=dataCheck.regular_worker.total_shift_hours
            singleRecord.daily_working_total_hour=dataCheck.daily_worker.total_shift_hours
            regsfthrs= parseFloat(regsfthrs) + parseFloat(moment.duration(dataCheck.regular_worker.total_shift_hours).asHours());
            dailysfthrs =  parseFloat(dailysfthrs) + parseFloat(moment.duration(dataCheck.daily_worker.total_shift_hours).asHours());
    
    
            //department
            singleRecord.department_working_time=dataCheck.department_working_time
            singleRecord.departments_need_attention=dataCheck.departments_need_attention
            singleRecord.department_productivity_percentage=dataCheck.department_productivity_percentage;
            
            Object.keys(dataCheck.department_productivity_percentage).forEach(key2 => {
              deptPercentage.push({
                  date:moment(loopDate).format('YYYY-MM-DD'),
                  name:key2,
                  percentage:dataCheck.department_productivity_percentage[key2],
                  working_time:dataCheck.department_working_time[key2]?dataCheck.department_working_time[key2]:"00:00:00",
                  // rating:ratingCal(dataCheck.department_productivity_percentage[key2]),
                  // badges:dataCheck.department_productivity_percentage[key2] >= 80?"Gold Badge":
                  //         dataCheck.department_productivity_percentage[key2] < 80 && dataCheck.department_productivity_percentage[key2] <= 60?"Silver Badge":"No Badge"
                })
            });
    
            
            
            //subDepartment
            singleRecord.sub_department_working_time=dataCheck.sub_department_working_time
            singleRecord.sub_department_productivity_percentage=dataCheck.sub_department_productivity_percentage
    
            //subDepartment Average
            Object.keys(dataCheck.sub_department_working_time).forEach(key2 => {
              subDepartmentAvg.push({
                  date:moment(loopDate).format('YYYY-MM-DD'),
                  name:key2,
                  percentage:dataCheck.sub_department_productivity_percentage[key2],
                  working_time:dataCheck.sub_department_working_time[key2]?dataCheck.sub_department_working_time[key2]:"00:00:00",
                  
                })
            });
    
    
    
            //employee
            // singleRecord.list_of_top_twenty_performers=dataCheck.list_of_top_twenty_performers
            // singleRecord.employees_need_attention=dataCheck.employees_need_attention
    
            //badge silver
            singleRecord.regular_silver_badge=dataCheck.regular_worker.badges.silver_badge.number_of_employees
            singleRecord.daily_silver_badge=dataCheck.daily_worker.badges.silver_badge.number_of_employees
            sCount=sCount+dataCheck.regular_worker.badges.silver_badge.number_of_employees + dataCheck.daily_worker.badges.silver_badge.number_of_employees
            //badge gold
            singleRecord.regular_gold_badge=dataCheck.regular_worker.badges.gold_badge.number_of_employees
            singleRecord.daily_gold_badge=dataCheck.daily_worker.badges.gold_badge.number_of_employees
            gCount= gCount + dataCheck.regular_worker.badges.gold_badge.number_of_employees+ dataCheck.daily_worker.badges.gold_badge.number_of_employees;
            //NoBadge 
            singleRecord.regular_no_badge=dataCheck.regular_worker.badges.no_badge.number_of_employees
            singleRecord.daily_no_badge=dataCheck.daily_worker.badges.no_badge.number_of_employees
            nCount=nCount+dataCheck.regular_worker.badges.no_badge.number_of_employees+dataCheck.daily_worker.badges.no_badge.number_of_employees
    
            
            //ratingRegular by percentage
            singleRecord.regular_five_star_percent=dataCheck.regular_worker.ratings.five_stars.percentage_of_employees
            singleRecord.regular_four_star_percent=dataCheck.regular_worker.ratings.four_star.percentage_of_employees
            singleRecord.regular_three_star_percent=dataCheck.regular_worker.ratings.three_star.percentage_of_employees
            singleRecord.regular_two_star_percent=dataCheck.regular_worker.ratings.two_star.percentage_of_employees
            singleRecord.regular_one_star_percent=dataCheck.regular_worker.ratings.one_star.percentage_of_employees
            singleRecord.regular_zero_star_percent=dataCheck.regular_worker.ratings.zero_star.percentage_of_employees
    
    
    
    
            //ratingRegular by count
            singleRecord.regular_five_star_count=dataCheck.regular_worker.ratings.five_stars.number_of_employees
            singleRecord.regular_four_star_count=dataCheck.regular_worker.ratings.four_star.number_of_employees
            singleRecord.regular_three_star_count=dataCheck.regular_worker.ratings.three_star.number_of_employees
            singleRecord.regular_two_star_count=dataCheck.regular_worker.ratings.two_star.number_of_employees
            singleRecord.regular_one_star_count=dataCheck.regular_worker.ratings.one_star.number_of_employees
            singleRecord.regular_zero_star_count=dataCheck.regular_worker.ratings.zero_star.number_of_employees        
    
            sr5 = parseFloat(sr5) + parseFloat(dataCheck.regular_worker.ratings.five_stars.number_of_employees)
            sr4 = parseFloat(sr4) + parseFloat(dataCheck.regular_worker.ratings.four_star.number_of_employees)
            sr3 = parseFloat(sr3) + parseFloat(dataCheck.regular_worker.ratings.three_star.number_of_employees)
            sr2 = parseFloat(sr2) + parseFloat(dataCheck.regular_worker.ratings.two_star.number_of_employees)
            sr1 = parseFloat(sr1) + parseFloat(dataCheck.regular_worker.ratings.one_star.number_of_employees)
            sr0 = parseFloat(sr0) + parseFloat(dataCheck.regular_worker.ratings.zero_star.number_of_employees)
    
            
    
            //ratingDaily by percentage
            singleRecord.daily_five_star_percent=dataCheck.daily_worker.ratings.five_stars.percentage_of_employees
            singleRecord.daily_four_star_percent=dataCheck.daily_worker.ratings.four_star.percentage_of_employees
            singleRecord.daily_three_star_percent=dataCheck.daily_worker.ratings.three_star.percentage_of_employees
            singleRecord.daily_two_star_percent=dataCheck.daily_worker.ratings.two_star.percentage_of_employees
            singleRecord.daily_one_star_percent=dataCheck.daily_worker.ratings.one_star.percentage_of_employees
            singleRecord.daily_zero_star_percent=dataCheck.daily_worker.ratings.zero_star.percentage_of_employees
    
            singleRecord.daily_shift_hour = dataCheck.daily_worker.total_shift_hours
            singleRecord.regular_shift_hour = dataCheck.regular_worker.total_shift_hours
    
    
    
            //ratingDaily by count
            singleRecord.daily_five_star_count=dataCheck.daily_worker.ratings.five_stars.number_of_employees
            singleRecord.daily_four_star_count=dataCheck.daily_worker.ratings.four_star.number_of_employees
            singleRecord.daily_three_star_count=dataCheck.daily_worker.ratings.three_star.number_of_employees
            singleRecord.daily_two_star_count=dataCheck.daily_worker.ratings.two_star.number_of_employees
            singleRecord.daily_one_star_count=dataCheck.daily_worker.ratings.one_star.number_of_employees
            singleRecord.daily_zero_star_count=dataCheck.daily_worker.ratings.zero_star.number_of_employees        
    
            //daily
            sr5 = parseFloat(sr5) + parseFloat(dataCheck.daily_worker.ratings.five_stars.number_of_employees)
            sr4 = parseFloat(sr4) + parseFloat(dataCheck.daily_worker.ratings.four_star.number_of_employees)
            sr3 = parseFloat(sr3) + parseFloat(dataCheck.daily_worker.ratings.three_star.number_of_employees)
            sr2 = parseFloat(sr2) + parseFloat(dataCheck.daily_worker.ratings.two_star.number_of_employees)
            sr1 = parseFloat(sr1) + parseFloat(dataCheck.daily_worker.ratings.one_star.number_of_employees)
            sr0 = parseFloat(sr0) + parseFloat(dataCheck.daily_worker.ratings.zero_star.number_of_employees)
            data.push(singleRecord)
            dayLoop++
          }
        }


        SetGoldCount(gCount)
        SetSilverCount(sCount)
        SetNoBadgeCount(nCount)

        SetFiveCount(sr5);
        SetFourCount(sr4);
        SetThreeCount(sr3);
        SetTwoCount(sr2);
        SetOneCount(sr1);
        SetZeroCount(sr0);

        SetRegularCount(regularAttennce);
        SetDailyCount(dailyAttennce);
       
        SetTotalBillDailyHrs(dailysfthrs.toFixed(2));
        SetTotalBillRegularHrs(regsfthrs.toFixed(2));

        SetRegularWorkingHour(regHour);
        SetDailyWorkingHour(dailyHour);
        
        SetDailyWastedHour( dailyWasted)
        SetRegularWastedHour(regularWasted)
        SeConversionDailyCount( (dailysfthrs * conversionRate))
        SeConversionDailyWastedCount((dailyWasted * conversionRate))
        //SetRegularWastedHour((regularWasted * conversionRate).toFixed(2))
      
        SeConversionRegularCount( (regsfthrs * conversionRate))

        SetPercentDailyWastedHour(
          ((dailyWasted*100)/dailysfthrs))
        SetPercentRegularWorkHour ( ((regHour *100)/regsfthrs))
        SetPercentDailyWorkHour((( dailyHour *100)/dailysfthrs))
        SetPercentRegularWastedHour((( (regularWasted *100 )/regsfthrs)))
        if(agency.length==0){
          SetAgencyList([...new Set(empAgency)])
        
        }

        let s1=[];
      
        s1.push({name:"Regular Worker",data:data.map(item=>item.regular_attendance)})
        s1.push({name:"Daily Worker",data:data.map(item=>item.daily_attendance)})
        let cat = data.map(item=> moment(item.date).format('DD MMM'))


  let topPerStarformer =[];
  topPerStarformer.push(...res.data['list_of_top_twenty_performers'].top_performer_dw);
  topPerStarformer.push(...res.data['list_of_top_twenty_performers'].top_performer_rw);


  topPerStarformer.sort(function(a,b){
    return b.working_percentage - a .working_percentage
  })


      // topPerStarformer = topPerStarformer.sort(function(a,b){
      //         return parseFloat(b.working_percentage) - parseFloat(a.working_percentage);
      // });
      let topPerformerDaily= res.data['list_of_top_twenty_performers']['top_performer_dw']?res.data['list_of_top_twenty_performers']['top_performer_dw']:topPerStarformer.filter(item=>item.worker_type=='daily_worker') 
      let topPerformerReg = res.data['list_of_top_twenty_performers']['top_performer_rw']?res.data['list_of_top_twenty_performers']['top_performer_rw']:topPerStarformer.filter(item=>item.worker_type=='regular_worker')
      topPerStarformer=topPerStarformer.slice(0,20);
      topPerformerDaily= topPerformerDaily.sort(function(a,b){
        return parseFloat(b.working_percentage) - parseFloat(a.working_percentage);
      }).slice(0,20);
      topPerformerReg  = topPerformerReg.sort(function(a,b){
        return parseFloat(b.working_percentage) - parseFloat(a.working_percentage);
      }).slice(0,20);

      topPerStarformer=topPerStarformer.map(item=>{
        item.rating= ratingCal(item.working_percentage);
        return item;
      }) 

      topPerformerDaily=   topPerformerDaily.map(item=>{
        item.rating= ratingCal(item.working_percentage);
        return item;
      }) 
      topPerformerReg=topPerformerReg.map(item=>{
        item.rating= ratingCal(item.working_percentage);
        return item;
      }) 

        
      SetTopPerformer(topPerStarformer);
      SetTopPerformerDaily(topPerformerDaily);
      SetTopPerformerReg(topPerformerReg);
  //end topperformer


      let departmentPerformance=[]
      for(let i=0;i<deptPercentage.length;i++){
        
          let index = departmentPerformance.findIndex(item=>item.name== deptPercentage[i].name);
        
          if(index > -1){
  
      //      departmentPerformance[index].percentage =departmentPerformance[index].percentage + deptPercentage[i].percentage
            departmentPerformance[index].working_time = parseFloat(departmentPerformance[index].working_time) + moment.duration(deptPercentage[i].working_time).asSeconds() 
          }else{
            deptPercentage[i].working_time =  moment.duration(deptPercentage[i].working_time).asSeconds()
          //This will return all value   
            let currentallPercentage  = deptPercentage.filter(item=> item.name == deptPercentage[i].name);
            let actualPercemtage = currentallPercentage.reduce((oldVal, item) => oldVal + parseFloat(item.percentage),0)/currentallPercentage.length
            let dept = deptPercentage[i];
            dept.percentage = parseFloat(actualPercemtage.toFixed(2)) 
            // departmentPerformance[index].percentage = [index].percentage + deptPercentage[i].percentage
            departmentPerformance.push(dept)
          }
      }
        
      departmentPerformance = departmentPerformance.sort(function(a,b){
        return b.percentage -  a.percentage;
      })

      departmentPerformance = departmentPerformance.filter(item=> item.working_time > 0)


      departmentPerformance= departmentPerformance.map(item=>{
        item.working_numeric_time = item.working_time;
        item.working_time = item.working_time;
        item.rating = ratingCal(item.percentage)
        item.badges=item.percentage >= 80?"Gold Badge":item.percentage < 80 && item.percentage >= 60?"Silver Badge":"No Badge"
        return item
      })

      
      SetDepartment(departmentPerformance);
      let deptLower= [...departmentPerformance].sort(function(a,b){
        return a.percentage - b.percentage  ;
      })

      

      SetDepartmentLower(deptLower);
    

      // Sub Department Calculation Start
      let subdepartmentPerformance=[]
      for(let i=0;i<subDepartmentAvg.length;i++){
        
          let index = subdepartmentPerformance.findIndex(item=>item.name== subDepartmentAvg[i].name);
          
          if(index > -1){
            //subdepartmentPerformance[index].percentage =(subdepartmentPerformance[index].percentage + subDepartmentAvg[i].percentage)/2
            subdepartmentPerformance[index].working_time = parseFloat(subdepartmentPerformance[index].working_time) + moment.duration(subDepartmentAvg[i].working_time).asSeconds() 
          }else{
            subDepartmentAvg[i].working_time =  moment.duration(subDepartmentAvg[i].working_time).asSeconds()
            let currentallPercentage  = subDepartmentAvg.filter(item=> item.name == subDepartmentAvg[i].name);
            let actualPercemtage = currentallPercentage.reduce((oldVal, item) => oldVal + parseFloat(item.percentage),0)/currentallPercentage.length
            let sub_dept = subDepartmentAvg[i];
            
            sub_dept.percentage = parseFloat(actualPercemtage.toFixed(2))
            subdepartmentPerformance.push(sub_dept)
          }
      }
      
      subdepartmentPerformance = subdepartmentPerformance.sort(function(a,b){
        return b.percentage -  a.percentage;
      })

      subdepartmentPerformance = subdepartmentPerformance.filter(item=> item.working_time > 0)

      subdepartmentPerformance= subdepartmentPerformance.map(item=>{
        item.working_numeric_time = item.working_time;
        item.working_time = item.working_time;
        item.rating = ratingCal(item.percentage)
        item.badges=item.percentage >= 80?"Gold Badge":item.percentage < 80 && item.percentage >= 60?"Silver Badge":"No Badge"
        return item
      })
    let subLower = [...subdepartmentPerformance].sort(function(b,a){
        return b.percentage -  a.percentage;
      })

      SetSubDepartmentLower(subLower);

      let empAttention= res.data['employees_need_attention']?res.data['employees_need_attention'] :[]

  empAttention.sort(function(a,b){
        return a.working_percentage - b.working_percentage  ;
      })

      empAttention.map(item=>{
        item.rating= ratingCal(item.working_percentage);
        return item;
      })

      SetEmpNeedAttention(empAttention);
        
      SetSubDepartment(subdepartmentPerformance);


        let s2=[];
        SetDataAll(data);
        SetChart1(false);
  setTimeout(function(){
    SetChart1Delay(false);
  },2000)
        

      })
  }
  },[dates,agency,permission ])

  //all perfect Attendance
useEffect(()=>{
  if(permission.length > 0){

  
  if(!chart1){
  let data=[...dataAll];
  let s2=[];

if(reportby=='day'){
    //count
    s2.push({
      name:"Total Regular Workers",
      data:data.map(item=>item.regular_attendance),
      labelTooltip:"Regular Worker",
      type: 'column',
      yAxis: 0,
      color:"#7cb5ec",
      tooltip: {
        valueSuffix: ' Nos',
        valueDecimals:0,
      }
   })
   //hours
   s2.push({
    name:"Total Regular Hours",
    color:"#7cb5ec",
    data:data.map(item=>moment.duration(item.regular_shift_hour).asHours()),
    labelTooltip:"Regular Worker",
    type: 'spline',
    yAxis: 1,
    tooltip: {
      valueSuffix: 'Hrs',
      valueDecimals:2,
    }
 })
 s2.push({
  name:"Total Regular Bill",
  color:"#7cb5ec",
  data:data.map(item=> (moment.duration(item.regular_shift_hour).asHours()) * conversionRate),
  labelTooltip:"Regular Worker",
  type: 'spline',
  yAxis: 2,
  tooltip: {
    valueSuffix: '$',
    valueDecimals:2,
  }
})

s2.push({
    name:"Total Daily Workers",
    color:"#434348",
    data:data.map(item=>item.daily_attendance),
    labelTooltip:"daily Worker",
    type: 'column',
    yAxis: 0,
    tooltip: {
      valueSuffix: ' Nos',
      valueDecimals:0,
    }
})
//hours
s2.push({
    name:"Total Daily Hours",
    data:data.map(item=>moment.duration(item.daily_shift_hour).asHours()),
    labelTooltip:"daily Worker",
    type: 'spline',
    color:"#434348",
    yAxis: 1,
    tooltip: {
    valueSuffix: 'Hrs',
    valueDecimals:2,
}
})
s2.push({
  name:"Total Daily Bill",
  color:"#434348",
  data:data.map(item=> (moment.duration(item.daily_shift_hour).asHours()) * conversionRate),
  labelTooltip:"daily Worker",
  type: 'spline',
  yAxis: 2,
  tooltip: {
  valueSuffix: '$',
  valueDecimals:2,

}
})

let cat= data.map(item=>moment(item.date).format("DD MMM"))

SetChartData((prev)=>({series:s2,categories:cat,yAxis:chartData.yAxis}))

}
if(reportby=='month'){
  let monthDiff=getMonths(dates.start,dates.end,'month')
  let cat=monthDiff.map(item=>monthInt[item.month]);
  //now averaging start for 6 Variable
  
  let newAverage=[]
    for(let j =0 ;j<monthDiff.length;j++){
      let data= [...dataAll].filter(item=>parseFloat(moment(item.date).format('MM')) == monthDiff[j].month )
      
    //now calculate value for all 4 variable
    let dailyAttennce =0;
    let regularAttennce =0;
    let regHour=0
    let dailyHour=0
      for(let i=0;i<data.length;i++){
        
        dailyAttennce=dailyAttennce+data[i].daily_attendance
      
        regularAttennce=regularAttennce+data[i].regular_attendance
  
        regHour = regHour +moment.duration(data[i].regular_shift_hour).asHours();
        dailyHour = dailyHour +moment.duration(data[i].daily_working_hour).asHours();      
      } 
      newAverage.push({regular_attendance:regularAttennce,regular_shift_hour:regHour,daily_attendance:dailyAttennce,daily_working_hour:dailyHour})
     
    }
    //loop end of month average stored in newVariable
  
    //series plot
    s2.push({
      name:"Total Regular Workers",
      data:newAverage.map(item=>item.regular_attendance),
      labelTooltip:"Regular Worker",
      type: 'column',
      yAxis: 0,
      color:"#7cb5ec",
      tooltip: {
        valueSuffix: ' Nos',
        valueDecimals:0,
      }
   })
   //hours
   s2.push({
    name:"Total Regular Hours",
    color:"#7cb5ec",
    data:newAverage.map(item=>item.regular_shift_hour),
    labelTooltip:"Regular Worker",
    type: 'spline',
    yAxis: 1,
    tooltip: {
      valueSuffix: 'Hrs',
      valueDecimals:2,
    }
  })
  s2.push({
  name:"Total Regular Bill",
  color:"#7cb5ec",
  data:newAverage.map(item=> item.regular_shift_hour * conversionRate),
  labelTooltip:"Regular Worker",
  type: 'spline',
  yAxis: 2,
  tooltip: {
    valueSuffix: '$',
    valueDecimals:2,
  }
  })
  
  s2.push({
    name:"Total Daily Workers",
    color:"#434348",
    data:newAverage.map(item=>item.daily_attendance),
    labelTooltip:"daily Worker",
    type: 'column',
    yAxis: 0,
    tooltip: {
      valueSuffix: ' Nos',
      valueDecimals:0,
    }
  })
  //hours
  s2.push({
    name:"Total Daily Hours",
    data:newAverage.map(item=>item.daily_working_hour),
    labelTooltip:"daily Worker",
    type: 'spline',
    color:"#434348",
    yAxis: 1,
    tooltip: {
    valueSuffix: 'Hrs',
    valueDecimals:2,
  }
  })
  s2.push({
  name:"Total Daily Bill",
  color:"#434348",
  data:newAverage.map(item=> item.daily_working_hour * conversionRate),
  labelTooltip:"daily Worker",
  type: 'spline',
  yAxis: 2,
  tooltip: {
  valueSuffix: '$',
  valueDecimals:2,
  
  }
  })
  
  SetChartData((prev)=>({series:s2,categories:cat,yAxis:prev.yAxis}))
  
  }
  if(reportby=='week'){
  
    const srt = dates.start
    const ed  = dates.end
    let monthDiff=getMonths(srt,ed,'week');
  
   
    // let      cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
        let   cat = monthDiff.map((item,index)=>{return item.weekName})
     let newAverage=[]
      for(let j =0 ;j<monthDiff.length;j++){
        let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))
  
        //now calculate value for all 4 variable
      let dailyAttennce =0;
      let regularAttennce =0;
      let regHour=0
      let dailyHour=0
  
        for(let i=0;i<data.length;i++){
          
          dailyAttennce=dailyAttennce+data[i].daily_attendance
          regularAttennce=regularAttennce+data[i].regular_attendance
    
          regHour = regHour +moment.duration(data[i].regular_shift_hour).asHours();
          dailyHour = dailyHour +moment.duration(data[i].daily_shift_hour).asHours();      
        } 
        newAverage.push({regular_attendance:regularAttennce,regular_shift_hour:regHour,daily_attendance:dailyAttennce,daily_shift_hour:dailyHour})
        
      }
      //loop end of month average stored in newVariable
    
      //series plot
      s2.push({
        name:"Total Regular Workers",
        data:newAverage.map(item=>item.regular_attendance),
        labelTooltip:"Regular Worker",
        type: 'column',
        yAxis: 0,
        color:"#7cb5ec",
        tooltip: {
          valueSuffix: ' Nos',
          valueDecimals:0,
        }
     })
     //hours
     s2.push({
      name:"Total Regular Hours",
      color:"#7cb5ec",
      data:newAverage.map(item=>item.regular_shift_hour),
      labelTooltip:"Regular Worker",
      type: 'spline',
      yAxis: 1,
      tooltip: {
        valueSuffix: 'Hrs',
        valueDecimals:2,
      }
    })
    s2.push({
    name:"Total Regular Bill",
    color:"#7cb5ec",
    data:newAverage.map(item=> item.regular_shift_hour * conversionRate),
    labelTooltip:"Regular Worker",
    type: 'spline',
    yAxis: 2,
    tooltip: {
      valueSuffix: '$',
      valueDecimals:2,
    }
    })
    
    s2.push({
      name:"Total Daily Workers",
      color:"#434348",
      data:newAverage.map(item=>item.daily_attendance),
      labelTooltip:"daily Worker",
      type: 'column',
      yAxis: 0,
      tooltip: {
        valueSuffix: ' Nos',
        valueDecimals:0,
      }
    })
    //hours
    s2.push({
      name:"Total Daily Hours",
      data:newAverage.map(item=>item.daily_shift_hour),
      labelTooltip:"daily Worker",
      type: 'spline',
      color:"#434348",
      yAxis: 1,
      tooltip: {
      valueSuffix: 'Hrs',
      valueDecimals:2,
    }
    })
    s2.push({
    name:"Total Daily Bill",
    color:"#434348",
    data:newAverage.map(item=> item.daily_shift_hour * conversionRate),
    labelTooltip:"daily Worker",
    type: 'spline',
    yAxis: 2,
    tooltip: {
    valueSuffix: '$',
    valueDecimals:2,
    
    }
    })
    
    SetChartData((prev)=>({series:s2,categories:cat,yAxis:prev.yAxis}))
    
    }

  }
}
},[chart1,dataAll,conversionRate,dataAll,reportby,permission])


  //not in used chart
useEffect(()=>{
  if(permission.length> 0){

  
  SetChart2(true);


if(!chart1){



    if(chartreportby=='percent' && chartSortBy=='working_hour'){
   let s1=[];
   let reg= [...dataAll].map(item=>{return item.regular_working_percentage});   
   let daily= [...dataAll].map(item=>{
    
    return item.daily_working_percentage
  });  
   let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
   s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix: '%', valueDecimals:2}})
   s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: '%', valueDecimals:2}})
   SetChartDataReport({series:s1,categories:cat})
  }
  else if (chartreportby=='percent' && chartSortBy=='wasted_hour' ){
    let s1=[];
    let reg= [...dataAll].map(item=>{return item.regular_waste_percentage});   
    let daily= [...dataAll].map(item=>{return item.daily_waste_percentage});  
    let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
    s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix: '%', valueDecimals:2}})
    s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: '%', valueDecimals:2}}) 
    SetChartDataReport({series:s1,categories:cat})
  }else if(chartreportby=='hour' && chartSortBy=='working_hour'){
    let s1=[];
    let reg= [...dataAll].map(item=>{return moment.duration(item.regular_working_hour).asHours()});   
    let daily= [...dataAll].map(item=>{return moment.duration(item.daily_working_hour).asHours()});  
    let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
    s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix: 'Hrs', valueDecimals:2}})
    s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: 'Hrs', valueDecimals:2}})
    
    SetChartDataReport({series:s1,categories:cat})
  }else if(chartreportby=='hour' && chartSortBy=='wasted_hour'){
    let s1=[];
    let reg= [...dataAll].map(item=>{return moment.duration(item.regular_wasted_hour).asHours()});   
    let daily= [...dataAll].map(item=>{return moment.duration(item.daily_wasted_hour).asHours()});  
    let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
    s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix: 'Hrs', valueDecimals:2}})
    s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: 'Hrs', valueDecimals:2}}) 
    SetChartDataReport({series:s1,categories:cat})
  }else{
    if(chartSortBy=='wasted_hour'){
    let s1=[];
    let reg= [...dataAll].map(item=>{return moment.duration(item.regular_wasted_hour).asHours() * conversionRate });   
    let daily= [...dataAll].map(item=>{return moment.duration(item.daily_wasted_hour).asHours() * conversionRate});  
    let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
    s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix: '$', valueDecimals:2}})
    s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: '$', valueDecimals:2}}) 
    SetChartDataReport({series:s1,categories:cat})
  }else{
    let s1=[];
    let reg= [...dataAll].map(item=>{return moment.duration(item.regular_working_hour).asHours() * conversionRate});   
    let daily= [...dataAll].map(item=>{return moment.duration(item.daily_working_hour).asHours() * conversionRate});  
    let cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
    s1.push({name:"Regular Worker",data:reg,tooltip: {valueSuffix:'$', valueDecimals:2}})
    s1.push({name:"Daily Worker",data:daily,tooltip: {valueSuffix: '$', valueDecimals:2}}) 
   
    SetChartDataReport({series:s1,categories:cat})
  }
  }

  setTimeout(function(){
    SetChart2(false);
  },1000)
}
  }
},[dataAll,chart1,chartreportby,chartSortBy,permission])


//Badge Chart 
useEffect(()=>{
  if(permission.length> 0){
  let gold= []
  let silver=[]
  let nobadge=[]
  let cat =[]
  let s1=[];
  let newAverage=[]
  let total=[];
  if(!chart1){
    
    if(badgeChart=="all"){


      
      if (reportby=='day'){
        gold= [...dataAll].map(item=>{return (parseFloat(item.regular_gold_badge) + parseFloat(item.daily_gold_badge) )});   
        silver= [...dataAll].map(item=>{return (parseFloat(item.regular_silver_badge) + parseFloat(item.daily_silver_badge) )});  
        nobadge= [...dataAll].map(item=>{return (parseFloat(item.regular_no_badge) + parseFloat(item.daily_no_badge) )});  
        cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
        total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});   
        
      }else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=>monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){

          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))
          gold.push(data.reduce((oldVal, item) => oldVal + parseFloat(item.regular_gold_badge) + parseFloat(item.daily_gold_badge),0) ) ;   
          silver.push(data.reduce((oldVal, item) => oldVal + parseFloat(item.regular_silver_badge) + parseFloat(item.daily_silver_badge),0) ) ;   
          nobadge.push(data.reduce((oldVal, item) => oldVal + parseFloat(item.regular_no_badge) + parseFloat(item.daily_no_badge) ,0) ) ;   
          total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
          
       }
        

      }



      s1.push({name:"Gold Badge",data:gold,type:"spline",color:"gold" })
      s1.push({name:"Silver Badge",data:silver,type:"spline",color:"silver"})
      s1.push({name:"No Badge",data:nobadge,type:"spline",color:"red"})
      
      // let avg =total.reduce((oldVal,newVal) => oldVal +newVal,0)
      

            let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
          // let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1.data.filter(item=>item>0).length
          let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
        
      SetChartDataBadges({series:s1,categories:cat,total:total,mutliAvg:mAVg })
    }
    else if(badgeChart=="regular"){
      
      if (reportby=='day'){
        gold= [...dataAll].map(item=>{return item.regular_gold_badge});   
        silver= [...dataAll].map(item=>{return item.regular_silver_badge});  
        nobadge= [...dataAll].map(item=>{return item.regular_no_badge});  
        cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
        total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
      }else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=>monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){
          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))          

          gold.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_gold_badge),0 )) ;   
          silver.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_silver_badge),0 )) ;   
          nobadge.push(data.reduce((oldVal, item) => oldVal +parseFloat(item.regular_no_badge),0 )) ;  
                  total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
        }
      }
      let avg =total.reduce((oldVal, item) => oldVal + parseFloat(item) ,0) /total.filter(item=>item> 0).length ;
      s1.push({name:"Gold Badge",data:gold,type:"spline",color:"gold"})
      s1.push({name:"Silver Badge",data:silver,type:"spline",color:"silver"})
      s1.push({name:"No Badge",data:nobadge,type:"spline",color:"red"})

            let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
        let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
   
      SetChartDataBadges({series:s1,categories:cat,total:total,mutliAvg:mAVg})
    }else{
      
      if (reportby=='day'){
        gold= [...dataAll].map(item=>{return item.daily_gold_badge});   
        silver= [...dataAll].map(item=>{return item.daily_silver_badge});  
        nobadge= [...dataAll].map(item=>{return item.daily_no_badge});  
        cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
                total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
      }
      else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=>monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){
          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))          
 
          gold.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_gold_badge),0) ) ;   
          silver.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_silver_badge),0 )) ;   
          nobadge.push(data.reduce((oldVal, item) => oldVal + parseFloat(item.daily_no_badge),0) ) ;   
                  total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
        }
      }
            let avg =total.reduce((oldVal, item) => oldVal + parseFloat(item) ,0) /total.filter(item=>item> 0).length ;
      s1.push({name:"Gold Badge",data:gold,type:"spline",color:"gold"})
      s1.push({name:"Silver Badge",data:silver,type:"spline",color:"silver"})
      s1.push({name:"No Badge",data:nobadge,type:"spline",color:"red"})

      let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
      
      SetChartDataBadges({series:s1,categories:cat,total:total,mutliAvg:mAVg})
    }
  }
  }
},[badgeBy,chart1,badgeChart,reportby,permission])


useEffect(()=>{
  if(permission.length> 0){
  SetChart3(true)
  let s1=[];
let five=  []
let four=  []
let three= []
let two=   []
let one=   []
let zero=  []
let cat =[]
let total=[];
  if(!chart1){
    
    if(ratingfor=="all"){

      if(reportby=='day'){
        five=  [...dataAll].map(item=>{return  item.regular_five_star_count  + item.daily_five_star_count });   
        four=  [...dataAll].map(item=>{return  item.regular_four_star_count  + item.daily_four_star_count });  
        three=  [...dataAll].map(item=>{return item.regular_three_star_count + item.daily_three_star_count });  
        two=   [...dataAll].map(item=>{return   item.regular_two_star_count   + item.daily_two_star_count  });  
        one=   [...dataAll].map(item=>{return   item.regular_one_star_count   + item.daily_one_star_count  });  
        zero=  [...dataAll].map(item=>{return  item.regular_zero_star_count  + item.daily_zero_star_count });  
        total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
       cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
       
      }else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=> monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){
          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))          

          five.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_five_star_count) + parseFloat(item.daily_five_star_count),0) ) 
          four.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_four_star_count) + parseFloat(item.daily_four_star_count),0)) 
          three.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_three_star_count) + parseFloat(item.daily_three_star_count),0)) 
          two.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_two_star_count) + parseFloat(item.daily_two_star_count),0)) 
          one.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_one_star_count) + parseFloat(item.daily_one_star_count),0)) 
          zero.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_zero_star_count) + parseFloat(item.daily_zero_star_count),0)) 
                  total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
        }
      }
      

      s1.push({name:"Five Star",data:five,type:"spline",color:"#7cb5ec"})
      s1.push({name:"Four Star",data:four,type:"spline",color:"#434348"})
      s1.push({name:"Three Star",data:three,type:"spline",color:"#90ed7d"})
      s1.push({name:"Two Star",data:two,type:"spline",color:"#f7a35c"})
      s1.push({name:"One Star",data:one,type:"spline",color:"#8085e9"})
      s1.push({name:"Zero Star",data:zero,type:"spline",color:"#f15c80"})

      let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
        let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
      
      SetChartDataRating({series:s1,categories:cat,type:"spline",total:total,mutliAvg:mAVg})
    }
    else if(ratingfor=="regular"){
      if(reportby=='day'){
          five= [...dataAll].map(item=>{return item.regular_five_star_count});   
          four= [...dataAll].map(item=>{return item.regular_four_star_count});  
          three= [...dataAll].map(item=>{return item.regular_three_star_count});  
          two= [...dataAll].map(item=>{return item.regular_two_star_count});  
          one= [...dataAll].map(item=>{return item.regular_one_star_count});  
          zero= [...dataAll].map(item=>{return item.regular_zero_star_count});  
          cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
                  total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
      }
      else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=>monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){
          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))          
          five.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_five_star_count) ,0) ) 
          four.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_four_star_count) ,0)) 
          three.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_three_star_count) ,0)) 
          two.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_two_star_count),0)) 
          one.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_one_star_count),0)) 
          zero.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.regular_zero_star_count),0))
          total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
                  
                  
        }
      }
      s1.push({name:"Five Star",data:five,type:"spline",color:"#7cb5ec"})
      s1.push({name:"Four Star",data:four,type:"spline",color:"#434348"})
      s1.push({name:"Three Star",data:three,type:"spline",color:"#90ed7d"})
      s1.push({name:"Two Star",data:two,type:"spline",color:"#f7a35c"})
      s1.push({name:"One Star",data:one,type:"spline",color:"#8085e9"})
      s1.push({name:"Zero Star",data:zero,type:"spline",color:"#f15c80"})  
          let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
        let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
      
      SetChartDataRating({series:s1,categories:cat,type:"spline",total:total,mutliAvg:mAVg})
    }
    else if(ratingfor=="daily"){
      if(reportby=='day'){
          five= [...dataAll].map(item=>{return item.daily_five_star_count});   
          four= [...dataAll].map(item=>{return item.daily_four_star_count});  
          three= [...dataAll].map(item=>{return item.daily_three_star_count});  
          two= [...dataAll].map(item=>{return item.daily_two_star_count});  
          one= [...dataAll].map(item=>{return item.daily_one_star_count});  
          zero= [...dataAll].map(item=>{return item.daily_zero_star_count});  
          cat = [...dataAll].map(item=> moment(item.date).format('DD MMM'))
          total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
      }
      else{
        let monthDiff=getMonths(dates.start ,dates.end,reportby);

        if(reportby=='week'){
          // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
                cat = monthDiff.map((item,index)=>{return item.weekName})
        }else{
          cat = monthDiff.map(item=>monthInt[item.month]); 
        }
        for(let j =0 ;j<monthDiff.length;j++){
          let data= [...dataAll].filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end))          
          five.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_five_star_count) ,0) ) 
          four.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_four_star_count) ,0)) 
          three.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_three_star_count) ,0)) 
          two.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_two_star_count),0)) 
          one.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_one_star_count),0)) 
          zero.push(data.reduce((oldVal, item) => oldVal +  parseFloat(item.daily_zero_star_count),0)) 
          total = [...dataAll].map(item=>{return (parseFloat(item.daily_attendance) + parseFloat(item.regular_attendance) )});
        }
      }

            s1.push({name:"Five Star",data:five,type:"spline",color:"#7cb5ec"})
      s1.push({name:"Four Star",data:four,type:"spline",color:"#434348"})
      s1.push({name:"Three Star",data:three,type:"spline",color:"#90ed7d"})
      s1.push({name:"Two Star",data:two,type:"spline",color:"#f7a35c"})
      s1.push({name:"One Star",data:one,type:"spline",color:"#8085e9"})
      s1.push({name:"Zero Star",data:zero,type:"spline",color:"#f15c80"})
   
      let mAVg =[] ;
      
        for(let i=0;i<s1.length;i++){
        let av = s1[i].data.reduce((old,newVal) => old + newVal,0)/s1[i].data.filter(item=>item>0).length
          let avgPush={
                    color: s1[i].color,
                    width: 1,
                    value: av,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: av.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
          
        }
      
      SetChartDataRating({series:s1,categories:cat,type:"spline",total:total,mutliAvg:mAVg})
    }
      SetChart3(false);
    // setTimeout(function(){
    
    // },1000)
  }
}

},[ratingfor,chart1,reportby,permission])

  function handleDateSelect(date) {
    updateSelectedDate(date);
    setDates((prev) => ({ ...prev, [`end`]: date }));
SetDataAll([]);
   SetDepartment([]);
   SetSubDepartment([]);
   SetSubDepartmentLower([]);
   SetEmpNeedAttention([])

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function starRatingRender(params){
        

    var op =document.createElement('div');

    var eGui = document.createElement('div');
     
        eGui.innerHTML=`${starRating(params.value)}`;
            


  
     return eGui;



}


  function AGTable(prm){
    let arr;
    let data=[]
    if(prm=='top'){
if(topFunctionBy=='department'){
  data=[...department]
}else{
  data=[...subdepartment]
}
    }else{
if( bottomFunctionBy=='department'){
  data=[...departmentLower]
}else{
  data=[...subDepartmentLower]
}
    }

    if(prm=='top'){

    if(topFunction=='working_time'){
      
      data= data.sort(function(a,b){
        return b.working_numeric_time  - a.working_numeric_time
      })

    }else if(topFunction=="badge"){
      data = data.sort(function(a,b){
        return b.rating  - a.rating
      })
    }else if(topFunction=='rating'){
      data = data.sort(function(a,b){
        return b.rating  - a.rating
      })
    }
    else if(topFunction=='percentage'){
      data = data.sort(function(a,b){
        return b.percentage  - a.percentage
      })
    }
  }else{
    
  }
  let  key =""
  if(prm=="top"){
      key = data.length +topFunction+prm;
  }else{
      key = data.length +bottomFunction+prm;
  }
    
      
      let arr2=[];
     
      if(data){   
        arr2.push( 
      
          <AgGridColumn
            field="name"
            headerName={"Name"}
          flex= {3}
          /> )

       if((topFunction=='rating' && prm=='top') || (bottomFunction=='rating' && prm=='low') ){
        arr2.push(<AgGridColumn
          field="rating"
          headerName={"Rating"}
          cellRenderer= {(params)=>starRatingRender(params)}
          flex= {1}
        />
        )
       }
       if((topFunction=="badge" && prm=='top')  || (bottomFunction=='badge' && prm=='low')){
        arr2.push(<AgGridColumn
          field="badges"
          headerName={"Badge"}
          flex= {1}
        />
        )
       }   
       if((topFunction=="working_time" && prm=='top') || (bottomFunction=='working_time' && prm=='low')){
        arr2.push(<AgGridColumn
          field="working_time"
          headerName={"Working Time"}
          
          valueFormatter={(params)=>ConvertSectoDay(params.value)}
          flex= {1}
        />
        )
       }
       if((topFunction=='percentage' && prm=='top') || (bottomFunction=='percentage' && prm=='low')){
        arr2.push(<AgGridColumn
          field="percentage"
          headerName={"Percentage"}
          
          valueFormatter={(params)=>params.value+"%"}
          flex= {1}
        />
        )
       }   
     
      arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 350 , width: "calc(100% - 1px)"}}>     
      <AgGridReact
              rowHeight={35}
              enableRangeSelection= {true}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'name',
              }}
              headerHeight={30}
          defaultColDef={{sortable: true,resizable: true}}
           pagination= {data.length>500}
           paginationPageSize= {500}             
              rowData={data}
              key={key}

           >    
{arr2}
      </AgGridReact>
      </div></div>
      
        
              }
         return arr;    
  
  
    }

    function AGTableEmployee(prm){
      let arr;

      let data=[];
      if(prm == 'top'){
        if(topPerformBy=='all'){

          data= [...topPerformer]

          }else if(topPerformBy=='regular'){
            data= [...topPerformerReg]

          }else{
            data= [...topPerformerDaily]

          }
      }else{
        if(lowPerformBy =='all'){

          data= [...empNeedAttention]

          }else if(lowPerformBy=='regular'){
            data= [...empNeedAttention].filter(item=>item.worker_type == 'regular_worker');

          }else{
            data= [...empNeedAttention].filter(item=>item.worker_type == 'daily_worker');
          }
      }



      let  key = data.length+"topPerformer"+topPerformBy+prm;
        
        let arr2=[];
       
        if(data){   
          arr2.push( 
        
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              flex= {3}
            /> )
            arr2.push(<AgGridColumn
              field="worker_type"
              headerName={"Worker"}
              valueFormatter= {(params)=>params.value=='regular_worker'?"Regular Worker":"Daily Worker"}
              flex= {1}
            />)
          arr2.push(<AgGridColumn
            field="rating"
            headerName={"Rating"}
            cellRenderer= {(params)=>starRatingRender(params)}
            flex= {1}
          />)
          
       
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 350 , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                enableRangeSelection= {true}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
                }}
                headerHeight={30}
            defaultColDef={{sortable: true,resizable: true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
  
             >    
  {arr2}
        </AgGridReact>
        </div></div>
        
          
                }
           return arr;    
    
    
      }


  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  


  function starRating(val){
    let rate='';
    if(val==0){
        return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
    }else{
        for(let i=1;i<=5;i++){
            if(i<=val){
                rate+=`${StarFill}`;
            }else{
                rate+=`${StarEmpty}`;
            }
        }
    }
    return `<div class="ratingFill ratingAG">${rate}</div>`;


  }


  


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  


  const handleDate = (date, type) => {
   setDates((prev) => ({ ...prev, [`${type}`]: date }));
   SetDataAll([]);
   SetDepartment([]);
   SetSubDepartment([]);
   SetSubDepartmentLower([]);
   SetEmpNeedAttention([])

  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  function filterResultHandler(){

  }

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };

  function GetRandomDigit(min=0,max=10){
    return  Math.floor(Math.random() * (max - min + 1)) + min;
  }

  


  useEffect(()=>{
    SeConversionDailyCount((dailyWorkingHour * conversionRate).toFixed(2))
    SeConversionRegularCount((regularWorkingHour * conversionRate).toFixed(2))

  },[conversionRate,dataAll])
 

 
  const getMonths = (fromDate, toDate,performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
  if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
  // else {return getMonths(fromDate, toDate,'month');} 
  else{ 
      let now = toDate
      let daysOfYear = [];
   let currentDate = fromDate;
   let sDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - sDate) /
        (24 * 60 * 60 * 1000));
         
    var weekNumber = Math.ceil(days / 7);
      let weekArray=[{start:"",end:"",month:"",year:"",condStart:"",condEnd:"",weekName:"Week "+weekNumber}];
     
      let loop=0;
      let sMonth= moment(fromDate).format("MM");
      let mLoop = getMonths(fromDate, toDate,'month');




      // for(let i=0;i<mLoop.length;i++){

        // if(i==(mLoop.length - 1)){
        //   now = toDate
        // }else{
        //   let MonthAct= month.find(item=>item.intMonth== mLoop[i].month);
        //   now= moment(`${mLoop[i].year},${mLoop[i].month},${MonthAct.days}`).toDate();
        // }
    let startDate = new Date(fromDate)
    let endDate = new Date(toDate)
    for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
      let loopDate= startDate


if(moment(loopDate).isoWeekday() == 6){
//cat.push(moment(loopDate).format('YYYY-MM-DD'));
// series2.push(0)


if(weekArray[loop] && weekArray[loop].start==''){
  weekArray[loop].start=moment(loopDate).format('YYYY-MM-DD');
  weekArray[loop].condStart="condition 1"
}
  weekArray[loop].end=moment(loopDate).format('YYYY-MM-DD');
  weekArray[loop].month=moment(loopDate).format('MM');
  weekArray[loop].year=moment(loopDate).format('YYYY');
  weekArray[loop].condEnd="condition 1"
   weekArray[loop].weekName="Week "+weekNumber
weekNumber++
loop++;


}
else if(moment(loopDate).isoWeekday() == 7){

}
else{
// let newInitialize={start:"",end:"",month:"",year:""};
let MonthAct= month.find(item=>item.intMonth== moment(loopDate).format('MM'));

if(weekArray[loop] && weekArray[loop].start=='' && weekArray[loop].end=='' && moment(loopDate).format('YYYY-MM-DD') == moment(now).format('YYYY-MM-DD')    ){
  weekArray[loop].end=moment(loopDate).format('YYYY-MM-DD')
  weekArray[loop].start=moment(loopDate).format('YYYY-MM-DD')
  weekArray[loop].month=moment(loopDate).format('MM');
  weekArray[loop].year=moment(loopDate).format('YYYY');
  weekArray[loop].weekName="Week "+weekNumber
  weekArray[loop].condEnd="condition 4"  
}
if(weekArray[loop] && weekArray[loop].end=='' && moment(loopDate).format('YYYY-MM-DD') == moment(now).format('YYYY-MM-DD')){
  weekArray[loop].end=moment(loopDate).format('YYYY-MM-DD')
  weekArray[loop].month=moment(loopDate).format('MM');
  weekArray[loop].year=moment(loopDate).format('YYYY');
  weekArray[loop].weekName="Week "+weekNumber
  weekArray[loop].condEnd="condition 5"
  let newInitialize={start:"",end:"",month:"",year:"",condStart:"",condEnd:"",weekName:""};
  weekArray.push(newInitialize)
}

else if(weekArray[loop] && weekArray[loop] && weekArray[loop].start=='' ){
  weekArray[loop].start=moment(loopDate).format('YYYY-MM-DD')
  weekArray[loop].condStart="condition 2"
  weekArray[loop].weekName="Week "+weekNumber
}
else if(weekArray[loop]){

}else{
  let newInitialize={start:"",end:"",month:"",year:"",condStart:"",condEnd:"",weekName:""};
  weekArray.push(newInitialize)
  weekArray[loop].start=moment(loopDate).format('YYYY-MM-DD')
  weekArray[loop].condStart="condition 3"


}

//weekArray["week"+weekCount].push()
}

//  daysOfYear.push(new Date(d));
}


let weekArrayIndex = weekArray.findIndex(item=>item.start!="" && item.end==""); 

if(weekArrayIndex > -1){
  weekArray[weekArrayIndex].end=moment(toDate).format('YYYY-MM-DD');
}
weekArray =weekArray.filter(item => item.start!='' && item.end!='');

return weekArray; 
    }
    
  
}


// Compare chart
useEffect(()=>{

if(permission.length> 0){

let data=[...dataAll];
let cat=[];
let s1Data=[]
let s2Data=[]
let s3Data=[]
let series1=[]
let series2=[]
let allSeries=[]
//hour total = total_shift_hour of reg + total_shift_hour of daily 
//total_gained
let crvRate=1;
let toolnotation="Hrs"
let y2 = yaxis[0]
if(compareFilter1=='dollar'){
  crvRate=conversionRate;
  toolnotation='$'
  y2 = yaxis[1]
}

if(compareFilter1=='percent'){
  toolnotation='%'
  y2 = yaxis[2]
}



if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='all' && compareFilter3=='total_gained'){
  
  if(reportby=='day'){
    s1Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours()) * crvRate))
    s2Data = data.map(item=> ((moment.duration(item.regular_working_hour).asHours() +  moment.duration(item.daily_working_hour).asHours()) * crvRate))
    s3Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours()) * crvRate))
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{
      cat = monthDiff.map(item=>monthInt[item.month]); 
    
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >= moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
     
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours()) * crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   (moment.duration(item.regular_working_hour).asHours() +  moment.duration(item.daily_working_hour).asHours()) * crvRate ,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours()) * crvRate,0))    
  }
  }

  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Logged Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )
 
 
}else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='regular_worker' && compareFilter3=='total_gained'){ 
  
  if(reportby=='day'){
  s1Data = data.map(item=> ((moment.duration(item.regular_shift_hour).asHours() ) *crvRate))
  s2Data = data.map(item=> ((moment.duration(item.regular_working_hour).asHours())*crvRate)) 
  s3Data = data.map(item=> ((moment.duration(item.regular_shift_hour).asHours() ) *crvRate))
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.regular_shift_hour).asHours()) * crvRate,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.regular_shift_hour).asHours()) * crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   (moment.duration(item.regular_working_hour).asHours()) * crvRate ,0) )
  }
  }
  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Logged Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )

}
else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='daily_worker' && compareFilter3=='total_gained'){
    
  if(reportby=='day'){
    s1Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() )*crvRate))
    s3Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() )*crvRate))
    s2Data = data.map(item=>  ((moment.duration(item.daily_working_hour).asHours()  )*crvRate))
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }
  else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours()) * crvRate,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours()) * crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   (moment.duration(item.daily_working_hour).asHours()) * crvRate ,0) )
  }

  } 
 
  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline",valAs:compareFilter1} )
  allSeries.push({name:"Logged Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline",valAs:compareFilter1 } )
}
//end of total_gained
//end of total_wasted
else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='all' && compareFilter3=='total_wasted'){
  if(reportby=='day'){
    s1Data = data.map(item=> ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours() )*crvRate))
    s3Data = data.map(item=> ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours() )*crvRate))
    s2Data = data.map(item=> ((moment.duration(item.regular_wasted_hour).asHours() +  moment.duration(item.daily_wasted_hour).asHours()  )*crvRate))
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }
  else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours() ))*crvRate,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.daily_shift_hour).asHours() +  moment.duration(item.regular_shift_hour).asHours() ))*crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   ((moment.duration(item.regular_wasted_hour).asHours() +  moment.duration(item.daily_wasted_hour).asHours()  ))*crvRate ,0) )
  }

  }
  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )
  
}else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='regular_worker' && compareFilter3=='total_wasted'){ 
  if(reportby=='day'){
    s1Data = data.map(item=> ((moment.duration(item.regular_shift_hour).asHours() )*crvRate))
    s3Data = data.map(item=> ((moment.duration(item.regular_shift_hour).asHours() )*crvRate))
    s2Data = data.map(item=> ((moment.duration(item.regular_wasted_hour).asHours() )*crvRate))
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }  else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.regular_shift_hour).asHours())*crvRate,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.regular_shift_hour).asHours())*crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   (moment.duration(item.regular_wasted_hour).asHours() )*crvRate ,0) )
  }

  }
  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )

}
else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='daily_worker' && compareFilter3=='total_wasted'){
  if(reportby=='day'){
  s1Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() )*crvRate))
  s3Data = data.map(item=>  ((moment.duration(item.daily_shift_hour).asHours() )*crvRate))
  s2Data = data.map(item=>  ((moment.duration(item.daily_wasted_hour).asHours())*crvRate))
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }
  else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours())*crvRate,0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  (moment.duration(item.daily_shift_hour).asHours())*crvRate,0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   (moment.duration(item.daily_wasted_hour).asHours() )*crvRate ,0) )
  }

  }
  allSeries.push({name:"Planned Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )
}
//gain_wasted
else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='all' && compareFilter3=='gained_wasted'){
  if(reportby=='day'){
    s1Data = data.map(item=> ((moment.duration(item.regular_working_hour).asHours() +  moment.duration(item.daily_working_hour).asHours())*crvRate))
    s3Data = data.map(item=> ((moment.duration(item.regular_shift_hour).asHours() +  moment.duration(item.daily_shift_hour).asHours())*crvRate))
    s2Data = data.map(item=> ((moment.duration(item.regular_wasted_hour).asHours() +  moment.duration(item.daily_wasted_hour).asHours())*crvRate))
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.regular_working_hour).asHours() +  moment.duration(item.daily_working_hour).asHours())*crvRate),0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.regular_shift_hour).asHours() +  moment.duration(item.daily_shift_hour).asHours())*crvRate),0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   ((moment.duration(item.regular_wasted_hour).asHours() +  moment.duration(item.daily_wasted_hour).asHours())*crvRate),0) )
  }

  }
  allSeries.push({name:"Logged Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )
 
}else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='regular_worker' && compareFilter3=='gained_wasted'){ 
  if(reportby=='day'){
  s1Data = data.map(item=> (moment.duration(item.regular_working_hour).asHours() *crvRate))
  s3Data = data.map(item=> (moment.duration(item.regular_shift_hour).asHours() *crvRate))
  s2Data = data.map(item=> (moment.duration(item.regular_wasted_hour).asHours()  *crvRate))
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
}else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.regular_working_hour).asHours())*crvRate),0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.regular_shift_hour).asHours())*crvRate),0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   ((moment.duration(item.regular_wasted_hour).asHours())*crvRate),0) )
  }

  }
  allSeries.push({name:"Logged Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )

}
else if(( compareFilter1=='hour' || compareFilter1=='dollar' ) && compareFilter2=='daily_worker' && compareFilter3=='gained_wasted'){
  if(reportby=='day'){
  s1Data = data.map(item=>  (moment.duration(item.daily_working_hour).asHours()*crvRate) )
  s3Data = data.map(item=>  (moment.duration(item.daily_shift_hour).asHours()*crvRate) )
  s2Data = data.map(item=>  (moment.duration(item.daily_wasted_hour).asHours() *crvRate) )
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.daily_working_hour).asHours())*crvRate),0) )
      s3Data.push(data2.reduce((oldVal, item) => oldVal +  ((moment.duration(item.daily_shift_hour).asHours())*crvRate),0) )
      s2Data.push(data2.reduce((oldVal, item) => oldVal +   ((moment.duration(item.daily_wasted_hour).asHours())*crvRate),0) )
  }

  }
  allSeries.push({name:"Logged Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
  allSeries.push({name:"Wasted Hrs",data:s2Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline" } )
}
else if(( compareFilter1=='percent') && compareFilter2=='all' && compareFilter3=='logged'){
 if(reportby=='day'){
  s1Data = data.map(item=> 
   ( (moment.duration(item.daily_working_hour).asHours() + moment.duration(item.regular_working_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours() + moment.duration(item.regular_shift_hour).asHours())) )
  
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >= moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0)) 
    
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (( moment.duration(item.daily_working_hour).asHours() + moment.duration(item.regular_working_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours() + moment.duration(item.regular_shift_hour).asHours())),0)/data2.length )

  }
  
  }

  
  allSeries.push({name:"Total Logged Hours",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )

}
else if(( compareFilter1=='percent') && compareFilter2=='all' && compareFilter3=='wasted'){
if(reportby=='day'){
 // s1Data = data.map(item=>  (item.daily_waste_percentage +  item.regular_waste_percentage)/2 )
  s1Data = data.map(item=>
    ( (moment.duration(item.daily_wasted_hour).asHours() + moment.duration(item.regular_wasted_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours() + moment.duration(item.regular_shift_hour).asHours())) )
 
  
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0) ) 
      // s1Data.push(data2.reduce((oldVal, item) => oldVal +  (item.daily_waste_percentage +  item.regular_waste_percentage)/2,0) )
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  ( (moment.duration(item.daily_wasted_hour).asHours() + moment.duration(item.regular_wasted_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours() + moment.duration(item.regular_shift_hour).asHours())),0)/data2.length )
  }
  }
  allSeries.push({name:"Wasted Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
 
}
else if(( compareFilter1=='percent') && compareFilter2=='regular_worker' && compareFilter3=='logged'){
 
   if(reportby=='day'){
  s1Data = data.map(item=>  (item.regular_working_percentage) )
  s1Data = data.map(item=>  
    (( moment.duration(item.regular_working_hour).asHours()) * 100  )/( (moment.duration(item.regular_shift_hour).asHours()))
    )
  
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (( moment.duration(item.regular_working_hour).asHours()) * 100  )/( (moment.duration(item.regular_shift_hour).asHours())),0) /data2.length)
  }
  }
  allSeries.push({name:"Total Logged Hours",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )

}
else if(( compareFilter1=='percent') && compareFilter2=='regular_worker' && compareFilter3=='wasted'){
  if(reportby=='day'){
  // s1Data = data.map(item=>  ( item.regular_waste_percentage) )
  s1Data = data.map(item=>  (( moment.duration(item.regular_wasted_hour).asHours()) * 100  )/( (moment.duration(item.regular_shift_hour).asHours())) )
  
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (( moment.duration(item.regular_wasted_hour).asHours()) * 100  )/( (moment.duration(item.regular_shift_hour).asHours())) ,0) /data2.length)
  }
  }
  allSeries.push({name:"Wasted Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
 

}
else if(( compareFilter1=='percent') && compareFilter2=='daily_worker' && compareFilter3=='logged'){

  if(reportby=='day'){
    // s1Data = data.map(item=>  (item.daily_working_percentage) )
    s1Data = data.map(item=>  (( moment.duration(item.daily_working_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours())) )
    
    cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
    }else{
      let monthDiff=getMonths(dates.start ,dates.end,reportby);
      if(reportby=='week'){
        // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
              cat = monthDiff.map((item,index)=>{return item.weekName})
      }else{  
        cat = monthDiff.map(item=>monthInt[item.month]); 
      }
      
      for(let j =0 ;j<monthDiff.length;j++){
        let data2 = data.filter(item=>moment(item.date) >=moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0)) 
        s1Data.push(data2.reduce((oldVal, item) => oldVal +  (( moment.duration(item.daily_working_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours())),0) )
    }
    }
    allSeries.push({name:"Total Logged Hours",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )

}
else if(( compareFilter1=='percent') && compareFilter2=='daily_worker' && compareFilter3=='wasted'){
if(reportby=='day'){
  // s1Data = data.map(item=>  (item.daily_waste_percentage) )
  s1Data = data.map(item=> (( moment.duration(item.daily_wasted_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours())) )
  
  cat = dataAll.map(item=>{return moment(item.date).format('DD MMM') })
  }else{
    let monthDiff=getMonths(dates.start ,dates.end,reportby);
    if(reportby=='week'){
      // cat = monthDiff.map((item,index)=>{return "Week"+(index+1)})
            cat = monthDiff.map((item,index)=>{return item.weekName})
    }else{  
      cat = monthDiff.map(item=>monthInt[item.month]); 
    }
    for(let j =0 ;j<monthDiff.length;j++){
      let data2 = data.filter(item=>moment(item.date) >= moment(monthDiff[j].start) &&  moment(item.date) <= moment(monthDiff[j].end) && (item.daily_attendance > 0 || item.regular_attendance > 0)) 
      s1Data.push(data2.reduce((oldVal, item) => oldVal +  (( moment.duration(item.daily_wasted_hour).asHours()) * 100  )/( (moment.duration(item.daily_shift_hour).asHours())),0)/data2.length )

  }

  }
  
  allSeries.push({name:"Wasted Hrs",data:s1Data,tooltip: {valueSuffix: toolnotation, valueDecimals:2},type:"spline"} )
}
for(let i=0 ;i<allSeries.length;i++){
 let newData = allSeries[i].data.map(item=>{
                if (Number.isNaN(item)) {
                        item = 0;
                    }
                    return item;
 }) 

 allSeries[i].data = newData

 
}

  
SetChartDataCompare({series:allSeries,categories:cat,total:s3Data,compareFilter1:compareFilter1,avg:s2Data})
}
},[compareFilter1,compareFilter2,compareFilter3,dataAll,reportby,permission])

function time_convert(num)
 { 
  var hours = Math.floor(num / 60);  
  var minutes = num % 60;

  if(isNaN (hours)){
    hours=0
  }
  if(isNaN(minutes)){
    minutes =0 
  }
  return hours.toFixed(0) + " hr " + minutes.toFixed(0)+" min";         
}

function SetAgencyListValue(val){
  if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<agencyList.length;i++){
        value.push(agencyList[i])
      }
      if((val.length -1) == value.length ){
        SetAgency([]);  
      }else{
        SetAgency(value);
      }

      
  }else{
    SetAgency(val);
  }
}
  return (
    
                <>{permissionAPICall?
            permission.length > 0?
            <div className="manpowerManagementMainDiv">
     
      <Container className="header-breadcrumb">
        <Row className="hideInPdf">
        <Col lg={6}>
            {/* <CommonHeading title="Report" /> */}


            {/* <CommonHeading title="Visitor Management" /> */}
             <div className="VisitorHeaderTab">
              <h5 className={activeTab=='overview'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>SetActiveTab('overview')}>Report Overview</h5>
              <h5 className={activeTab=='report'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>props.history.push('/report')}>Report Download</h5>
             </div>
                {/* <h5 class="commonHeading">Visitor Management</h5> */}

          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
              />
            </div>
          </Col>
        </Row>
        <Row>

<div id={"reportArea"} style={{width:"100%"}}>
  <Row className="reportChild hideAll">
    <Col lg={12} style={{padding:"0px 40px"}} >
      <div className="borderLess invoiceTable" style={{width:"100%"}}>
        <ul>
          <li style={{display:"inline-block",width:"55%",position:"relative",verticalAlign:"top"}} >
            <img src={userDetails.org_logo} />
          </li>
          <li colSpan={2} style={{display:"inline-block",width:"45%",position:"relative",left:"0%"}}>
            <table>
              <tr style={{padding:"8px 0px"}}><td>Downloaded on:-</td><td><strong>{moment().format('DD MMM YYYY')}</strong></td></tr>
              <tr style={{padding:"8px 0px"}}><td>Report Range:-</td><td><strong>{moment(dates.start).format('DD MMM YYYY') + " - " +moment(dates.end).format('DD MMM YYYY')}</strong></td></tr>
              <tr style={{padding:"8px 0px"}}><td>Report By:-</td><td><strong>{reportby}</strong></td></tr>
              <tr style={{padding:"8px 0px"}}><td>Conersion Rate Hr to $:-</td><td><strong>{conversionRate}</strong></td></tr>
            </table>
          </li>
        </ul>
      </div>

    </Col>

</Row>
        <Col lg={12} className={"reportChild hideInPdf"} style={{position:"relative"}}>
              <div
                className="manpowerManagementEmployeeListMainDiv m-t"
                style={{ backgroundColor: "#fff", padding: "0.8rem 1rem" }}
              >
 
                <Row className="dateBox minimalDiv" style={{margin:"0px"}}>
                    <Col lg={3} className={"p-l-0 p-r-0"}><h2 >{reportTitle}</h2></Col>
                    <Col lg={2} className="p-r-0">
                    {/* <div className="contactRankText">
                      {getTranslatedText("Report By")}
                    </div> */}
                    <div
                      className="startDateEndDateReportBY"
                      style={{ paddingLeft: "0px" }}
                    >
                        <div className="reportBtn"><button onClick={()=>SetReportBy('day')} className={reportby=='day'?'button button-primary button-active':'button button-primary'}>Day</button> </div>
                        <div className="reportBtn"><button onClick={()=>SetReportBy('week')} className={reportby=='week'?'button button-primary button-active':'button button-primary'}>Week</button> </div>
                        <div className="reportBtn"><button onClick={()=>SetReportBy('month')} className={reportby=='month'?'button button-primary button-active':'button button-primary'}>Month</button> </div>
                        
                       {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>

                  </Col>
                  
                  <Col style={{maxWidth:"150px"}} >
                    <div className="labelMergeInput">
                        <label className="zIndiLabelNew">Hr to $ Rate</label>
                        <input type={"number"} className="form-control"  value={conversionRate} 
                        // onChange={(e)=>{
                        //      let val = e.target.value;
                        //      val = val.replace( /^[-+]?[0-9]+\.[^0-9]+$/, '')
                        //      if(val.length>0){
                        //       SeConversionRate(val)
                        //     }

                        // }}
                        onChange={(e) => {
                          let val = e.target.value;
                          
                          // Check if the value is empty or contains only a decimal point
                          if (val === '' || val === '.') {
                            SeConversionRate(''); 
                          } else {
                            val = parseFloat(val); 
                            SeConversionRate(isNaN(val) ? '' : val.toString()); 
                          }
                        }}
                        pattern="^[0-9]*$" 
                        /> 
                      </div>
                  </Col>
                  
                  
                  <Col  className="p-r-0" style={{maxWidth:"250px"}}>
                    {/* <div className="contactRankText">
                      {getTranslatedText("End Date")}
                    </div> */}
                                        <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) =>{handleDate(date, "start");SetChart1Delay(true)}}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        value={dates.start}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={parseInt(userDetails?.org_id)== 31 && moment().subtract(120, "days").toDate() > new Date('2023-08-01')?new Date('2023-08-01'):new Date('2023-01-01')}
                       filterDate={isWeekday}
                      />
                    </div>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      
                      <DatePicker
                        selected={dates.end}
                        onChange={(date) =>{ handleDate(date, "end");SetChart1Delay(true)}}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={moment(dates.start).toDate()}
                        value={dates.end}
                        popperPlacement={"bottom-end"}
                        filterDate={isWeekday}
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>

                  <Col lg={3}>
                     <div className="startDateEndDateReportBY" style={{float:"left",width:"100%"}}>
                       {/*<div className="reportBtn" id={"reportBtn"}>
                         <button className='button button-primary button-active' onClick={()=>printDocument() }>Export</button> </div> */}
                         <div className="selectStyle2" style={{width:"150px",display:"inline-block"}}>
                        <Select
              placeholder="Select Agency"
                        
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetAgencyListValue(val)}}
              //defaultValue={department_id}
              value={agency}
              key="departmentFilterv2Agency2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
                              mode="tags"
                              disabled={chart1Delay}
              // mode={"multiple"}
          >
    
            <Option value='all' key={"Agency2 All"}>All</Option>
            {
              agencyList.map((item,index)=>{
                return <Option value={item} key={item+index+"1"}>{item}</Option>
              })
            }
            
          </Select> 
          </div>
                        
                        {!chart1Delay?
                        
                        <div className="reportBtn" id={"reportBtn"}>
                           
                           <button className='button button-primary button-active' onClick={()=>window.print() }><img src={ExportPdf}/> Export </button></div>:""}
                         </div> 
                        {/* <PDFDownloadLink document={<DemoPDF />} fileName="demo.pdf">Download</PDFDownloadLink> */}
                        {/* <PDFDownloadLink document={<DemoPDF />} fileName="demo.pdf">
                            {({ blob, url, loading, error }) => ( 'Download now!')}
                            </PDFDownloadLink> */}
                          {/* {!chart1?<ReportTemplate 
                                
                                //First Section Attendance Bill
                                data={dataAll}
                                dataLogo={userDetails.org_logo}
                                dataPlanned={chartData}
                                total_regular_work_hour={totalBillRegularHrs}
                                total_regular_waste_hour={regularWastedHour}
                                total_daily_work_hour = {totalBillDailyHrs}
                                total_daily_wasted_hour = {dailyWastedHour}
                                avg_regular_shift={time_convert((totalBillRegularHrs/regularCount) *60)}
                                avg_daily_shift ={time_convert((totalBillDailyHrs/dailyCount)*60)}
                                
                                //Second Section Work Time Achieved Work Force
                                regular_work_percentage = {percentRegularWorkHour}
                                daily_work_percentage = {percentDailyWorkHour}
                                regular_wasted_percentage = {percentRegularWastedHour}
                                daily_wasted_percentage={percentDailyWastedHour}
                                work_chart = {chartDataCompare}
                                
                                // Third Section Perfomance Badges

                                gold_badge={goldCount}
                                silver_badge={silverCount}
                                no_badge={noBadgeCount}
                                badgeChart = {chartDataBadges}

                                //Star Rating
                                five_star={fiveCount}
                                four_star={fourCount}
                                three_star={threeCount}
                                two_star={twoCount}
                                one_star={oneCount}
                                zero_star={zeroCount}
                                ratingChart={chartDataRating}

                                //top function
                                top_function_department={department}
                                top_function_sub_department={subdepartment}

                                //top function regular
                                top_performer_regular={topPerformerReg}
                                top_performer_daily={topPerformerDaily}

                              />:""} */}
                  </Col>
                </Row>


              

</div>

            </Col>
            
            
            <Col lg={12} className={"reportChild"}>
              <div
                className="manpowerManagementEmployeeListMainDiv plannedWorkForce m-t"
                style={{ backgroundColor: "#fff", padding: "2rem 1.5rem" }}
              >
                <Row className="bdrHeader">
                <Col lg={6}>
                    <h2 className="titleHeaderReport">Planned Work Force</h2>
                </Col>
                <Col lg={6} className="titleHeaderReport titleHeaderReportAvg p-l-0">
                  <div className="averageStyle">
                  {!chart1?<><div><div style={{marginRight:"5px",textAlign:"right"}}>Shift Avg Hr :</div></div>
                   <div style={{float:"right"}}><strong>{time_convert(  (totalBillRegularHrs/regularCount) *60)}</strong> Regular Worker  <span style={{margin:"0px 5px"}}>/</span>  <strong>{time_convert((totalBillDailyHrs/dailyCount)*60)}</strong> Daily Worker</div></>:""}
                  </div>
                </Col>
                </Row>
                

                <div className="chartMainDiv chartMainDivProductivity chartMainDivReport">
                 
                  <div className="chart">
                    <div className="titleBox">
                      <div className="titleSmall col p-l-0 p-r-0">Total Regular Workers: <strong>{!chart1?regularCount:"-"} <span>Nos</span></strong> </div>
                      <div className="titleSmall col p-l-0 p-r-0" style={{textAlign:"center"}}>Total Billable Hours: <strong>{!chart1?ConvertSectoDay(parseFloat(totalBillRegularHrs)*60*60):"-"} </strong></div>
                      <div className="titleSmall col p-l-0 p-r-0" style={{textAlign:"right"}}>Total Bill: <strong><span>$</span> {!chart1?(totalBillRegularHrs * conversionRate).toFixed(2):"-"}</strong></div>
                      {/* <div className="titleCount">1200</div> */}
                    </div>
                    {/* <BarChart
                      categories={series2.categories}
                      series={series2.series}
                      seriesName={"Regular Worker"}
                    /> */}
                    
                  </div>
                  <div className="chart">
                    <div className="titleBox">
                    <div className="titleSmall col p-l-0 p-r-0">Total Daily Workers: <strong>{!chart1?dailyCount:"-"} <span>Nos</span></strong> </div>
                      <div className="titleSmall col p-l-0 p-r-0" style={{textAlign:"center"}}>Total Billable Hours: <strong>{!chart1?ConvertSectoDay(parseFloat(totalBillDailyHrs)*60*60):"-"}</strong></div>
                      <div className="titleSmall col p-l-0 p-r-0" style={{textAlign:"right"}}>Total Bill: <strong><span>$</span> {!chart1?(totalBillDailyHrs * conversionRate).toFixed(2):"-"}</strong></div>
                    </div>
                    {/* <BarChart
                      categories={series2.categories}
                      series={series2.series}
                      seriesName={"Daily Worker"}
                    /> */}
                  </div>
                </div>
                <div style={{marginTop:"15px",paddingTop:"10px",paddingBottom:"10px",overflow:"hidden",borderRadius:"20px",background:"#fff"}}>
                   {chart1?<div className="loadCenter"><img src={spinnerLoader} /></div>:<div style={{height:"300px"}}><ChartReportMultiAxis 
                        chartData={chartData}
                        hideUnd={true} 
                        close={()=>SetChart1(false)}
                         yAuto={true} height={300} 
                         legendEnable={false} key={"reportV2Attendance"}
                         
                         /></div>}
                </div>






</div>

            </Col>
            
{/* Total Hour and Waste hour */}

{/* <Col lg={12} className="m-t">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >

                  <div style={{marginTop:"15px",paddingTop:"10px",paddingBottom:"10px",overflow:"hidden",borderRadius:"20px",background:"#fff"}}>
                  <div style={{height:"300px"}}>

                  {chart2?<div className="loadCenter"><img src={spinnerLoader} /></div>:
                    <ChartReportMultiAxis 
                        chartData={chartDataReport}
                        hideUnd={true} 
                        close={()=>SetChart1(false)}
                         yAuto={true} height={300} 
                         legendEnable={false} key={"reportV3"} 
                        sortby={chartSortBy}
                        
                         />}
                </div>
                </div>

                </div>
                </Col> */}

{/* Compare Conversion */}

<Col lg={12} className="m-t reportChild" >
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                <div className="bdrHeader">
                <h2 className="titleHeaderReport">Achieved Work Force</h2>
                </div>
                
                
                                                    <div className="legendReportContainer legendReportContainerSmallSpace legendReportContainerHighlight">
                                  <div className="row">
                                          <div className="legendBox legendBoxReport bgPrintLightblue col">
                                              <h4>Total Working Time </h4>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Regular Worker</div>
                                                <div className="col text-right"><strong>{!chart1?isNaN(percentRegularWorkHour.toFixed(2))?0:percentRegularWorkHour.toFixed(2) :"-"}% / {!chart1?ConvertSectoDay( regularWorkingHour *60*60 )  :"-"}</strong></div>
                                              </div>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Daily Worker</div>
                                                <div className="col text-right"><strong>{!chart1?isNaN(percentDailyWorkHour.toFixed(2))?0:percentDailyWorkHour.toFixed(2):"-"}% / {!chart1?ConvertSectoDay(dailyWorkingHour*60*60):"-"}</strong></div>
                                              </div>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Billable Logged</div>
                                                {/* <div className="col text-right"><strong>${!chart1?(parseFloat(conversionDailyCount) + parseFloat(conversionRegularCount)):"-"}</strong></div> */}
                                                <div className="col text-right"><strong>${!chart1?((parseFloat(regularWorkingHour) + parseFloat(dailyWorkingHour) )*conversionRate).toFixed(2):"-"}</strong></div>
                                              </div>
                                          </div>
                                        
                                          <div className="legendBox legendBoxReport  col">
                                              <h4>Total Wasted Time</h4>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Regular Worker</div>
                                                <div className="col text-right"><strong>{!chart1?isNaN(percentRegularWastedHour.toFixed(2))?0:percentRegularWastedHour.toFixed(2):"-"}% / {!chart1?ConvertSectoDay(regularWastedHour * 60 *60):"-"}</strong></div>
                                              </div>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Daily Worker</div>
                                                <div className="col text-right"><strong>{!chart1?isNaN(percentDailyWastedHour.toFixed(2))?0:percentDailyWastedHour.toFixed(2):"-"}% / {!chart1?ConvertSectoDay(dailyWastedHour*60*60) :"-"} </strong></div>
                                              </div>
                                              <div className={"legendListReport"}>
                                                <div className="col text-left">Wasted</div>
                                                <div className="col text-right"><strong>${!chart1?( (parseFloat(regularWastedHour) + parseFloat(dailyWastedHour)) * conversionRate).toFixed(2):"-"}</strong></div>
                                              </div>
                                          </div>

                                </div>
                  </div>
                                          <div className="m-t legendReportContainer legendReportContainerSmallSpace legendReportContainerSmallSpace3">
<Row>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">
      
      <h6 style={{margin:"0px",lineHeight:"40px"}}>Compare</h6>  </Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto  noBorderBtm col">
        <Select
              placeholder="Compare By"
              optionFilterProp="children"
             onChange={(val) => {
                SetCompareFilter1(val);
                if (val === 'percent') {
                  SetCompareFilter3('logged');
                } else if (val === 'hour' || val === 'dollar') {
                  SetCompareFilter3('total_gained');
                } else {
                  if (compareFilter3 === 'logged') {
                    SetCompareFilter3('total_gained');
                  }
                }
              }}
              
              //defaultValue={department_id}
              value={compareFilter1==''?"Select Type":compareFilter1}
              key="departmentFilterv6"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
            
          >
            <Option value='dollar' key={"ChartByPercent"}>Dollar</Option>
             <Option value='hour' key={"ChartByHour"}>Hour</Option>                     
             <Option value='percent' key={"ChartByHourPerc"}>Percent</Option>                     
          </Select> 
    </Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">
    <Select
              placeholder="Compare By"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetCompareFilter2(val)}}
              //defaultValue={department_id}
              value={compareFilter2==''?"Select Type":compareFilter2}
              key="departmentFilterv6"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
            <Option value='all' key={"ChartByPercentAll"}>All</Option>
            <Option value='regular_worker' key={"ChartByPercentReg"}>Regular Worker</Option>
             <Option value='daily_worker' key={"ChartByHourDail"}>Daily Worker</Option>                     
          </Select> 
    </Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">
      {compareFilter1!='percent'?
    <Select
              placeholder="Compare By"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetCompareFilter3(val)}}
              //defaultValue={department_id}
              value={compareFilter3==''?"Select Type":compareFilter3}
              key="departmentFilterv7"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
            
            <Option value='total_gained' key={"ChartByPercentAllTG"}>Total vs Logged </Option>
            <Option value='total_wasted' key={"ChartByPercentReg"}>Total vs Wasted</Option>
             <Option value='gained_wasted' key={"ChartByHourDail"}>Logged vs Wasted</Option>                     
            
          </Select>:
          <Select
              placeholder="Compare By"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetCompareFilter3(val)}}
              //defaultValue={department_id}
              value={compareFilter3==''?"Select Type":compareFilter3}
              key="departmentFilterv7"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
            <Option value='logged' key={"ChartByPercentAllTGwasted"}>Logged </Option>
            <Option value='wasted' key={"ChartByPercentReglogged"}>Wasted</Option>
          </Select>
              } 
    </Col>
    </Row>


                                          </div>
                                        
                               

                  <div style={{marginTop:"15px",paddingTop:"10px",paddingBottom:"10px",overflow:"hidden",borderRadius:"20px",background:"#fff"}}>
                  <div style={{height:"300px"}}>

                  {chart2?<div className="loadCenter"><img src={spinnerLoader} /></div>:
                    <ChartReportMultiAxis 
                        chartData={chartDataCompare}
                        hideUnd={true} 
                        close={()=>SetChart1(false)}
                         yAuto={true} height={300} 
                         legendEnable={false} 
                        sortby={chartSortBy}
                        compareFilter1={compareFilter1}
                        key={"reportV2Compare"}
                         />}
                </div>
                </div>
                </div>
                </Col>


{/* Badge Start */}
            <Col lg={12} className="m-t reportChild">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                            
                                <div className="bdrHeader">
                                <h2 className="titleHeaderReport">Perfomance Badges</h2>
                </div>
<div className="legendReportContainer legendReportContainerSmallSpace legendReportContainerSmallSpace3">
<Row>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">Total Gold Badge <strong>{goldCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">Total Silver Badge <strong>{silverCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">Total No Badge <strong>{noBadgeCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">
    {/* {badgeChart=="regular"?"Regular Worker":"Daily Worker"} */}
    
      <Select
              placeholder="Badge By"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetBadgeChart(val)}}
              //defaultValue={department_id}
              value={badgeChart==''?"Select Type":badgeChart}
              key="departmentFilterv8"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
            {/* <Option value='all' key={"ChartByPercentAllTG"}>All</Option> */}
            <Option value='all' key={"ChartByPercentReg"}>All</Option>
            <Option value='regular' key={"ChartByPercentReg"}>Regular Worker</Option>
             <Option value='daily' key={"ChartByHourDail"}>Daily Worker</Option>                     
          </Select> 

     </Col>
    </Row>
    </div>
       <div style={{height:"300px",marginTop:"10px",background:"#fff"}}>

{chart2?<div className="loadCenter"><img src={spinnerLoader} /></div>:
  <ChartReportMultiAxis 
      chartData={chartDataBadges}
      hideUnd={true} 
      close={()=>SetChart3(false)}
       yAuto={true} height={300} 
       legendEnable={false} 
      sortby={chartSortBy}
      key={"reportV2Badges"}
      
       />}

</div>

                </div>
               </Col> 


{/* Rating Start */}
            <Col lg={12} className="m-t reportChild">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Worker Star Rating</h2>                
                </div>
                
<div className="legendReportContainer legendReportContainerSmallSpace legendReportContainerSmallSpace3"  >
<Row>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(5)}}></div> <strong>{fiveCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(4)}}></div> <strong>{fourCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(3)}}></div> <strong>{threeCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(2)}}></div> <strong>{twoCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(1)}}></div> <strong>{oneCount}</strong></Col>
    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col"><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(0)}}></div> <strong>{zeroCount}</strong></Col>

    <Col className="legendBox legendBoxReport legendBoxReportAuto noBorderBtm col">
    <Select
              placeholder="Badge By"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetRatingfor(val)}}
              //defaultValue={department_id}
              value={ratingfor==''?"Select Type":ratingfor}
              key="departmentFilterv9"
              style={{width:"100%",marginTop:"15px"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
            {/* <Option value='all' key={"ChartByPercentAllTG"}>All</Option> */}
            <Option value='all' key={"ChartByPercentReg"}>All</Option>
            <Option value='regular' key={"ChartByPercentReg"}>Regular Worker</Option>
             <Option value='daily' key={"ChartByHourDail"}>Daily Worker</Option>                     
          </Select> 
  
</Col>
   </Row> 
   </div>
       <div style={{height:"300px",marginTop:"10px",background:"#fff"}}>

{chart3?
  <div className="loadCenter">
    <img src={spinnerLoader} />
    </div>:
    <ChartReportMultiAxis 
      chartData={chartDataRating}
      hideUnd={true} 
      close={()=>SetChart3(false)}
       yAuto={true} height={300} 
       legendEnable={false} 
      sortby={chartSortBy}
      key={"reportV2Rating"}
      
       />}
</div>

              </div>
             </Col> 
            
             {/* Top Function and performer */}



            <Col lg={12} className="reportChild hideInPdf">
              <div
                className="manpowerManagementEmployeeListMainDiv m-t"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                <div className="legendReportContainer legendReportContainerSmallSpace">
                <Row>
                <Col className="p-l-0" style={{height:"400px"}}>
                  <div className="headerArea legendBox legendBoxReport legendBoxReportAuto col">
                    <Row>
                      <Col lg={6}>
                    <h6 >Top Function</h6></Col>
                    <Col lg={3}>
                      <Select
                          placeholder="Function"
                          optionFilterProp="children"
                        
                          onChange={(val)=>{SetTopFunctionBy(val)}}
                        
                          value={topFunctionBy ==''?"Select Function":topFunctionBy}
                          key="departmentFilterv2TopFunctionBY"
                          style={{width:"200px"}}
                          className={'bgSkyBlue'}
                          dropdownClassName={'smallerDrop'}
                          // mode={"multiple"}
                      >
                        <Option value='department' key={"ChartByPercentWorkingDept"}>Department</Option>
                        <Option value='sub_department' key={"ChartByPercentbadgeSubDept"}>Sub Department</Option>        
                      </Select>
                    </Col>
                    <Col lg={3}>
                    <Select
                        placeholder="Function"
                        optionFilterProp="children"
                        //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
                        onChange={(val)=>{SetTopFunction(val)}}
                        //defaultValue={department_id}
                        value={topFunction==''?"Select Function":topFunction}
                        key="departmentFilterv2TopFunction"
                        style={{width:"200px"}}
                        className={'bgSkyBlue'}
                        dropdownClassName={'smallerDrop'}
                        // mode={"multiple"}
                    >
                      <Option value='percentage' key={"AGChartByHourRatingpercentage"}>percentage</Option>                            
                      <Option value='working_time' key={"AGChartByPercentWorking"}>Working Time</Option>
                      <Option value='badge' key={"AGChartByPercentbadge"}>Badge</Option>
                      <Option value='rating' key={"AGChartByHourRating"}>Rating</Option>              

                    </Select>

                    </Col>
                    
                    </Row>
                   
                  </div>
                  <div className="agTableStart m-t" >
                      {AGTable('top')}
                    </div>
                </Col>
                <Col className="p-l-0" style={{height:"400px"}}>
                  <div className="headerArea legendBox legendBoxReport legendBoxReportAuto col">
                    <Row>
                      <Col lg={6}>
                        <h6 >Top Performer</h6>
                      </Col>
                      <Col lg={6}>
                      <Select
                          placeholder="Badge By"
                          optionFilterProp="children"
                          //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
                          onChange={(val)=>{SetTopPerformBy(val)}}
                          //defaultValue={department_id}
                          value={topPerformBy==''?"Select Type":topPerformBy}
                          key="departmentFilterv10"
                          style={{width:"80%"}}
                          dropdownClassName={'smallerDrop'}
                          className={'bgSkyBlue'}
                          // mode={"multiple"}
                      >
                        {/* <Option value='all' key={"ChartByPercentAllTG"}>All</Option> */}
                        <Option value='all' key={"ChartByPercentRegtopPerformBy131"}>All</Option>
                        <Option value='regular' key={"ChartByPercentRegtopPerformBy11"}>Regular Worker</Option>
                        <Option value='daily' key={"ChartByHourDailtopPerformBy21332"}>Daily Worker</Option>                     
                      </Select> 
                      </Col>
                    </Row>
                  </div>
                  <div className="agTableStart m-t" >
                      {AGTableEmployee('top')}
                    </div>
                </Col>




          




                </Row>
                </div>




</div>
</Col>


             {/* Bottom Function and performer */}



            <Col lg={12} className="reportChild hideInPdf">
              <div
                className="manpowerManagementEmployeeListMainDiv m-t"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                <div className="legendReportContainer legendReportContainerSmallSpace">
                <Row>
                <Col className="p-l-0" style={{height:"400px"}}>
                  <div className="headerArea legendBox legendBoxReport legendBoxReportAuto col">
                    <Row>
                      <Col lg={6}>
                    <h6 >Bottom Function</h6></Col>
                    <Col lg={3}>
                      <Select
                          placeholder="Function"
                          optionFilterProp="children"
                        
                          onChange={(val)=>{SetBottomFunctionBy(val)}}
                        
                          value={bottomFunctionBy ==''?"Select Function":bottomFunctionBy}
                          key="departmentFilterv2BottomFunctionBY"
                          style={{width:"200px"}}
                          className={'bgSkyBlue'}
                          dropdownClassName={'smallerDrop'}
                          // mode={"multiple"}
                      >
                        <Option value='department' key={"ChartByPercentWorkingDeptBottom"}>Department</Option>
                        <Option value='sub_department' key={"ChartByPercentbadgeSubDeptBottom"}>Sub Department</Option>        
                      </Select>
                    </Col>
                    <Col lg={3}>
                    <Select
                        placeholder="Function"
                        optionFilterProp="children"
                        //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
                        onChange={(val)=>{SetBottomFunction(val)}}
                        //defaultValue={department_id}
                        value={bottomFunction==''?"Select Function":bottomFunction}
                        key="departmentFilterv2BottomFunction"
                        style={{width:"200px"}}
                        className={'bgSkyBlue'}
                        dropdownClassName={'smallerDrop'}
                        // mode={"multiple"}
                    >
                      <Option value='percentage' key={"AGChartByHourRatingpercentageBottom"}>percentage</Option>                            
                      <Option value='working_time' key={"AGChartByPercentWorkingBottom"}>Working Time</Option>
                      <Option value='badge' key={"AGChartByPercentbadgeBottom"}>Badge</Option>
                      <Option value='rating' key={"AGChartByHourRatingBottom"}>Rating</Option>              

                    </Select>

                    </Col>
                    
                    </Row>
                   
                  </div>
                  <div className="agTableStart m-t" >
                      {AGTable('low')}
                    </div>
                </Col>
                <Col className="p-l-0" style={{height:"400px"}}>
                  <div className="headerArea legendBox legendBoxReport legendBoxReportAuto col">
                    <Row>
                      <Col lg={6}>
                        <h6 >Low Performer
                                     <div className="relative">
                                                <img
                                                    alt=""
                                                    className="helpicon"
                                                    src={helpIcon}
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(`attendanceHelp`)
                                                    }
                                                    onMouseLeave={() =>
                                                        handleMouseLeave(`attendanceHelp`)
                                                    }
                                                />
                                                <div className="descHelp" id="attendanceHelp">
                                                List of workers with less than 70% working hour
                                                </div>
                                            </div>

                        </h6>
                      </Col>
                      <Col lg={6}>
                      <Select
                          placeholder="Badge By"
                          optionFilterProp="children"
                          //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
                          onChange={(val)=>{SetLowPerformBy(val)}}
                          //defaultValue={department_id}
                          value={lowPerformBy==''?"Select Type":lowPerformBy}
                          key="departmentFilterv10Low"
                          style={{width:"80%"}}
                          dropdownClassName={'smallerDrop'}
                          className={'bgSkyBlue'}
                          // mode={"multiple"}
                      >
                        {/* <Option value='all' key={"ChartByPercentAllTG"}>All</Option> */}
                        <Option value='all' key={"ChartByPercentRegtopPerformBy131Low"}>All</Option>
                        <Option value='regular' key={"ChartByPercentRegtopPerformBy11Low"}>Regular Worker</Option>
                        <Option value='daily' key={"ChartByHourDailtopPerformBy21332Low"}>Daily Worker</Option>                     
                      </Select> 
                      </Col>
                    </Row>
                  </div>
                  <div className="agTableStart m-t" >
                      {AGTableEmployee('low')}
                    </div>
                </Col>




          




                </Row>
                </div>




</div>
</Col>


{/* Top Function PDF Table */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Top Function By Department</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Department Name</th>
              <th>Working Hours</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {department.map(item=>{
  return (
    <tr key={"departmentTable"+item.name}>
      <td>{item.name}</td>
      <td>{ConvertSectoDay(item.working_time)}</td>
      <td>{(item.percentage).toFixed(2)+"%"}</td>
      <td>{item.badges}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 

{/* Top Function Sub PDF Table */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Top Function  By Sub Department</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Sub Department Name</th>
              <th>Working Hours</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {subdepartment.map(item=>{
  return (
    <tr key={"departmentTableSub"+item.name}>
      <td>{item.name}</td>
      <td>{ConvertSectoDay(item.working_time)}</td>
      <td>{(item.percentage).toFixed(2)+"%"}</td>
      <td>{item.badges}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 

{/* Top performer PDf Regular Worker */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Top Performer Regular Worker</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Employee Name</th>
              <th>Worker ID</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {topPerformerReg.map(item=>{
  return (
    <tr key={"departmentTableEmployee"+item.emp_name}>
      <td>{item.emp_name}</td>
      <td>{item.worker_id}</td>
      <td>{(parseFloat(item.working_percentage)> 100?100:item.working_percentage).toFixed(2)+"%"}</td>
      <td>{item.working_percentage>= 80?"Gold Badges":item.working_percentage>= 60 && item.working_percentage<80?"Silver Badges":"No Badges"}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 

{/* Top performer PDf Daily Worker */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Top Performer Daily Worker</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Employee Name</th>
              <th>Worker ID</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {topPerformerDaily.map(item=>{
  return (
    <tr key={"departmentTableEmployee"+item.emp_name}>
      <td>{item.emp_name}</td>
      <td>{item.worker_id}</td>
      <td>{(parseFloat(item.working_percentage)> 100?100:item.working_percentage).toFixed(2)+"%"}</td>
      <td>{item.working_percentage>= 80?"Gold Badges":item.working_percentage>= 60 && item.working_percentage<80?"Silver Badges":"No Badges"}</td>      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 

{/* Bottom Function PDF Table */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Bottom Function By Department</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Department Name</th>
              <th>Working Hours</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {departmentLower.map(item=>{
  return (
    <tr key={"departmentTable"+item.name}>
      <td>{item.name}</td>
      <td>{ConvertSectoDay(item.working_time)}</td>
      <td>{(item.percentage).toFixed(2)+"%"}</td>
      <td>{item.badges}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 
{/* Bottom Sub Function Sub PDF Table */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Bottom Function  By Sub Department</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Sub Department Name</th>
              <th>Working Hours</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {subDepartmentLower.map(item=>{
  return (
    <tr key={"departmentTableSub"+item.name}>
      <td>{item.name}</td>
      <td>{ConvertSectoDay(item.working_time)}</td>
      <td>{(item.percentage).toFixed(2)+"%"}</td>
      <td>{item.badges}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 

{/* Low performer PDf Regular Worker */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Low Performer Regular Worker</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Employee Name</th>
              <th>Worker ID</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {[...empNeedAttention].filter(item=>item.worker_type == 'regular_worker').map(item=>{
  return (
    <tr key={"departmentTableEmployee"+item.emp_name}>
      <td>{item.emp_name}</td>
      <td>{item.worker_id}</td>
      <td>{(parseFloat(item.working_percentage)> 100?100:item.working_percentage).toFixed(2)+"%"}</td>
      <td>{item.working_percentage>= 80?"Gold Badges":item.working_percentage>= 60 && item.working_percentage<80?"Silver Badges":"No Badges"}</td>
      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 


{/* Low performer PDf Daily Worker */}
<Col lg={12} className="m-t reportChild hideAll">
              <div
                className="manpowerManagementEmployeeListMainDiv"
                style={{ backgroundColor: "#fff", padding: "1rem" }}
              >
                                                <div className="bdrHeader">
                                                <h2 className="titleHeaderReport">Low Performer Daily Worker</h2>                
                </div>
                
       <div style={{marginTop:"10px",background:"#fff"}}>

    <table className="withBorder">
        <thead>
            <tr>
              <th>Employee Name</th>
              <th>Worker ID</th>
              <th>Performance</th>
              <th>Badges</th>
              <th>Rating</th>
            </tr>
        </thead>

        <tbody>
 {[...empNeedAttention].filter(item=>item.worker_type == 'daily_worker').map(item=>{
  return (
    <tr key={"departmentTableEmployee"+item.emp_name}>
      <td>{item.emp_name}</td>
      <td>{item.worker_id}</td>
      <td>{(parseFloat(item.working_percentage)> 100?100:item.working_percentage).toFixed(2)+"%"}</td>
      <td>{item.working_percentage>= 80?"Gold Badges":item.working_percentage>= 60 && item.working_percentage<80?"Silver Badges":"No Badges"}</td>      <td><div style={{maxWidth:"80px",display:"inline-block",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: starRating(item.rating)}}></div></td>
    </tr>
  )
 })}


    </tbody>
    </table>
    
    
</div>

              </div>
             </Col> 



</div>


          </Row>
    
              </Container>
    </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
    
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ReportOverview)
);
