import React from 'react'
import ReactHighcharts from 'react-highcharts'

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696" 
}
const BarChart = (props) => {

    let totalSiteLocation = localStorage.getItem('totalSiteLocation') ? localStorage.getItem('totalSiteLocation') : 0;
    let totalEmployeeTag = localStorage.getItem('totalEmployeeTag') ? localStorage.getItem('totalEmployeeTag') : 0;
    let MaxValue = null; 
   
    if( props.seriesName=='Risky Locations'){
        MaxValue = totalSiteLocation;
    }   
    else if( props.seriesName=='Attendance' || props.seriesName=='Super Spreader' || props.seriesName=='Super Bridger' ){
        MaxValue = totalEmployeeTag;
    }

    let plotLines ='';

    let disptitle='';
   
    if(props.seriesName=='Risky Locations'){
        disptitle= 'Location';
    
    }
    else if(props.seriesName=='Area Index' || props.seriesName=='Formit Index' ){
        disptitle= 'Risk';
    }else if(props.seriesName=='Attendance' || props.seriesName=='Super Spreader' || props.seriesName=='Super Bridger' ){
        disptitle= 'Peoples';
    }else{
        disptitle= props.seriesName;
    }

    let config = {
        chart: {
            type: 'column',
            height:props.seriesName=='Performance'?375:window.innerWidth<1367?200:270,
            borderRadius: 8,
            zoomType: 'x',
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            useHTML: true,
            formatter:  function () {

                  return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x}</span><span style="font-size: 10px" class="tool-tip-date">${this.point.options.tooltipDate}</span><br/>
                         <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                         <span dx="0" class="tool-tip-name"> ${disptitle}: </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y}</tspan>`;
                },
              //shared: props.isTooltipShared,
             borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: 15
        },
        xAxis: {
            categories: props.categories || [1,2,3,4,5,6,7],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?6:7);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
            title: {
                text: null
            },
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 1,
            min: 0,
            max : MaxValue,
            // max: 100,
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            },
 
            labels: {
                enabled: true
            },


        },
        series: [{
            name: props.seriesName || '',
            showInLegend: false,
            data:  props.series || [{ y: 15, name: 1 }, { y: 42, name: 2 }, { y: 8, name: 3 }, { y: 72, name: 4 }, { y: 55, name: 5 }, { y: 88, name: 6 }, { y: 25, name: 7 }],


        }]
    }

  
    if(props.seriesName=='Area Index' || props.seriesName=='Formit Index' ){
    
        config = {
            chart: {
                type: 'column',
                height: window.innerWidth<1367?200:270,
                borderRadius: 8,
                zoomType: 'x',
               spacingLeft: 30,
                spacingRight: 30,
            },
            
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: null
            },
            tooltip: {
                useHTML: true,
                formatter:  function () {

                    
                      return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${this.x}</span><span style="font-size: 10px" class="tool-tip-date">${this.point.options.tooltipDate}</span><br/>
                             <span style="background:${this.color}" x="8" dy="15" class="tool-tip-status"></span>
                             <span dx="0" class="tool-tip-name"> ${disptitle}: </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y}</tspan>`;
                    },
                  //shared: props.isTooltipShared,
                 borderColor: '#FFFFFF',
                backgroundColor: '#FFFFFF',
                borderRadius: 15
            },
            xAxis: {
                categories: props.categories || [1,2,3,4,5,6,7],
                tickPositioner: function () {
                    let positions = []
                    let tick = Math.floor(this.dataMin)
                    let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 6: 7 );
        
                    if (this.dataMax === this.dataMin) {
                        return [0]
                    }
    
                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
    
                    return positions;
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                gridLineDashStyle: 'ShortDash',
                gridLineWidth: 0,
                min: 0,
                max: 100,
                labels: {
                    enabled: false
                },
                    
                plotLines: [
                    {
                        color: riskLevelColor.high,
                        width: 1,
                        value: 100,
                        dashStyle: 'LongDash',
                        zIndex: 100,
                        label: {
                            text: 'High',
                            style: {
                                color: riskLevelColor.high,
                                fontWeight: '500',
                                fontSize: '10px'

                            },
                            rotation:270,
                            className:'Demo-high labelfixchart',
                            y: window.innerWidth<1367?35:45,
                            x:-10
                        }
                    },
                    {
                        color: riskLevelColor.medium,
                        width: 1,
                        value: 66,
                        dashStyle: 'LongDash',
                        zIndex: 100,
                        label: {
                            text: 'Medium',
                            style: {
                                color: riskLevelColor.medium,
                                fontWeight: '500',
                                fontSize: '10px'
                            },
                            rotation:270,
                            className:'Demo-high labelfixchart',
                            y: window.innerWidth<1367?45:55,
                            x:-10
                        }
                    },
                    {
                        color: riskLevelColor.low,
                        width: 1,
                        value: 33,
                        dashStyle: 'LongDash',
                        zIndex: 100,
                        label: {
                            text: 'Low',
                            style: {
                                color: riskLevelColor.low,
                                fontWeight: '500',
                                fontSize: '10px'

                            },
                            rotation:270,
                            className:'Demo-high labelfixchart',
                            y: window.innerWidth<1367?35:45,
                            x:-10
                        }
                    }
                ]
            },
            series: [{
                name: props.seriesName || '',
                showInLegend: false,
                data:  props.series || [{ y: 15, name: 1 }, { y: 42, name: 2 }, { y: 8, name: 3 }, { y: 72, name: 4 }, { y: 55, name: 5 }, { y: 88, name: 6 }, { y: 25, name: 7 }],
                zones: [
                    {
                        value: 33,
                        color: riskLevelColor.low
                    },
                    {
                        value: 66,
                        color: riskLevelColor.medium
                    },
                    {
                        // value: 100,
                        color: riskLevelColor.high
                    }
                ]
            }]
        }




    }



    return (
        <div    >
            <ReactHighcharts config={config} />
        </div>
    )
}

export default BarChart