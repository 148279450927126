import React from 'react'
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts-exporting'
import moment from "moment";

// HighchartsExporting(ReactHighcharts.Highcharts)

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}


const StackChart = (props) => {
   
   
    let type= props.categories.length < 7?'week':'month';
    let config = {
        chart: {
            type: 'areaspline',
            height: props.height?props.height:270,
            spacing: [0,0,0,0],
            events: {
            load: function(event) {
                event.target.reflow();
            }
    }
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: props.categories,
            startOnTick:true,
            labels : {
                formatter:function(){
                    
                    if(props.typePerormance && (props.typePerormance=='week' || props.typePerormance=='today' || props.typePerormance=='month')){
                        
                         return this.value       
                    }else{
                    return type=="week"?moment(this.value).format('ddd, MMM DD'):moment(this.value).format('DD MMM');
                    }
                   
                }

              },

              tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment;
                if(props.tickPositioner){
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?3:2);
                }
                else if(props.modalChart){
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?3:2);
                }
                else if(type=='week' || props.typePerormance=='today'){
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?1:1);
                }else{
                    increment = Math.ceil((this.dataMax - this.dataMin) / window.innerWidth<1367?2:1);
                }
                
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min: 0,
            max:100,
            labels: {
                enabled: true,
                formatter:function(){
                    
                    return this.value+"%";
                    
                   
                }
            },
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },        
        tooltip: {
            useHTML: true,
            shared:true,
             width: 250, 
            headerFormat: '<div class="headerTooltip"><span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">{point.key}</div>',
            // formatter:  function () {
            //      return `<span style="font-size: 10px;padding-right:15px;" class="tool-tip-x">${props.typePerormance && (props.typePerormance=='week' || props.typePerormance=='month' || props.typePerormance=='today')?this.x:props.type=='week'?moment(this.x).format("ddd, MMM DD"):moment(this.x).format("DD MMM")}</span><br/>
            //              <span dx="0" class="tool-tip-name">${this.series.name} : </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${this.y.toFixed(2)}%</tspan>`;                         
            //     } ,
            pointFormat: '<div class="tooltipContent"><span class="tooltipcolorIndicator" style="background: {series.color};width:4px;height:4px;border-radius:50%"></span> <span dx="0" class="tool-tip-name">{series.name}: </td>' +
            '<span style="font-weight:bold;text-align: right" dx="0" class="tool-tip-result">{point.y:.2f}%</div>',
        //     formatter: function () {
        //     var highestSeries = this.points.reduce(function (acc, point) {
        //         return point.y > acc.y ? point : acc;
        //     });

        //     return '<div style="border: 2px solid ' + highestSeries.series.color + '">' +
        //         highestSeries.series.tooltipOptions.pointFormatter.call(highestSeries) +
        //         '</div>';
        // },

         //footerFormat: '</table>',
        //     pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
        //     '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        // footerFormat: '</table>',
        valueDecimals: 2, 
        },
        // plotOptions: {
        //     column: {
        //         stacking: 'normal',
        //         dataLabels: {
        //             enabled: false
        //         }
        //     }
        // },
            plotOptions: {
        series: {
            stacking: 'normal',
            //  pointStart: -1,
            marker: {
        enabled: false,
      }
        }
    },
//         plotOptions: {
//         area: {
//       stacking: 'percent',
//       marker: {
//         enabled: false
//       }
//     }
//   },
        series: props.series
    }

    if(props.width){
        config.chart.width = props.width?props.width:"100%"
    }
    // if(props.modalChart){
    //     config.xAxis.labels={enabled:false,title: {text: null},gridLineColor: 'transparent'}
    //     config.yAxis={labels:{enabled:false},title: {text: null},gridLineColor: 'transparent'}
    // }


    return (
        <div id={"propskey"+props.key1?props.key1:"-"} className={"propskey1"+props.key1?props.key1:"-"}>
            
              <ReactHighcharts config={config} isPureConfig={true} key={props.key1?props.key1:"uniqueKey"+Math.random(0,10)} />
        </div>
    )
}

export default StackChart