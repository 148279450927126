import axios from "axios";


let prefixURL = process.env.REACT_APP_URL


export function getMovementData(requestBody , sessionID, id) {
    //old api
    // return axios.get(prefixURL + `/get_employee_movement_report?session=${sessionID}&date=${requestBody.date}&org_id=${id}&start_time=${requestBody.start_time}&end_time=${requestBody.end_time}&tag_serial=${requestBody.tag_serial}`)
    //     .then(res => res.data).catch(err => err)

        return axios.get(prefixURL + `/get_employee_tracker?session=${sessionID}&date=${requestBody.date}&org_id=${id}&start_time=${requestBody.start_time}&end_time=${requestBody.end_time}&worker_id=${requestBody.worker_id}&work_day=${requestBody.work_day}`)
        .then(res => res.data).catch(err => err)

}

export function getEmployeeTracker(requestBody , sessionID, id) {
    
    return axios.get(prefixURL + `/get_employee_tracker?session=${sessionID}&date=${requestBody.date}&org_id=${id}&start_time=${requestBody.start_time}&end_time=${requestBody.end_time}&worker_id=${requestBody.worker_id}&work_day=${requestBody.work_day}`)
        .then(res => res.data).catch(err => err)
}

export function getActiveEmployee(requestBody , sessionID, id,sub_org_id) {
    
    return axios.get(prefixURL + `/onboarding/active_workers_in_hour?session=${sessionID}&date=${requestBody.date}&org_id=${id}&hour=${requestBody.hour}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

