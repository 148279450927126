import React, { useEffect } from 'react';

const GoogleTranslateWidget = () => {
  

  return (
    <div>
      <div id="google_translate_element" style={{ float: "right" }}></div>
    </div>
  );
};

export default GoogleTranslateWidget;
