import React, { useState, useEffect,useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'

import { Link } from 'react-router-dom'

import ReactModal from "react-modal";
import { Checkbox, Select   } from 'antd';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ExcelJs from "exceljs";
import ChartReport from "./ChartReport";
import { getPproductivityDetailOverview } from "../../productivity/actionMethods/actionMethods";
import moment from "moment";
import { getPeformanceIndividualHistory } from "../../productivity/actionMethods/actionMethods";
import { prop } from "ramda";
import { getPerformanceHistory } from "../actionMethods/actionMethods";
// import {ReactComponent as StarFill} from '../../assets/images/star-sharp.svg'
// import {ReactComponent as StarEmpty} from '../../assets/images/star-sharp-empty.svg'

export default function Performance(props){

    let StarFill =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f2bc19"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM351.9 311.8l33.72 103.9l-88.25-64.18c-5.61-4.08-13.21-4.08-18.82 0L190.3 415.6l33.72-103.9c2.139-6.589-.2053-13.81-5.808-17.88L129.9 229.6h109.2c6.933 0 13.08-4.465 15.22-11.06L288 114.8l33.7 103.8c2.141 6.594 8.285 11.06 15.22 11.06h109.2l-88.34 64.24C352.2 297.1 349.8 305.2 351.9 311.8z"/></svg>`;
    let StarEmpty =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#b5b1a6"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"/></svg>`;
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    const [allDataPeformance,SetAllDataPerformance]=useState([]);
    const [dataPeformance,SetDataPerformance]=useState([]);
    const [showDataDefault,SetShowDataDefault]=useState([]);
    const [chartLoader,SetChartLoader]=useState(true);
    const [filterBtn,SetFilterBtn]= useState([])
    const [filterBtnAgency,SetFilterBtnAgency]= useState([])
    const [filterActive,SetFilterActive]= useState([])
    const [chartData,SetChartData] = useState({series:[],categories:[],week:[]})
    const [chartDataAgency,SetChartDataAgency] = useState({series:[],categories:[],week:[]})
    const [filterActiveAgency,SetFilterActiveAgency] = useState('');
    const [chart1,SetChart1] = useState({series:[],categories:[]})
    const [chart2,SetChart2] = useState(true)
    const [loader,SetLoader]=useState(true);
    const [isLoading,SetisLoading] =useState(true);
    const [dateCount,SetDateCount] =useState(true);
    const [totalcount,SetTotalCount] =useState(true);
    const [count, SetCount]= useState(0)
    const monthInt= ['','Jaunary',"February","March","April","May","June","July","August","September","October","November","December"]
    const [week,SetWeek]=useState([]);
    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
// mergeArray(allData,moDiff[z].month,moDiff[z].start,moDiff[z].end,props.reportPerformance)
     const mergeArray = (data, mnth, start, end, type = 'month', emp) => {
  let newArray = [];

  if (!emp) {
    emp = [...data];
  }


  if (type === 'month') {
    for (let i = 0; i < emp.length; i++) {
      const isDuplicate = newArray.some(
        (item) =>
          item.worker_id === emp[i].worker_id &&
          moment(item.date).isSameOrAfter(moment(start).set({ hour: 0, minute: 0, second: 0 })) &&
          moment(item.date).isSameOrBefore(moment(end).set({ hour: 23, minute: 59, second: 59 }))
      );

      if (!isDuplicate) {
        const currentData = [...data].filter(
          (item) =>
            item.worker_id === emp[i].worker_id &&
            moment(item.date).isSameOrAfter(moment(start)) &&
            moment(item.date).isSameOrBefore(moment(end)) &&
            !props.isWeekday(item.date)
        );
          
        if (currentData.length > 0) {
          const uniqueReduce =
            currentData.reduce((a, b) => a + b.worked_hour, 0) /
            currentData.filter((item) => item.worked_hour > 0 && !props.isWeekday(item.date)).length;

          const unique = { ...currentData[0] }; // Create a shallow copy

          if(isNaN(uniqueReduce)){
              unique.worked_hour = 0;
          }else{
              unique.worked_hour = uniqueReduce;
          }
          unique.rating = ratingCal(uniqueReduce);

          

          newArray.push(unique);
        }
      }
    }
  } else {
    for (let i = 0; i < emp.length; i++) {
      const isDuplicate = newArray.some(
        (item) =>
          item.worker_id === emp[i].worker_id && item.start === start && item.end === end
      );

      if (!isDuplicate) {
        const currentData = data.filter(
          (item) =>
            item.worker_id === emp[i].worker_id &&
            moment(item.date).isSameOrAfter(moment(start)) &&
            moment(item.date).isSameOrBefore(moment(end)) &&
            !props.isWeekday(item.date)
        );

        if (currentData.length > 0) {
          const uniqueReduce =
            currentData.reduce((a, b) => a + b.worked_hour, 0) /
            currentData.filter((item) => item.worked_hour > 0).length;

          const unique = { ...currentData[0] }; // Create a shallow copy
          unique.range = moment(start).format('DD-MMM') + " - " + moment(end).format('DD-MMM');
          unique.start = start;
          unique.end = end;

          

          // unique.worked_hour = uniqueReduce;
          if(isNaN(uniqueReduce)){
              unique.worked_hour = 0;
          }else{
              unique.worked_hour = uniqueReduce;
          }
          
          unique.rating = ratingCal(uniqueReduce);
          newArray.push(unique);
        }
      }
    }
  }

  return newArray;
};


      function ratingCal(val){
        if(parseFloat(val)>= 90){
            return 5
        }else if(parseFloat(val)>= 80 && parseFloat(val)< 90){
            return 4
        }
        else if(parseFloat(val)>= 70 && parseFloat(val)< 80){
            return 3
        }
        else if(parseFloat(val)>= 60 && parseFloat(val)< 70){
            return 2
        }
        else if(parseFloat(val)>= 50 && parseFloat(val) < 60){
            return 1
        }else{
            return 0
        }

      }

      const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]

      const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };

function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}

 function generateWeeks(startDate, endDate) {
  const weeks = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const weekStart = new Date(currentDate);
    const weekEnd = new Date(currentDate);
    
    if (currentDate.getDay() === 0) {
      // If it's a Sunday, start the week from Sunday
      weekEnd.setDate(currentDate.getDate() + 6); // Set to Saturday
    } else {
      // Otherwise, start the week from the given startDate
      weekEnd.setDate(currentDate.getDate() + (6 - currentDate.getDay())); // Set to Saturday
    }

    if (weekEnd > endDate) {
      // Adjust the end date for the last week
      weekEnd.setDate(endDate.getDate());
    }

    const weekInfo = {
      start: weekStart.toISOString().split('T')[0],
      end: weekEnd.toISOString().split('T')[0],
      month: (weekStart.getMonth() + 1).toString().padStart(2, '0'),
      year: weekStart.getFullYear().toString(),
      weekName: `Week ${getWeekNumber(weekStart)}`,
    };

    weeks.push(weekInfo);
    currentDate = new Date(weekEnd);
    currentDate.setDate(currentDate.getDate() + 1); // Move to next day (Sunday or the next day of the end of the last week)
  }

  return weeks;
}
function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}
    

   useEffect(()=>{
//let res= dummy;
async function fetchData() { 
    try {
        if(props.filterEnableCycle){  
        let deptSelected= [...props.department_id];
        let subdeptSelected= props.sub_department_id;
        SetFilterActive("")
        if(deptSelected.length==1){
            SetFilterBtn(subdeptSelected);
        }else{
            SetFilterBtn(props.department_id);
        }
        
        let removeDept =props.employee.filter(item=> subdeptSelected.indexOf(item.sub_department) > -1 ).map(el=>el.department);
        for(let i=0;i<removeDept.length;i++ ){
          let index = deptSelected.indexOf(removeDept[i])
          if(index > -1 ){
            deptSelected.splice(index, 1); 
          }
      
      
        }


        let emp = props.employee.filter(item=> (deptSelected.indexOf(item.department) > -1 || subdeptSelected.indexOf(item.sub_department) > -1 ) && props.agency.indexOf(item.agency_name) > -1 );
          
      
      let arr =[];  
      let dateAr=[];
      
      let inc=1;
      
      SetisLoading(true);
      SetTotalCount(emp.length);
      let incCal=0
      SetShowDataDefault([])
      
      // Loop through month
    let allDataMonth=[]  
    let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
    
    const allData=[];      

    let requestBody ={}
    requestBody.start_date = getDateFormat(props.start_date);

requestBody.end_date = getDateFormat(props.end_date);

requestBody.slug =props.type;


let performanceObject = await getPerformanceHistory(requestBody,userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{
  if(res.status==200){
    return res.data
  }else{
    return []
  }
});





    if(props.reportPerformance === 'month'){
    let loopInner=0;

      for (const [i,row] of emp.entries()) {  
      

            inc++
            loopInner++
            SetCount(loopInner)
                
                let data=[];
            
                if(performanceObject[emp[i].worker_id]){
                 
                 Object.keys(performanceObject[emp[i].worker_id]).map((key) => {
                  let inRange= moDiff.find(el=>moment(el.start) <= moment(key) && moment(key) <= moment(el.end) );
                    if(inRange){
                      
                      data.push({
                          date:key,
                          worked_hour:performanceObject[emp[i].worker_id][key],
                          department:emp[i].department,
                          sub_department:emp[i].sub_department,
                          name:emp[i].emp_name,
                          tag_serial:emp[i].tag_serial,
                          worker_id:emp[i].worker_id,
                          month:parseInt(moment(key).format('MM')),range:moment(inRange.start).format('DD')+" - "+moment(inRange.end).format('DD'),
                          rating:ratingCal(performanceObject[emp[i].worker_id][key]),agency_name:emp[i].agency_name});
                    }                 
                   
                })
                
                allData.push(...data);           

                }

           
            props.exportEnableFunction();
            

            if(loopInner==[emp.length - 1]){
            
     
             //props.exportEnableDisablePerformance()   
             
             
            }

    

        // })
    }
    
            //  if((inc== (emp.length *moDiff.length) )   || inc -1 == (emp.length *moDiff.length) ){
                let uniq=[];

                let cat=  ['5 Star',"4 Star","3 Star","2 Star","1 Star","0 Star"];
                let series=[];

                for(let z=0;z<moDiff.length;z++){
                  let MonthAct= month.find(item=>item.intMonth== moDiff[z].month)
                    let seriesdata=[]
                 
                    const preventDuplicate = Object.freeze([...allData])
                 
                    let uniq1=mergeArray(preventDuplicate,moDiff[z].month,moDiff[z].start,moDiff[z].end,'month',emp)
                    
                 
                    seriesdata.push((uniq1.filter(item=>item.worked_hour>=90 ).length *100)/uniq1.length) 
                    seriesdata.push((uniq1.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90).length*100) /uniq1.length) 
                    seriesdata.push((uniq1.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80).length*100 )/uniq1.length)
                    seriesdata.push((uniq1.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70).length*100)/uniq1.length) 
                    seriesdata.push((uniq1.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 ).length*100)/uniq1.length) 
                    seriesdata.push((uniq1.filter(item=>item.worked_hour<50).length*100)/uniq1.length) 
                    
                    let startLegend;
                    let endLegend;
                    if(z==0 && z== moDiff.length-1 ){
                      startLegend= moment(props.start_date).format("DD");
                      endLegend= moment(props.end_date).format("DD");
                      
                    }
                    else if(z==0){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend = MonthAct.days
                    }else if(z == moDiff.length-1){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend= moment(props.end_date).format("DD");
                        
                    }else{
                        startLegend = '01';
                        endLegend = MonthAct.days;
                    }
                    series.push({name:monthInt[moDiff[z].month],data:seriesdata,legend:moment(moDiff[z].start).format("DD")+"-"+moment(moDiff[z].end).format("DD")});
                    
                    uniq.push(...uniq1)
                }



                
                 SetDataPerformance(uniq);
                 SetAllDataPerformance(uniq);
                 
    
                 SetChartLoader(false);
                
                 SetisLoading(false);
                 SetChartData({series:series,categories:cat,week:[]});
                 SetChartDataAgency({series:series,categories:cat,week:[]});
                 AGTable(uniq,"AllDataEMpPeform")   
            
  }else{

    SetWeek(moDiff);
 
 
      let loopInner=0;
  

          for (const [i,row] of emp.entries()) {  
        
  
          let requestBody={}
          requestBody.slug = props.type
          requestBody.worker_id = emp[i].worker_id
        
  
         
        // await getPeformanceIndividualHistory(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
              
              inc++
              loopInner++
              SetCount(loopInner)

              // if(res.status==200){
                let data=[]
                  if(performanceObject[emp[i].worker_id]){
                  Object.keys(performanceObject[emp[i].worker_id]).map((key) =>{
                     let inRange= moDiff.find(el=>moment(el.start) <= moment(key) && moment(key) <= moment(el.end) );
                     data.push({date:key,worked_hour:performanceObject[emp[i].worker_id][key],department:emp[i].department,sub_department:emp[i].sub_department,name:emp[i].emp_name,tag_serial:emp[i].tag_serial,worker_id:emp[i].worker_id,month:parseInt(moment(key).format('MM')),range:moment(inRange.start).format('DD')+" - "+moment(inRange.end).format('DD'),
                      rating:ratingCal(performanceObject[emp[i].worker_id][key]),agency_name:emp[i].agency_name})   
        
                  });
                  
                  

                  allData.push(...data);           
                }
              // }
              let datetest = Math.ceil(inc/emp.length) - 1;
              


      }
      
       
                let dataMap=[]
                
                  let uniq=[];
  
                  let cat=  ['5 Star',"4 Star","3 Star","2 Star","1 Star","0 Star"];
                  let series=[];
                  
                  let moDiff2= getMonths(new Date(props.start_date),new Date(props.end_date),'week');
                  // allData = allData.sort((a, b) => {
                  //   return new Date(a.date) - new Date(b.date);
                  // });
                  
                  
                  for(let z=0;z<moDiff.length;z++){
                      let seriesdata=[]
                      
                      let uniq1=mergeArray(allData,moDiff[z].month,moDiff[z].start,moDiff[z].end,props.reportPerformance,emp)
                    
                        
                      let startLegend;
                      let endLegend;
                      startLegend =moment(moDiff[z].start).format("DD");
                      endLegend = moment(moDiff[z].end).format("DD MMM");
                      
                      seriesdata.push((uniq1.filter(item=>item.worked_hour>=90 ).length *100)/uniq1.length) 
                      seriesdata.push((uniq1.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90).length*100) /uniq1.filter(item=>item.worked_hour> 0).length) 
                      seriesdata.push((uniq1.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80).length*100 )/uniq1.filter(item=>item.worked_hour> 0).length)
                      seriesdata.push((uniq1.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70).length*100)/uniq1.filter(item=>item.worked_hour> 0).length) 
                      seriesdata.push((uniq1.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 ).length*100)/uniq1.filter(item=>item.worked_hour> 0).length) 
                      seriesdata.push((uniq1.filter(item=>item.worked_hour<50).length*100)/uniq1.length) 
                      series.push({name:"Week "+(z+1),data:seriesdata,tooltipWeek:`${moment(moDiff[z].start).format('DD MMM')} - ${moment(moDiff[z].end==''?props.end_date:moDiff[z].end).format('DD MMM')}`,legend:startLegend+"-"+endLegend} );
                     
                      uniq.push(...uniq1)

                  }
                  props.exportEnableFunction();
                     
  
  
                  
                   SetDataPerformance(uniq);
                   SetAllDataPerformance(uniq);
                   
      
                   SetChartLoader(false);
                   
                   SetisLoading(false);
                   SetChartData({series:series,categories:cat,week:[]});
                   SetChartDataAgency({series:series,categories:cat,week:[]});
                   AGTable(uniq,"AllDataEMpPeform")   

    
    }
      
        }
      }catch (err) {

    }
  }
  fetchData() 
      },[props.filterEnableCycle,props.inc]);

      useEffect(()=>{
      
        if(props.exportEnableCycle){
          exportToExcel();
        }
        },[props.exportEnableCycle])
        const exportToExcel = () => {
            let sheetName = "PerformanceAnalysis";
            let headerName= "Report"
            let fileName='';
            if(props.start_date == props.end_date){
                fileName = "PerformanceAnalysis"+"-"+moment(props.start_date).format("MMM DD")+".xlsx";
            }else{
                fileName = "PerformanceAnalysis"+"-"+moment(props.start_date).format("MMM DD")+" - "+moment(props.end_date).format("MMM DD")+".xlsx";
            }
            
            const workbook = new ExcelJs.Workbook();
            let loc = [];
            
          
            let worksheet = workbook.addWorksheet('Performance Analysis');
            let cols =[
               {name:'Name', header: 'Name', key: 'emp_name'},
               {name:'Date', header: 'Date', key: 'date'},
              {name:'Tag Serial', header: 'Tag Serial', key: 'tag_serial'},
              {name:'Agency Name', header: 'Agency Name', key: 'agency_name'},
              {name:'Department', header: 'Department', key: 'department'},
              {name:'Sub Department', header: 'Sub Department', key: 'sub_department'},
              {name:'Worker ID', header: 'Worker ID', key: 'worker_id'},
              {name:'Performance %', header: 'worked_hour', key: 'worked_hour'},
              {name:'Star Rating', header: 'rating', key: 'rating'},
            ];
          
            worksheet.addTable({
              name: sheetName,
              ref: "A1", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : cols,
              rows: allDataPeformance.map((e) => {
                let arr =[];
                  arr.push(e.name,props.reportPerformance=='month'?e.range+" "+monthInt[e.month]:e.range,e.tag_serial,e.agency_name,e.department,e.sub_department,e.worker_id,parseFloat(e.worked_hour.toFixed(2)),e.rating);
                 return arr;
              })
            })
          
            worksheet.state = 'visible';
            const table = worksheet.getTable(sheetName);
            // for (let i = 0; i < table.table.columns.length; i++) {
            //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
            //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
            //     type: "pattern",
            //     pattern: "solid",
            //     fgColor: { argb: "d0cfcf" }
            //   };
          
          
            // }
            worksheet.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
            
            table.commit();
            const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
          
          
          
          
            
          
            props.exportProcessExist();
          
          
          
          };
      function capitalizeFirstLetter(string) {
        if(string){
          return string.toLowerCase();
        }
      }


      function starRating(val){
        let rate='';
        if(val==0){
            return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
        }else{
            for(let i=1;i<=5;i++){
                if(i<=val){
                    rate+=`${StarFill}`;
                }else{
                    rate+=`${StarEmpty}`;
                }
            }
        }
        return `<div class="ratingFill ratingAG">${rate}</div>`;


      }
      function starRatingRender(params){
        

        var op =document.createElement('div');
    
        var eGui = document.createElement('div');
         
            eGui.innerHTML=`${starRating(params.value)}`;
                
    
    
      
         return eGui;
    
    
    
    }

      function AGTable(data,key){

        key = data.length
        let arr;
        let arr2=[];
        
        if(data){  
          data = data.sort((a,b)=>{
            return new Date(a.date) -  new Date(b.date)
          })
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/ 8:(props.widthContainer - 90)/ 9       
          
          arr2.push(  
            <AgGridColumn
                field="name"
                headerName={"Name"}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
                cellClass = {"ag-cell--textUppercase"}
            />
          )
          arr2.push(  
            <AgGridColumn
                field="month"
                headerName={"Date"}
                valueFormatter={(params)=>props.reportPerformance == 'month'?params.data.range+" "+monthInt[params.value]:params.data.range}
                cellClass = {"ag-cell--textUppercase"}
            />
          )

        arr2.push( 
          
        <AgGridColumn
          field="tag_serial"
          headerName={"Tag Serial"}
        /> )
     
        arr2.push(  
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
              cellClass = {"ag-cell--textUppercase"}
          />
        )
    
         arr2.push( 
      <AgGridColumn
        field="agency_name"
        headerName={"Agency Name"}
        cellClass={'textCapitalize textCap'}
      />
     )
          
     
        arr2.push( 
          
          <AgGridColumn
            field="department"
            headerName={"Department"}
          /> )
    
        arr2.push( 
          
          <AgGridColumn
            field="sub_department"
            headerName={"Sub Department"}
          /> )
      
          arr2.push( 
          
            <AgGridColumn
              field="worked_hour"
              headerName={"Performance %"}
              valueFormatter={(params)=>parseFloat(params.value.toFixed(2))}
              //cellRenderer= {(params)=>starRatingRender(params)}
            /> )
    
    
            arr2.push( 
          
                <AgGridColumn
                  field="rating"
                  headerName={"Star Rating"}
                  cellRenderer= {(params)=>starRatingRender(params)}
                /> )
        
        
    
    
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                

                enableRangeSelection= {true}
                //onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
        
                }}
                width={widthCal}
                headerHeight={35}
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >    
        {arr2}
        </AgGridReact>
        </div></div>
        
        SetShowDataDefault(arr);          
            
    }
            
    
    
      }
      
      function AGGridEMptyMEssage(key){
        let msg;
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        return msg
      }

      function getLastDayAndTotalDays(year, month) {
  // Get the last day of the month
          const lastDay = new Date(year, month, 0).getDate();

          // Get the total number of days in the month
          const firstDay = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          const totalDays = (lastDayOfMonth - firstDay) / (1000 * 60 * 60 * 24) + 1;

          return { lastDay, totalDays };
        }

      useEffect(()=>{
        let moDiff=[];
        if(props.reportPerformance=='month'){
          
          moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        }else{

          moDiff=[...week]
        }
        let series=[]



        let cat=  ['5 Star',"4 Star","3 Star","2 Star","1 Star","0 Star"];
        // let series=[]
        // series.push((uniq.filter(item=>item.worked_hour>=90 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive)) ).length *100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100) /uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100 )/uniq.length)
        // series.push((uniq.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive)) ).length*100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour<50).length*100)/uniq.length) 
  

        let startLegend;
        let endLegend;

        for(let z=0;z<moDiff.length;z++){
          let MonthAct= month.find(item=>item.intMonth== moDiff[z].month)    
          let uniq= allDataPeformance.filter(item=> (item.department.includes(filterActive) || item.department==filterActive  )|| item.sub_department.includes(filterActive) || item.sub_department==filterActive );
            let seriesdata=[]

            let uniq1=mergeArray(uniq,moDiff[z].month,moDiff[z].start,moDiff[z].end)
           
            
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=90 ).length *100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90).length*100) /uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80).length*100 )/uniq1.length)
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70).length*100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 ).length*100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour<50).length*100)/uniq1.length) 

            
            if(props.reportPerformance=='month'){
              


                    if(z==0 && z== moDiff.length-1 ){
                      startLegend= moment(props.start_date).format("DD");
                      endLegend= moment(props.end_date).format("DD");
                      
                    }
                    else if(z==0){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend = MonthAct.days
                    }else if(z == moDiff.length-1){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend= moment(props.end_date).format("DD");
                        
                    }else{
                        startLegend = '01';
                        endLegend = MonthAct.days;
                    }
                    // series.push({name:monthInt[moDiff[z].month],data:seriesdata,legend:startLegend+"-"+endLegend});
                    series.push({name:monthInt[moDiff[z].month],data:seriesdata,legend:startLegend+"-"+endLegend});
// end here
        
            }else{

  
              startLegend =moment(moDiff[z].start).format("DD");
              endLegend = moment(moDiff[z].end).format("DD MMM");
              series.push({name:"Week "+(z+1),data:seriesdata,tooltipWeek:`${moment(moDiff[z].start).format('DD MMM')} - ${moment(moDiff[z].end==''?props.end_date:moDiff[z].end).format('DD MMM')}`,legend:startLegend+"-"+endLegend});
            }
            
            uniq.push(...uniq1)
        }


         SetChartData({series:series,categories:cat,week:[] });

        //SetChartData({series:[{name:"Performance",data:series}],categories:cat})
        
      },[filterActive])

      useEffect(()=>{
        let moDiff=[];
        if(props.reportPerformance=='month'){
          
          moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        }else{

          moDiff=[...week]
        }
        let series=[]



        let cat=  ['5 Star',"4 Star","3 Star","2 Star","1 Star","0 Star"];
        // let series=[]
        // series.push((uniq.filter(item=>item.worked_hour>=90 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive)) ).length *100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100) /uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100 )/uniq.length)
        // series.push((uniq.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive))).length*100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 && (item.department.includes(filterActive) || item.sub_department.includes(filterActive)) ).length*100)/uniq.length) 
        // series.push((uniq.filter(item=>item.worked_hour<50).length*100)/uniq.length) 
  

        let startLegend;
        let endLegend;

        for(let z=0;z<moDiff.length;z++){
          let MonthAct= month.find(item=>item.intMonth== moDiff[z].month)    
          let uniq= allDataPeformance.filter(item=> item.agency_name.includes(filterActiveAgency) );
            let seriesdata=[]
            let uniq1=mergeArray(uniq,moDiff[z].month,moDiff[z].start,moDiff[z].end)
            
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=90 ).length *100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=80 && parseFloat(item.worked_hour)<90).length*100) /uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=70 && parseFloat(item.worked_hour)<80).length*100 )/uniq1.length)
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=60 && parseFloat(item.worked_hour)<70).length*100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour>=50 && parseFloat(item.worked_hour)<60 ).length*100)/uniq1.length) 
            seriesdata.push((uniq1.filter(item=>item.worked_hour<50).length*100)/uniq1.length) 

            
            if(props.reportPerformance=='month'){
              


                    if(z==0 && z== moDiff.length-1 ){
                      startLegend= moment(props.start_date).format("DD");
                      endLegend= moment(props.end_date).format("DD");
                      
                    }
                    else if(z==0){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend = MonthAct.days
                    }else if(z == moDiff.length-1){
                        startLegend= moment(props.start_date).format("DD");
                        endLegend= moment(props.end_date).format("DD");
                        
                    }else{
                        startLegend = '01';
                        endLegend = MonthAct.days;
                    }
                    // series.push({name:monthInt[moDiff[z].month],data:seriesdata,legend:startLegend+"-"+endLegend});
                    series.push({name:monthInt[moDiff[z].month],data:seriesdata,legend:startLegend+"-"+endLegend});
// end here
              
            }else{

  
              startLegend =moment(moDiff[z].start).format("DD");
              endLegend = moment(moDiff[z].end).format("DD MMM");
              series.push({name:"Week "+(z+1),data:seriesdata,tooltipWeek:`${moment(moDiff[z].start).format('DD MMM')} - ${moment(moDiff[z].end==''?props.end_date:moDiff[z].end).format('DD MMM')}`,legend:startLegend+"-"+endLegend});
            }
            
            uniq.push(...uniq1)
        }


         SetChartDataAgency({series:series,categories:cat,week:[] });

        //SetChartData({series:[{name:"Performance",data:series}],categories:cat})
        
      },[filterActiveAgency])




      return (
    
        
        props.filterEnableCycle?<>
        
        
         <div className="reportChart">

         {isLoading?
         <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>
                                  // <div className="text-center loader-filter ">
                                      
                                  //     <div className="loadingInfo loadingInfo2" style={{marginBottom:"20px"}}>
                                    
                                  //       <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                  //       <strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</div>
  
                                  //     </div>
                                        
                                  // </div>
                                  
                                  :""}


                                  {!isLoading?<div className="accordionHeader" onClick={()=>{SetChart2(!chart2);SetChartLoader(false); }}>                                      
                                      {chart2?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Agency Performance</span>
                                 </div>:""}


                                 { !isLoading && chart2?
                                 <div className="accordionContentReport">
                                  <div className="buttonFilterArea">
                                  <button className={filterActiveAgency==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetFilterActiveAgency('')}>All</button>
                              {props.agency.map(item =>{
                              
                                return <button className={filterActiveAgency==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetFilterActiveAgency(item)}>{item} </button>
                              })}</div>
{isLoading || chartLoader?
"":
                                  <div style={{minHeight:"300px"}}> <ChartReport chartData={chartDataAgency} hideUnd={true} close={()=>SetChart2(false)} yAuto={true} height={300} legendEnable={true} key={"reportV2"} /></div>}
                                 </div>:""}

{/* CHart Department */}
                                  {!isLoading?<div className="accordionHeader" onClick={()=>{SetChart1(!chart1);SetChartLoader(false); }}>                                      
                                      {chart1?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Department Performance</span>
                                 </div>:""}


                                 { !isLoading && chart1?
                                 <div className="accordionContentReport">
                                  <div className="buttonFilterArea">
                                  <button className={filterActive==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetFilterActive('')}>All</button>
                              {filterBtn.map(item =>{
                              
                                return <button className={filterActive==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetFilterActive(item)}>{item} </button>
                              })}</div>
{isLoading || chartLoader?
"":
                                  <div style={{minHeight:"300px"}}> <ChartReport chartData={chartData} hideUnd={true} close={()=>SetChart1(false)} yAuto={true} height={300} legendEnable={true} key={"reportV2"} /></div>}
                                 </div>:""}
                                 {/* End CHart Department */}
                                 </div>
        {showDataDefault}</>:""
      )

}