import React from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'


const PrivateRoute = ({component: Component, ...rest }) => {
   
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    
    return (
      <Route onEnter={() => window.analytics()} {...rest} render={props => (
         isLoggedIn && isLoggedIn == 'true' ?  (
            <Component {...props} {...rest}/>
          ) : (
            <Redirect to={{
              pathname: '/login',
            }}/>
          )
      
    )}/>
)
/* 
if(window.location.hostname =='localhost' && window.location.pathname=='/geo-map-management' ){
  return (
      <Route onEnter={() => window.analytics()} {...rest} render={props => (
         isLoggedIn && isLoggedIn == 'true' ?  (
            <Component {...props} {...rest}/>
          ) : (
            <Redirect to={{
              pathname: '/login',
            }}/>
          )
      
    )}/>
)


}else if (window.location.hostname =='webapp.traceplus.ai' && window.location.pathname=='/geo-map-management'){
  window.location.assign(`http://demo.traceplus.ai/geo-map-management`)
}
else if (window.location.hostname =='demo.traceplus.ai' && window.location.pathname!=='/geo-map-management'){
  window.location.assign(`https://webapp.traceplus.ai${window.location.pathname}`)
}else{
  return (
    <Route onEnter={() => window.analytics()} {...rest} render={props => (
      isLoggedIn && isLoggedIn == 'true' ?  (
         <Component {...props} {...rest}/>
       ) : (
         <Redirect to={{
           pathname: '/login',
         }}/>
       )
   
 )}/>
)
}
  */
}

export default (PrivateRoute) 