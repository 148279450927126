import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "../../../../siteManagement/styles/siteManagement.scss";
import "../../../../manPowerManagement/style/manpowerManagement.scss"
import CommonDatePicker from "../../../../common/commonDatePicker";
import { getTranslatedText } from "../../../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../../../dashboard/actionMethods/actionMethods";
import { prepareDateObj } from "../../../../dashboard/components/helper";

import downArrowFill from "../../../../assets/images/down-arrowFill.png";
import DashboardLanguage from "../../../../components/dashboardLanguage";
import batteryIcon from "../../../../assets/traceplusImages/battery.svg";
import emailIcon from "../../../../assets/traceplusImages/employee_email_icon.svg";
import dropdownIcon from "../../../../assets/images/down-arrow.png";
import selectedPinkArrowIcon from "../../../../assets/traceplusImages/pink_right_arrow_icon.svg";
import empIDIcon from "../../../../assets/traceplusImages/employee_id_icon.svg";
import helpIcon from "../../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../../assets/traceplusImages/sort.png'
import upIcon from '../../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../../assets/traceplusImages/down-arrow.png'
import '../../../styles/policyCompliance.scss'
import { Checkbox, Button, Select } from 'antd';
import Modal from 'react-modal'
import {getPolicyOverview} from '../../../actionMethods/actionMethods';
import spinnerLoader from "../../../../assets/images/loader.svg"
import DeviceStatus from "../../../../components/DeviceStatus";
import {ReactComponent as Image404 } from  '../../../../assets/images/11132-ai.svg'



function PolicyComplianceTeam(props) {
  let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
  let interval = 5;
  let idVal = props.match.params.id.replace(":", "");
  let policy_start_date=localStorage.getItem('policy_start_date')?new Date(moment(localStorage.getItem('policy_start_date')).format('YYYY-MM-DD 23:59:59')):new Date(moment(date).format('YYYY-MM-DD 23:59:59'))
  let policy_end_date=localStorage.getItem('policy_end_date')?new Date(moment(localStorage.getItem('policy_end_date')).format('YYYY-MM-DD 23:59:59')):new Date(moment(date).format('YYYY-MM-DD 23:59:59'))
 

  const [employeeDetails, updateEmployeeDetails] = useState("");
  const [prevEmployeeDetails, updatePrevEmployeeDetails] = useState("");

  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [infectedFlag, updateInfectedFlag] = useState(false);

  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [prevemployeeData, setprevEmployeeData] = useState("");
  const [employeeHistoryData, setEmployeeHistoryData] = useState('');

  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [chartData, setChartData] = useState({ series: [], categories: [] });
  
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

 // const [testedPositiveDate, updateTestedPositiveDate] = useState(null);

    const [sortKey, setSortKey] = useState('access')
    const [sortType, setSortType] = useState('desc')

    const [testedPositiveDate, updateTestedPositiveDate] = useState(date);
    const [chartLoader, setChartLoader] = useState(true);

    const[dataCapacity,SetDataCapacity]=useState([]);
    const[dataAccess,SetAcessCapacity]=useState([]);
    const[dataAccessPrev,SetAcessPrev]=useState([])
    const[dataCapacityPrev,SetDataCapacityPrev]=useState([]);
    const[dataTeam,SetTeamData]=useState([])
    const [sortKeyaccess, setSortKeyaccess] = useState('access')
    const [sortTypeaccess, setSortTypeaccess] = useState('desc')
    const[teamName,SetTeamName]=useState('')
    const [sortKeycapacity, setSortcapacity] = useState('capacity')
    const [sortTypecapacity, setSortTypecapacity] = useState('desc')
    const [loader,SetLoader]=useState(true)
    const [isAll, setIsAll] = useState(true)
    const [selected, setSelected] = useState([])
    const [TempAcess,SetTempAcess]=useState([])
    const [TempCapacity,SetTempCapacity]=useState([])
    const capacityBreach=[];
    const accessBreach=[];
    const teamData=[];  

    let Height= window.innerHeight - 100;
    const initialHeight = Height >810?Height:810; 
    const leftheight = initialHeight - 310;

    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);

useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.sub_page_name=="Policy Compliance Team"); 
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);


  

  useEffect(() => {
    if(permission && permission.length > 0){
    DateChangeHandler()
    }
  }, [dates,permission]);

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }




  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }

  const handleDate = (date, type) => {
    setDates((prev) => ({ ...prev, [`${type}`]: date }));
    
  };

  function DateChangeHandler(){
    if(permission && permission.length > 0){
    let requestBody=[]
    requestBody.start_date=getDateFormat(dates.start);
    requestBody.end_date=getDateFormat(dates.end);
    let arrCapacity=[];
    let arrAcess=[];
    let employeeData=[];
  
    SetLoader(true);
        getPolicyOverview(requestBody,userSession, org_id,'team').then(res => {
            if (res.status === 200) {
                let data= res.data;
              Object.keys(data).forEach((key) => {
                
                if(key==idVal){
                SetTeamName(key)
                
                for(let index=0;index<data[key].employee.length;index++){
                  let element= data[key].employee[index];
                  let brcount=0;
                  Object.keys(element).forEach((key2) => {
                   
                  
                  //Accessibility Location
                  
                  let loop2 =element[key2].accesability.location;
                    let accesability= element[key2].accesability.location
                    let capacity =element[key2].capacity.location
                  Object.keys(accesability).forEach((key3) => {
                      let el2=accesability[key3]
                      arrAcess.push({location:el2.name,occurence:el2.count,name:key2});
                      brcount=brcount +el2.count 
                       
                      //LOop for Location 
                  });
                  Object.keys(capacity).forEach((key3) => {
                    let el2=capacity[key3]
                    arrCapacity.push({location:el2.name,occurence:el2.count,name:key2});
                    brcount=brcount +el2.count 
                    
                  });

                  employeeData.push({name:key2,
                    tag_serial:element[key2].tag_id,
                    team:key,
                    occurence:brcount,
                  });

            
                    
                  });
                }
                
              
              SetTeamData(employeeData);
              SetAcessPrev(arrAcess)
              SetAcessCapacity(arrAcess);
              SetDataCapacity(arrCapacity);
              SetDataCapacityPrev(arrCapacity);  
               
                SetLoader(false)  
              }else{

              }
              })


            }
              
            
        });
      }
  }

  useEffect(()=>{
 
    let arr=[];
    let arrTemp=[];
    let sel = [...selected];
    let LocationCheck=[];

    for (let i=0;i<dataCapacity.length;i++){
      if((isAll || sel.includes(dataCapacity[i].name))  && parseInt(dataCapacity[i].occurence) > 0){
        if(LocationCheck.includes(dataCapacity[i].location)){
          let item  = arr.find(item => item.location === dataCapacity[i].location); 
          let occurence = parseInt(item.occurence) + parseInt(dataCapacity[i].occurence);
          arr.splice(item,1);
          arr.push({
            location:dataCapacity[i].location,
            occurence:occurence 
          })

        }else{
          LocationCheck.push(dataCapacity[i].location);
          arr.push({
            location:dataCapacity[i].location,
            occurence:dataCapacity[i].occurence 
          })
        }

      } 

    }
    SetTempCapacity(arr);
  },[dataCapacity,selected])

  useEffect(()=>{
    let arr=[];
    let arrTemp=[];
    let sel = [...selected];
    let LocationCheck=[];
   
    for (let i=0;i<dataAccess.length;i++){
      if((isAll || sel.includes(dataAccess[i].name))  && parseInt(dataAccess[i].occurence) > 0){

        if(LocationCheck.includes(dataAccess[i].location)){
          let item  = arr.find(item => item.location === dataAccess[i].location); 
          let occurence = parseInt(item.occurence) + parseInt(dataAccess[i].occurence);
          arr.splice(item,1);
          arr.push({
            location:dataAccess[i].location,
            occurence:occurence 
          })
   
        }else{
          LocationCheck.push(dataAccess[i].location);
          arr.push({
            location:dataAccess[i].location,
            occurence:dataAccess[i].occurence 
          })
        }

      } 

    }
    SetTempAcess(arr);
  },[dataAccess,selected])

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleManpowerManagementList() {
    props.history.push("/policy-management");
  }



  function handleDateSelect(date) {
updateSelectedDate(date)
setDates({
  start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
});

  }

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };






  const handleSort = (key) => {
    
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
  }

  

  function showMostCapacityBreach(data) {
    let arr = [];
    let arr2=[];
    let sel= [...selected]
    
    
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if(element.occurence > 0 && (sel.indexOf(element.name) || isAll )){
      arr.push(
            <tr className="employee-detail-table-tr" key={generateKey(index)}>   
                <td style={{width:window.innerWidth>1400?"75%":"60%"}}>{capitalizeFirstLetter(element.location)}</td>              
                <td className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>{element.occurence}</td>

            </tr>
      );

      }


    }
    return arr;
  }

function showTeamMemberData(data){
    let arr = [];
    let arr2=[];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
  
      if(element.occurence > 0){
        arr.push(
          <tr className="employee-detail-table-tr" key={generateKey(index)}  >   
              <td className="employee-detail-table-tr-td" style={{maxWidth:"30px",width:"30px"}}>
              <Checkbox onChange={(e) => onChangeCheckbox(e, element.name)} checked={isAll || selected.includes(element.name)} />
            </td>
              <td>{capitalizeFirstLetter(element.name)}</td>
              <td className="breached-data"><span className="breached-member violated">Breached</span></td>
          </tr>
    );
      }else{
      arr.push(
            <tr className="employee-detail-table-tr disabled-row" key={generateKey(index)}>   
                <td className="employee-detail-table-tr-td" style={{maxWidth:"30px",width:"30px"}}>
                {/* <Checkbox onChange={(e) => onChangeCheckbox(e, element.name)} checked={isAll || selected.includes(element.name)} /> */}
                <Checkbox  />
              </td>
                <td>{capitalizeFirstLetter(element.name)}</td>
                <td className="breached-data"><span className="breached-member non-violated">Not Breached</span></td>
            </tr>
      );

      }


    }
    return arr;
}
useEffect(()=>{
  showMostCapacityBreach(TempCapacity);
  showMostAccessBreach(TempAcess);
},[selected])

function onChangeCheckbox(e, index) {
  let arr = [...selected]
 
  setIsAll(false);
  if(e.target.checked &&selected.includes(index)){
 
    setSelected(arr)
  }else if(e.target.checked){
    arr.push(index)
 
    setSelected(arr)
  }else if(selected.includes(index) && e.target.checked===false){
    let i = arr.indexOf(index)
    arr.splice(i, 1)
 
    setSelected(arr)
  }
  else {
 
      arr.push(index)
      setSelected(arr)
  }
}

  const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

  
  function showMostAccessBreach(data) {
    let arr = [];
    let arr2=[];
    let occurence=[];
    let locName=[];
    let sel= [...selected];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if(element.occurence > 0 && (sel.indexOf(element.name)|| isAll ) ){
 
      arr.push(
<tr className="employee-detail-table-tr" key={generateKey(index)}>   
                <td style={{width:window.innerWidth>1400?"75%":"60%"}}>{capitalizeFirstLetter(element.location)}</td>
                <td className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>{element.occurence}</td>
            </tr>
      );
      }
    }


    return arr;
  }

function handleSortcapacity(){
   
    setSortTypecapacity(sortTypecapacity=='desc'?'asc':'desc');
    
}
function handleSortAcess(){
    setSortTypeaccess(sortTypeaccess=='desc'?'asc':'desc')
}

useEffect(() => {
    let arr=[];
    arr = TempCapacity.sort((a,b) => {
        return  sortTypecapacity=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetDataCapacity(arr); 
    
  }, [sortTypecapacity]);


  useEffect(() => {
    let arr=[];
    arr = TempAcess.sort((a,b) => {
        return  sortTypeaccess=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetAcessCapacity(arr)
  }, [sortTypeaccess]);

  function onChangeAllCheckbox(e, type) {
    // alert(e.target.checked)
    if (e.target.checked) {
        let arr = Array.from({ length: type === 'team' ? dataTeam.length : dataTeam.length }, (v, i) => i);
        setIsAll((prev) => !prev)
        setSelected(arr)
    } else {
        setIsAll(false)
        setSelected([])
    }

}

if(permissionAPICall && permission.length == 0){
  return(<Col lg={12}>
    
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>)

}
  if (capacityBreach || accessBreach) {
 
    return (
      <div className={loader===true?"manpowerManagementMainDiv siteViewMainDiv loading":"manpowerManagementMainDiv siteViewMainDiv"}>
         <div className="loading-div">
              <img src={spinnerLoader}/>
          </div>
        <div className="employeeDetailsMainDiv">
          <Container className="header-breadcrumb">
              
            <Row>
              <Col lg={5} className="m-t-sm">
                <div className="siteViewHeaderDiv">
                  <span
                    className="smallHeader"
                    onClick={handleManpowerManagementList}
                  >
                    {getTranslatedText("Policy Compliance")}
                  </span>
                  <span className="breadCrumbArrow"> &gt; </span>
                  <span className="mediumHeader">
                    {getTranslatedText("Team")}
                  </span>
                </div>
              </Col>

              <Col lg={7} className="text-right">
              
              <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
              </Col>
            </Row>
            <div className="mostInteractedMainDiv mostInteractedMainDiv2 m-t-lg" style={{height:800}}>
            <div className="m-b">
            <Row>
              <Col lg={12}>
                  <div className="compliance-header" style={{ marginBottom: 20 }}>
                  <Row>
                    <Col lg={7}>
                    <h5 className="font-bold">{teamName}</h5>
                    {/* <p>from 13 Nov, 2021 to 20 Nov, 2021</p> */}
                    </Col>
                    <Col lg={1}>
                    <div className="alertDiv" style={{float:"right"}}>
                              <div className="viewAllEmployeesButton disabledBtn">
                                Alert
                              </div>
                            </div>
                   
                      </Col>
                    <Col lg={2} className="p-r-0 p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                          <Col lg={2} className="p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-end"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                             
                            </div>
                          </Col>
                         </Row> 
                  </div>
              </Col>
          </Row>
          <div class="border-outer pad-10">
          <div className="top-title">   <h6>People</h6></div>
                      <Row style={{ margin: 0 }}>
                       <Col className="p-l-0" lg={6}>
                       <Col className="p-l-0 p-r-0 ov-hidden" lg={12}>
                        
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                                <th style={{width:"30px"}}>
                                <Checkbox onChange={(e) => onChangeAllCheckbox(e, 'team')} checked={isAll} />
                                </th>
                              <th >
                                Name
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of Employee 
                                </span>

                              </th>
                            
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 475, minHeight:leftheight }}
                            >

                              {showTeamMemberData(dataTeam)}
                            </Scrollbars>

                            
                          </table>
                        </Col>

                       
                        </Col>

                        <Col className="p-l-0 p-r-0" lg={6}>
                        <Row style={{ margin: 0 }}>
                         <Col className="p-l-0 p-r-0 ov-hidden" lg={12} style={{marginBottom:20}}>   

                           <div className="top-title"> <h6>Capacity breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:window.innerWidth>1400?"75%":"60%"}} >
                                Location
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of Location 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypecapacity === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortcapacity('capacity')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150,minHeight:(leftheight/2)- 85 }}
                            >
                              {showMostCapacityBreach(TempCapacity)}
                            </Scrollbars>

                
                          </table>
                       
                       
                       
                        </Col>

                        <Col className="p-l-0 p-r-0 ov-hidden" lg={12}>
                        <div className="top-title">   <h6>Access breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:window.innerWidth>1400?"75%":"60%"}}>
                                Location
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of Location 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypeaccess === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortAcess('access')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 150,minHeight:(leftheight/2)- 85 }}
                            >
                              {showMostAccessBreach(TempAcess)}
                            </Scrollbars>
                            
                          </table>
                        </Col>
                        </Row>
                                    
                        </Col>
                        </Row>
                      </div>
                    </div>
             </div>
           
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(PolicyComplianceTeam)
);
