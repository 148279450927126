import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Switch } from "antd";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import "../../../../siteManagement/styles/siteManagement.scss";
import "../../../../manPowerManagement/style/manpowerManagement.scss"
// import spinnerLoader from "../../../../assets/images/loader.svg";
import CommonDatePicker from "../../../../common/commonDatePicker";
import { getTranslatedText } from "../../../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../../../dashboard/actionMethods/actionMethods";
import { prepareDateObj } from "../../../../dashboard/components/helper";

import downArrowFill from "../../../../assets/images/down-arrowFill.png";
import DashboardLanguage from "../../../../components/dashboardLanguage";
import batteryIcon from "../../../../assets/traceplusImages/battery.svg";
import emailIcon from "../../../../assets/traceplusImages/employee_email_icon.svg";
import dropdownIcon from "../../../../assets/images/down-arrow.png";
import selectedPinkArrowIcon from "../../../../assets/traceplusImages/pink_right_arrow_icon.svg";
import empIDIcon from "../../../../assets/traceplusImages/employee_id_icon.svg";
import helpIcon from "../../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../../assets/traceplusImages/sort.png'
import upIcon from '../../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../../assets/traceplusImages/down-arrow.png'
import '../../../styles/policyCompliance.scss'
import Modal from 'react-modal'
import {getPolicyOverview} from '../../../actionMethods/actionMethods';
import spinnerLoader from "../../../../assets/images/loader.svg"
import DeviceStatus from "../../../../components/DeviceStatus";
import {ReactComponent as Image404 } from  '../../../../assets/images/11132-ai.svg'

function PolicyCompliancePeople(props) {
  let date = localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date();
  let policy_start_date=localStorage.getItem('policy_start_date')?new Date(moment(localStorage.getItem('policy_start_date')).format('YYYY-MM-DD 23:59:59')):new Date(moment(date).format('YYYY-MM-DD 23:59:59'))
  let policy_end_date=localStorage.getItem('policy_end_date')?new Date(moment(localStorage.getItem('policy_end_date')).format('YYYY-MM-DD 23:59:59')):new Date(moment(date).format('YYYY-MM-DD 23:59:59'))
  let interval = 5;
  let idVal = props.match.params.id.replace(":", "");

  const [employeeDetails, updateEmployeeDetails] = useState("");
  const [prevEmployeeDetails, updatePrevEmployeeDetails] = useState("");
  const [loader,SetLoader]=useState(true);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [infectedFlag, updateInfectedFlag] = useState(false);

  const [employeeIndexData, updateEmployeeIndexData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [prevemployeeData, setprevEmployeeData] = useState("");
  const [employeeHistoryData, setEmployeeHistoryData] = useState('');

  const [selectedDate, updateSelectedDate] = useState(date);
  
  const [dates, setDates] = useState({
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [chartData, setChartData] = useState({ series: [], categories: [] });
 // const [testedPositiveDate, updateTestedPositiveDate] = useState(null);

    const [sortKey, setSortKey] = useState('access')
    const [sortType, setSortType] = useState('desc')

    const [testedPositiveDate, updateTestedPositiveDate] = useState(date);
    const [chartLoader, setChartLoader] = useState(true);

    const[dataCapacity,SetDataCapacity]=useState([]);
    const[dataAccess,SetAcessCapacity]=useState([]);
  
    const [sortKeyaccess, setSortKeyaccess] = useState('access')
    const [sortTypeaccess, setSortTypeaccess] = useState('desc')
  
    const [sortKeycapacity, setSortcapacity] = useState('capacity')
    const [sortTypecapacity, setSortTypecapacity] = useState('desc')

    const[tabData,setTabData]=useState([]);
    const [tabPeopleData,SetTabPeopleData]=useState([]);
    const [isAll, setIsAll] = useState(true)
    const [selected, setSelected] = useState([])
    const [capacityBreach,setCapacityBreach]=useState([]);
    const[accessBreach,setAccesBreach]=useState([]);
    const [personName,setPersonName]=useState([]);
    const[teamName,SetTeamName]=useState([]);

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  let Height= window.innerHeight - 100;
  const initialHeight = Height >810?Height:810; 
  const leftheight = initialHeight - 460;

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const EMPDATA =[
      {
          name:"Bruce Banner",
          team:"Gamma Team Resarch",
          startdate:"13-11-2021",
          enddate:"15-11-2021",
      }
  ]

 

useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.sub_page_name=="Policy Compliance People"); 
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }


useEffect(() => {
  if(permission && permission.length > 0){

    DateChangeHandler()
  }
}, [dates,permission]);


  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }


  const handleDate = (date, type) => {
    setDates((prev) => ({ ...prev, [`${type}`]: date }));
  };

  

  function DateChangeHandler(){
    if(permission && permission.length > 0){

    let requestBody=[]
    requestBody.start_date=getDateFormat(dates.start);
    requestBody.end_date=getDateFormat(dates.end);
    let arrCapacity=[];
    let arrAcess=[];
    SetLoader(true);
        getPolicyOverview(requestBody,userSession, org_id,'employee').then(res => {
            if (res.status === 200) {
                let data= res.data;
              Object.keys(data).forEach((key) => {
                if(key==idVal){
                setPersonName(key);
                
                Object.keys(data[key].accesability.location).forEach((key2) => {
                    let el2=data[key].accesability.location;
                    arrAcess.push({location:el2[key2].name,occurence:el2[key2].count});  
                });
                Object.keys(data[key].capacity.location).forEach((key2) => {
                  let el2=data[key].capacity.location;
                   arrCapacity.push({location:el2[key2].name,occurence:el2[key2].count});
              });
                SetAcessCapacity(arrAcess);
                SetDataCapacity(arrCapacity);             
                SetLoader(false)  
              }else{
              }
              })


            }
              
            
        });
      }
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleManpowerManagementList() {
    props.history.push("/policy-management");
  }



  function handleDateSelect(date) {
    updateSelectedDate(date)
    setDates({
      start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
      end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
  }



  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };




  const handleSort = (key) => {
    
    setSortKey(key)
    setSortType(sortType === 'desc' ? 'asc' : 'desc')
  }

  

  function showMostCapacityBreach(data) {
    let arr = [];
    let arr2=[];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if(element.occurence > 0){

      arr.push(
            <tr className="employee-detail-table-tr" key={generateKey(index)}>   
                <td style={{width:window.innerWidth>1400?"75%":"60%"}}>{capitalizeFirstLetter(element.location)}</td>
                <td className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>{element.occurence}</td>
            </tr>
      );
      }



    }
    return arr;
  }

  const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

  
  function showMostAccessBreach(data) {
    let arr = [];
    let arr2=[];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if(element.occurence > 0){
    
      arr.push(
<tr className="employee-detail-table-tr" key={generateKey(index)}>   
                <td style={{width:window.innerWidth>1400?"75%":"60%"}}>{capitalizeFirstLetter(element.location)}</td>
                <td className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>{element.occurence}</td>
            </tr>
      );
      }
    }

    return arr;
  }

function handleSortcapacity(){
   
    setSortTypecapacity(sortTypecapacity=='desc'?'asc':'desc');
    
}
function handleSortAcess(){
    setSortTypeaccess(sortTypeaccess=='desc'?'asc':'desc')
}

useEffect(() => {
    let arr=[];
    arr = dataCapacity.sort((a,b) => {
        return  sortTypecapacity=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetDataCapacity(arr); 
  
  }, [sortTypecapacity]);


  useEffect(() => {
    let arr=[];
    arr = dataAccess.sort((a,b) => {
        return  sortTypeaccess=='desc'? b.occurence - a.occurence  : a.occurence - b.occurence
     })
     SetAcessCapacity(arr)
  }, [sortTypeaccess]);

  

  

  if(permissionAPICall && permission.length == 0){
    return(<Col lg={12}>
      
  <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
  </Col>)

  }
  if (capacityBreach || accessBreach) {
 
    return (
      
      <div className={loader===true?"manpowerManagementMainDiv siteViewMainDiv loading":"manpowerManagementMainDiv siteViewMainDiv "}>
            <div className="loading-div">
                  <img src={spinnerLoader}/>
             </div>
             
        <div className="employeeDetailsMainDiv">

          <Container className="header-breadcrumb">
              
            <Row>
              <Col lg={5} className="m-t-sm">
                <div className="siteViewHeaderDiv">
                  <span
                    className="smallHeader"
                    onClick={handleManpowerManagementList}
                  >
                    {getTranslatedText("Policy Compliance")}
                  </span>
                  <span className="breadCrumbArrow"> &gt; </span>
                  <span className="mediumHeader">
                    {getTranslatedText("People")}
                  </span>
                </div>
              </Col>

              <Col lg={7} className="text-right">
              <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
              </Col>
            </Row>
          </Container>
          <Container>  
            <div className="mostInteractedMainDiv mostInteractedMainDiv2 m-t-lg ht-auto" style={{minHeight:"90vh"}}>
            <div className="m-b">
            <Row>
              <Col lg={12}>
                  <div className="compliance-header" style={{ marginBottom: 20 }}>
                   <Row>
                     <Col lg={8}>
                    <h5 className="font-bold">{personName}</h5>
                    {/* <p>Gamma Ray Resarch</p> */}
                    </Col>
                    <Col lg={2} className="p-r-0 p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />
                              {/* <div className="dropdownIconDiv">
                                <img src={dropdownIcon} />
                              </div> */}
                            </div>
                          </Col>
                          <Col lg={2} className="p-l-0">
                            <div
                              className="startDateEndDateMainDiv date-end"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                              />
                             
                            </div>
                          </Col>
                    </Row>
                    {/* <p>from 13 Nov, 2021 to 20 Nov, 2021</p> */}
                  </div>

              </Col>
          </Row>
                      <Row style={{ margin: 0 }}>
                        <Col className="p-l-0" lg={6}>
                           <div className="top-title"> <h6>Capacity breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:window.innerWidth>1400?"75%":"60%"}}>
                                Location
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`interHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`interHelp`)
                                  }
                                />
                                <span className="descHelp" id="interHelp">
                                List of Location 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" style={{width:window.innerWidth>1400?"25%":"40%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypecapacity === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortcapacity('capacity')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 320,minHeight:leftheight }}
                            >
                              {showMostCapacityBreach(dataCapacity)}
                            </Scrollbars>

                            
                          </table>
                          <div className="alertDiv">
                              <div className="viewAllEmployeesButton disabledBtn">
                                Alert
                              </div>
                            </div>
                        </Col>

                        <Col className="p-l-0 p-r-0" lg={6}>
                        <div className="top-title">   <h6>Access breach events</h6></div>
                          <table className="tableBox">
                            <tr className="employee-detail-table-tr">
                              <th style={{width:window.innerWidth>1400?"75%":"60%"}}>
                                Location
                                <img
                                  src={helpIcon}
                                  alt=""
                                  onMouseEnter={() =>
                                    handleMouseEnter(`AccessinterHelp`)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(`AccessinterHelp`)
                                  }
                                />
                                <span className="descHelp" id="AccessinterHelp">
                                List of Location 
                                </span>

                              </th>
                              <th className="employee-detail-table-tr-td occurence-center" width={{width:window.innerWidth>1400?"25%":"40%"}}>
                                Occurence
                                <img
                                  alt=''
                                  className='sorticon'
                                  src={sortTypeaccess === 'asc' ? upIcon : downIcon }
                                  onClick={() => handleSortAcess('access')}
                                />
                              </th>
                            </tr>
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: 320,minHeight:leftheight}}
                            >
                              {showMostAccessBreach(dataAccess)}
                            </Scrollbars>
                          </table>
                          
                          <div className="alertDiv">
                              <div className="viewAllEmployeesButton disabledBtn">
                                Alert
                              </div>
                            </div>

                        </Col>
                        

                      </Row>
                      
                    </div>
             </div>
           
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-t-lg">
        <img src={spinnerLoader} className="m-t-lg" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(PolicyCompliancePeople)
);
