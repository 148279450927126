import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
// import Viewer from '../hooks/Viewer'
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import MapLive from '../../assets/images/maplive.png'

import { Switch } from 'antd';

import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, filter
} from 'ramda'

import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider} from 'antd';
import { Select } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from  '../../assets/images/location.png'

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from '../../realtimeTracker/actionMethods/actionMethods';

import { getPproductivitylist } from "../../productivity/actionMethods/actionMethods";
// import useSmplrJs from '../hooks/useSmplrJs';
// import TextField from '@mui/material/TextField';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';
//import h337 from "heatmap.js";
import h337 from "heatmap.js";


let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];

let layer=[];
let dataPush=[];
const HeatMap = (props) => {
  const { Option } = Select;
  
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  

  const [width, setWidth] = useState(0);
 
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  

  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState('');
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState('');
    
  const [employeeID,SetEmployeeID]=useState(props.match.params && props.match.params.tag?props.match.params.tag:'');
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?[props.match.params.hour]:[]);
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 0;
  let lastReqId = null
  const [slideMax,SetSlideMax]=useState(0);
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  

  const elementRef =useRef(null);
  const heatRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [forward,SetForward]=useState(true);
  const [oldPosition,SetOldPosition]=useState(0);
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const [selectedStartTime,SetSelectedStartTime]=useState([])
  const cordHeatMap =[
  {"x":46,"y":54,"name":"gavril 2"},
  {"x":86,"y":62,"name":"gavril 3"},
  {"x":210,"y":73,"name":"Security"},
  {"x":262,"y":74,"name":"Gate 1-2"},
  {"x":352,"y":63,"name":"Gate 5-6"},
  {"x":422,"y":65,"name":"Gate 7-8"},
  {"x":539,"y":20,"name":"Canteen 1"},
  {"x":616,"y":15,"name":"Prayer Room"},
  {"x":253,"y":142,"name":"Zone 1-1"},
  {"x":333,"y":138,"name":"Zone 2-1"},
  {"x":420,"y":134,"name":"Zone 3-1"},
  {"x":217,"y":197,"name":"3PL OB"},
  {"x":262,"y":219,"name":"Zone 1-2"},
  {"x":348,"y":215,"name":"Zone 2-2"},
  {"x":423,"y":214,"name":"Zone 3-2"},
  {"x":530,"y":150,"name":"MP 1"},
  {"x":530,"y":254,"name":"MP 2"},
  {"x":610,"y":126,"name":"Truck Bay 2"},
  {"x":610,"y":126,"name":"Truck Bay 2"},
  {"x":603,"y":146,"name":"Gate OB 2"},
  {"x":610,"y":243,"name":"Gate OB 3"},
  {"x":618,"y":308,"name":"Truck Bay 1"},
  {"x":371,"y":382,"name":"RTM"},
  {"x":295,"y":362,"name":"Air Linehaul"},
  {"x":230,"y":361,"name":"Tap OB Staging"},
  {"x":198,"y":453,"name":"Toilet"},
  {"x":252,"y":462,"name":"FM Dispatcher"},
  {"x":352,"y":437,"name":"Office 2"},
  {"x":418,"y":433,"name":"Office 1"},
  {"x":534,"y":390,"name":"MultiFunction"},
  {"x":590,"y":444,"name":"Last Mile Enterance"}]
  
  // const h337 = useScript(
  //   "https://pm28k14qlj.codesandbox.io/test-external-script.js"
  // );

  useEffect(() => {
    
    if(elementRef && elementRef.current){
     
      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
    
    
  }, [elementRef,spaceID,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){
      SetDepartmentID(useRegular.department)
    }
  }


}
},[props.match.params,employeeAll,dailyEmpDefault])

// useSmplrJs({ onLoad })

useEffect(() => {
  
  if(heatRef && heatRef.current){
  

  var heatmapInstance = h337.create({
    container: document.querySelector('.App')
  });
  getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
    if(res.status==200){
      var points = [];
      var max = 0;
      var width = 673;
      var height = 481;
      var len = 50;
    

      for(let i =0;i<res.data.location.length;i++){
        let elm =res.data.location[i]
        let val= Math.random()
        max = Math.max(max, val);
        let pos = cordHeatMap[i];
        if(pos){
          var point = {
            x:pos.x ,
            y: pos.y,
            value: val
          };
        points.push(point);

        }

      }
    
      var data = {
        max: max,
        data: points
      };    
      
    
      heatmapInstance.setData(data);
    }
  })


}
},[permissionAPICall,permission])


  const time = [
    {time:"12:00 AM",value:"00:00:00",intVal:0 },
    {time:"01:00 AM",value:"01:00:00",intVal:1},
    {time:"02:00 AM",value:"02:00:00",intVal:2},
    {time:"03:00 AM",value:"03:00:00",intVal:3},
    {time:"04:00 AM",value:"04:00:00",intVal:4},
    {time:"05:00 AM",value:"05:00:00",intVal:5},
    {time:"06:00 AM",value:"06:00:00",intVal:6},
    {time:"07:00 AM",value:"07:00:00",intVal:7},
    {time:"08:00 AM",value:"08:00:00",intVal:8},
    {time:"09:00 AM",value:"09:00:00",intVal:9},
    {time:"10:00 AM",value:"10:00:00",intVal:10},
    {time:"11:00 AM",value:"11:00:00",intVal:11},
    {time:"12:00 PM",value:"12:00:00",intVal:12},
    {time:"01:00 PM",value:"13:00:00",intVal:13},
    {time:"02:00 PM",value:"14:00:00",intVal:14},
    {time:"03:00 PM",value:"15:00:00",intVal:15},
    {time:"04:00 PM",value:"16:00:00",intVal:16},
    {time:"05:00 PM",value:"17:00:00",intVal:17},
    {time:"06:00 PM",value:"18:00:00",intVal:18},
    {time:"07:00 PM",value:"19:00:00",intVal:19},
    {time:"08:00 PM",value:"20:00:00",intVal:20},
    {time:"09:00 PM",value:"21:00:00",intVal:21},
    {time:"10:00 PM",value:"22:00:00",intVal:22},
    {time:"11:00 PM",value:"23:00:00",intVal:23},   
  ]




  useEffect(() => {
    let perm= props.role.geo_map_permissions?JSON.parse(props.role.geo_map_permissions):null;
   
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}
// useEffect(()=>{
  
//   if(permission.employee=="True"){
    
//     getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
//       if(res.status==200){
//         SetSpaceID(res.data.space_id)
//         //adding random planned 
//         let data = res.data.location
//         for(let i =0 ;i<res.data.location.length;i++){

//         }

//         SetLocationCordinate(res.data.location);
//         Setloading(false);

//       }

//     })
//   }
// },[permission])
useEffect(()=>{
 
  SetDepartmentID('');
  SetDeparment([]);
  SetSubDeparment([]);
  SetSubDepartmentID([]);
  if(props.match.params.hour == start_time && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
  }
  
  SetDailyEmpDefault([]);
  SetEmployeeAll([])
  SetDailyEmpDepartment([]);
  SetSlug('')
  if(permission.employee=="True"){
    

    let arr=[];

    let requestBody={};
    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];

    
    getPproductivitylist(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      if(empList.find(item=>item.tag_serial == employeeID )){
        SetSlug('regular_worker');
      }
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      //SetDeparment(dept)
      SetRegularEmpDepartment(dept)
  
      
    })
  
    let requestBody2={};
    requestBody2.startdate=getDateFormat(selectedDate)
    requestBody2.enddate=getDateFormat(selectedDate)
    requestBody2.slug='daily_worker'
    let deptDaily=[]
    let catIndexDaily=0;
    let empListDaily= [];
    getPproductivitylist(requestBody2,userDetails.session,org_id,sub_org_id).then(res=>{
    
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empListDaily.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            deptDaily.push({name:key,sub_department:subdept}) 
          }else{

            deptDaily.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empListDaily.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }
      if(empListDaily.find(item=>item.tag_serial == employeeID )){
        SetSlug('daily_worker');
      }

      SetDailyEmpDefault(empListDaily)
      SetDailyEmpDepartment(deptDaily)
  
      
    })



  }

},[permission,selectedDate])  

useEffect(()=>{


  if(props.match.params.hour == start_time[0] && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
    SetDepartmentID('');
    SetSubDepartmentID('');

  }
  
  let dept = slug=='employee'?regularEmpDepartment:dailyEmpDepartment
  // SetDepartmentID('');
  SetDeparment(dept);
  

},[slug,regularEmpDepartment,dailyEmpDepartment])


useEffect(()=>{
  if(permission.employee=="True"){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    //SetEmployeeID('')
    if(props.match.params.hour == start_time &&  props.match.params.tag==employeeID){
      let subName = slug=='employee'?employeeDefault.filter(item=>item.tag_serial== employeeID)?.sub_department:dailyEmpDefault.filter(item=>item.tag_serial== employeeID)?.sub_department;
      
      SetSubDepartmentID(subName?subName:'')

    }else{
      SetEmployeeID('')
      SetSubDepartmentID('')
   
      
    }
      let sub2= slug=='employee'?department.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) ):dailyEmpDepartment.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) )
      for (let j=0;j<sub2.length;j++){
      subDept = subDept.concat(sub2[j].sub_department);
    }  
  if(subDept.length > 0){
  SetSubDeparment(subDept);  
  }   

  let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id):dailyEmpDefault.filter(item=>item.department== department_id)
  SetEmployeeAll(empList2);
  }
},[permission,department_id])

useEffect(()=>{
  if(permission.employee=="True"){
    let sub=[];  
    let subDept=[];
    //SetEmployeeID('')
    if(props.match.params.hour == start_time & props.match.params.tag==employeeID){

    }else{
      SetEmployeeID('')
    }
    let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) ):dailyEmpDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) )
   
    SetEmployeeAll(empList2,department_id); 
  }
},[permission,sub_department_id])


  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  



  
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  
  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']


  



    


      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
        
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }
    function HandleRange(e){
      if(currentCount> e){
        SetForward(false);
      }else{
        SetForward(true);
      }
      setCount(e);
     
      // SetAutoPlay(false);       
    }
   

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  

  function filterResultHandler(tag=employeeID,stime=start_time,bigTimer=startBigger){



   
    
  }


  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeStartTime(val){

    let dept= shiftBasedDropdown;
  
    if(val.indexOf('all')> -1){
        let value =dept;
        if((val.length -1) == value.length ){
          SetStartTime([])
        }else{
          SetStartTime(value);
        }
    }else{
      SetStartTime(val);
    }
  }

  function getCursorPosition(canvas, event) {
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    
    navigator.clipboard.writeText(JSON.stringify({"x:": x, "y":y,name:""}))
}

// useEffect(()=>{
//   let canvasElem = document.querySelector("canvas");
          
//   canvasElem.addEventListener("mousedown", function(e)
//   {
//     getCursorPosition(canvasElem, e);
//   });

// },[])


  
  return (
  <>

            {/* <div className='heatmap App' id="heatmap" style={{width:"673px",height:"481px"}}>
                       
                        <canvas width="673" height="481"
        style={{backgroundImage:`url(${MapLive})`}}>
    </canvas>
                      </div> */}

    {permissionAPICall?permission.employee=="True"? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              <div className='tabHeaderList active' >
              <CommonHeading title="Employee Tracker"/>
              </div>   
              <div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-management')}>
              <CommonHeading title="Facility Tracker" />
              </div>
              <div className='tabHeaderList ' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val);}}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                disabled={!lagFreeFlag}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>

            <div className='inlineBlockFilter'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>{SetDepartmentID(val); }}
                value={department_id==''?"Select Department":department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''?true:false}
            >
             
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>{SetSubDepartmentID(val); }}
                    disabled={department_id==''?true:false}                   
                    value={sub_department_id==''?"Select Sub Department":sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetEmployeeID(val); }}
                    disabled={department_id=='' || sub_department_id.length == 0?true:false}                   
                    value={employeeID==''?"Select Employee":employeeID}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                    {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item)=>{
                        return <Option value={item.tag_serial} key={"Employee V1"+item.emp_name}>{item.emp_name}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterLabel' style={{width:"150px"}}>
                  <label>Shift</label> <span style={{float:"right"}}>{shiftStarttime} - {ShiftEndtime}</span>

                {/* <Select
                    placeholder="Select Start Time"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>SetStartTime(val)}
                    
                    value={start_time}
                    key="StartTimeV1"
                    dropdownClassName={'smallerDrop'}

                >
                    {
                    time.map((item)=>{
                        return <Option value={item.value} key={"Employee V1"+item.value}>{item.time}</Option>
                    })
                    }
                    
                </Select> */}
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Time"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{onChangeStartTime(val);}}
                    value={start_time}
                    key="EndTimeV1"
                    dropdownClassName={'smallerDrop'}
                    disabled={shiftStarttime==''||ShiftEndtime==''}
                    mode={'multiple'}
                >
                  <Option value='all' key={"All shift Hour v1 All"}>All Shift Hour</Option>
                    {
                    shiftBasedDropdown.map((item)=>{

                        return <Option value={item} key={"Employee V2"+item}>{item+":00"}</Option>
                    })
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={ department_id==''  || start_time.toString()==''  || employeeID==''}>Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>






      <Row className="m-t">
        <Col lg={9}  ref={elementRef}>
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                    <div className='mapLiveStyle' >
                      <div className='heatmap App' id="heatmap" style={{width:"673px",height:"481px"}}>
                        <img src={MapLive} ref={heatRef}/>
                       
                      </div>
                      <div className='mapBox'>
                        {/* <div className='area1'>Demo</div>     */}
                      </div>
                    </div>
                                    







                  </Col>
          <Col lg={3} className="p-l-0 ">
          <div className="legendArea manStyle legendArea2" style={{height:heightMap+50,background:"#fff"}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={enable?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>
                    <Scrollbars style={{height:(heightMap -50)}}>{filterTimeData.map((item,index)=>{
                   
                      return(
                                <div key={"tracker"+item.time+index} className={enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}</div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{moment(item.time).utc().format('hh:mm:ss A')}</div>
                                </div>
                                )
                    })}</Scrollbars>
                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}


export default HeatMap