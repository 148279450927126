import axios from "axios";


let prefixURL = process.env.REACT_APP_URL



export function getCycleCount(sessionID, id,date,slug,filter='') {

    return axios.get(prefixURL + `/get_cycle_count_report?session=${sessionID}&date=${date}&org_id=${id}&slug=${slug}&filter=${filter}`)
        .then(res => res.data).catch(err => err)
}
