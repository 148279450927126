
import React, { useRef, useState,useEffect } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import '../styles/style.scss'
import {ReactComponent as Plus} from  '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import {ReactComponent as Eye} from '../../assets/images/eye.svg'
import Scrollbars from 'react-custom-scrollbars';
import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';
import { getPproductivitylist } from '../../productivity/actionMethods/actionMethods';
import {getCycleCount} from '../actionMethods/actionMethods'
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {  Select   } from 'antd';


const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};

function CycleCount(props) {
  const { Option } = Select;
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [dataCountCycle,SetDataCountCycle]= useState([])
  const [dataCountCycleDefault,SetDataCountCycleDefault]= useState([])

const [location,SetLocation] = useState([]);

const [department,SetDeparment] =useState([]);
const [department_id,SetDepartmentID] =useState([]);

const [sub_department,SetSubDeparment] =useState([]);
const [sub_department_id,SetSubDepartmentID] =useState([]);


const [employee,SetEmployee] =useState([]);
const [permission,SetPermission]=useState({View:"False",Create:"False",edit:"False",remove:"False"});
const [permissionAPICall,SetPermissionAPICall]=useState(false);
  
 const [parentAccord,SetParentAccord]=useState([]);
 const [modalData,SetModalData]=useState([]);
 const [showModal,SetShowModal]=useState(false);
 const [loader,Setloader]=useState(true);
 let hourCheck = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00']
 const headerRow=[
    'Time','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00'
 ]
 const headerRow2Disp=[
  'Time','07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM','02 PM','03 PM','04 PM','05 PM','06 PM','07 PM','08 PM','09 PM','10 PM','11 PM','00 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM'
 ]
 const headerTime= [
  7,8,9,10,11,12,13,
  14,15,16,17,18,19,20,
  21,22,23,0,1,2,3,4,5,6
]

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  const [width,SetWidth] =useState()
  function getRandomItem(arr,no=5) {
    let data=[];
    for(let i=0;i<no;i++){
      const randomIndex = Math.floor(Math.random() * arr.length);
      data.push(arr[randomIndex]) ;

    }
    return data;
}
function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}
  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function HandlerAccordParent(val){
 
    let ActivList = [...parentAccord];
    let index = ActivList.indexOf(val)
  
    if(index !== -1){  
      ActivList.splice(index, 1);
      SetParentAccord(ActivList);
    }else{
      ActivList.push(val);
      SetParentAccord([val]);
    }
  }
useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.page_name=="Cycle Count");
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);


useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);


    for(let i =0;i<department_id.length;i++){
      let sub2= department.filter(item=>item.name.toString().toLowerCase().includes(department_id[i].toLowerCase()) )

      for (let j=0;j<sub2.length;j++){
        subDept = subDept.concat(sub2[j].sub_department);
      }  
    }

  if(subDept.length > 0){
    SetSubDeparment(subDept);  
  }

    
  }
},[permission,department_id])

useEffect(() => {
  if(permission.length > 0){
  let requestBody={}
    requestBody.date= getDateFormat(date);
    let dataformat=[];
    Setloader(true);

    getSiteLocations(requestBody,userSession,org_id,sub_org_id).then(res=>{
      if(res.status==200){
        let loc=  res.data.location_details;
        getCycleCount(userSession,org_id,requestBody.date,'one_hour').then(res2=>{
          if(res2.status == 200){
            let data = res2.data;

            
            for (let i=0; i<loc.length;i++){




              let dataNew = loc[i];

              let allData= data[loc[i].loc_tag]
              let singleCount={}
              singleCount.name= dataNew.name;
              singleCount.data={};
                            
              for(let y=0;y<24;y++){
                singleCount.data[`${y}`]={}
                let hour = y<10?"0"+y+":00":y+":00"
                let plan = allData?allData.plan.find(item =>item.hour == hour):null;
                if(plan){
                  if(hourCheck.indexOf(hour)> -1){
                    if(plan.reported_date == moment(selectedDate).add('days',1).format('YYYY-MM-DD')){
                      singleCount.data[`${y}`].plan= plan.cycle_count  
                    }else{
                      singleCount.data[`${y}`].plan= 0  
                    }
                  }else{
                    singleCount.data[`${y}`].plan= plan.cycle_count
                  }
                  
                }else{
                  singleCount.data[`${y}`].plan= 0
                }
                let actual = allData?allData.actual.find(item =>item.contact_time == hour):null;
                singleCount.data[`${y}`].actual={}
               if(actual){
                if(hourCheck.indexOf(hour)> -1){
                  if(actual.reported_date == moment(selectedDate).add('days',1).format('YYYY-MM-DD')){
                    singleCount.data[`${y}`].actual.actualWorker=actual.cycle_count;
                    singleCount.data[`${y}`].actual.actualWorkerList=actual.nodes;
                  }else{
                    singleCount.data[`${y}`].actual.actualWorker=0;
                    singleCount.data[`${y}`].actual.actualWorkerList=[];    
                  }

                }else{
                  singleCount.data[`${y}`].actual.actualWorker=actual.cycle_count;
                  singleCount.data[`${y}`].actual.actualWorkerList=actual.nodes;
                }

               }else{
                singleCount.data[`${y}`].actual.actualWorker=0;
                singleCount.data[`${y}`].actual.actualWorkerList=[];
               }
               
              }


              dataformat.push(singleCount);



            }
            


          }
          SetDataCountCycle(dataformat);
          SetDataCountCycleDefault(dataformat);
          Setloader(false)

        })


        SetLocation(res.data.location_details);
     
      }
    })


    let arr=[];

    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }
     
      SetEmployee(empList)      
      SetDeparment(dept)
    })

  }

},[permission,selectedDate]);


useEffect(() => {
  
  if(permission.length > 0 && (department_id.length > 0 || sub_department_id.length >0)){
   
  let data =[...dataCountCycleDefault];
  let loc = [...location];
  let deptTag=[];
  let dept = department_id;
  let subdept= sub_department_id;
  if(dept.length == 1 && subdept.length==1 ){
    deptTag = employee.filter((item) => item.department == dept[0] && item.sub_department== subdept[0] ).map((item)=>{ return item.tag_serial});
  }
  else if (dept.length > subdept.length ){
    for(let i=0;i<dept.length;i++){
      let dt= employee.filter((item) => item.department == dept[i]).map((item)=>{ return item.tag_serial});
   
      deptTag.push(...dt);
    }
  }else if(subdept.length > 0) {
    for(let i=0;i<subdept.length;i++){
      let dt= employee.filter((item) => item.department == dept[0] && item.sub_department== subdept[i] ).map((item)=>{ return item.tag_serial});
      deptTag.push(...dt);
    }
  }else{
    deptTag= employee.map((item)=>{ return item.tag_serial});   
  }
  
  let dataformat=[];
  Setloader(true)

  for(let i= 0;i<data.length;i++){
    

    let singleCount = {}
    singleCount.name =data[i].name
    singleCount.data={};
    
    Object.keys(data[i].data).forEach(key=>{
      
      let element = data[i].data[key];

      singleCount.data[`${key}`]={}
      singleCount.data[`${key}`].actual={}
      singleCount.data[`${key}`].plan = element.plan
      let actual = element.actual.actualWorkerList;
      var filteredArray = actual.filter(function(n) {
        return deptTag.indexOf(n) !== -1;
      });

      singleCount.data[`${key}`].actual.actualWorker=filteredArray.length;
      singleCount.data[`${key}`].actual.actualWorkerList=filteredArray;
    })

    dataformat.push(singleCount);
  }

SetDataCountCycle(dataformat);
Setloader(false)

  }
  
},[permission,department_id,sub_department_id]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

function ModalFormatData(hourData,location_id ,hour,timespent,hourFormat){
 
let arr =[];
arr.push(
  <div className='titleArea'> 
   <h4>{location_id.toLowerCase()} {hourFormat} <button className='alignRightFixed' onClick={()=>SetShowModal(false)}>x</button></h4>
  </div>
)

arr.push(AGTable(hourData,timespent))
return arr;
}

function ModalViewBox(location_id,hour,type,hourFormat){


 let data= dataCountCycle.filter(item=>item.name==location_id);
 let arr =[];

 if(hour!==''){

  let hourData=[];
  let allTag =data[0].data[hour].actual.actualWorkerList;
  allTag = allTag.length === 0?[]:allTag;
  for(let i=0;i<allTag.length;i++){
    let emp = employee.find(item=>item.tag_serial == allTag[i]);
    if(emp){
      hourData.push(emp);
    }else{
      let blankArray = {}
      blankArray.emp_name=' - '
      blankArray.department=' - '
      blankArray.sub_department=' - '
      blankArray.tag_serial=allTag[i];
      hourData.push(blankArray);
    }
  }
  let newData= [];
  arr = ModalFormatData(hourData,location_id,hour,false,hourFormat);

 }else{
  
  let emp=[];
 
Object.keys(data[0].data).forEach((key)=>{
  let dataNew = data[0].data[key].actual.actualWorkerList?data[0].data[key].actual.actualWorkerList.map(item=>{
    let result= employee.find(el=>el.tag_serial == item);
    if(result){
      return result;
    }
  }
 ):[]

  for(let i=0;i<dataNew.length;i++){
   
    if(dataNew[i]){    
      let index = emp.findIndex(item=> item.emp_name ==  dataNew[i].emp_name)
      if(index > -1){
        emp[index].timespent=emp[index].timespent + 5;
      }else{
        dataNew[i].timespent = 5;
        emp.push(dataNew[i]);
      }
    }
  }
})
//emp=emp.map( (item,index)=>{item.sno =index+1; return item })

arr = ModalFormatData(emp,location_id,'',false);

 }
  
 
SetModalData(arr);
SetShowModal(true)
}

function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}

function viewIconRenderer(params){
  var eGui = document.createElement('div');
  eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
   return eGui;
}

function ViewBoxModalHour(data,value,location,hour){
  let dataAll = [...dataCountCycle];
  let currentCLick = dataAll.filter(item=>item.name == location);


  if(data.Time=='Actual'){
   
    
   let time =  headerRow2Disp.indexOf(hour);
    
    ModalViewBox(location,headerTime[time-1],'',hour)

  }


}

function AGTable(data,timespent){

  if(data){

    let widthCal= 0;
    
    if( ((window.innerWidth * 80)/100) > 1100){
      widthCal=timespent?((1100 - 320)/4) :((1100- 150)/4) ;
    }else{
      widthCal=timespent?(( (window.innerWidth * 80)/100) - 125)/5:((( (window.innerWidth * 80)/100) - 75)/4) - 10;
    }
  
  
  
  let arr;
    arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={35}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 50,field: 'name',
          }}
          headerHeight={35}
          defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
          //pagination={true}
          rowData={data}
          key={'keyAGTable'}
          onCellClicked={ (event) =>event.data.emp_name?window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank"):window.open(`/productivity/daily-worker/:${event.data.tag_serial}`, "_blank")  }
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
        
      {timespent?
      <AgGridColumn
          field="timespent"
          width={125}
          headerName={"Time spent"}
          valueFormatter={(params)=>params.value+" min"}
          cellClass={'textCapitalize textCap'}
      />:""}
        <AgGridColumn
          field="emp_name"
          headerName={"Name"}
          valueFormatter={(params)=>params.value.toLowerCase()}
          cellClass={'textCapitalize textCap'}
      />
      <AgGridColumn
          field="worker_id"
          headerName={"Emp ID"}
          valueFormatter={(params)=>params.value.toLowerCase()}
          cellClass={'textCapitalize textCap'}
      />
      <AgGridColumn 
          field="department" 
          headerName="Department" 
          valueFormatter={(params) =>params.value.toLowerCase()}    
          cellClass={'textCapitalize textCap'}
         >
      </AgGridColumn> 
       <AgGridColumn
         field="sub_department"
         headerName="Sub Department"
         cellClass={'textCapitalize textCap'}
         valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
      }} ></AgGridColumn> 
      <AgGridColumn
         field="sub_department"
         headerName="View"
         cellClass={'textCapitalize textCap'}
         cellRenderer= {(params)=>viewIconRenderer(params)} 
         width={80}
         >
        </AgGridColumn> 
  </AgGridReact>
  </div></div>
  
  return arr
}else{
  return ;
}

}

function colorDataRenderer(params,op){
  var eGui = document.createElement('div');
    
  
    if(params.value=='Actual'){
        eGui.innerHTML = params.value;
    }
    else if(params.data.Time == 'Actual'){
      if(op[1][params.column.colId] ==0) {
        eGui.innerHTML = '-';
      }   
      else if(op[0][params.column.colId] > op[1][params.column.colId]){
            eGui.innerHTML = '<div class="redFont">'+params.value+'</div>';
          }else{
            eGui.innerHTML = eGui.innerHTML = '<div class="greenFont">'+params.value+'</div>';
          }
            
    }
    else if(params.data.Time == 'Planned'){
      eGui.innerHTML = params.value==0?'-':params.value;
    }
    
    else{     
      eGui.innerHTML = params.value;
    }
    return eGui;
}
function AGTableVertical(data,timespent,key,location){
  var result = Object.keys(data).map((key) => ({label:"",hour:key,planned:data[key].plan,actualWorker:data[key].actual.actualWorker,actualWorkerList:data[key].actual.actualWorkerList}) );

  //let widthCal=timespent?1200/6:(1200/5) - 10;
  let widthCal2 = (window.innerWidth -  ( (13*18)  + (72) ) )/25 ; 
  //let widthCal2 = 10; 
  
  let op = [];
  let header={};
  let planned={};
  let actual ={}
  for(let i=0;i<headerRow2Disp.length;i++){

    header[headerRow2Disp[i]]=headerRow2Disp[i];
 
    
    if(header[headerRow2Disp[i]]== 'Time'&& i==0){
      header[headerRow2Disp[i]]='Hour';
      planned[headerRow2Disp[i]]='Planned'
      actual[headerRow2Disp[i]]='Actual'
    }
    
    if(i>0){
      let hourAct =result.findIndex(item=>parseInt(item.hour) == headerTime[i-1])
      if(hourAct > -1){
         
        planned[headerRow2Disp[i]]=result[hourAct].planned
        actual[headerRow2Disp[i]]=result[hourAct].actualWorker
      }
      
    }

  }
  //op.push(header);
  op.push(planned);
  op.push(actual);



let inner=[];

for(let i=0;i<headerRow2Disp.length;i++){
 
    if(headerRow2Disp[i] == '07 AM'){
      inner.push(<AgGridColumn
        key={location+key}
        field={headerRow2Disp[i]}
        headerName={headerRow2Disp[i]}
        cellClass={'textCapitalize textCap'}
        pinned={i==0?'left':''}
        width={widthCal2>40?widthCal2:40}
        onCellClicked={(params)=>ViewBoxModalHour(params.data,params.value,location,headerRow2Disp[i])}
        headerClass={i==0?'':headerTime[i-1]>=7  ?'OlderDay':'NewerDay' }
        cellRenderer= {(params)=>colorDataRenderer(params,op)}
        headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <span class="tooltipcustomDate" >${moment(selectedDate).format('DD MMM')}</span>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
        </div>`	
      }}
        //cellClassRules={params=>params.data.value}
    />)
    }else if(headerRow2Disp[i] == '00 AM'){
      inner.push(<AgGridColumn
        key={location+key}
        field={headerRow2Disp[i]}
        headerName={headerRow2Disp[i]}
        cellClass={'textCapitalize textCap'}
        pinned={i==0?'left':''}
        width={widthCal2>40?widthCal2:40}
        onCellClicked={(params)=>ViewBoxModalHour(params.data,params.value,location,headerRow2Disp[i])}
        headerClass={i==0?'':headerTime[i-1]>=7  ?'OlderDay':'NewerDay' }
        cellRenderer= {(params)=>colorDataRenderer(params,op)}
        headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <span class="tooltipcustomDate" >${moment(selectedDate).add('days', 1).format('DD MMM')}</span>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
        </div>`	
      }}
        //cellClassRules={params=>params.data.value}
    />)
    }else{
      inner.push(<AgGridColumn
        key={location+key}
        field={headerRow2Disp[i]}
        headerName={headerRow2Disp[i]}
        cellClass={'textCapitalize textCap'}
        pinned={i==0?'left':''}
        width={widthCal2>40?widthCal2:40}
        onCellClicked={(params)=>ViewBoxModalHour(params.data,params.value,location,headerRow2Disp[i])}
        headerClass={i==0?'':headerTime[i-1]>=7  ?'OlderDay':'NewerDay' }
        cellRenderer= {(params)=>colorDataRenderer(params,op)}
       
        //cellClassRules={params=>params.data.value}
    />)
    }
    

}

  let arr;
    arr=<div className={"keyAGTable keyAGTableLessZindex keyAGTableLessSpace"} key={key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((op.length > 0? op.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={35}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 50,field: 'name',
  
          }}
          headerHeight={35}

       defaultColDef={{sortable: false,resizable: true,minWidth:40, width:75 , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
          //pagination={true}
          rowData={op}
          key={'keyAGTable'}
          //onCellClicked={ (event) => window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")  }
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
        {inner}  
  </AgGridReact>
  </div></div>
  
  return arr
  

}




  function  GridExample(data) {
    
    let arr =[];


if(data.length > 0){

    for(let i=0;i<data.length;i++){
      let header = <div className='headerNewFormat'>
                        <Row>
                          <Col lg={10} className="firstrowWithTitle"><span className='icons' onClick={()=>HandlerAccordParent(i)}>{parentAccord.indexOf(i)!=-1?<Minus/>:<Plus></Plus> }</span>{data[i].name}</Col>
                          <div className='secondRow' style={{width:"20%"}}></div>
                          <Col lg={2} className="thirdRows"><span className='modalBtn' onClick={()=>ModalViewBox(data[i].name,'','parent')}><Eye/> View {/*<span className='viewsize'>({data[i].avg_cycle_count})</span>*/}</span></Col>
                        </Row>
                    </div>
                   let contentBodypush =AGTableVertical(data[i].data,'',data[i].name,data[i].name);
                         //hour loop start                         
                         Object.keys(data[i].data).forEach(key=>{
                          
                          let avgPlanned=0;
                          let avgActual=0;

                         })
                         //hour loop end

       let contentBody=<div className='contentAreaBody'>{contentBodypush}</div>               
 
      let oneRecord= <div className={parentAccord.indexOf(i)!=-1?'singleRowList active':'singleRowList'} key={'singleRowList'+i+data[i].name}>{header}<div className='contentBodyObject'>{contentBody}</div></div> ;
      arr.push(oneRecord);


    }

  }else{

  }
  
    return arr;
  };





  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
        
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  function handleCloseModal(){
    SetShowModal(false)
  }

  function onChangeDepartment(val){
    if(val.indexOf('all')> -1){
        let value =[];
        for(let i=0;i<department.length;i++){
          value.push(department[i].name)
        }
        if((val.length -1) == value.length ){
          SetDepartmentID([]);  
        }else{
          SetDepartmentID(value);
        }
  
        
    }else{
      SetDepartmentID(val);
    }
    SetSubDepartmentID([]);
    
    
  }
  function onChangeSubDepartment(val){
    //val=[val] 
    if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<sub_department.length;i++){
        value.push(sub_department[i])
      }
      if((val.length -1) == value.length ){
        SetSubDepartmentID([]);  
      }else{
        SetSubDepartmentID(value);
      }
  
      
  }else{
    SetSubDepartmentID(val);
  }
    
  }

  return (<>
    {permissionAPICall?permission.length > 0?<div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row style={{position:"relative",zIndex:"99"}}>
          <Col lg={6}>
            <CommonHeading title="Cycle Count" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
              />
            </div>
          </Col>
        </Row>
</Container>
<Container>
        <Row className="m-t" style={{position:"relative",zIndex:"9"}}>
          <Col lg={12}>
            <div className='listingRecordMainDiv manpowerManagementEmployeeListMainDiv'  style={{height:loader?"100vh":"auto"}}>
              {loader?<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>:<React.Fragment>
                      <div className='headerFilter'>
                      <Row className="">
            
                
                      <Col lg={2} className="p-l-0 report-filter">
                          <h4>Filter</h4>
                        </Col>              
            <Col lg={3} className="p-r-0  p-l-0 report-filter">
           
            <Select
          placeholder="Select Department"
          optionFilterProp="children"
          onChange={(val)=>onChangeDepartment(val)}
          //defaultValue={department_id}
          value={department_id}
          key="departmentFilterv2"
          style={{width:"100%"}}
          dropdownClassName={'smallerDrop'}
          mode={"multiple"}
      >
        <Option value='all' key={"Department All v2"}>Select All</Option>
          {
          department && department.length > 0? department.map((item)=>{
              return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
          }):""
          }
      </Select>        
            </Col>

            <Col lg={3} className="p-r-0 report-filter">
                <Select
              placeholder="Select Sub Department"
             
              style={{width:"100%"}}
              onChange={(val)=>onChangeSubDepartment(val)}
              //defaultValue={'Select Sub Department'}
              disabled={department_id.length>1 || department_id.length==0 ?true:false}
              value={sub_department_id}
              key="SubdepartmentFilterv2"
              dropdownClassName={'smallerDrop'}
              mode="multiple"
          >
            <Option value='all' key={"Sub Department All"}>SelectAll</Option>
              {
              sub_department && sub_department.length > 0? sub_department.map((item)=>{
                  return <Option value={item} key={"Sub Department"+item}>{item}</Option>
              }):""
              }
          </Select>
</Col>

                        <Col lg={3} className="p-r-0 report-filter">
                        </Col>



          </Row>
                      </div>
                      
                      
                      {dataCountCycle.length > 0? GridExample(dataCountCycle):<div className='noDataError'><h4>Oops No data found</h4></div>}</React.Fragment>} 
            </div>
          </Col>
        </Row>
      </Container>
      <ReactModal
                isOpen={showModal}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>


    </div>:<Col lg={12}>
    {/* <div className='errorPermission'></div> */}
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>}
  </>);
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(CycleCount)
);
