import React from 'react'
import { Row,Col,  Container } from 'react-bootstrap';
import Routes from '../common/routes';

class RightSideComponents extends React.Component{

    render(){
        let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));

       
        return(
            <div className={ ' RightSideDiv' + (isLoggedIn ? ' withLoginRightSideDiv' : ' withoutLoginRightSideDiv') }
            >
                <Routes role={this.props.role} loggedInParent={this.props.loggedInParent} pages={this.props.pages} weekDays={this.props.weekDays} holidayList={this.props.holidayList} workday={this.props.workday} />
            </div>
        )
    }

}

export default RightSideComponents