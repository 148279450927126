import React,{useEffect, useState} from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment';

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function ChartReportOverview(props) {
const [average,SetAverage]=useState(0);
const [percent85,SetPercent85]= useState(0)    
const [percentAverage,SetPercentAverage]= useState(0)    
const [plotLines,SetPLotLines] =useState([]);

    useEffect(()=>{
        
        if(props.breaktime){

        }else{

         let avg =[];
        
        let allVal=[];
        let higherVal=0;
        let allValue =[];

if(props.chartData.mutliAvg){
    let allHour = props.chartData.total.reduce(function(sum, current) {
        return sum + parseFloat(current);
    }, 0)
    let p85=0;
     let dayAvg =allHour/props.chartData.total.filter(item=>item >0).length;
        if(props.chartData.compareFilter1 && props.chartData.compareFilter1 == 'percent'){
            //SetPercent85(85);
        }else{
            p85 =(dayAvg *85)/100
            //SetPercent85(p85);
        }

        let mAVg =[...props.chartData.mutliAvg] ;
        let avgPush={
                    color: 'green',
                    width: 1,
                    value: p85,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: p85.toFixed(2),
                        align: 'left',
                        x: 0
                    }                    
                }
                mAVg.push(avgPush)
                SetPLotLines(mAVg)


}
    
else if(props.chartData.total && props.chartData.total.length>0){

          let higherArray = [...props.chartData.total].sort(function(a,b){
                return parseInt(b)  - parseInt(a)
            })
            if(higherArray.length > 0 && parseInt(higherArray[0]) > higherVal){
                                higherVal=higherArray[0]
            }
        
            
            // let avergaeVal= higherArray.reduce(function(sum, current) {
                     
            //     return sum + parseFloat(current);
            //   }, 0)/higherArray.filter(item=> parseInt(item)> 0) .length

                for (let i=0;i<props.chartData.series.length;i++){
        
                    let result = props.chartData.series[i].data.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0)/props.chartData.series[i].data.filter(item=>item> 0).length;
                allValue.push(result)

        }
        let allHour = props.chartData.total.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0)
if(props.chartData.avg){
    let avergaeVal = props.chartData.avg.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0)/props.chartData.avg.filter(item=>item>0).length

              SetPercentAverage(avergaeVal);
}else{
    
}
              let dayAvg =allHour/props.chartData.total.filter(item=>item >0).length;

            if(props.chartData.compareFilter1 && props.chartData.compareFilter1 == 'percent'){
                SetPercent85(85);
            }else{
                let p85 =(dayAvg *85)/100
                SetPercent85(p85);
            }
    

      
}else{
    
    for (let i=0;i<props.chartData.series.length;i++){
        
           let higherArray = [...props.chartData.series[i].data].sort(function(a,b){
                                return parseInt(b)  - parseInt(a)
                            })
                            if(higherArray.length > 0 && parseInt(higherArray[0]) > higherVal){
                                higherVal=higherArray[0]
                            }
            let result = props.chartData.series[i].data.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0)/props.chartData.series[i].data.filter(item=>item> 0).length;
                allValue.push(result)
             // allVal=allVal+result;
        }
        let avergaeVal= allValue.reduce(function(sum, current) {
                     
                return sum + parseFloat(current);
              }, 0)/allValue.length


if(  props.chartData.compareFilter1 && props.chartData.compareFilter1 == 'percent'){
              SetPercent85(85);
}else{
          let p85 =(higherVal *85)/100
              SetPercent85(p85);
              
}
         
              
         
         
            
              SetPercentAverage(avergaeVal);
                   

}

 

    }

    },[props.chartData])
    

    let config = {
        chart: {
            type: 'column',
            height: props.height?props.height: 400,
            zoomType: 'xy'
            //spacingLeft: 42,
                  },
        exporting: {
            enabled: false
        },

            legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        x: 0,
        y: 0,
        enabled: true,
        useHTML: props.useHTML?props.useHTML:false,
        labelFormatter: function () {
            if(this.options.legend){
                return '<span title="' + this.name + '">' + this.options.legend  + ' '+this.name+'</span>';
            }else{
                return '<span title="' + this.name + '">'+this.name+'</span>';
            }
            
        }
    
    },
    
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            shared: true
        },
        xAxis: {
            categories: props.chartData.categories || [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 10);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
            labels: {
                enabled: true,
                useHTML: true,
                formatter:  function () {

                    return `<b>${this.value}</b>`
                }
            },
            events: {
                load: function (e) {
                    
                //this.series[0].data[index].setState('hover');
                }
            },
          //  gridLineDashStyle: 'ShortDash',
           // gridLineWidth: 1,
            // max: (start + 3 * move),
            // min: (start + 2 * move)
        },
        yAxis: props.chartData.yAxis?props.chartData.yAxis:{
            title: {
                text: " "
            },
            gridLineDashStyle: 'ShortDash',
            gridLineWidth: 0,
            min: 0,
            labels: {
                enabled: true,
            },
            labels: {
                useHTML:true,
                formatter: function () {
                    if(props.compareFilter1=='percent'){
                        return this.value+"%"   
                    }else if(props.compareFilter1=='hour'){
                        return this.value+"Hrs"   
                    }else if(props.compareFilter1=='dollar'){
                        return "$"+this.value   
                    }else{
                        return this.value   
                    }
                     
                }
            },
            tickAmount:6,
              plotLines: plotLines.length> 0?plotLines:[
                {
                    color: 'green',
                    width: 1,
                    value: percent85,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: percent85.toFixed(2),
                        align: 'left',
                        x: 0
                    }

                    
                },
                {
                    color: 'brown',
                    width: 1,
                    value: percentAverage,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: percentAverage.toFixed(2),
                        align: 'left',
                        x: 0
                    }
                }],
            
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function () {
                              //  alert('Category: ' + this.category + ', value: ' + this.y);
                            }
                        }
                    }
                }
            },                    
        },

        series:props.chartData.series || []
    }
    

    return (
            <div>
                <ReactHighcharts isPureConfig={true}  config={config}  key= {props.key?props.key+"Graph":'reportv1Model'}/>
            </div>
        )
}


export default ChartReportOverview;