
import React, { useRef, useState,useEffect } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import '../../cycleCount/styles/style.scss'
import {ReactComponent as Plus} from  '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import {ReactComponent as Eye} from '../../assets/images/eye.svg'
import Scrollbars from 'react-custom-scrollbars';
//import { getSiteLocations } from '../../siteManagement/actionMethods/actionMethods';

import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import {getCycleCount} from '../../cycleCount/actionMethods/actionMethods'
import ExcelJs from "exceljs";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ChartReport from './ChartReport';
import { getBreakTimeReport } from '../actionMethods/actionMethods';
import { type } from 'ramda';


const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


function BreakTimeAnalysis(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [dataCountCycle,SetDataCountCycle]= useState([])

 const [location,SetLocation] = useState([]);
 
 const [loader,SetLoader]=useState(true);

  const [employee,SetEmployee] =useState([]);
  const [employeeAll,SetEmployeeDefault] =useState([]);
  const [dateList,SetDateList] =useState([]);
  const [permission,SetPermission]=useState({View:"False",Create:"False",edit:"False",remove:"False"});
  const [permissionAPICall,SetPermissionAPICall]=useState(false);
  
 const [parentAccord,SetParentAccord]=useState([]);
 const [modalData,SetModalData]=useState([]);
 const [showModal,SetShowModal]=useState(false);

 //const [dataAll,SetDataAll] =useState([]);
 const [agDataHour,SetAgDataHour]=useState([]);
 const [widthContainer, setWidthContainer] = useState(0);
 const elementRef = useRef(null);  
 const [chartData,SetChartData] = useState({series:[],categories:[]})
 const [chartActive,SetChartActive] = useState(false);
 const [activeChart,SetActiveChart]= useState('');
 const [activeDate,SetActiveDate]= useState('');
 const [chart1,SetChart1]= useState(false);
 const [chartLoader,SetChartLoader]= useState(false);
 const [maxhour,SetMaxHour] =useState(0);

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  let hourCheck = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00']
  
  const headerRow=[
    '07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00'
  ]

  const headerRow2Disp=[
    '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM','02 PM','03 PM','04 PM','05 PM','06 PM','07 PM','08 PM','09 PM','10 PM','11 PM','00 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM'
  ]
  
  const headerTime= [
    7,8,9,10,11,12,13,
    14,15,16,17,18,19,20,
    21,22,23,0,1,2,3,4,5,6
  ]
  const dummyVal = ['n','n','n','n','n','n','y/20min','y/10min','y/30min','y/40min','n','n']

  
  useEffect(() => {

    if(permission && permissionAPICall && elementRef && elementRef.current ){

      setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }
    
   
  }, [elementRef,permission]);

  function getRandomItem(arr,no=5) {
    let data=[];
    for(let i=0;i<no;i++){
      const randomIndex = Math.floor(Math.random() * arr.length);
      data.push(arr[randomIndex]) ;

    }
    return data;
}
function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

const [columnDefs, setColumnDefs] = useState([]);

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    let perm= props.role.report?props.role.report:null;
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{

    }
},[props.pages]);


useEffect(()=>{
if(props.exportEnableCycle){
  exportToExcel();
}
},[props.exportEnableCycle])

function numToSSColumnLetter(num) {
  let columnLetter = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    columnLetter = String.fromCharCode(65 + t) + columnLetter;
    num = (num - t) / 26 | 0;
  }
  return columnLetter || undefined;
}
function numberToLetters(num) {
  let letters = ''
  while (num >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
      num = Math.floor(num / 26) - 1
  }
  return letters
}
const exportToExcel = () => {
  let sheetName = "BreakTimeAnalysis";
  let headerName= "Report"
  const filename = "BreakTimeAnalysis"+"-"+moment(props.start_date).format("MMM DD")+".xlsx";
  const workbook = new ExcelJs.Workbook();
  let loc = [];
  

  let worksheet = workbook.addWorksheet('Break Count');
  let cols =[
    {name:'Date', header: 'Date', key: 'date'},
    {name:'Name', header: 'Name', key: 'emp_name'},
    {name:'Tag Serial', header: 'Tag Serial', key: 'tag_serial'},
    {name:'Agency Name', header: 'Agency Name', key: 'agency_name'},
    {name:'Department', header: 'Department', key: 'department'},
    {name:'Sub Department', header: 'Sub Department', key: 'sub_department'},
    {name:'Worker ID', header: 'Worker ID', key: 'worker_id'},
    {name:'Shift', header: 'Shift', key: 'shift'},
  ];

  for(let i=1;i<=maxhour;i++){

    cols.push({name:'Hour '+i, header: 'Hour '+i, key: 'hour_'+i})
  }


  worksheet.addTable({
    name: sheetName,
    ref: "A1", 
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
      width: 200
    },
    columns : cols,
    rows: employee.filter(item=>props.agency.indexOf(item.agency_name)> -1).map((e) => {
      let arr =[];
      arr.push(e.date)
      arr.push(e.emp_name)
      arr.push(e.tag_serial)
      arr.push(e.agency_name)
      arr.push(e.department)
      arr.push(e.sub_department)
      arr.push(e.worker_id)
      arr.push(e.shift)

      for(let i=1;i<=maxhour;i++){
        arr.push(e['hour_'+i]?e['hour_'+i]=='Yes'?e['hour_'+i]:'-':'-');
      }
       return arr;
    })
  })

  worksheet.state = 'visible';
  const table = worksheet.getTable(sheetName);
  // for (let i = 0; i < table.table.columns.length; i++) {
  //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
  //     worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "d0cfcf" }
  //   };


  // }
  worksheet.columns.forEach(column => {
    const lengths = column.values.map(v => v.toString().length);
    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
    column.width = maxLength+2;
  });
  
  table.commit();
  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;"
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };
//const stream = fs.createWriteStream(filename);

workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(filename, buffer);
});




  

  props.exportProcessExist();



};


useEffect(() => {

},[props.exportEnableCycle])


useEffect(() => {
  if(props.filterEnableCycle){

  let requestBody={}
  let deptSelected= [...props.department_id];
  let subdeptSelected= props.sub_department_id;
  let removeDept =props.employee.filter(item=> subdeptSelected.indexOf(item.sub_department) > -1 ).map(el=>el.department);
  for(let i=0;i<removeDept.length;i++ ){
    let index = deptSelected.indexOf(removeDept[i])
    if(index > -1 ){
      deptSelected.splice(index, 1); 
    }


  }


  let emp = props.employee.filter(item=> (deptSelected.indexOf(item.department) > -1 || subdeptSelected.indexOf(item.sub_department) > -1)  );
  
  emp = emp.filter(item=>props.agency.indexOf(item.agency_name) > -1)

  requestBody.start_date=getDateFormat(props.start_date);
  requestBody.end_date=getDateFormat(props.end_date);
  requestBody.treshold=props.cycleFilter;
  requestBody.slug=props.type;

let arr =[];  
let dateAr=[];
getBreakTimeReport(requestBody,userSession,org_id,sub_org_id).then(res=>{
    SetLoader(false);
    let maxHourVal =0;

  if(res.status== 200){
        let element = res.data;
     
 
        Object.keys(element).forEach((key) => {

       dateAr.push(key);

        Object.keys(element[key]).forEach((key2) => {

          let singleDateArray={};
          let empdetail= emp.find(item=>item.tag_serial == key2);
        
          
          if(empdetail){
          
            let empSpecific = element[key][key2];
            // object for hour 
            let hourVal=[]
            singleDateArray.date= key;
            singleDateArray.emp_name= empdetail.emp_name;
            singleDateArray.tag_serial= key2;
            singleDateArray.department= empdetail.department;
            singleDateArray.sub_department= empdetail.sub_department;
            
            singleDateArray.worker_id= empdetail.worker_id;
            singleDateArray.agency_name= empdetail.agency_name;

            Object.keys(empSpecific).forEach((key3) => {
              
              if(key3!=='shift_end_time' && key3!=='shift_start_time'){
                
                let hour=1; 
                hourVal.push(...Object.keys(empSpecific[key3]).map((key4) =>empSpecific[key3][key4])) ;
                
                
              }else{

                var local = moment.utc(empSpecific['shift_start_time']).format('HH:mm');

                var local2 = moment.utc(empSpecific['shift_end_time']).format('HH:mm');
                

                
                singleDateArray.shift= local+" - "+local2;  
              }
              if(hourVal.length>maxHourVal){
                maxHourVal= hourVal.length;
              }
            });
            
            
            for(let i= 0 ;i<24;i++ ){
              singleDateArray['hour_'+(i+1)] = hourVal[i]?hourVal[i]:"No";
            }
            arr.push(singleDateArray);
  
          }
          
        });    
    });

      
    SetMaxHour(maxHourVal)
    SetDateList(dateAr);
    let empList=[];  
    SetEmployee(arr)
    SetEmployeeDefault(arr);
    SetLoader(false);
    let categories=[];
    let color = ['#ef5e8c','#32325d','#ecf2f9']
    let series=[]

    for(let i=1;i<=maxHourVal;i++){
      categories.push('Hour '+i);

      series.push({y:arr.filter(item=>item['hour_'+i].includes('Yes')).length * 100 / arr.length , name: 'Break Time', color: '#dedee3',hour:i});  
    }
    let sort =  series.sort(function(a, b){
      return b.y - a.y
    }) 
     
     if(series.length> 0){series[0].color="#90cf4e"}
     if(series.length> 2){series[1].color="#a7d872"}
     if(series.length> 3){series[2].color="#ace084"}  
     series.sort(function(a, b){
      return a.hour - b.hour
   })

    SetChartData({series:series,categories:categories});

  }
});
//here code end



  }
},[props.filterEnableCycle,props.inc]);


useEffect(()=>{
  let activeChartData = []
  setTimeout(function(){
    SetChartLoader(false);
  },500)
  let categories = [];


 
 let empList= employee.filter(item=>item.department.includes(activeChart) ||item.sub_department.includes(activeChart) )
 let series=[];

 for(let i=1;i<=maxhour;i++){   
   series.push({ y:empList.filter(item=>item['hour_'+i].includes('Yes')).length * 100 / empList.length , name: 'Break Time', color: '#dedee3',hour:i,agency_name:i.agency_name });
   categories.push('Hour '+i);
 }

  let sort =  series.sort(function(a, b){
    return b.y - a.y
  })

  if(series.length> 0){series[0].color="#90cf4e"}
  if(series.length> 2){series[1].color="#a7d872"}
  if(series.length> 3){series[2].color="#ace084"}  
  series.sort(function(a, b){
     return a.hour - b.hour
  })
  SetChartData({series:series,categories:categories});

},[activeChart,employee])

useEffect(()=>{
  let emp= [...employeeAll]
  let filter = emp.filter(item=>item.date.includes(activeDate) )

  SetEmployee(filter);
},[activeDate])
function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}


function slugify(string) {  
  return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
}


function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}

  //params.data,params.value,location


  


    function AGTable(data){

      if(data){

        let widthCal= 0;
let inner=[];
let widthCal2 = (window.innerWidth -  300)/(7+maxhour) ;
inner.push( <AgGridColumn
  field="date"
  headerName={"Date"}
  valueFormatter={(params)=>params.value?params.value.toLowerCase():''}
  cellClass={'textCapitalize textCap'}
   width={widthCal2}
/>
)

inner.push( <AgGridColumn
    field="emp_name"
    headerName={"Emp Name"}
    valueFormatter={(params)=>params.value?params.value.toLowerCase():''}
    cellClass={'textCapitalize textCap'}
     width={widthCal2}
/>
)
inner.push( <AgGridColumn
  field="worker_id"
  headerName={"Emp ID"}
  valueFormatter={(params)=>params.value}
  cellClass={'textCapitalize textCap'}
  width={widthCal2}
/>
)
    inner.push( 
      <AgGridColumn
        field="agency_name"
        headerName={"Agency Name"}
        cellClass={'textCapitalize textCap'}
      />
     )
inner.push( <AgGridColumn
  field="department"
  headerName={"Department"}
  valueFormatter={(params)=>params.value?params.value.toLowerCase():''}
    cellClass={'textCapitalize textCap'}
  width={widthCal2}
/>
)
inner.push( <AgGridColumn
  field="sub_department"
  headerName={"Sub Department"}
  valueFormatter={(params)=>params.value?params.value.toLowerCase():''}
  cellClass={'textCapitalize textCap'}
  
  width={widthCal2}
/>
)
inner.push( <AgGridColumn
  field="shift"
  headerName={"Shift Hour"}
  valueFormatter={(params)=>params.value?params.value.toLowerCase():''}
  cellClass={'textCapitalize textCap'}
  
  width={widthCal2}
/>
)


for(let i=1;i<=maxhour;i++){
      inner.push(<AgGridColumn

        key={"Working_hour_"+headerRow2Disp[i]}
        field={"hour_"+i}
        headerName={"H"+i}
        width={widthCal2}
        valueFormatter={(params)=>params.value=="No"?"-":params.value}
    />)
}
      let arr;
        arr=<div className={"keyAGTable keyAGTableLessZindex keyAGTableLessSpace"}><div className="ag-theme-alpine if cell-size-40" style={{height: 120 + ((data.length > 0? data.length:1)*36),maxHeight:900 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 50,field: 'emp_name',
              }}
              headerHeight={35}
              pagination={true}
              defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              groupDisplayType={'groupCols'}  
              animateRows={false}    
              rowGroupPanelShow={'always'}
              columnGroupPanelShow={'always'}
              paginationAutoPageSize={true}
              scrollbarWidth={10}
              rowData={data}
              key={'keyAGTable'}
              onCellClicked={ (event) => window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")  }
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
              }        
           >
            {inner}
      </AgGridReact>
      </div></div>
      
      return arr
    }else{
      return ;
    }
    
    }
  

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
        
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }
  function handleCloseModal(){
    SetShowModal(false)
  }

  useEffect(()=>{

    // exportEnableDisable={exportEnableDisable}
    // filterEnableDisable={filterEnableDisable}
    // min={filterSlide[0]}
    // max={filterSlide[1]}
    // date={dates}
    // department_id= {department_id}
    // sub_department_id= {sub_department_id}
    // filterEnableCycle ={filterEnableCycle}
    // exportEnableCycle = {exportEnableCycle}


  },[props])

  return (<>
    {permissionAPICall?permission=="True"?<div className="manpowerManagementMainDiv" >
      <Container className="header-breadcrumb">
       <Row className="m-t" style={{position:"relative",zIndex:9}}>
          <Col lg={12} ref={elementRef}>
            <div className=''>
              <>{
                      <div className=''>
                      
                    

                       {props.filterEnableCycle?<>
                       
                        {loader?<Col lg={12} md={12} sm={12} xs={12} className="" style={{textAlign:"center"}}>
                        
                        <img src={spinnerLoader} />
                    </Col>:""}



                    {loader?"":<><div className="reportChart m-b">
                                  <div className="accordionHeader" onClick={()=>{SetChart1(!chart1);SetChartLoader(false); }}>                                      
                                      {chart1?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Hour</span>
                                 </div>
                                 
                                 {chart1?<div className="accordionContentReport">
                                        <div className="buttonFilterArea">
                                    <button className={activeChart==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>{SetActiveChart('');SetChartLoader(true) }}>All</button>
                                    { props.department_id.length > 0?props.department_id.map((item) =>{
                                      return <button className={activeChart==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=> {SetActiveChart(item);SetChartLoader(true) }}>{item} </button>
                                    }):props.sub_department? props.sub_department.map(item=>{
                                      return <button className={activeChart==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>{SetActiveChart(item);SetChartLoader(true) }}>{item} </button>
                                    }):""} 
                                    </div>
                              {chartLoader?
<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                   <div style={{minHeight:"300px"}}><ChartReport chartData={chartData} close={()=>SetChart1(false)} legendEnable={true} key={"reportV1"} breaktime={true} /></div>
                                  }</div>:""}
                              
                               
                               
                      </div>
                      <div className="buttonFilterArea buttonFilterArea2">
                                    <button className={activeDate==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>{SetActiveDate(''); }}>All</button>
                                    { dateList.map((item) =>{
                                      return <button className={activeDate==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=> {SetActiveDate(item); }}>{item}</button>
                                    })} 
                                    </div>
            
                       {AGTable(employee)}</>
                                  }
                       </>:""}
                       
                       
                       </div>}
                    
                  </>
                    
            </div>
          </Col>
        </Row>
      </Container>
      <ReactModal
                isOpen={showModal}
                style={customStyles}
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}   
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>


    </div>:<Col lg={12}>
    {/* <div className='errorPermission'></div> */}
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>}
  </>);
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(BreakTimeAnalysis)
);
