/* global smplr */
import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  Fragment,memo
} from 'react'
import {
  reject
} from 'ramda'



import PropTypes from 'prop-types'

import useSmplrJs from  './useSmplrJs'


const Viewer = memo(({ onReady, cameraPlacement,changeTab,spaceID,mode,firstLoad }) => {
  useSmplrJs({ onLoad })

  //const INITIAL_MODE = mode?mode:'3d'
  const INITIAL_MODE = mode?mode:'3d'

  
  
  const [points, dispatchPoint] = useReducer((points, action) => {
      
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return points.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:
        console.error(`Unknown action type ${action.type}`)
    }
    
  },[])


  function onLoad () {
    if(smplr){

    
    const space = new smplr.Space({
      spaceId: spaceID,
      spaceToken: 'X',
      containerId: 'smplr-container',
      clientToken: 'pub_4561dd6966994e328d56234e3999422e',
    })
    
    window.space = space

    space.startViewer({
      preview: true,
      mode:INITIAL_MODE,
      allowModeChange : true,
      onReady: () => onReady(space),
      onError: error => console.error('Could not start viewer', error)
    })
  }
  }
  

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '60%'
      }}
      className={"manStyle"}
    >
      <div
        id='smplr-container'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'whitesmoke'
        }}
      />
      
    </div>
  
  )
})

Viewer.propTypes = {
  onReady: PropTypes.func.isRequired,
  cameraPlacement: PropTypes.object
}

export default Viewer